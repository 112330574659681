import Button from '../../components/common/Buttons';
import IcSearch from "../../assets/images/icon/system/ic_search.svg";
import IcSymbol from "../../assets/images/symbol/symbol.png";
import EventItem from "../../components/calendar/EventItem";
import './search.css';
import Typography from '../../components/common/Typography';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { setLayout, setPage } from '../../store/layout';
import { useLocation, useNavigate } from 'react-router-dom';
import { searchEvent } from '../../features/event/services/eventService';
import { eventType } from '../../features/event/stores/eventType';
import { searchRoutine } from '../../features/routine/services/insertRoutineService';
import Swal from 'sweetalert2';
import { RoutineDetailProps } from '../../features/routine/types/routineInsertType';
import Chips from '../../components/common/Chips';
const Search = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();

    const [searchText, setSearchText] = useState('');
    const [searchResults, setSearchResults] = useState<{ events: eventType[], routines: RoutineDetailProps[] }>({ events: [], routines: [] });
    const [showRecentSearches, setShowRecentSearches] = useState(false);
    const [recentSearches, setRecentSearches] = useState<{ keyword: string, date: string }[]>([]);
    const [showNoSearchResults, setShowNoSearchResults] = useState(false);
    const [showInitialMessage, setShowInitialMessage] = useState(false);
    const [selectedRecentSearch, setSelectedRecentSearch] = useState<string>('');

    useEffect(() => {
        dispatch(setLayout('historyBack'));
        dispatch(setPage('검색'));
    }, [dispatch]);

    useEffect(() => {
        const params = new URLSearchParams(location.search);
        const searchTextFromUrl = params.get('keyword') || '';
        setSearchText(searchTextFromUrl);
        setSelectedRecentSearch(searchTextFromUrl);
    }, [location]);

    useEffect(() => {
        const storedRecentSearches = JSON.parse(localStorage.getItem('recentSearches') || '[]');
        setRecentSearches(storedRecentSearches);
        if (searchText === '') {
            setShowInitialMessage(true);
        }
    }, []);

    const handleRecentSearchClick = (recentSearch: string) => {
        setSelectedRecentSearch(recentSearch);
        setShowRecentSearches(false);
        setSearchText(recentSearch);
    };

    useEffect(() => {
        if (selectedRecentSearch) {
            handleSearch();
        }
    }, [selectedRecentSearch]);

    const handleClearRecentSearches = () => {
        localStorage.removeItem('recentSearches');
        setRecentSearches([]);
    };

    const handleDeleteRecentSearch = (search: { keyword: string, date: string }) => {
        const updatedRecentSearches = recentSearches.filter(item => item !== search);
        setRecentSearches(updatedRecentSearches);
        localStorage.setItem('recentSearches', JSON.stringify(updatedRecentSearches));
    };

    const handleSearch = async () => {
        if (searchText.trim() === '') {
            Swal.fire({
                text: '검색어를 입력해주세요',
                confirmButtonText: '돌아가기',
            });
            return;
        } else {
            setShowInitialMessage(false);
            try {
                setShowRecentSearches(false);
                const eventData = await searchEvent(searchText);
                const routineData = await searchRoutine(searchText);
                setSearchResults({ events: eventData, routines: routineData });

                const currentDate = new Date();
                const searchDate = `${currentDate.getFullYear()}-${currentDate.getMonth() + 1}-${currentDate.getDate()}`;
                const recentSearch = { keyword: searchText, date: searchDate };

                let updatedRecentSearches = JSON.parse(localStorage.getItem('recentSearches') || '[]');

                updatedRecentSearches = updatedRecentSearches.filter((item: { keyword: string; }) => item.keyword !== searchText);
                updatedRecentSearches.unshift(recentSearch);
                updatedRecentSearches = updatedRecentSearches.slice(0, 5);
    
                localStorage.setItem('recentSearches', JSON.stringify(updatedRecentSearches));
                setRecentSearches(updatedRecentSearches);
    
                const params = new URLSearchParams();
                params.set('keyword', searchText);
                navigate(`?${params.toString()}`, { replace: true });
    
                if (eventData.length === 0 && routineData.length === 0) {
                    setShowNoSearchResults(true);
                } else {
                    setShowNoSearchResults(false);
                }

            } catch (error) {
                console.error('검색어 조회에 실패하였습니다.', error);
            }
        }
    };

    const formatDate = (dateString: string): string => {
        const date = new Date(dateString);
        const year = date.getFullYear();
        const month = date.getMonth() + 1;
        const day = date.getDate();

        const formattedMonth = month < 10 ? `0${month}` : `${month}`;
        const formattedDay = day < 10 ? `0${day}` : `${day}`;

        return `${year}년 ${formattedMonth}월 ${formattedDay}일`;
    };

    const formatSearchDate = (dateString: string | number | Date) => {
        const date = new Date(dateString);
        const month = date.getMonth() + 1;
        const day = date.getDate();
        return `${month}월 ${day}일`;
    };

    const handleKeyPress = (e: { key: string; }) => {
        if (e.key === 'Enter') {
            handleSearch();
        }
    };

    return (
        <>
            <div className="search-wp grid-20">
                <div className='search-top mb-28'>
                    <div className="search-container">
                        <input 
                            type="text" 
                            name="" 
                            id="" 
                            placeholder='검색어를 입력하세요' 
                            value={searchText}
                            onClick={() => setShowRecentSearches(true)} 
                            onChange={(e) => setSearchText(e.target.value)}
                            onKeyDown={handleKeyPress} 
                            autoFocus 
                        />
                    </div>
                    <Button size='sm' variant='text' onClick={handleSearch}><img src={IcSearch} alt="icon" /></Button>
                </div>
                {/* 최근 검색어 목록 */}
                {/* 
                    최근검색어가 있을 경우에만 보여주기!
                    없을 경우에만 아래 코드에 있는 ai안내카드가 보임!
                 */}
                {showRecentSearches && recentSearches.length > 0 && (
                <div className='search-history'>
                    <div className='history-top'>
                        최근 검색어
                        <Button variant='text' size='sm' color='main' onClick={handleClearRecentSearches}>전체삭제</Button>
                    </div>
                    <ul className='history-list'>
                        {/* 검색아이템 */}
                        {recentSearches.map((search, index) => (
                            <li key={index}>
                                <p onClick={() => handleRecentSearchClick(search.keyword)}>{search.keyword}</p>
                                <span>
                                    {formatSearchDate(search.date)}
                                    <button className='btn-listremove' onClick={() => handleDeleteRecentSearch(search)}></button> 
                                </span>
                            </li>
                        ))}
                    </ul>
                </div>
                )}
                
                {/* 이벤트 */}
                {!showRecentSearches && searchResults.events.length > 0 && (
                    <>
                        <Typography className='mb-8' variant='h5'>일정</Typography>
                        <ul className='ul-eventlist'>
                            {searchResults.events.map((event, index) => (
                                <li key={index} className='mb-28'>
                                    {/* <div className="eventitem-tit mb-16 subtit-02">{formatDate(event.startDate.toString())}</div> */}
                                    <div className='card' onClick={() => { navigate('/event', {state: {mode: 'board', idx: event.idx}})}}>
                                        <div className={'dilylist-item'}>
                                            {/* 카테고리명 들어가게 해주세요 */}
                                            <p className='caption mb-8'>{event.calendarName}</p>
                                            <span className={'item-lbl'}>
                                                <div className={`chips chips-sm ${event.label}`}></div>
                                            </span>
                                            <p className={'item-tit'}>{event.name}</p>
                                            <p className={'item-date'}>{formatDate(event.startDate.toString())} - {formatDate(event.endDate.toString())}</p>
                                            {/* 해시태그영역 */}
                                            {event.tag && (
                                            <p className='caption mt-8 list-chips-list'>
                                                {event.tag.split(' ').map((tag, index) => (
                                                    <Chips key={index} color='grey' size='sm'>{tag}</Chips>
                                                ))}
                                            </p>
                                            )}
                                        </div>
                                    </div>
                                </li>
                            ))}
                        </ul>
                    </>
                )}

                {/* 루틴 */}
                {!showRecentSearches && searchResults.routines.length > 0 && (
                    <>
                        <Typography className='mb-8' variant='h5'>습관</Typography>
                        <ul className='ul-eventlist'>
                            {searchResults.routines.map((routine, index) => (
                                <li key={index} className='mb-28'>
                                    {/* <div className="eventitem-tit mb-16 subtit-02">{formatDate(routine.startDate.toString())}</div> */}
                                    <div className='card' onClick={() => navigate(`/${routine.type === 'REPETITION' ? 'routineday' : 'challenge'}/${routine.idx}`)}>
                                        <div className={'dilylist-item'}>
                                            {/* 카테고리명 들어가게 해주세요 */}
                                            <p className='caption mb-8'>{routine.categoryName}</p>
                                            <span className={'item-lbl'}>
                                                {/* <div className={`chips chips-sm ${routine.categoryColor}`}></div> */}
                                                {routine.emoji}
                                            </span>
                                            <p className={'item-tit'}>{routine.name}</p>
                                            <p className={'item-date'}>{formatDate(routine.startDate.toString())}{routine.endDate && ` - ${formatDate(routine.endDate.toString())}`}</p>
                                        </div>
                                    </div>
                                </li>
                            ))}
                        </ul>
                    </>
                )}
                    
                {/* 검색 아직 안했을 경우 */}
                {showInitialMessage && (
                    <div className='card card-nosearch'>
                        <img src={IcSymbol} alt="symbol" className='ic-symbol'/>
                        <Typography className='mt-16' variant='h5'>하루달 AI에게 물어보세요!</Typography>
                        <Typography className='mt-8' variant='body2'>잊고 있었던 일정, 습관 등을 빠르게 찾아보세요</Typography>
                        <Button size='sm' color='main' className='mt-16' onClick={() => navigate('/ai')}>하루달AI로 바로가기</Button>
                    </div>
                )}

                {/* 검색 결과가 없는 경우 */}
               {showNoSearchResults && (
                    <div className='card card-nosearch'>
                        <img src={IcSymbol} alt="symbol" className='ic-symbol'/>
                        <Typography className='mt-16' variant='h5'>검색어와 일치하는 결과가 없습니다.</Typography>
                        <Typography className='mt-8' variant='body2'>잊고 있었던 일정, 습관 등을 빠르게 찾아보세요</Typography>
                        <Button size='sm' color='main' className='mt-16' onClick={() => navigate('/ai')}>하루달AI로 바로가기</Button>
                    </div>
                )}
            </div>
        </>
    )
}

export default Search;
