import React from 'react';

const Error404: React.FC = () => {

    return (
        <div>
            <h1>해당페이지는 존재하지 않습니다.</h1>
        </div>
    );
};

export default Error404;
