import { useNavigate } from "react-router-dom";
import Button from "../../components/common/Buttons";
import NoimageSymbol from "../../assets/images/noimages/img_noimage_symbol.png";
import Typography from "../../components/common/Typography";
import './mypage.css';
import Avatar from "../../components/common/Avatar";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { setLayout, setPage } from "../../store/layout";

import AOS from "aos";
import "aos/dist/aos.css";
import { useUserInfo } from "../../features/user/hooks/useUserInfo.hook";


const Mypage = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const { values, interests, imageSrc, fetchUserInfo, fetchInterests, getInterestEmoji, handleLogout, handleWithdrawal } = useUserInfo();

    useEffect(() => {
        AOS.init();
        fetchUserInfo();
        fetchInterests();
        dispatch(setLayout('historyBack'));
        dispatch(setPage('마이페이지'));   
    }, []);

    const handleEditBtn = () => {
        navigate('/editProfile');
    }
    
    return (
        <>
            <div className="grid-20 mypage-wp">
                {/* my profile */}
                <div className="myprofile-box mt-20" data-aos="fade" data-aos-duration="2000">
                    <Avatar size="lg" src={imageSrc || NoimageSymbol}/>
                    <Typography variant="h3" className="myprofile-nickname">내 닉네임은 {values?.nickname} 
                        <span onClick={handleEditBtn} /> 
                    </Typography>
                    <Typography className="myprofile-text mt-16" variant="h5"><span>{values?.age}대</span><span>{values?.gender === 'FEMALE' ? '여성' : '남성'}</span> {interests.length > 0 ? "이고" : ""} </Typography> 
                    {interests.length > 0 && (
                        <Typography className="myprofile-text mt-8" variant="h5">
                            요즘 나의 취향은<br />
                            {interests.map(interest => (
                                <span key={interest.idx} style={{ display: 'inline-block', marginTop: '8px' }}> {getInterestEmoji(interest.name)} {interest.name}</span>
                            ))}
                        </Typography>
                    )}
                </div>             
                {/* menu list */}
                <div className="card mt-34">
                    <ul className="mypage-list">
                        <li><Typography variant="h5">📆 캘린더</Typography></li>
                        <li><a onClick={() => navigate('/calendarYear')}>연도별 보기</a></li>
                        <li><a onClick={() => navigate('/calendarMonth')}>월별 모아보기</a></li>
                        <li><a onClick={() => navigate('/calendar')}>월별로 보기</a></li>
                        <li><a onClick={() => navigate('/multiCalendarsheet', { state: { settingMode: true } })}>캘린더 설정하기</a></li>
                    </ul>
                </div>
                <div className="card mt-20">
                    <ul className="mypage-list">
                        <li><Typography variant="h5">🤟 습관</Typography></li>
                        <li><a onClick={() => navigate('/routine')}>일별 보기</a></li>
                        <li><a onClick={() => navigate('/routineweekly')}>모아보기</a></li>
                        <li><a onClick={() => navigate('/challengelist')}>챌린지 모아보기</a></li>
                        <li><a onClick={() => navigate('/routinerest')}>휴식중인 습관 보기</a></li>
                    </ul>
                </div>
                <div className="card mt-20">
                    <ul className="mypage-list">
                        <li><Typography variant="h5">🔍 기타</Typography></li>
                        <li><a onClick={() => navigate('/ai')}>하루달 AI와 대화하기</a></li>
                        <li><a onClick={() => navigate('/diary')}>나의 통계 보기</a></li>
                    </ul>
                </div>

                <Button className="mt-20 btn-logout" onClick={handleLogout}>로그아웃</Button>
                <div style={{ opacity: '.8' }}>
                    <Button style={{ margin: 'auto'}} variant="text" size="sm" onClick={handleWithdrawal}>회원탈퇴</Button>
                </div>
            </div>
        </>
    )
}

export default Mypage;
 