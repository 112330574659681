import { createSlice } from '@reduxjs/toolkit';
import { NotificationType } from '../types/notificationType';

interface InitialState {
    notificationList: NotificationType[];
    allRead: boolean;
}

const initialState: InitialState = {
    notificationList: [],
    allRead: true,
};

const notification = createSlice({
    name: 'notification',
    initialState,
    reducers: {
        updateNotificationList(state, action) {
            state.notificationList = action.payload;
            state.allRead = action.payload.every((notification: { isRead: boolean; }) => notification.isRead); 
        },
    },
});

export default notification.reducer;
export const { updateNotificationList } = notification.actions;
