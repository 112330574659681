import React, { useState } from 'react';
import CycleSelect from '../event/CycleSelect'
import Button from './../common/Buttons'

interface InpTimePickerProps {
    setTimeModal: Function,
    selectedTime: Date
}

const InpTimePicker: React.FC<InpTimePickerProps> = ({ setTimeModal, selectedTime }) => {
    const [selectedHour, setSelectedHour] = useState<number>(0);
    const [selectedMinute, setSelectedMinute] = useState<number>(0);
    const [selectedPeriod, setSelectedPeriod] = useState<string>('AM');

    const handleHourSelect = (hour: number) => {
        setSelectedHour(hour);
    };

    const handleMinuteSelect = (minute: number) => {
        setSelectedMinute(minute);
    };

    const handlePeriodSelect = (period: string) => {
        setSelectedPeriod(period);
    };

    const handleSave = () => {
        setTimeModal(selectedHour, selectedMinute, selectedPeriod);
        // 여기에서 선택된 시간을 저장하거나 다른 작업을 수행할 수 있습니다.
    };

    return (
        <>
            <div style={{ display: 'flex', justifyContent: 'space-between'}}>
                <CycleSelect renderNum={12} onZeroDisplay={false} className="select-hour" onSelect={handleHourSelect} startNum={selectedTime.getHours() % 12 || 12} />
                <CycleSelect renderNum={11} onZeroDisplay={true} className="select-minute" onSelect={handleMinuteSelect} startNum={selectedTime.getMinutes()}/>
                <CycleSelect texts={ ['AM', 'PM'] } className="select-period" onSelect={handlePeriodSelect}/>
            </div>
            <Button onClick={handleSave} size='lg'>선택하기</Button>
        </>
    );
};

export default InpTimePicker;
