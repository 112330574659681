import { useEffect, useRef, useState } from "react";
import BgOverlay from "./BgOverlay";

// 나중에 보완하기
const emojiData = [
    { label: "😀", emojis: ["😀", "😃", "😄", "😁", "😆", "😅", "😂", "😊", "😇", "🙂", "😍", "🥰", "😌", "😋", 
                            "😎", "🤩", "😏", "😉", "🙃", "😜", "🤪", "😐", "😑", "😶", "🙄", "😬", "🤐", "😒",
                            "😕", "😟", "🙁", "😔", "😣", "😖", "😞", "😢", "😭", "😪", "😥", "😓", "🤧", "🤥",
                            "😡", "😠", "🤬", "😤", "😩", "😫", "😨" ] },
    { label: "🐶", emojis: ["🐶", "🐱", "🐭", "🐹", "🐰", "🦊", "🦝", "🐻", "🐼", "🦁", "🐯", "🐮", "🐷", "🐺",
                            "🐵", "🐔", "🐧", "🦆", "🐸", "🐢", "🦎", "🐍", "🐊", "🐉", "🐟", "🐠", "🐡", "🦈", 
                            "🐙", "🦑", "🐬", "🐳", "🐋", "🦞", "🦀", "🦐", ] },
    { label: "🍕", emojis: ["🍕", "🍔", "🍟", "🌭", "🍿", "🧀", "🍖", "🥪", "🥙", "🌮", "🌯", "🥗", "🥘", "🥫", 
                            "🍝", "🍜", "🍲", "🍛", "🍣", "🍱", "🥟", "🍤", "🍙", "🍚", "🍘", "🍥", "🥠", "🍢", 
                            "🍡", "🍧", "🍨", "🍦", "🥧", "🍰", "🎂", "🧁", "🥮", "🍮", "🍭", "🍬", "🍫", "🍿", 
                            "🍩", "🍪", "🌰", "🥜", "🍯", "🥛", "🍼", "☕", "🍵", "🧃", "🥤", "🍶", "🍺", "🍻", 
                            "🥂", "🍷", "🥃", "🍸", "🍹", "🍾"] },
    { label: "⚽", emojis: ["⚽", "🏀", "🏈", "⚾", "🎾", "🏐", "🏉", "🎱", "🥏", "🏓", "🏸", "🥊", "🥋", "⛸️", 
                            "🎿", "🛷", "🥌", "⛷️", "🏂", "🏋️‍♂️", "🤼‍♂️", "🤸‍♂️", "⛹️‍♂️", "🤺", "🤾‍♂️", "🏌️‍♂️", "🏇", "🏄‍♂️", 
                            "🏊‍♂️", "🤽‍♂️", "🚣‍♂️", "🏊‍♂️", "🏄‍♂️", "🚴‍♂️", "🚵‍♂️", "🤹‍♂️", "🛹", "🎮", "🕹️", "🎳", "🎯", "🎲", 
                            "♟️", "🎰"] },          
    { label: "🚗", emojis: ["🚗", "🚕", "🚙", "🚌", "🚎", "🏎", "🚓", "🚑", "🚒", "🚐", "🚚", "🚛", "🏍️", "🛵", 
                            "🚲", "🛴", "🚜", "🛺", "🚍", "🚘", "🚖", "🚡", "🚠", "🚟", "🚃", "🚅", "🚄", "🛸", 
                            "🚀", "🛶", "⛵", "🚤", "🛳️", "⛴️", "🛥️", "🚢"] },
    { label: "❤", emojis: ["❤", "💚", "💙", "💛", "💜", "🖤", "🤍", "💔", "❣️", "💕", "💞", "💓", "💗", "💖", 
                            "💘", "💝", "💟", "❤️‍🔥", "❤️‍🩹"] },
    { label: "🌺", emojis: ["🌺", "🌻", "🌹", "🌷", "🌼", "🌸", "💐", "🌾", "🌿", "☘️", "🍀", "🍁", "🍂", "🍃", 
                            "🌱", "🌲", "🌳", "🌴", "🌵", "🌾", "🌾"] },
    { label: "🌟", emojis: ["🌟", "✨", "💫", "⭐", "🌠", "🎇", "🎆", "🌈", "☀️", "🌤️", "⛅", "🌥️", "☁️", "🌦️", 
                            "🌧️", "⛈️", "🌩️", "🌨️", "❄️", "☃️", "⛄", "🌬️", "💨", "🌪️", "🌫️", "🌊", "💧", "💦", "☔", "🍂"] },
    { label: "🎶", emojis: ["🎶", "🎵", "🎼", "🎤", "🎧", "🎷", "🎸", "🎺", "🎻", "🪕", "🥁", "🎹", "🎙️", "🎚️", "🎛️"] },
    { label: "📚", emojis: ["📚", "📖", "📓", "📔", "📒", "📕", "📗", "📘", "📙"] },
    { label: "🎨", emojis: ["🎨", "🖌️", "🖍️", "✏️", "📏", "📐", "📝", "📜", "🖊️", "🖋️", "✒️"] },
    { label: "🌍", emojis: ["🌍", "🌎", "🌏", "🌐", "🗺️", "🗾", "🧭", "🏔️", "⛰️", "🌋", "🗻", "🏕️", "🏖️", "🏜️", "🏝️"] },
    { label: "🎉", emojis: ["🎉", "🎊", "🎈", "🎁", "🎀", "🪅", "🎆", "🎇", "🧨", "🎏", "🎐", "🎍", "🎋", "🎎"] },
    { label: "🚀", emojis: ["🚀", "🛸", "🛰️", "🌌", "🔭", "🪐"] },
    { label: "📱", emojis: ["📱", "📲", "💻", "🖥️", "🖨️", "🕹️", "📷", "📸", "📹", "🎥", "📽️", "💾", "💿", "📀", "📼"] },
];

/**
 * onSaveEmoji로 해당 컴포넌트 화면 제어
 * 추후 드래드 닫기 기능 추가예정,
 */
interface EmojiSheetProps {
    onSaveEmoji: (emoji: string) => void;
    selectedEmoji: string;
    closeEmojiSheet: () => void;
  }

const EmojiSheet: React.FC<EmojiSheetProps> = ({ onSaveEmoji,  selectedEmoji: initialSelectedEmoji, closeEmojiSheet }) => {
    const [activeTab, setActiveTab] = useState<string>('😀');
    const [selectedEmoji, setSelectedEmoji] = useState<string>('');
    const tabRef = useRef<HTMLUListElement>(null);
  
    const handleTabClick = (tab: string) => {
        setActiveTab(tab);
    };

    const handleEmojiClick = (emoji: string | JSX.Element) => {
        if (typeof emoji === 'string') {
            setSelectedEmoji(emoji);
        } else {
           // eslint-disable-next-line no-lone-blocks
           {emojiData.find(item => item.label === activeTab)?.emojis.map((emoji, index) => (
            <li key={index} className={selectedEmoji === emoji ? "active" : ""} onClick={() => handleEmojiClick(emoji)}>
                {typeof emoji === 'string'}
            </li>
            ))}
        }
    };

    // 이모지 클릭 핸들러 수정
    const handleSaveClick = () => {
        onSaveEmoji(selectedEmoji); // 상위 컴포넌트의 콜백 함수 호출
    };
    
    // 선택된 이모지 표시
    useEffect(() => {
        if (initialSelectedEmoji) {
            const selectLabel = emojiData.find(item => item.emojis.includes(initialSelectedEmoji));
            if (selectLabel) {
                setActiveTab(selectLabel.label);
                setSelectedEmoji(initialSelectedEmoji);
            }
        }
    }, [initialSelectedEmoji]);

    useEffect(() => {
        if (tabRef.current) {
            tabRef.current.scrollTo({ left: 0, behavior: 'smooth' });
        }
    }, [activeTab]);

    return (
      <>
        <div className='card card-drawer'>
            <div className='sheet-header'>
                <div className='sheet-handle' ></div>
            </div>
            {/* tab start */}
            <ul className="emoji-tab">
                {emojiData.map(item => (
                    <li key={item.label} className={activeTab === item.label ? "active" : ""} onClick={() => handleTabClick(item.label)}>
                    {item.label}
                    </li>
                ))}
            </ul>
            {/* tab end */}
          
            {/* emoji start */}
            <div className="card card-emoji">
                <ul>
                {emojiData.find(item => item.label === activeTab)?.emojis.map((emoji, index) => (
                        <li key={index} className={selectedEmoji === emoji ? "active" : ""} onClick={() => handleEmojiClick(emoji)}>{emoji}</li>
                    ))}
                </ul>
            </div>
            {/* emoji end */}
            <button className="btn btn-lg main mt-20" onClick={handleSaveClick}>저장하기</button>
        </div>
        <BgOverlay close={closeEmojiSheet}/>
      </>
    );
  }
  
  export default EmojiSheet;