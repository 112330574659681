import { ReactElement, useState } from "react";
import { interestType } from "../../../features/user/types/userDetailType";
import { useNavigate } from "react-router";
import { interestsSave } from "../../../features/auth/services/interestsService";

function AdditionalChoose() {
    const navigate = useNavigate();
    const [ activeList, setActiveList ] = useState<string[]>([]);

    const interestList:Array<interestType> = [
        {emoji: '📚', text:'공부하기'},
        {emoji: '🏃‍♀️', text:'운동하기'},
        {emoji: '💻', text:'업무보기'},
        {emoji: '🍔', text:'패스트푸드'},
        {emoji: '🍜', text:'누들'},
        {emoji: '🍰', text:'디저트'},
        {emoji: '🏖', text:'바다'},
        {emoji: '🏞', text:'산'},
        {emoji: '✈', text:'여행'},
        {emoji: '🌃', text:'야경보기'},
        {emoji: '🌆', text:'노을보기'},
        {emoji: '🏊‍♂️', text:'수영하기'},
        {emoji: '🎨', text:'그림그리기'},
        {emoji: '🚿', text:'샤워하기'},
        {emoji: '💸', text:'돈모으기'},
    ]

    const handleInterestButton = (item:string) => {
        setActiveList(prevList => {
            if (prevList.includes(item)) {
              return prevList.filter(currentItem => currentItem !== item);
            } else {
              return [...prevList, item];
            }
        });
    }

    const handleStartButton = () => {
        // 저장 이후 calendar화면 이동
        interestsSave(activeList);

        navigate('/calendar?tutorial=y');
    }

    return (
        <>
            <h1 className='title-02 mb-34'>선택한 항목을 하루달 <span className="text-symbol"></span>이<br/>분석해 맞춤 서비스를 제공하고 있어요!</h1>

            <form className="additional-choose">
                <ul className="ul-add-choose">
                    {
                        interestList.length > 0 &&
                        interestList.map((value,idx):ReactElement=>{

                            let classNm = 'btn';
                            if(activeList.includes(value.text)) classNm += ' active';

                            return (
                                <li key={idx}>
                                    <button type="button" className={classNm} onClick={() => handleInterestButton(value.text)}>
                                        <p className="body-emoji mb-8">{value.emoji}</p>
                                        <p className="body-02">{value.text}</p>
                                    </button>
                                </li>
                            )
                        })
                    }
                </ul>
            </form>

            {/* next button */}
            <div className="next-btn-wp">
                <button className='btn btn-text mb-16' onClick={() => handleStartButton()}>다음에 할게요</button>
                <button className='btn btn-lg' onClick={() => handleStartButton()}>시작하기</button>
            </div>
        </>
    )
}

export default AdditionalChoose;