import React, { useState } from 'react';
import BgOverlay from "./BgOverlay";
import InpDatePicker from "./InpDatePicker";
import './datepicker.css';
import InpTimePicker from "./InpTimePicker";

interface DateTimePickerSheetProp {
    tabType: string,
    setDayModal: (date: Date) => void,
    setTimeModal: (hour: number, minute: number, period: string) => void,
    startDate?: Date,
    endDate?: Date
} 

const DateTimePickerSheet: React.FC<DateTimePickerSheetProp> = ({ tabType, setDayModal, setTimeModal, startDate, endDate }) => {
    const [activeTab, setActiveTab] = useState<string>(tabType);
    

    const handleTabClick = (tab: string) => {
        setActiveTab(tab);
    };

    const tabChange = () => {
        setActiveTab('tab2');
    }

    return (
        <>
            <div className='card card-drawer' onClick={(event) => event.stopPropagation()}>
                <div className='sheet-header'>
                    <div className='sheet-handle'></div>
                </div>
                <div className='card-tabs mb-20'>
                    <button onClick={() => handleTabClick('tab1')} className={activeTab === 'tab1' ? 'active' : ''}>날짜 선택</button>
                    <button onClick={() => handleTabClick('tab2')} className={activeTab === 'tab2' ? 'active' : ''}>시간 선택</button>
                </div>
                <div className='tabs-content'>
                    {activeTab === 'tab1' && <InpDatePicker setDayModal={setDayModal} startDate={startDate} endDate={endDate} tabChange={tabChange} />}
                    {activeTab === 'tab2' && <InpTimePicker setTimeModal={setTimeModal} startDate={startDate} endDate={endDate} />}
                </div>
            </div>
            <BgOverlay />
        </>
    );
};

export default DateTimePickerSheet;
