import React, { ReactNode } from "react";

// Typography 컴포넌트의 props 타입 정의
interface TypographyProps {
    variant?: "h1" | "h2" | "h3" | "h4" | "h5" | "p" | "body1" | "body2" | "caption" ;
    className?: string;
    children: ReactNode;
    onClick?: (e: MouseEvent) => void;
}

const Typography: React.FC<TypographyProps> = ({variant="p",className,children,onClick}) => {

  // 타입에 따른 태그와 기본 클래스 이름 매핑
  const typeToTagAndClassMap = {
    h1: { tag: 'h1', defaultClass: 'title-01' },
    h2: { tag: 'h2', defaultClass: 'title-02' },
    h3: { tag: 'h3', defaultClass: 'title-03' },
    h4: { tag: 'h4', defaultClass: 'subtit-01' },
    h5: { tag: 'h5', defaultClass: 'subtit-02' },
    p: { tag: 'p', defaultClass: '' },
    body1: { tag: 'p', defaultClass: 'body-01' },
    body2: { tag: 'p', defaultClass: 'body-02' },
    caption: { tag: 'p', defaultClass: 'caption' },
  };

  // 선택된 타입에 따른 태그와 기본 클래스 이름
  const { tag, defaultClass } = typeToTagAndClassMap[variant] || typeToTagAndClassMap['p'];
  
  const finalClassName = `${defaultClass} ${className}`.trim();

  return React.createElement(tag, { className: finalClassName, onClick }, children);
}

export default Typography;