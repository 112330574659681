import './routinecomponent.css';
import { RoutineProps, WeekProps } from '../../features/routine/types/routineDayType';
import { useDispatch } from 'react-redux';
import { setSelectedDay } from '../../features/routine/stores/routine';
import { useSelector } from 'react-redux';
import { RootState } from '../../store';

interface RoutineWeekProps {
    week: WeekProps;
}

const RoutineWeek: React.FC<RoutineWeekProps> = ({
    week
}) => {

    const dispatch = useDispatch();
    const selectedDay = useSelector((state:RootState) => state.routine.selectedDay);

    const handleDayClick = (date:Date) => {
        dispatch(setSelectedDay(date.toISOString()))
    }

    const pieCalc = (completionRate:number|undefined) => {
        if(completionRate == undefined || completionRate == 0) {
            return '';
        } else if(completionRate < 50){
            return ' pie-25';
        } else if(completionRate < 75) {
            return ' pie-25 pie-50';
        } else if(completionRate < 100) {
            return ' pie-25 pie-50 pie-75';
        } else if(completionRate == 100) {
            return ' pie-25 pie-50 pie-75 pie-100';
        } 
    }

    // active 체크도 해야함
    const isSelected = (date:Date) => {
        return selectedDay.slice(0, 10) === date.toISOString().slice(0, 10) ? ' active' : '';
    }

    return (
        <>
            <ul className="week-number">
                {
                    week.map((day,dayIndex) => {
                       return( 
                        <li key={dayIndex} className={"number-box" + pieCalc(day.completionRate) + isSelected(day.fullDate)} onClick={() => handleDayClick(day.fullDate)}>
                            <p>{day.date}</p>
                              <div className="slice"></div>
                              <div className="slice"></div>
                              <div className="slice"></div>
                              <div className="slice"></div>
                              <div className="slice"></div>
                        </li>
                       )
                    })
                }
                {/* <li className="number-box pie-25">4</li>
                <li className="number-box pie-50">5</li>
                <li className="number-box pie-75">6</li>
                <li className="number-box pie-100">7</li>
                <li className="number-box">8</li>
                <li className="number-box">9</li>
                <li className="number-box active">10</li> */}
            </ul>
        </>
    )
}

export default RoutineWeek;