import Palette from "./Palette";
import BgOverlay from "./BgOverlay";

interface PaletteSheetProps {
    openSheet: () => void;
    setColor: (colors: string) => void;
}

/**
 * 콜백에 대해서 정리 필요
 * 추후 드래드 닫기 기능 추가예정
 */

const PaletteSheet: React.FC<PaletteSheetProps> = ({ openSheet, setColor }) => {

    return (
        <>
             <div className='card card-drawer'>
                <div className='sheet-header'>
                    <div className='sheet-handle'></div>
                </div>
                {/* palette start */}
                <Palette onClick={setColor}/>
                {/* palette end */}
                <button className="btn btn-lg main mt-20" onClick={openSheet}>저장하기</button>
            </div>
            <BgOverlay close={openSheet}/>    
        </>
    )
}

export default PaletteSheet;