import { ReactElement, useEffect } from 'react'
import { Navigate, Outlet, useLocation, useNavigate } from 'react-router-dom'
import useAuth from '../hooks/useAuth';

interface PrivateRouteProps {
    children?: ReactElement
    authentication: boolean // true :인증을 반드시 해야하만 접속가능, false : 인증을 반디스 안해야만 접속 가능
}

export default function PrivateRoute( {authentication} : PrivateRouteProps): React.ReactElement | null {
    const { isLoggedIn }  = useAuth();
    const navigate = useNavigate();
    const location = useLocation();
    
    useEffect(() => {
      if (isLoggedIn === null) {
        // 로그인 상태가 아직 확정되지 않았을 때 처리
        return; // 또는 로딩 인디케이터 반환
      }

      if (authentication && !isLoggedIn) {
        navigate('onboarding', {
          state: {
            from: location.pathname
          },
          replace: true
        });
      } else if(!authentication && isLoggedIn) {
          navigate('calendar', {
              state: {
                from: location.pathname
              },
              replace: true
          });
      }
    }, [isLoggedIn, navigate, location]);

    return <Outlet/>
}