
import Button from '../common/Buttons';
import Typography from '../common/Typography';
import IcRight  from "../../assets/images/icon/system/ic_expand_right.svg";
import './notificationcomponent.css';
import { NotificationType } from '../../features/notification/types/notificationType';
import useNotificationHook from '../../features/notification/hooks/useNotification.hook';
import { useNavigate } from 'react-router-dom';

function StatisticNotiCard(props: NotificationType) {
    const navigate = useNavigate();

    const { formatNotificationDate, handleCardClick, isNotificationRead } = useNotificationHook();

    const isRead = isNotificationRead(props.idx);

    return (
        <>
            <div className={`card noti-card ${isRead ? 'noti-read' : ''}`} onClick={() => handleCardClick(props.idx)}>
                <div className='noti-card-top mb-8'>
                    <Typography variant='body2' className='noti-tit'><span className='mr-8'>📊</span>통계</Typography>
                    <Typography variant='body2' className='noti-date'>
                        {formatNotificationDate(props.date)}
                    </Typography>
                </div>
                <Typography variant='h5' className='mb-8'>이번 주 통계가 작성되었어요, 지금 확인하시겠어요?</Typography>
                <div className='noti-card-bottom'>
                    <Button variant='text' size='sm' onClick={() => {navigate('/diary'); handleCardClick(props.idx);}}>
                        통계 보러가기
                        <img src={IcRight} alt="icon" />
                    </Button>
                </div>
            </div>
        </>
    )
}

export default StatisticNotiCard;