import { ReactNode } from "react";


// Button 컴포넌트의 props 타입 정의
interface ButtonProps {
    type?: "button" | "submit" | "reset" ;
    className?: string;
    disabled?: boolean;
    variant?: "contained" | "outline" | "text";
    color?: "main" | "error" | "warning" | "success" | "info";
    size?: "sm" | "md" | "lg";
    children: ReactNode;
    onClick?: () => void;
    style?: React.CSSProperties;
}

const Button: React.FC<ButtonProps> = ({ 
    type="button", 
    className = '', 
    disabled=false,
    variant = '', 
    color = '', 
    size = '', 
    children, 
    onClick,
    style  
}) => {
    const prefix = 'btn-';
    const classes = [
        'btn',
        variant && `${prefix}${variant}`,
        color && `${color}`,
        size && `${prefix}${size}`,
        className
    ].filter(Boolean).join(' ');


    return (
        <button
            type={type}
            className={classes}
            disabled={disabled}
            onClick={onClick}
            style={style}
        >
            {children}
        </button>
    )
}

export default Button;