
import Button from "../../components/common/Buttons";
import NoimageSymbol from "../../assets/images/noimages/img_noimage_symbol.png";
import Typography from "../../components/common/Typography";
import './mypage.css';
import { useEffect } from "react";
import { interestType } from "../../features/user/types/userDetailType";
import { useDispatch } from "react-redux";
import { setLayout, setPage } from "../../store/layout";

import AOS from "aos";
import "aos/dist/aos.css";
import DropMenu from "../../components/common/DropMenu";
import { useUserInfo } from "../../features/user/hooks/useUserInfo.hook";

const EditProfile = () => {
    const dispatch = useDispatch();
    
    const { 
        imageSrc,
        values,
        interests, 
        fetchUserInfo, 
        fetchInterests, 
        setShowGenderMenu, 
        showGenderMenu, 
        setShowAgeMenu, 
        showAgeMenu, 
        ageOptions, 
        handleAgeChange, 
        genderOptions, 
        handleGenderChange, 
        interestList,
        selectedInterests, 
        setSelectedInterests,  
        handleInterestClick,
        handleUpdate, 
        handleLogout, 
        handleWithdrawal, 
        handleNicknameChange,
        handleImageChange 
    } = useUserInfo();

    useEffect(() => {
        AOS.init();
        fetchUserInfo();
        fetchInterests();
        dispatch(setLayout('historyBack'));
        dispatch(setPage('정보 수정'));   
    }, []);

    useEffect(() => {
        if (interests && interests.length > 0) {
            const matchedInterests = interests.filter(item => interestList.some(interest => interest.text === item.name));
            if (matchedInterests.length > 0) {
                setSelectedInterests(matchedInterests.map(item => item.name));
            }
        }
    }, [interests]);
    
    const getInterestStyle = (interest: interestType) => {
        const isSelected = selectedInterests.includes(interest.text);
        const backgroundColor = isSelected ? 'var(--color-text-01)' : 'var(--background-w)';
        const color = isSelected ? 'var(--background-w)' : 'black';
        return {
            display: 'inline-block',
            marginTop: '8px',
            backgroundColor,
            color
        };
    };
    
    return (
        <>
            <div className="grid-20 mypage-wp">
                <div className='inp-group group-center'  style={{ padding: '20px' }}>
                    <div className="upload-profile mb-4">
                        <div className="avatar">
                            <img src={imageSrc ? imageSrc : NoimageSymbol} alt="noimage" />
                        </div>
                        <input className='inp-txt' type="file" name="" id="" accept="image/*" onChange={handleImageChange} />
                    </div>
                </div>
                <p className="body-02 mb-8">내 정보</p>
                <div className="card" style={{ marginBottom: '20px' }}>
                    <div className="write-control">
                        <input
                            className='inp-txt'
                            type="text"
                            name=""
                            id=""
                            placeholder='최소 2글자, 최대 8글자로 작성해주세요'
                            value={values?.nickname}
                            onChange={handleNicknameChange}
                        />                    
                    </div>
                </div>
                <div className="card" style={{ marginBottom: '20px', position: 'relative' }}>
                    <div className="write-control" onClick={() => setShowGenderMenu(!showGenderMenu)}>
                            <p className="body-01 control-tit">성별</p>
                            <p className="btn-edit txt-input">
                                {values?.gender === 'FEMALE' ? '여성' : '남성'}
                            </p>
                    </div>
                    {showGenderMenu && (
                        <DropMenu 
                            items={genderOptions.map(option => ({
                                label: option.label,
                                onClick: () => handleGenderChange(option.value)
                            }))}
                            style={{ position: 'absolute', top: '44px', right: '20px' }}
                        />
                    )}
                </div>
                <div className="card" style={{ marginBottom: '20px', position: 'relative' }}>
                    <div className="write-control" onClick={() => setShowAgeMenu(!showAgeMenu)}>
                            <p className="body-01 control-tit">나이</p>
                            <p className="btn-edit txt-input">
                                {values?.age && values.age >= 40 ? '40대 이상' : `${values?.age}대`}
                            </p>
                    </div>
                    {showAgeMenu && (
                        <DropMenu 
                            items={ageOptions.map(option => ({
                                label: option.label,
                                onClick: () => handleAgeChange(option.value)
                            }))}
                            style={{ position: 'absolute', top: '44px', right: '20px' }}
                        />
                    )}
                </div>
                <p className="body-02 mb-8">관심사</p>
                <Typography className="myprofile-text mt-8" variant="h5">
                    {interestList.map((interest, index) => (
                        <span 
                            key={index} 
                            style={getInterestStyle(interest)}
                            onClick={() => handleInterestClick(interest)}
                        >
                            {interest.emoji} {interest.text}
                        </span>
                    ))}
                </Typography>
                <div className="body-02 mb-8" style={{ width: '100%', height: '2px', backgroundColor: 'var(--stroke-01)', margin: '25px 0'}}></div>
                <p>
                    <span style={{ margin: '10px',  fontSize: '14px' }} onClick={handleLogout}>로그아웃</span>
                    <span style={{ color: 'var(--color-text-04)', fontSize: '14px' }} onClick={handleWithdrawal}>회원탈퇴</span>
                </p>
                <Button className="mt-20 btn-logout" style={{ marginTop: '60px' }} onClick={handleUpdate} disabled={values.nickname.length < 2 || values.nickname.length > 8}>저장하기</Button>
            </div>
        </>
    )
}

export default EditProfile;
 