
import Button from '../common/Buttons';
import Typography from '../common/Typography';
import IcRight  from "../../assets/images/icon/system/ic_expand_right.svg";
import './notificationcomponent.css';
import { NotificationType } from '../../features/notification/types/notificationType';
import useNotificationHook from '../../features/notification/hooks/useNotification.hook';
import { useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { RoutineDetailProps } from '../../features/routine/types/routineInsertType';
import { findRoutine } from '../../features/routine/services/insertRoutineService';

function RoutineNotiCard(props: NotificationType) {
    const navigate = useNavigate();

    const { formatNotificationDate, handleCardClick, isNotificationRead } = useNotificationHook();
    const [routine, setRoutine] = useState<RoutineDetailProps | null>(null);

    useEffect(() => {
        const fetchRoutine = async () => {
            const response = await findRoutine(props.relateId);
            setRoutine(response.data);
        };
        
        fetchRoutine();
    }, [props.relateId]);

    const isRead = isNotificationRead(props.idx);
    const routineType = routine?.type === "REPETITION" ? '습관' : '챌린지';

    return (
        <>
            <div className={`card noti-card ${isRead ? 'noti-read' : ''}`}>
                {routine && (
                    <>
                        <div className='noti-card-top mb-8'>
                            <Typography variant='body2' className='noti-tit'>
                                <span className='mr-8'>{routine.type === "REPETITION" ? '🤟' : '🔥'} </span>
                                {routineType}
                            </Typography>                            
                            <Typography variant='body2' className='noti-date'>
                                    {formatNotificationDate(props.date)}
                            </Typography>                
                        </div>
                            <Typography variant='h5' className='mb-8'>{routine.name}, 오늘의 {routine.type === "REPETITION" ? '습관을' : '챌린지를'} 시작해 볼까요?</Typography>
                        <div className='noti-card-bottom'>
                            <Button variant='text' size='sm' onClick={() => {navigate('/routine'); handleCardClick(props.idx);}}> 
                                {routineType} 체크하기
                                <img src={IcRight} alt="icon" />
                            </Button>
                        </div>
                    </>
                )}
            </div>
        </>
    )
}

export default RoutineNotiCard;