import React, { useEffect, useState } from 'react';
import RoutineWrite from '../../components/routine/RoutineWrite';
// import routine styles
import './routine.css';
import './board.css';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { setLayout, setPage } from '../../store/layout';

const RoutineAdd: React.FC = () => {
    const location = useLocation();

    const routineDetail = location.state ? location.state.routineDetail : null;
    const [editMode, setEditMode] = useState(location.state ? location.state.editMode : false);

    const [activeTab, setActiveTab] = useState<string>('tab1');
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(setLayout('write'));

        if (editMode) {
            // editMode가 true일 경우
            if (routineDetail?.type === 'REPETITION') {
                dispatch(setPage('습관 수정하기'));
            } else if (routineDetail?.type === 'ACHIEVEMENT') {
                dispatch(setPage('챌린지 수정하기'));
            }
        } else {
            if (activeTab === 'tab1') {
                dispatch(setPage('새로운 습관 등록'));
            } else if (activeTab === 'tab2') {
                dispatch(setPage('새로운 챌린지 등록'));
            }
        }
    }, [activeTab, editMode, routineDetail, dispatch]);

    const handleTabClick = (tab: string) => {
        setActiveTab(tab);
    };

    return (
        <>
            <div className="board-wp grid-20 write">
                {!editMode && (
                    <div className="card-tabs mb-20">
                        <button onClick={() => handleTabClick('tab1')} className={activeTab === 'tab1' ? 'active' : ''}>
                            습관
                        </button>
                        <button
                            onClick={() => handleTabClick('tab2')}
                            className={activeTab === 'tab2' ? 'challenge active' : 'challenge'}
                        >
                            챌린지
                        </button>
                    </div>
                )}
                <div className="tabs-content">
                    {(!editMode && activeTab === 'tab1') || (editMode && routineDetail?.type === 'REPETITION') ? (
                        <RoutineWrite type="REPETITION" routineDetail={routineDetail} editMode={editMode} />
                    ) : null}
                    {(!editMode && activeTab === 'tab2') || (editMode && routineDetail?.type === 'ACHIEVEMENT') ? (
                        <RoutineWrite type="ACHIEVEMENT" routineDetail={routineDetail} editMode={editMode} />
                    ) : null}
                </div>
            </div>
        </>
    );
};

export default RoutineAdd;
