import React from 'react';
// import footer styles
import './footer.css';
import { ReactComponent as IcCalendar } from '../../assets/images/icon/system/ic_calendar.svg';
import { ReactComponent as IcRoutine } from '../../assets/images/icon/system/ic_circle_right_alt.svg';
import { ReactComponent as IcAi } from '../../assets/images/icon/product/ic_simbol.svg';
import { ReactComponent as IcDiary } from '../../assets/images/icon/system/ic_chart.svg';
import { ReactComponent as IcMypage } from '../../assets/images/icon/system/ic_user.svg';
import { useLocation, useNavigate } from 'react-router';

const Footer: React.FC = () => {
    const navigate = useNavigate();
    const location = useLocation(); // 현재 경로를 가져옵니다

    // 현재 경로와 메뉴 항목의 경로가 일치하는지 확인하는 함수
    const isActive = (path: string) => location.pathname.indexOf(path) > -1;

    return (
        <>
            <footer>
                <div className="bottom-bar">
                    <ul> 
                        {/* 
                            활성화된 메뉴 addclass active
                         */}
                        <li className={isActive('/calendar') ? 'active' : ''}>
                            <a onClick={() => navigate('/calendar')}>
                                <div className="menu-icon ic-calendar">
                                    <IcCalendar/>
                                </div>
                                <p className="menu-name">캘린더</p>
                            </a>
                        </li>

                        <li className={isActive('/routine') ? 'active' : ''}>
                            <a onClick={() => navigate('/routine')}>
                                <div className="menu-icon ic-routine">
                                    <IcRoutine/>
                                </div>
                                <p className="menu-name">습관</p>
                            </a>
                        </li>

                        <li className={isActive('/ai') ? 'active' : ''}>
                            <a onClick={() => navigate('/ai')}>
                                <div className="menu-icon ic-ai">
                                    <IcAi/>
                                </div>
                                <p className="menu-name">AI</p>
                            </a>
                        </li>

                        <li className={isActive('/diary') ? 'active' : ''}>
                            <a onClick={() => navigate('/diary')}>
                                <div className="menu-icon ic-diary">
                                    <IcDiary/>
                                </div>
                                <p className="menu-name">통계</p>
                            </a>
                        </li>

                        <li className={isActive('/mypage') ? 'active' : ''}>
                            <a onClick={() => navigate('/mypage')}>
                                <div className="menu-icon ic-mypage">
                                    <IcMypage/>
                                </div>
                                <p className="menu-name">내정보</p>
                            </a>
                        </li>
                    </ul>
                </div>
            </footer>
        </>
    );
};

export default Footer;
