import { useEffect, useState } from 'react';
import './routineCalendar.css';

import dayjs from 'dayjs';
import { getMonthlyRoutine } from '../../features/routine/services/monthlyRoutineService';
import { MonthRoutineProps } from '../../features/routine/types/routineDayType';

interface RoutineCalendarProps {
    idx?: string,
    calendar: number[][],
    month: string,
}

const RoutineCalendar: React.FC<RoutineCalendarProps> = ({idx,calendar,month}) => {
    const [completeRoutineList, setCompletedRoutineList] = useState<MonthRoutineProps[]>([]);
    
    const daysOfWeek = ['일', '월', '화', '수', '목', '금', '토'];

    useEffect(() => {
        const updateList = async () => {
            if (idx != null) {
                const list = await getMonthlyRoutine(
                    idx,
                    dayjs(month, 'YYYYMM').startOf('month').toDate(),
                    dayjs(month, 'YYYYMM').endOf('month').toDate()
                );
                setCompletedRoutineList(list);
            }
        };

        updateList();
    }, [idx, month]);

    useEffect(() => {
        console.log(completeRoutineList);
    },[completeRoutineList])

    const getRoutineStatus = (day: number) => {
        const targetDate = dayjs(`${month}${String(day).padStart(2, '0')}`, 'YYYYMMDD').toDate();
        const routine = completeRoutineList.find(routine => dayjs(routine.completedDate).isSame(targetDate, 'day'));
        return routine ? { completed: true, emoji: routine.emoji } : { completed: false, emoji: null };
    };

    const todayClass = (day: number) => {

        return '';
    }

    return (
        <>
            <div className="item-calendar">
                {/* 요일 */}
                <ul className="day-week mb-20">
                    {daysOfWeek.map((day) => (
                        <li key={day}><p>{day}</p></li>
                    ))}
                </ul>
                {/* weekly */}
                {
                    calendar.map((week, index) => (
                        <ul className="weekly-row mb-20">
                            {week.map((day,idx) => (
                                <li key={idx} className={todayClass(day)} >
                                    <p>{day !== 0 ? day : ''}</p>
                                    {day !== 0 && (() => {
                                        const { completed, emoji } = getRoutineStatus(day);
                                        return completed && (
                                            <div className="day-routine mt-8">
                                                {emoji}
                                            </div>
                                        );
                                    })()}
                                </li>
                            ))
                                
                            }
                        </ul>
                    ))
                }

                {/* <ul className="weekly-row mb-20">
                    <li><p>6</p></li>
                    <li><p>7</p></li>
                    <li className="today">
                        <p>8</p>
                        <div className="day-routine mt-8">
                            💪
                        </div>
                    </li>
                    <li><p>9</p></li>
                    <li><p>10</p></li>
                    <li><p>11</p></li>
                    <li><p>12</p></li>
                </ul> */}

            </div>
        </>
    )
}

export default RoutineCalendar;
