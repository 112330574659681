
// import Swiper JS
import { Swiper, SwiperClass, SwiperSlide } from 'swiper/react';
// import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';
import { Pagination } from 'swiper/modules';
import { EffectFade } from 'swiper/modules';

import Copyright from "../../components/common/Copyright";
// import auth styles
import './auth.css';
import { useRef } from 'react';
import { useNavigate } from 'react-router';
//  import imgages
import OnboardingImg01  from "../../assets/images/pages/login/img_onboarding01.png";
import OnboardingImg02  from "../../assets/images/pages/login/img_onboarding02.png";
import OnboardingImg03  from "../../assets/images/pages/login/img_onboarding03.png";
import OnboardingImg04  from "../../assets/images/pages/login/img_onboarding04.png";


function Onboarding() {
    const navigate = useNavigate();

    // Swiper 인스턴스를 저장할 ref 생성
    const swiperRef = useRef<SwiperClass | null>(null);

    // 다음 슬라이드로 이동하는 함수
    const goToNextSlide = () => {
        if (swiperRef.current) {
            swiperRef.current?.slideNext();
        }
    };

    // 다음 페이지로 가는 함수
    const goToLogin = () => {
        navigate('/login');
    }

    return (
        <>
            <div className='onboarding-wp'>
                <Swiper 
                    pagination={{ clickable: true }} 
                    modules={[Pagination, EffectFade]} effect="fade"
                    spaceBetween={20}
                    onSwiper={(swiper:SwiperClass) => (swiperRef.current = swiper)}
                >
                    {/* slide start */}
                    <SwiperSlide>
                        <div>
                            {/* login link */}
                            <a className='btn btn-text skip-link' onClick={goToLogin}>건너뛰기</a>
                            {/* title */}
                            <h1 className='title-02 mb-34'>오늘은 어떻게 보낼지<br />고민이신가요?</h1>
                        </div>

                        {/* imgage */}
                        <div className='onboarding-imgbox'><img src={OnboardingImg01} alt="onboardingImage" /></div>
                        <div>
                            {/* next button */}
                            <button className='btn btn-lg mt-34 mb-34' onClick={goToNextSlide}>하루달과 함께 일정을 작성하세요</button>
                            {/* copyright */}
                            <Copyright/>
                        </div>
                    </SwiperSlide>
                    {/* slide end */}

                    <SwiperSlide>
                        <div>
                            <a className='btn btn-text skip-link' onClick={goToLogin}>건너뛰기</a>
                            <h1 className='title-02 mb-34'>하루달이 성향을 파악해<br/>습관을 추천해드려요</h1>
                        </div>
                        <div className='onboarding-imgbox'><img src={OnboardingImg02} alt="onboardingImage" /></div>
                        <div>
                            <button className='btn btn-lg mt-34 mb-34' onClick={goToNextSlide}>나에게 맞는 습관은?</button>
                            <Copyright/>
                        </div>
                    </SwiperSlide>

                    <SwiperSlide>
                        <div>
                            <a className='btn btn-text skip-link' onClick={goToLogin}>건너뛰기</a>
                            <h1 className='title-02 mb-34'>습관을 달성하는<br/>동기 부여가 부족하다면?</h1>
                        </div>
                        <div className='onboarding-imgbox'><img src={OnboardingImg03} alt="onboardingImage" /></div>
                        <div>
                            <button className='btn btn-lg mt-34 mb-34' onClick={goToNextSlide}>챌린지를 활용해보세요</button>
                            <Copyright/>
                        </div>
                    </SwiperSlide>

                    <SwiperSlide>
                        <div>
                            <h1 className='title-02 mb-34'>그럼 이제 하루달을<br />시작해 볼까요?</h1>
                        </div>
                        <div className='onboarding-imgbox'><img src={OnboardingImg04} alt="onboardingImage" /></div>
                        <div>
                            <button className='btn btn-lg mt-34 mb-34' onClick={goToLogin}>시작하기</button>
                            <Copyright/>
                        </div>
                    </SwiperSlide>

                </Swiper>
            </div>

        </>
    )
}


export default Onboarding;