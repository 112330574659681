
import { useNavigate } from 'react-router';
import Typography from '../../components/common/Typography';
import { ChallengeProps, RoutineProps } from '../../features/routine/types/routineDayType';
import Checkbox from '../common/Checkbox';

import './routinecomponent.css';
import Swal from 'sweetalert2';
import { useSelector } from 'react-redux';
import { RootState } from '../../store';
import dayjs from 'dayjs';

interface RoutineListProps {
    routine: RoutineProps | ChallengeProps;
    toggleRoutineCompletion: (routineId: number) => void;
}

/**
 * 루틴 체크 컴포넌트
 */
const RoutineCheck: React.FC<RoutineListProps> = ({
    routine,
    toggleRoutineCompletion
}) => {
    
    const navigate = useNavigate();
    const selectedDay = useSelector((state: RootState) => state.routine.selectedDay);
    const today = dayjs();

    const handleRoutineCheckBox = (id:number) => {

        if(dayjs(selectedDay).isAfter(today,'day')){
            Swal.fire({
                text: '현재 날짜 이후의 루틴은 달성할 수 없어요',
                showCancelButton: false,
                confirmButtonText: '돌아가기',
            })
            return;
        }

        // 챌린지인 경우
        if (isChallenge(routine)) {
            if (routine.repeatedNumber === routine.completedNumber) {
                Swal.fire({
                    text: '이미 달성한 챌린지예요',
                    showCancelButton: false,
                    confirmButtonText: '돌아가기',
                })
                return;
            }

            // 달성 시 효과 주기!
            if (!routine.isCompleted &&routine.repeatedNumber - routine.completedNumber === 1) {
                Swal.fire({
                    text: '정말 챌린지를 달성하셨나요? 한번 달성하면 취소가 불가능해요',
                    showCancelButton: true,
                    reverseButtons: true,
                    confirmButtonText: '완료하기',
                    cancelButtonText: '돌아가기'
                }).then((result) => {
                    if (result.isConfirmed) {
                        toggleRoutineCompletion(id);
                    }
                });
                
                return;
            }
        }
        toggleRoutineCompletion(id)
    }

    const isChallenge = (routine: RoutineProps | ChallengeProps): routine is ChallengeProps => {
        return (routine as ChallengeProps).repeatedNumber !== undefined;
    }

    const handleNavigation = (id: number) => {
        if (isChallenge(routine)) {
            navigate(`/challenge/${id}`);
        } else {
            navigate(`/routineday/${id}`);
        }
    }

    return (
        <>
            <li className="card mb-16">
                <Typography className='mb-4 mt-4' variant='caption'>{routine.categoryName}</Typography>
                <div className="list-left" onClick={() => handleNavigation(routine.id)}>
                    <Typography variant='h5'>{routine.emoji}</Typography>
                    <Typography variant='h5' className='ml-8'>{routine.name}</Typography>
                    
                    {
                        // 달성 된 경우 다르게 표현??
                        isChallenge(routine) && (
                        <Typography variant='caption' className='ml-4'>({routine.completedNumber}/{routine.repeatedNumber})</Typography>
                        )
                    }
                </div>
                <div className="list-right">
                    <Checkbox id={'routine'+routine.id.toString()} checked={routine.isCompleted} onChange={() => handleRoutineCheckBox(routine.id)}/>
                </div>
            </li>
        </>
    )
}

export default RoutineCheck;