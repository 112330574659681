import React, { useContext, useEffect, useState } from 'react';
// import header styles
import '../default/header.css';
import { useNavigate } from 'react-router';
import DropMenu from '../../components/common/DropMenu';
import { useSelector } from 'react-redux';
import { RootState } from '../../store';
import { useDispatch } from 'react-redux';
import { setCalendarSelect, setDateManuallySelected, setSelectedDay, setShowRestMode } from '../../features/routine/stores/routine';
import DatePickerSheet from '../../components/routine/DatePickerSheet';
import { deleteRestMode } from '../../features/routine/services/insertRoutineService';
import { RoutineDetailProps } from '../../features/routine/types/routineInsertType';
import dayjs from 'dayjs';
import Swal from 'sweetalert2';

interface HeaderProps {
    handleDeleteRoutine? : () => void;
    routineDetail?: RoutineDetailProps | null;
}

const Header: React.FC<HeaderProps> = ({ handleDeleteRoutine, routineDetail }) => {
    const [showMainDropMenu, setShowMainDropMenu] = useState(false);
    const [showDetailDropMenu, setShowDetailDropMenu] = useState(false);
    const [showDatePicker, setShowDatePicker] = useState(false);
    const [dateFormat, setDateFormat] = useState<String>();
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const allRead = useSelector((state: RootState) => state.notification.allRead);
    
    const selectedDay = useSelector((state:RootState) => state.routine.selectedDay);
    const layout = useSelector((state:RootState) => state.layout.layout);
    const page = useSelector((state:RootState) => state.layout.page);

    // selectDay 최초 세팅
    useEffect(() => {
        if(selectedDay == ''){
            dispatch(setSelectedDay(new Date().toISOString()));
        }
    },[])

    useEffect(() => {
        setDateFormat(formatDate(selectedDay));
    },[selectedDay])

    const handleChallengeEdit = () => {
        if (routineDetail && routineDetail.type === 'ACHIEVEMENT' && routineDetail.completedNumber === routineDetail.repeatedNumber) {
            Swal.fire({
                text: '완료된 챌린지는 수정할 수 없어요',
                confirmButtonText: '돌아가기',
            });
            setShowDetailDropMenu(false);
            return;
        }
        setShowDetailDropMenu(false);
        navigate('/routineadd', { state: { routineDetail, editMode: true } });
    };

    const handleChallengeRest = () => {
        if (routineDetail && routineDetail.type === 'ACHIEVEMENT' && routineDetail.completedNumber === routineDetail.repeatedNumber) {
            Swal.fire({
                text: '완료된 챌린지는 휴식모드를 지정할 수 없어요',
                confirmButtonText: '돌아가기',
            });
            setShowDetailDropMenu(false);
            return;
        }
        setShowDetailDropMenu(false);
        dispatch(setShowRestMode(true));
    };

    const mainMenuItems = [
        { label:'일별보기',onClick:()=>{setShowMainDropMenu(false); navigate('/routine')} },
        { label:'습관 모아보기',onClick:()=>{setShowMainDropMenu(false); navigate('/routineweekly')} },
        { label:'챌린지 모아보기',onClick:()=>{setShowMainDropMenu(false); navigate('/challengelist')} },
        { label:'휴식중인 습관 보기',onClick:()=>{setShowMainDropMenu(false); navigate('/routinerest')} }
    ]

    const detailMenuItems = [
        ...(routineDetail?.isRest === false ? [{ label: '수정하기', onClick: handleChallengeEdit }] : []),
        ...((layout === 'detail' && routineDetail?.isRest == false )? [{ label: '휴식모드 지정', onClick: handleChallengeRest }] : []),
        ...((layout === 'detail' && routineDetail?.isRest == true )? [{ label: '휴식모드 해제',onClick:()=>{if (routineDetail?.idx) {deleteRestMode(routineDetail.idx)}; navigate('/routine')}}] : []),
        { label: '삭제하기',onClick:()=>{setShowDetailDropMenu(false); handleDeleteRoutine?.(); } }
    ];

    function formatDate(selectDate: string): string {
        const date = new Date(selectDate);

        const year = date.getFullYear(); // 연도를 가져옵니다.
        const month = date.getMonth() + 1; // 월을 가져옵니다. (getMonth()는 0부터 시작하므로 1을 더해줍니다.)

        // 최종 형식을 맞춰 문자열로 반환합니다.
        return `${year}년 ${month}월`;
    }

    function chageSelectedDate(date:Date) {
        dispatch(setSelectedDay(dayjs(new Date(date)).format('YYYY-MM-DD HH:mm:ss')));
        dispatch(setCalendarSelect(true));
        setShowDatePicker(false);
        dispatch(setDateManuallySelected(true));
    }

    return (
        <>
            {/* 
                scroll이 발생하면 header에 add class "scrolling"
             */}
            <header>
                <div className="nav-bar">
                    <ul className="nav-left">
                        {
                            layout === 'main' ? (
                                <>
                                    <li onClick={() => setShowDatePicker(!showDatePicker)}>
                                        <div className="nav-changedate">
                                            <h3 className="pl-8 subtit-02">{dateFormat}</h3>
                                            <button className="nav-icon btn-changedate"></button>
                                        </div>
                                    </li>
                                </>
                            ) : (
                                <>
                                    {
                                        (layout === 'rest') ? (
                                            <li className="left-back">
                                                <button className="nav-icon btn-back" onClick={() => navigate('/routine')}></button>
                                            </li>
                                        ) : (
                                            <li className="left-back">
                                                <button className="nav-icon btn-back" onClick={() => navigate(-1)}></button>
                                            </li>
                                        )
                                    }
                                    <li className="title-center">
                                        <div className="nav-dropbox">
                                            <h3 className="subtit-02">{page}</h3>
                                        </div>
                                    </li>
                                </>
                            )
                        }
                    </ul>
                    <ul className="nav-right">
                        {
                            layout == 'main' &&
                            <>
                                <li onClick={() => navigate('/search')}><button className="nav-icon btn-search"></button></li>
                                <li onClick={() => navigate('/notification')}>
                                    <button className={`ml-8 nav-icon btn-noti ${!allRead ? 'active' : ''}`}></button>
                                </li>                                
                                <li><button className="ml-8 nav-icon btn-layout" onClick={() => setShowMainDropMenu(!showMainDropMenu)}></button></li>
                            </>
                        }
                        {
                            (layout == 'detail' || layout == 'challengeDetail') && 
                            <>
                                <li><button className="nav-icon btn-edit" onClick={() => setShowDetailDropMenu(!showDetailDropMenu)}></button></li>
                            </>
                        } 
                    </ul>
                </div>

                {showMainDropMenu && <DropMenu items={mainMenuItems} background={true} style={{position: 'fixed'}} showDropMenu={setShowMainDropMenu}/>}

                {showDetailDropMenu && <DropMenu items={detailMenuItems} background={true} style={{position: 'fixed'}} showDropMenu={setShowDetailDropMenu}/>}

                {showDatePicker && <DatePickerSheet selectedDay={new Date(selectedDay)} changeSelectedDay={chageSelectedDate} setShowDatePicker={setShowDatePicker}/>}
            </header>
        </>
    );
};

export default Header;
