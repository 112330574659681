import React from 'react';
import BgOverlay from "../event/BgOverlay";
import InpDatePicker from "./InpDatePicker";
import '../event/datepicker.css';

interface DatePickerSheetProp {
    setDayModal: (isOpen: boolean) => void;
    onDateSelect: (date: Date | null) => void;
    startDate?: Date | null;
    currentDate: Date | null | undefined;
}

const DatePickerSheet: React.FC<DatePickerSheetProp> = ({ setDayModal, onDateSelect, startDate, currentDate }) => {
    const handleClose = () => {
        setDayModal(false);
    };

    return (
        <>
            <div className='card card-drawer' onClick={(event) => event.stopPropagation()}>
                <div className='sheet-header'>
                    <div className='sheet-handle'></div>
                </div>
                <div className='tabs-content'>
                    <InpDatePicker setDayModal={setDayModal} startDate={startDate} currentDate={currentDate} onDateSelect={onDateSelect} />
                </div>
            </div>
            <BgOverlay close={handleClose} />
        </>
    );
};

export default DatePickerSheet;
