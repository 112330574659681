import React from 'react';
import BgOverlay from './BgOverlay';
// import BgOverlay from './BgOverlay';

interface DropMenuItem {
  label: string;
  onClick: () => void;
}

interface DropMenuProps {
  items: DropMenuItem[];
  background?:boolean;
  style?:object;
  showDropMenu?: (show: boolean) => void;
}
/** 
 * 사용예시: 
 * const menuItems = [
    { label: "연도별 보기", onClick: () => console.log("연도별 보기") },
    { label: "월별 모아보기", onClick: () => console.log("월별 모아보기") },
    { label: "월별로 보기", onClick: () => console.log("월별로 보기") },
  ];
 * <DropMenu items={menuItems} />; 
 * */
const DropMenu: React.FC<DropMenuProps> = ({ items, background = false, style = {},showDropMenu = () => {} }) => {
  return (
    <>
    <div className='drop-out' style={style}>
        <div className='drop-menu'>
          <ul>
            {items.map((item, index) => (
              <li key={index} onClick={item.onClick}>
                <a>{item.label}</a>
              </li>
            ))}
          </ul>
        </div>
      </div>
      { background && <BgOverlay close={() => showDropMenu(false)} />}
    </>
  );
}

export default DropMenu;