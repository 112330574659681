import { useState } from "react";
import { userDetailErrors, insertUserDetailType } from "../types/userDetailType";
import { validateNickname, validateProfile } from "../services/userDeatilService";

export const useUserDetailForm = (initUserDetail: insertUserDetailType) => {
    const [values, setValues] = useState<insertUserDetailType>(initUserDetail);
    const [errors, setErrors] = useState<userDetailErrors>({});
    const [imageSrc, setImageSrc] = useState<string>('');

    const handleImageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const file = event.target.files ? event.target.files[0] : null;
    
        const error = validateProfile(file);
        if (!error) {
          setValues(prevState => ({ ...prevState, profile: file }));
          const reader = new FileReader();
          reader.onload = (loadEvent) => setImageSrc(loadEvent.target?.result as string);
          if(file) reader.readAsDataURL(file);
        }
        setErrors(prevState => ({ ...prevState, profile: error }));
    };

    const handleInputNickname = (event: React.ChangeEvent<HTMLInputElement>) => {
        const nickName = event.target.value;
        const error = validateNickname(nickName);

        setErrors(prevState => ({ ...prevState, nickName: error }));
        if (!error) {
        setValues(prevState => ({ ...prevState, nickName }));
        }
    }

    const selectValue = (type: "gender" | "age", value: any) => {
        setValues(prevState => ({ ...prevState, [type]: value }));
    };

    const setActive = (type: "gender" | "age", value: any) => {
        return (type === 'gender' && value === values.gender) || (type === 'age' && value === values.age) ? 'active' : '';
    };

    return { values, setErrors, errors, imageSrc, handleImageChange, handleInputNickname, selectValue, setActive };

}