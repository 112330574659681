import { getData } from "../../../apis/api"
import { Challenge, ChallengeStatProps } from "../types/challengeType";


export const findAllChallenge = async (status: boolean) => {
    const response = await getData<Challenge[]>(`/routine/challenge?page=1&size=50&status=${status}`);

    return response;
};

export const findChallengeStatistics = async () => {
    const response = await getData<ChallengeStatProps[]>(`/routine/challengeStat`);

    return response.data;
};