import Button from '../common/Buttons';
import Typography from '../common/Typography';
import './notificationcomponent.css';
import { NotificationType } from '../../features/notification/types/notificationType';
import useNotificationHook from '../../features/notification/hooks/useNotification.hook';
import SelectCalendarSheet from '../common/SelectCalendarSheet';
import { useState } from 'react';
import { Calendar } from '../../features/multiCalendar/types/calendarType';

function InviteNotiCard(props: NotificationType) {
    const {
        formatNotificationDate,
        handleAcceptReject,
        handleCardClick,
        isRead,
        isSelectCalendarSheetOpen,
        setIsSelectCalendarSheetOpen,
    } = useNotificationHook(props.isRead);

    const closeCalendarSheet = () => {
        setIsSelectCalendarSheetOpen(false);
    };

    return (
        <>
            <div className={`card noti-card ${isRead ? 'noti-read' : ''}`} onClick={() => handleCardClick(props.idx)}>
                <div className="noti-card-top mb-8">
                    <Typography variant="body2" className="noti-tit">
                        <span className="mr-8">❤</span>
                        {props.type === 'CALENDAR' ? '캘린더 초대' : '일정 초대'}
                    </Typography>
                    <Typography variant="body2" className="noti-date">
                        {formatNotificationDate(props.date)}
                    </Typography>
                </div>
                <Typography variant="h5" className="mb-8">
                    {props.content} 멤버로 입장하시겠어요?
                </Typography>
                <div className="noti-card-bottom">
                    <Button
                        variant="outline"
                        size="sm"
                        className="mr-8"
                        onClick={() => handleAcceptReject(props.type, props.relateId, false)}
                    >
                        거절
                    </Button>
                    <Button
                        size="sm"
                        onClick={() => {
                            if (props.type === 'CALENDAR') {
                                handleAcceptReject(props.type, props.relateId, true);
                            } else {
                                setIsSelectCalendarSheetOpen(true);
                            }
                        }}
                    >
                        입장하기
                    </Button>
                </div>
            </div>

            {isSelectCalendarSheetOpen && (
                <SelectCalendarSheet
                    close={closeCalendarSheet}
                    onClick={(calendar: Calendar) => handleAcceptReject(props.type, props.relateId, true, calendar.idx)}
                />
            )}
        </>
    );
}

export default InviteNotiCard;
