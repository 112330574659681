// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* 
    waiting-for-harudal
 */

 body:has(.grid-20.waiting-for-harudal) header{
    display: none;
 }

.grid-20.waiting-for-harudal {
    margin-top: 0;
    margin-bottom: 68px;
    /* width: 100vw; */
    height: calc(100vh - 68px);
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
}

.waiting-for-harudal .bounce-harudal {
    position: relative;
    top: 20px;
    width: 100px;
    border-radius: 50%;
    box-shadow: 0 10px 10px rgba(0, 0, 0, .2);
    animation: bbounce .4s ease infinite alternate;
    margin-bottom: 64px;
}

@keyframes bbounce {
    100% {
        top: -20px;
        box-shadow: 0 50px 25px rgba(0, 0, 0, .1);
    }
}

.waiting-text{
    position: fixed;
    top: 50%;
    transform: translateY(-50%);
    left: 0;
    right: 0;
    text-align: center;
}

.waiting-text button{
    margin: 0 auto;
    margin-top: 2rem;
}`, "",{"version":3,"sources":["webpack://./src/pages/diary/waiting.css"],"names":[],"mappings":"AAAA;;EAEE;;CAED;IACG,aAAa;CAChB;;AAED;IACI,aAAa;IACb,mBAAmB;IACnB,kBAAkB;IAClB,0BAA0B;IAC1B,gBAAgB;IAChB,aAAa;IACb,mBAAmB;IACnB,uBAAuB;AAC3B;;AAEA;IACI,kBAAkB;IAClB,SAAS;IACT,YAAY;IACZ,kBAAkB;IAClB,yCAAyC;IACzC,8CAA8C;IAC9C,mBAAmB;AACvB;;AAEA;IACI;QACI,UAAU;QACV,yCAAyC;IAC7C;AACJ;;AAEA;IACI,eAAe;IACf,QAAQ;IACR,2BAA2B;IAC3B,OAAO;IACP,QAAQ;IACR,kBAAkB;AACtB;;AAEA;IACI,cAAc;IACd,gBAAgB;AACpB","sourcesContent":["/* \n    waiting-for-harudal\n */\n\n body:has(.grid-20.waiting-for-harudal) header{\n    display: none;\n }\n\n.grid-20.waiting-for-harudal {\n    margin-top: 0;\n    margin-bottom: 68px;\n    /* width: 100vw; */\n    height: calc(100vh - 68px);\n    overflow: hidden;\n    display: flex;\n    align-items: center;\n    justify-content: center;\n}\n\n.waiting-for-harudal .bounce-harudal {\n    position: relative;\n    top: 20px;\n    width: 100px;\n    border-radius: 50%;\n    box-shadow: 0 10px 10px rgba(0, 0, 0, .2);\n    animation: bbounce .4s ease infinite alternate;\n    margin-bottom: 64px;\n}\n\n@keyframes bbounce {\n    100% {\n        top: -20px;\n        box-shadow: 0 50px 25px rgba(0, 0, 0, .1);\n    }\n}\n\n.waiting-text{\n    position: fixed;\n    top: 50%;\n    transform: translateY(-50%);\n    left: 0;\n    right: 0;\n    text-align: center;\n}\n\n.waiting-text button{\n    margin: 0 auto;\n    margin-top: 2rem;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
