import { CSSProperties, MouseEvent, ReactNode } from "react";


// Chips 컴포넌트의 props 타입 정의
interface ChipsProps {
    className?: string;
    type?: "error" | "warning" | "success" | "info";
    color?: "red" | "orange" | "yellow" | "green" | "blue" | "purple" | "grey" | "black";
    size?: "sm";
    children?: ReactNode;
    onClick?: (e: MouseEvent) => void;
    style?: CSSProperties;
}

const Chips: React.FC<ChipsProps> = ({ 
    className = '', 
    type = '', 
    color = '', 
    size = '', 
    children, 
    onClick,
    style  
}) => {
    const prefix = 'chips-';
    const classes = [
        'chips',
        type && `${type}`,
        color && `${color}`,
        size && `${prefix}${size}`,
        className
    ].filter(Boolean).join(' ');


    return (
        <div
            className={classes}
            onClick={onClick}
            style={style}
        >
            {children}
        </div>
    )
}

export default Chips;