import { getData, patchData } from "../../../apis/api";
import { LoginUser, UserInfo } from "../../user/types/user";
import { GetAuth, GetLogin } from "../types/authType";


// accessToken 재발급
export const refreshAccessToken = async (): Promise<void> => {
    try {
        const refreshToken = localStorage.getItem('refreshToken');
        if(!refreshToken) {
            throw new Error('refershToken이 없습니다.')
        }

        const response = await getData<GetAuth>('/auth/refresh?refresh-token='+refreshToken);

        const auth: GetAuth = response.data;

        localStorage.setItem('accessToken', auth.accessToken);
        localStorage.setItem('refreshToken', auth.refreshToken);
    } catch (error) {
        console.error(error);
        throw new Error('failed to refresh accessToken');
    }
}

// accessToken 가져오기
export const getAccessToken = (): string | null => {
    return localStorage.getItem('accessToken');
}

// refreshToken 가져오기
export const getRefershToken = (): string | null => {
    return localStorage.getItem('refreshToken');
}

// 로그인
const login = (accessToken:string, refreshToken:string)=> {
    localStorage.setItem('accessToken',accessToken);
    localStorage.setItem('refreshToken',refreshToken);
}

const appLogin = (appToken:string) => {
    try {
        if(window.AndroidApp) {
            window.AndroidApp.setAppToken(appToken);
        } 
        
    } catch (error) {
        console.log(error);
    }
}

export const getAppToken = async () => {
    try {
        if(window.AndroidApp) {
            const appToken = window.AndroidApp.getPushToken();
            const response = await patchData('/user/app-token',{appToken})

            console.log(response);
        }

        window.callbackPushTokeniOS = callbackPushTokeniOS;
        
    } catch (error) {
        console.log(error);
    }
}

//ios pushToken 받아오기
const callbackPushTokeniOS = async(data:any) => {
    console.log(data);
    const appToken = data;
    const response = await patchData('/user/app-token',{appToken})

    console.log(response);
}

// 로그아웃
export const sessionLogout= () => {
    localStorage.removeItem('accessToken');
    localStorage.removeItem('refreshToken');
}

// 네이버 로그인 링크
export const naverLoginLink = (state:string): void => {

    sessionLogout();
    localStorage.setItem('naverState',state);

    window.location.href = `${process.env.REACT_APP_NAVER_LOGIN_URL}?client_id=${process.env.REACT_APP_NAVER_CLIENT_ID}&response_type=code&redirect_uri=${process.env.REACT_APP_NAVER_REDIRECT_URI}&state=${state}
    `;
}

// 네이버 로그인 처리
export const naverLogin = async (state:string | null, code:string | null): Promise<LoginUser> => {

    const url = `/auth/naver-login?code=${code}&state=${state}`;
    const response = await getData<GetLogin>(url);
    console.log(response);
    const loginInfo:GetLogin = response.data;

    login(loginInfo.accessToken,loginInfo.refreshToken);
    appLogin(loginInfo.appToken);

    const user:LoginUser = {
        name:loginInfo.name,
        nickname:loginInfo.name,
        uuid:loginInfo.uuid,
        isUserDetail: loginInfo.isUserDetail,
        profile: loginInfo.profile,
        isMulti: false,
    }
    return user;
}

// 구글 로그인 링크
export const googleLoginLink = (state:string): void => {

    sessionLogout();
    localStorage.setItem('googleState',state);

    window.location.href = `${process.env.REACT_APP_GOOGLE_LOGIN_URL}?client_id=${process.env.REACT_APP_GOOGLE_CLIENT_ID}&response_type=code&scope=https%3A%2F%2Fwww.googleapis.com%2Fauth%2Fuserinfo.email%20https%3A%2F%2Fwww.googleapis.com%2Fauth%2Fuserinfo.profile&state=${state}&redirect_uri=${process.env.REACT_APP_GOOGLE_REDIRECT_URI}`;

}

// 구글 로그인 처리
export const googleLogin = async (code:string | null): Promise<LoginUser> => {

    const url = `/auth/google-login?code=${code}`;
    const response = await getData<GetLogin>(url);

    const loginInfo:GetLogin = response.data;

    login(loginInfo.accessToken,loginInfo.refreshToken);

    const user:LoginUser = {
        name:loginInfo.name,
        uuid:loginInfo.uuid,
        isUserDetail: loginInfo.isUserDetail,
        profile:loginInfo.profile,
        isMulti: false,
    }
    return user;
}

// 카카오 로그인 링크
export const kakaoLoginLink = () => {

    sessionLogout();
    window.location.href = `${process.env.REACT_APP_KAKAO_LOGIN_URL}?client_id=${process.env.REACT_APP_KAKAO_API_KEY}&redirect_uri=${process.env.REACT_APP_KAKAO_REDIRECT_URI}&response_type=code`;
}


// 카카오 로그인 처리
export const kakaoLogin = async (code:string | null): Promise<LoginUser> => {

    const url = `/auth/kakao-login?code=${code}`;
    const response = await getData<GetLogin>(url);

    const loginInfo:GetLogin = response.data;

    login(loginInfo.accessToken,loginInfo.refreshToken);

    const user:LoginUser = {
        name:loginInfo.name,
        uuid:loginInfo.uuid,
        isUserDetail: loginInfo.isUserDetail,
        profile: loginInfo.profile,
        isMulti: false,
    }
    return user;
}


// 애플 로그인 링크
export const appleLoginLink = (state:string) => {

    sessionLogout();
    window.location.href = `${process.env.REACT_APP_APPLE_LOGIN_URL}?client_id=${process.env.REACT_APP_APPLE_CLIENT_ID}&state=${state}&redirect_uri=${process.env.REACT_APP_APPLE_REDIRECT_URI}&response_type=code&scope=`;
}

// 애플 로그인 처리
export const appleLogin = async (code:string | null): Promise<LoginUser> => {
    console.log(code);
    const url = `/auth/apple-login?code=${code}`;
    const response = await getData<GetLogin>(url);

    const loginInfo:GetLogin = response.data;

    login(loginInfo.accessToken,loginInfo.refreshToken);

    const user:LoginUser = {
        name:loginInfo.name,
        uuid:loginInfo.uuid,
        isUserDetail: loginInfo.isUserDetail,
        profile: loginInfo.profile,
        isMulti: false,
    }
    return user;
}



// 회원 정보 가져오기
export const getUserInfo = async (): Promise<UserInfo> => {

    const response = await getData<UserInfo>('/user/me');

    const userInfo:UserInfo = response.data;

    return userInfo;
}

