import { getData } from "../../../apis/api";
import { Holiday } from "../../event/stores/eventType";

export const getSchedule = async (startDate: Date, endDate: Date) => {
    try {
        const isoStartDate = startDate.toISOString();
        const isoEndDate = endDate.toISOString();

        const response = await getData<Array<number>>(`/event?start-date=${isoStartDate}&end-date=${isoEndDate}`);
        
        return response.data;
    } catch (error) {
        console.error(error);
        throw new Error('failed to get schedule');
    }
}

export const setSelectSchedule = async (curDate: Date) => {
    try {
        const isoCurDate = curDate.toISOString();
        const response = await getData<Array<number>>(`/event/daily?date=${isoCurDate}`);

        return response.data;
    } catch (error) {
        console.error(error);
        throw new Error('failed to get schedule');
    }
}

export const setMonthSchedule = async (year: number, month: number) => {
    try {
        const lastDay = new Date(year, month + 1, 0).getDate();
        
        const isoCurDate = `${year}-${month < 10?'0'+month:month}-01T00:00:00.000Z`;
        const isoEndDate = `${year}-${month < 10?'0'+month:month}-${lastDay}T00:00:00.000Z`
        const response = await getData<Array<number>>(`/event/monthly?start-date=${isoCurDate}&end-date=${isoEndDate}`);
        return response;
    } catch (error) {
        console.error(error);
        throw new Error('failed to get schedule');
    }
}

export const getHolidays = async (year:string, month:string):Promise<Holiday[] | undefined> => {
    try {
        const response = await getData<Holiday[]>(`/event/rest?year=${year}&month=${month}`);
        
        if(response.status != 200) {
            // 에러처리
        }

        return response.data;

    } catch (error) {
        // 에러처리
    }
}
