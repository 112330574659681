import React, { useState, useEffect, Dispatch, SetStateAction } from 'react';

interface ResizableBarProps {
}


const ResizableBar: React.FC<ResizableBarProps> = () => {
    const [isDragging, setIsDragging] = useState<boolean>(false);
    const [lastY, setLastY] = useState<number>(0);

    useEffect(() => {
        const handleMouseMove = (e: MouseEvent | TouchEvent) => {
            if (isDragging) {
                const clientY = e instanceof MouseEvent ? e.clientY : e.touches[0].clientY;
                const deltaY = clientY - lastY;
                setLastY(clientY);
            }
        };

        document.addEventListener('touchmove', handleMouseMove);

        return () => {
            document.removeEventListener('touchmove', handleMouseMove);
        };
    }, [isDragging, lastY]);

    const handleTouchStart = (e: React.TouchEvent<HTMLDivElement>) => {
        setIsDragging(true);
        setLastY(e.touches[0].clientY);
    };

    // const handleTouchEnd = (e: React.TouchEvent<HTMLDivElement>) => {
    //     console.log(lastY);
    //     console.log(e.changedTouches[0].clientY);
    //     handleResizeEnd()
    // };

    return (
        <div className='sheet-header'
        >
            <div className='sheet-handle'></div>
        </div>
    );
};

export default ResizableBar;
