
function Copyright() {

    return (
        <p className="copyright">
            Copyright. HARUDAL All rights reserved.
        </p>
    )
}

export default Copyright;