import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { generateCalendar } from "../../utils/calendar/CalendarUtils"
import CalendarCompoent from "./CalendarCompoent";
// import calendarcomponent styles
import './calendarcomponent.css';
import { useDispatch } from "react-redux";
import { updateYearMonth } from "../../features/day/stores/day";
import { useNavigate } from "react-router-dom";
import { Holiday } from "../../features/event/stores/eventType";
import { getHolidays } from "../../features/schedule/service/scheduleService";

interface YearsCompoentProps {
    detectChangeSwiper: number;
    nowYear: number;
}

const YearsCompoent: React.FC<YearsCompoentProps> = ({detectChangeSwiper, nowYear}) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const selectDayReducer = useSelector((dateReducer: any) => dateReducer.day);
    const [calendar, setCalendar] = useState<number[][][]>();
    const [months, setMonths] = useState<Date[]>([new Date()]);
    const [holidayList,setHolidayList] = useState<Holiday[]>([]);

    const selectMonthMoveToMain = (month: number) => {
        const curDate = formatDate(new Date());
        let weekIndex = new Date(`${nowYear}-${month < 10 ? '0' + month : month}-01`).getDay();

        if (parseInt(curDate.split('-')[0]) === nowYear && parseInt(curDate.split('-')[1]) === month) {
            weekIndex = new Date().getDay();
            dispatch(updateYearMonth(nowYear, month, parseInt(curDate.split('-')[2]), weekIndex));
        } else {
            dispatch(updateYearMonth(nowYear, month, 1, weekIndex));
        }
        
        navigate("/calendar");
    }

    useEffect(() => {

        const allMonths = [];
        for (let month = 0; month < 12; month++) {
            allMonths.push(new Date(nowYear, month, 1));
            getHolidays(nowYear.toString(),(month+1).toString()).then((data) => {
                const newData = (data ?? []);
                setHolidayList((prevHolidayList) => [...prevHolidayList, ...newData]);
            });
        }
        
        setMonths(allMonths)
    }, [nowYear]);

    useEffect(() => {
        setCalendar(() => {
            const yearCal = [];
            for (let i = 0; i < 12; i++) {
                yearCal[i] = generateCalendar(selectDayReducer.year, i + 1);
            };
            return yearCal
        });

    }, [detectChangeSwiper]);

    // 날짜 형식 변경 (YYYY-MM-DD 형식)
    const formatDate = (date: Date) => {
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');
        return `${year}-${month}-${day}`;
    };

    return (
        <>
            <div className='years-paper'>
                <ul>
                    {months?.map((cal: Date, idx: number) => (
                        <li key={idx} className="years-itembox" onClick={() => selectMonthMoveToMain(idx+1)}>
                            <h3 className="subtit-01 pl-8 mt-8">{idx + 1}월</h3>
                            <CalendarCompoent calendar={cal} type="year" currentHolidays={holidayList} />
                        </li>
                    ))}
                </ul>
            </div>
        </>
    )
}

export default YearsCompoent;