import { useState } from "react";
import { Interests, interestType, updateUserDetailType } from "../types/userDetailType";
import { getUserInfo } from "../../auth/services/authService";
import { findInterests, interestsSave } from "../../auth/services/interestsService";
import { updateUserDetail, userWithdrawal } from "../services/userDeatilService";
import { useNavigate } from "react-router-dom";
import useAuth from "../../../hooks/useAuth";

const initUserInfo:updateUserDetailType = {
    age:10,
    gender:'MALE',
    nickname:'',
    profile:null
}

export const useUserInfo = () => {
    const { logout } = useAuth();
    const navigate = useNavigate();

    const [values, setValues] = useState<updateUserDetailType>(initUserInfo); 
    const [interests, setInterests] = useState<Interests[]>([]);
    const [imageSrc, setImageSrc] = useState<string>('');
    const [selectedInterests, setSelectedInterests] = useState<string[]>([]);
    const [showAgeMenu, setShowAgeMenu] = useState(false); 
    const [showGenderMenu, setShowGenderMenu] = useState(false); 

    const genderOptions = [
        { label: '남성', value: 'MALE' },
        { label: '여성', value: 'FEMALE' }
    ];

    const ageOptions = [
        { label: '10대', value: 10 },
        { label: '20대', value: 20 },
        { label: '30대', value: 30 },
        { label: '40대 이상', value: 40 }
    ];

    const interestList:Array<interestType> = [
        {emoji: '📚', text:'공부하기'},
        {emoji: '🏃‍♀️', text:'운동하기'},
        {emoji: '💻', text:'업무보기'},
        {emoji: '🍔', text:'패스트푸드'},
        {emoji: '🍜', text:'누들'},
        {emoji: '🍰', text:'디저트'},
        {emoji: '🏖', text:'바다'},
        {emoji: '🏞', text:'산'},
        {emoji: '✈', text:'여행'},
        {emoji: '🌃', text:'야경보기'},
        {emoji: '🌆', text:'노을보기'},
        {emoji: '🏊‍♂️', text:'수영하기'},
        {emoji: '🎨', text:'그림그리기'},
        {emoji: '🚿', text:'샤워하기'},
        {emoji: '💸', text:'돈모으기'}
    ];

    const fetchUserInfo = async () => {
        try {
            const response = await getUserInfo(); 
            setValues({
                profile: null, 
                nickname: response.nickname ?? '', 
                gender: response.gender,
                age: response.age,
            });
            if (response.profile) {
                setImageSrc(response.profile);
            }
        } catch (error) {
            console.error('사용자 정보를 불러오는데 실패하였습니다', error);
        }
    };

    const fetchInterests = async () => {
        try {
            const response = await findInterests();
            setInterests(response.data);
        } catch (error) {
            console.error('관심사를 불러오는데 실패하였습니다', error);
        }
    };

    const getInterestEmoji = (interestText: string) => {
        const interest = interestList.find(item => item.text === interestText);
        return interest ? interest.emoji : '';
    };

    const handleInterestClick = (interest: interestType) => {
        setSelectedInterests(prevSelected =>
            prevSelected.includes(interest.text)
                ? prevSelected.filter(item => item !== interest.text)
                : [...prevSelected, interest.text]
        );
    };

    const handleNicknameChange = ({ target: { value } }: React.ChangeEvent<HTMLInputElement>) => {
        setValues({ ...values, nickname: value });
    };

    const handleGenderChange = (value: string) => {
        setValues({ ...values, gender: value });
        setShowGenderMenu(false);
    };

    const handleAgeChange = (value: number) => {
        setValues({ ...values, age: value });
        setShowAgeMenu(false);
    };

    const handleImageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const file = event.target.files ? event.target.files[0] : null;
        setValues(prevState => ({ ...prevState, profile: file }));
        const reader = new FileReader();
        reader.onload = (loadEvent) => setImageSrc(loadEvent.target?.result as string);
        if(file) reader.readAsDataURL(file);
    };

    const handleUpdate = async () => {
        try {
            await updateUserDetail(values);
            await interestsSave(selectedInterests);
            navigate('/mypage', { state: { edit: true } });
        } catch (error) {
            console.error('유저 정보 수정에 실패하였습니다', error);
        }
    };

    const handleLogout = () => {
        logout();
        navigate('/login');
    };

    const handleWithdrawal = () => {
        userWithdrawal((success: any) => {
            if (success) {
                handleLogout(); 
            }
        });
    };

    return { 
        values, 
        setValues, 
        imageSrc,
        interests, 
        fetchUserInfo, 
        fetchInterests, 
        setShowGenderMenu, 
        showGenderMenu, 
        setShowAgeMenu, 
        showAgeMenu, 
        ageOptions, 
        handleAgeChange, 
        genderOptions, 
        handleGenderChange, 
        interestList,
        selectedInterests, 
        setSelectedInterests,  
        handleInterestClick,
        handleLogout, 
        handleUpdate, 
        handleWithdrawal,
        handleNicknameChange,
        handleImageChange,
        getInterestEmoji
    };

}