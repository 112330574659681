// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* 
    notification css
 */

 /* notification layout */
.notification-wp{
    min-height: calc(100vh - 4.5rem);
    margin: 4.5rem 0 0 0;
}

body:has(.notification-wp) footer .bottom-bar {
    display: none;
}

.bnr-noti{
    background-color: var(--background-01);
    padding: 1.25rem;
    text-align: center;
}

.bnr-noti p{
    color: var(--color-text-04);
    font-weight: var(--font-semibold);
}

.noti-value{
    color: var(--color-text-04);
}`, "",{"version":3,"sources":["webpack://./src/pages/notification/notification.css"],"names":[],"mappings":"AAAA;;EAEE;;CAED,wBAAwB;AACzB;IACI,gCAAgC;IAChC,oBAAoB;AACxB;;AAEA;IACI,aAAa;AACjB;;AAEA;IACI,sCAAsC;IACtC,gBAAgB;IAChB,kBAAkB;AACtB;;AAEA;IACI,2BAA2B;IAC3B,iCAAiC;AACrC;;AAEA;IACI,2BAA2B;AAC/B","sourcesContent":["/* \n    notification css\n */\n\n /* notification layout */\n.notification-wp{\n    min-height: calc(100vh - 4.5rem);\n    margin: 4.5rem 0 0 0;\n}\n\nbody:has(.notification-wp) footer .bottom-bar {\n    display: none;\n}\n\n.bnr-noti{\n    background-color: var(--background-01);\n    padding: 1.25rem;\n    text-align: center;\n}\n\n.bnr-noti p{\n    color: var(--color-text-04);\n    font-weight: var(--font-semibold);\n}\n\n.noti-value{\n    color: var(--color-text-04);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
