// import calendarcomponent styles
import './calendarcomponent.css';
import useMultiCalendarHook from '../../features/multiCalendar/hooks/useMultiCalendar.hook';
import { useEffect, useState } from "react";
import { NavLink, useLocation, useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import Header from '../../layout/multiCalendar/Header';
import { setLayout, setPage } from '../../store/layout';

const MultiCalendarSheet: React.FC = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const location = useLocation();

    const { calendars, handleCheckboxChange, handleSelectCalendars, setSelectedCalendars, selectedCalendars } = useMultiCalendarHook();
    const [settingMode, setSettingMode] = useState(location.state ? location.state.settingMode : false);


    useEffect(() => {
        dispatch(setLayout(settingMode ? 'calendarSelectEdit' : 'calendarSelect'));
        dispatch(setPage(settingMode ? '캘린더 설정하기' : '캘린더 선택하기'));
    }, [dispatch, settingMode]);

      
    useEffect(() => {
        const initialSelectedCalendars: { [calendarId: number]: boolean } = {};

        calendars.forEach(calendar => {
            initialSelectedCalendars[calendar.idx] = Boolean(calendar.selected);
        });

        setSelectedCalendars(initialSelectedCalendars);
    }, [calendars]);
    
    const handleFilterButtonClick = () => {
        setSettingMode(!settingMode);
    };
    
    return (
        <>
        {/* 
           캘린더 선택하기 : removeclass edit
           캘린더 편집하기 : addclass edit, remove btn-edit, remove checkbox
        */}
            <Header handleFilterButtonClick={handleFilterButtonClick} />
            <div className={`board-wp grid-20 multilist-sheet ${settingMode ? 'edit' : ''}`}>
                {/* 멀티캘린더 생성 */}
                <div className="card multi-add mt-20">
                    <p className="subtit-02">캘린더 생성하기</p>
                    <NavLink to="/multiCalendar">
                        <button className='btn btn-sm error btn-add'></button>
                    </NavLink>
                </div>
                {/* 멀티캘린더 리스트 */}
                <ul className='card multi-list mt-20'>
                    {calendars.map((calendar, index) => (
                        <li key={index} onClick={settingMode ? () => navigate('/multiCalendar', { state: { calendar, readMode: true } }) : undefined}>
                            <p className="subtit-02">
                                {calendar.emoji} {calendar.name}
                            </p>
                            <div className="checkbox small">
                                <input
                                    type="checkbox"
                                    id={`check${index + 1}`}
                                    checked={selectedCalendars[calendar.idx] || false}
                                    onChange={() => handleCheckboxChange(calendar.idx)}
                                />
                                <label htmlFor={`check${index + 1}`}></label>                             
                            </div>
                            {calendar.userGrade === "ADMIN" && (
                                <span className="admin">👑</span>
                                // <button className="btn btn-sm btn-edit" onClick={() => navigate('/multiCalendar', { state: { calendar, editMode: true } })}></button>
                            )}
                        </li>
                    ))}
                </ul>
                {/* 선택하기버튼 */}
                {!settingMode && <button className="btn btn-lg main mt-20" onClick={handleSelectCalendars}>선택하기</button>}
            </div>
        </>
    );
};

export default MultiCalendarSheet;
