
import Button from '../common/Buttons';
import Typography from '../common/Typography';
import IcRight  from "../../assets/images/icon/system/ic_expand_right.svg";
import './notificationcomponent.css';
import { NotificationType } from '../../features/notification/types/notificationType';
import useNotificationHook from '../../features/notification/hooks/useNotification.hook';
import { useEffect, useState } from 'react';
import { eventType } from '../../features/event/stores/eventType';
import { findEvent } from '../../features/event/services/eventService';
import { useNavigate } from 'react-router-dom';

function EventNotiCard(props: NotificationType) {
    const navigate = useNavigate();

    const { formatNotificationDate, handleCardClick, isNotificationRead } = useNotificationHook();
    const [event, setEvent] = useState<eventType | null>(null);

    useEffect(() => {
        const fetchEvent = async () => {
            const response = await findEvent(props.relateId);
            setEvent(response);
        };

        fetchEvent();
    }, [props.relateId]);

    const handleViewEvent = () => {
        if (event) {
            navigate('/event', {state: {mode: 'board', idx: event.idx}});
            handleCardClick(props.idx);
        }
    };
    
    const isRead = isNotificationRead(props.idx);

    return (
        <>
            <div className={`card noti-card ${isRead ? 'noti-read' : ''}`}>
                <div className='noti-card-top mb-8'>
                    <Typography variant='body2' className='noti-tit'><span className='mr-8'>📆</span>일정</Typography>
                    <Typography variant='body2' className='noti-date'>
                        {formatNotificationDate(props.date)}
                    </Typography>                
                </div>
                {event && (
                    <Typography variant='h5' className='mb-8'>{event.name} {event.remindTime}분 전이에요, 일정을 확인해 보세요!</Typography>
                )}
                <div className='noti-card-bottom'>
                    <Button variant='text' size='sm' onClick={handleViewEvent}>일정 보러가기<img src={IcRight} alt="icon" /></Button>
                </div>
            </div>
        </>
    )
}

export default EventNotiCard;