
import dayjs from 'dayjs';
import { DailyRoutineProps, WeeklyRoutineProps } from '../../features/routine/types/routineWeekType';
import Chips from '../common/Chips';
import Typography from '../common/Typography';
import './routinecomponent.css';
import Swal from 'sweetalert2';
import { useNavigate } from 'react-router-dom';

interface routineCardProps {
    index:number
    weeklyRoutine : WeeklyRoutineProps;
    routineCompleteChange : (day:string, id:number, idx:number) => void;
    startOfWeek: Date;
}


const weekListKr = ['일', '월', '화', '수', '목', '금', '토'];
const weekList = ["sunday", "monday", "tuesday", "wednesday", "thursday", "friday", "saturday"];;

const RoutineCard: React.FC<routineCardProps> = ({
    weeklyRoutine,
    routineCompleteChange,
    index,
    startOfWeek
}) => {

    const today = dayjs();
    const navigate = useNavigate();

    const routineClassName = (day:string) => {
        const dayItem = weeklyRoutine.dailyList.find(item => item.dayOfWeek === day);
        if(dayItem) {
            return dayItem.isCompleted ? '' : 'routine-fail'
        } else {
            return 'routine-not-found'
        }
    }

    const routineEmoji = (day:string) => {
        const dayItem = weeklyRoutine.dailyList.find(item => item.dayOfWeek === day);

        if (dayItem) {
            const restStartDate = weeklyRoutine.restStartDate;
            const restEndDate = weeklyRoutine.restEndDate;
            
            if (restStartDate && restEndDate) {
                const currentDate = dayjs(startOfWeek).add(weekList.indexOf(day), 'day').toDate();
                
                if (currentDate >= restStartDate && currentDate <= restEndDate) {
                    return (
                        <span style={{ fontSize: '14px', display: 'block'}}> 휴식 </span>
                    );
                }
            }

            return weeklyRoutine.emoji;
        } else {
            return ' '
        }
    }

    const handleRoutineComplete = async (day:string, id:number, idx:number) => {

        const start = dayjs(startOfWeek);
        const targetDate = start.add(idx, 'day');
        
        const dayItem = weeklyRoutine.dailyList.find(item => item.dayOfWeek === day);
    
        if (dayItem) {
            const restStartDate = dayjs(weeklyRoutine.restStartDate);
            const restEndDate = dayjs(weeklyRoutine.restEndDate);
    
            if (restStartDate.isValid() && restEndDate.isValid()) {
                const currentDate = dayjs(targetDate);
    
                if (currentDate.isSame(restStartDate, 'day') || currentDate.isSame(restEndDate, 'day') ||
                    (currentDate.isAfter(restStartDate, 'day') && currentDate.isBefore(restEndDate, 'day'))) {
                    return;
                }
            }
        }
    
        if (targetDate.isAfter(today, 'day')) {
            Swal.fire({
                text: '현재 날짜 이후의 루틴은 달성할 수 없어요',
                showCancelButton: false,
                confirmButtonText: '돌아가기',
            });
            
            return;
        }

        // completed를 취소하는 경우 경고창 표시
        if (dayItem?.isCompleted) {
            const result = await Swal.fire({
                text: '습관 달성을 취소하시겠어요?',
                showCancelButton: true,
                reverseButtons: true,
                confirmButtonText: '확인',
                cancelButtonText: '취소'
            });
    
            if (!result.isConfirmed) {
                return;
            }
        }

        routineCompleteChange(day, id, index);
    }

    if (!weeklyRoutine || !weeklyRoutine.dailyList || weeklyRoutine.dailyList.length === 0) {
        return null;
    }

    return (
        <>
            <div className='card'>
                <div className='routine-item mb-20'>
                    <Typography variant='h4' className='item-tit' onClick={() => navigate(`/routineday/${weeklyRoutine.id}`)}>{weeklyRoutine.name}</Typography>
                    <span className='item-lbl'>
                        {/* 
                            chips의 색상은 카테고리색상
                         */}
                        <Chips color={weeklyRoutine.color}>{weeklyRoutine.rate}%</Chips>
                    </span>
                </div>
                <ul className="week-day mb-8">
                    {
                        weekListKr.map((day,idx) => (
                            <li key={idx}>{day}</li>
                        ))
                    }
                    
                </ul>
                <ul className='week-mark'>
                    {/* 
                        routine-fail일 때 이벤트 추가(complete)
                     */}
                     {
                        weekList.map((day,idx) => (
                            <li key={idx} className={routineClassName(day)} onClick={() => handleRoutineComplete(day,weeklyRoutine.id,idx)}>
                                <Typography variant='h3'>{routineEmoji(day)}</Typography>
                            </li>
                        ))
                    }
                </ul>
            </div>
        </>
    )
}

export default RoutineCard;