import React, { useState, useRef, useEffect } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import './calendar.css';
import Tutorial from "../../components/calendar/Tutorial";
import CalendarComponent from "../../components/calendar/CalendarCompoent";
import DaylistSheet from '../../components/calendar/DaylistSheet';
import { useNavigate, useSearchParams, useLocation } from 'react-router-dom';
import { setLayout, setPage } from '../../store/layout';
import useAuth from '../../hooks/useAuth';
import useCalendarState from '../../features/schedule/hooks/useCalendarState';
import useTouchHandlers from '../../features/schedule/hooks//useTouchHandlers';
import useSlideChange from '../../features/schedule/hooks//useSlideChange';
import AOS from "aos";
import "aos/dist/aos.css";
import Button from '../../components/common/Buttons';
import SwiperCore from 'swiper';
import { Pagination } from 'swiper/modules';
import { useDispatch, useSelector  } from 'react-redux';
import { updateYearMonth } from '../../features/day/stores/day';
import { findAllCalendar } from '../../features/multiCalendar/services/multiCalendarService';
import { Calendar as CalendarType} from '../../features/multiCalendar/types/calendarType';

SwiperCore.use([Pagination]);

const Calendar: React.FC = () => {
    const { user, fetchUserData } = useAuth();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const location = useLocation();
    const selectDayReducer = useSelector((state: any) => state.day);
    const calendarStatus = location.state ? location.state.calendarStatus : null;

    const [searchParams] = useSearchParams();
    const isTutorial = searchParams.get('tutorial') === 'y';

    const [swiperClass, setSwiperClass] = useState<any | null>(null);
    const [dailylistSheetVisible, setDailylistSheetVisible] = useState<boolean>(calendarStatus !== 'Year');
    const [swiperContainer, setSwiperContainer] = useState<SwiperCore | null>(null);
    const [todayBtn, setTodayBtn] = useState(false);
    const [userCalendar, setUserCalendar] = useState<CalendarType>();

    const calTopRef = useRef<HTMLDivElement>(null);
    const subtit01Ref = useRef<HTMLHeadingElement>(null);
    const mainRef = useRef<HTMLButtonElement>(null);
    const listsRef = useRef<NodeListOf<HTMLUListElement>>(null);
    const itemsRef = useRef<NodeListOf<HTMLLIElement>>(null);

    const { months, currentHolidays } = useCalendarState(todayBtn, setTodayBtn, swiperContainer);
    const { setSwipeDrag, handleTouchStart, handleTouchMove, handleTouchEnd, handleDailylistSheetVisible } = useTouchHandlers(
        dailylistSheetVisible,
        setDailylistSheetVisible,
        swiperClass,
        calTopRef,
        subtit01Ref,
        mainRef,
        listsRef,
        itemsRef
    );
    const { handleSlideChange } = useSlideChange();

    useEffect(() => {
        dispatch(setLayout("Main"));
        dispatch(setPage(''));
        AOS.init();
    }, []);

    useEffect(() => {
        setSwiperClass(document.querySelector('.swiper'));
    }, []);

    useEffect(() => {
        const fetchCalendars = async () => {
            try {
                const response = await findAllCalendar();
                const selectedCalendars = response.data.filter(calendar => calendar.selected);
                if (selectedCalendars.length === 1) {
                    setUserCalendar(selectedCalendars[0]);
                }
            } catch (error) {
                console.error('캘린더 조회에 실패하였습니다.', error);
            }
        };
        fetchCalendars();
        fetchUserData();
    }, []);

    const handleTodayButton = () => {
        dispatch(updateYearMonth(new Date().getFullYear(), new Date().getMonth() + 1, new Date().getDate()));
        setTodayBtn(true);
    };
    
    const selectedDate = `${selectDayReducer.year}-${String(selectDayReducer.month).padStart(2, '0')}-${String(selectDayReducer.day).padStart(2, '0')}`;

    return (
        <>
            <div className='calendar-wp'
                onTouchStart={handleTouchStart}
                onTouchMove={handleTouchMove}
                onTouchEnd={handleTouchEnd}
            >
                <div className='cal-top grid-16 pt-0 pb-0 mb-4' data-aos="fade" data-aos-duration="2000" ref={calTopRef}>
                    <h2 className='subtit-01' onClick={() => navigate('/multicalendarsheet')} ref={subtit01Ref}>
                        {userCalendar !== undefined ? `${userCalendar?.emoji} ${userCalendar?.name}` : `${user?.nickname}의 멀티 캘린더`}                  
                    </h2>
                    <button className='btn btn-sm main' onClick={handleTodayButton} ref={mainRef}>오늘</button>
                </div>
                <div>
                    <Swiper initialSlide={2} onSwiper={setSwiperContainer} onTouchEnd={handleSlideChange}>
                        {months.map((monthCalendar, index) => (
                            <SwiperSlide className='grid-16' key={index}>
                                <CalendarComponent calendar={monthCalendar} currentHolidays={currentHolidays} handleDailylistSheetVisible={handleDailylistSheetVisible} />
                            </SwiperSlide>
                        ))}
                    </Swiper>
                </div>
                <div data-aos-delay="500" data-aos="fade-up" data-aos-duration="500">
                    {dailylistSheetVisible && <DaylistSheet settingCalendar={() => {}} setSwipeDrag={setSwipeDrag} currentHolidays={currentHolidays}/>}
                </div>
            </div>
            <Button onClick={() => navigate('/calendarday', { state: { mode: 'write', dateString: selectedDate } })} className="btn-floating"><span className='floating-symbol'></span></Button>
            {isTutorial && <Tutorial />}
        </>
    );
};

export default Calendar;