import { appleLoginLink, googleLoginLink, kakaoLoginLink, naverLoginLink } from "../../features/auth/services/authService";
import { stateGenerator } from "../../utils/auth";


function SocialLoginButtons() {

    return(
        <ul className="login-btn-wp mt-34">
            <li>
                <button className="btn-kakao" onClick={() => kakaoLoginLink()}>
                    카카오 소셜 로그인
                </button>
            </li>
            <li>
                <button className="btn-naver" onClick={() => naverLoginLink(stateGenerator(50))}>
                    네이버 소셜 로그인
                </button>
            </li>
            <li>
                <button className="btn-apple" onClick={() => appleLoginLink(stateGenerator(50))}>
                    애플 소셜 로그인
                </button>
            </li>
            <li>
                <button className="btn-google" onClick={() => googleLoginLink(stateGenerator(50))}>
                    구글 소셜 로그인
                </button>
            </li>
        </ul>
    )
}

export default SocialLoginButtons;