import { createSlice } from "@reduxjs/toolkit"

const initialState = {
    layout: 'main',
    page: ''
}

const layout = createSlice({
    name: 'layout',
    initialState,
    reducers: {
        setLayout(state, action) {
            state.layout = action.payload;
        },
        setPage(state, action) {
            state.page = action.payload;
        }
    }
})

export default layout.reducer;
export const { setLayout, setPage } = layout.actions;