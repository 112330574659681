
import { useRef } from 'react';
import { useNavigate } from 'react-router';

// import Swiper JS
import { Swiper, SwiperClass, SwiperSlide } from 'swiper/react';
// import Swiper styles
import 'swiper/css';
import "swiper/css/effect-fade";
import { EffectFade } from 'swiper/modules';

import './tutorial.css';

import { ReactComponent as IcAi } from '../../assets/images/icon/product/ic_simbol.svg';

import ImgAr01  from "../../assets/images/pages/calendar/img_tutorial_01.png";
import ImgAr02  from "../../assets/images/pages/calendar/img_tutorial_02.png";
import ImgAr03  from "../../assets/images/pages/calendar/img_tutorial_03.png";


function Tutorial() {

    const navigate = useNavigate();

    // Swiper 인스턴스를 저장할 ref 생성
    const swiperRef = useRef<SwiperClass | null>(null);

    // 다음 슬라이드로 이동하는 함수
    const goToNextSlide = () => {
        if (swiperRef.current) {
            if (swiperRef.current.activeIndex < swiperRef.current.slides.length - 1) {
                swiperRef.current?.slideNext();
            } else {
                navigate('/calendar');
            }
        }
    };

    return (
        <>
            <div className="tutorial-wp">
                <Swiper
                    effect={"fade"}
                    pagination={{ clickable: true }}
                    modules={[EffectFade]}
                    onSwiper={(swiper:SwiperClass) => (swiperRef.current = swiper)}
                    touchRatio={0}
                >
                    {/* slide start */}
                    <SwiperSlide className='slide-dashboard' onClick={goToNextSlide}>
                        <div className='grid-20'>
                            <h3 className='title-03'>연도별,월별,일정모아보기로<br />화면을 전환할 수 있어요</h3>
                        </div>
                        <div className='dash-box'>
                            <button className="nav-icon btn-layout"></button>
                        </div>
                        <img src={ImgAr01} alt="icon" />
                    </SwiperSlide>
                    <SwiperSlide className='slide-writebtn' onClick={goToNextSlide}>
                        <div className='grid-20'>
                            <h3 className='title-03'>퀵버튼을 누르면 일정을<br />등록할 수 있어요</h3>
                        </div>
                        <img src={ImgAr02} alt="icon" />
                    </SwiperSlide>
                    <SwiperSlide className='slide-ailink' onClick={goToNextSlide}>
                        <div className='grid-20'>
                            <h3 className='title-03'>하루달AI가 회원님의 성향을 분석해<br/>일정을 관리, 추천해드려요</h3>
                            <button className='btn btn-md main mt-20'>하루달AI 사용하러 가기</button>
                        </div>
                        <div className="menu-icon ic-ai">
                            <IcAi />
                        </div>
                        <img src={ImgAr03} alt="icon" />
                    </SwiperSlide>
                </Swiper>

            </div>
        </>
    )
}

export default Tutorial;