import Header from './routine/Header';
import Footer from './default/Footer';
import { Outlet } from 'react-router';

interface LayoutDefaultProps {
    children?: React.ReactElement;
}

const RoutineLayout = ({ children }: LayoutDefaultProps) => {
    return (
        <div>
            <Header />
            <main>
                {children || <Outlet />}
            </main>
            <Footer />
        </div>
    );
};
export default RoutineLayout;
