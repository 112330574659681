import React from 'react';
import BgOverlay from "../event/BgOverlay";
import InpTimePicker from "./InpTimePicker"; 

interface TimePickerSheetProp {
    setTimeModal: Function; // 선택된 시간을 처리할 함수
    closeTimePicker: () => void;
    selectedTime: Date;
}

const TimePickerSheet: React.FC<TimePickerSheetProp> = ({ setTimeModal, closeTimePicker, selectedTime }) => {
    return (
        <>
            <div className='card card-drawer' onClick={(event) => event.stopPropagation()}>
                <div className='sheet-header'>
                    <div className='sheet-handle'></div>
                </div>
                <div className='tabs-content'>
                    <InpTimePicker setTimeModal={setTimeModal} selectedTime={selectedTime} />
                </div>
            </div>
            <BgOverlay close={closeTimePicker}/>
        </>
    );
};

export default TimePickerSheet;
