import React, { useRef, useState, useEffect, forwardRef, useImperativeHandle } from 'react';

interface HarudalSliderProps {
    children: React.ReactNode[];
    initialSlide?: number;
    onSlideChange?: (index: number) => void; 
    onReachBeginning?: () => void;
    onReachEnd?: () => void;
    onTouchStart?: () => void;
    onTouchEnd?: () => void;
}

export interface HarudalSliderMethods {
    getCurrentIndex: () => number;
    setCurrentIndex: (index: number) => void;
}

const HarudalSlider = forwardRef<HarudalSliderMethods, HarudalSliderProps>(({ 
    children, 
    initialSlide = 0,
    onSlideChange,
    onReachBeginning,
    onReachEnd,
    onTouchStart,
    onTouchEnd
}, ref) => {
    const sliderContainerRef = useRef<HTMLDivElement>(null);
    const [isDragging, setIsDragging] = useState(false);
    const [startX, setStartX] = useState(0);
    const [currentTranslate, setCurrentTranslate] = useState(0);
    const [prevTranslate, setPrevTranslate] = useState(0);
    const [slideWidth, setSlideWidth] = useState(0);
    const [activeIndex, setActiveIndex] = useState(initialSlide);

    useEffect(() => {
        const slider = sliderContainerRef.current!;
        const initialTranslate = -initialSlide * slider.clientWidth;
        setCurrentTranslate(initialTranslate);
        setPrevTranslate(initialTranslate);

        if (initialSlide === 0) {
            onReachBeginning && onReachBeginning();
        } else if (initialSlide === children.length - 1) {
            onReachEnd && onReachEnd();
        }
    }, [initialSlide]);

    useImperativeHandle(ref, () => ({
        getCurrentIndex: () => activeIndex,
        setCurrentIndex: (index: number) => {
            const slidesElement = sliderContainerRef.current?.querySelector('.slides') as HTMLElement;
            if (!slidesElement || slideWidth === 0) return;

            const originalTransition = slidesElement.style.transition;
            slidesElement.style.transition = 'none';
            const newTranslate = -index * slideWidth;
            slidesElement.style.transform = `translateX(${newTranslate}px)`;

            requestAnimationFrame(() => {
                requestAnimationFrame(() => {
                    slidesElement.style.transition = originalTransition;
                });
            });

            setActiveIndex(index);
            setCurrentTranslate(newTranslate);
            setPrevTranslate(newTranslate);
        }
    }));

    const touchStart = (index: number) => (e: React.TouchEvent) => {
        onTouchStart && onTouchStart();
        setIsDragging(true);
        setStartX(e.touches[0].clientX - prevTranslate);
        setSlideWidth(sliderContainerRef.current!.clientWidth);
    };

    const touchMove = (e: React.TouchEvent) => {
        if (isDragging) {
            const currentPosition = e.touches[0].clientX;
            setCurrentTranslate(currentPosition - startX);
        }
    };

    const touchEnd = () => {
        setIsDragging(false);
        onTouchEnd && onTouchEnd();

        const movedBy = currentTranslate - prevTranslate;
        let newIndex = activeIndex;

        if (movedBy > slideWidth * 0.1) {
            newIndex = activeIndex - 1;
        } else if (movedBy < -slideWidth * 0.1) {
            newIndex = activeIndex + 1;
        }

        if(newIndex == -1 || newIndex == children.length) return; // 넘어가는 경우 방지

        setActiveIndex(newIndex);
        setPrevTranslate(-newIndex * slideWidth);
        setCurrentTranslate(-newIndex * slideWidth);

        console.log(newIndex);

        onSlideChange && onSlideChange(newIndex);

        if (newIndex === 0) {
            onReachBeginning && onReachBeginning();
        }
        if (newIndex === children.length - 1) {
            onReachEnd && onReachEnd();
        }
    };

    return (
        <div className='sliderContainer' ref={sliderContainerRef}>
            <div
                className='slides'
                onTouchStart={touchStart(0)}
                onTouchMove={touchMove}
                onTouchEnd={touchEnd}
                style={{ transform: `translateX(${currentTranslate}px)`, width: `${children.length * 100}%` }}
            >
                {children.map((child, index) => (
                    <div key={index} style={{ width: `${100 / children.length}%`, flexShrink: 0 }}>
                        {child}
                    </div>
                ))}
            </div>
        </div>
    );
});

export default HarudalSlider;
