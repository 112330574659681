import React from 'react';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import dayjs from 'dayjs';
import '../common/datepicker.css';

interface InpRestDatePickerProps {
    startDate: string | null;
    endDate: string | null;
    setStartDate: (date: string | null) => void;
    setEndDate: (date: string | null) => void;
    selectedDate: Date | null;
    setSelectedDate: (date: Date | null) => void;
}

const InpRestDatePicker: React.FC<InpRestDatePickerProps> = ({
    startDate,
    endDate,
    setStartDate,
    setEndDate,
    selectedDate,
    setSelectedDate,
}) => {
    
    const handleDayClick = (value: Date) => {
        const formattedDate = dayjs(value).format('YYYY-MM-DD');

        if (!startDate) {
            setStartDate(formattedDate);
            setSelectedDate(value);
        } else if (!endDate) {
            setEndDate(formattedDate);
            setSelectedDate(value);
        }
    };

    const tileClassName = ({ date }: { date: Date }) => {
        const formattedDate = dayjs(date).format('YYYY-MM-DD');
        
        if (startDate && endDate) {
            const start = new Date(startDate);
            const end = new Date(endDate);
            if (date >= start && date <= end) {
                return 'react-calendar__tile--active';
            }
        }

        if (formattedDate === startDate || formattedDate === endDate) {
            return 'react-calendar__tile--active';
        }
        
        return '';
    };

    return (
        <Calendar
            className='drawer-calendar'
            formatDay={(locale, date) => dayjs(date).format('DD')}
            calendarType="gregory"
            prevLabel=""
            nextLabel=""
            onClickDay={handleDayClick}
            value={selectedDate}
            minDate={startDate ? new Date(startDate) : new Date()}
            tileClassName={tileClassName}
        />
    );
};

export default InpRestDatePicker;
