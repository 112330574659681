import React, { useRef, useEffect, useState, useCallback } from 'react';
import './ai.css';
import Typography from '../../components/common/Typography';
import { ReactComponent as IcSend } from '../../assets/images/icon/system/ic_Send_light.svg';


import ChatHaruBubble from '../../components/ai/ChatHaruBubble';
import ChatWaiting from '../../components/ai/ChatWaiting';
import ChatCarousel from '../../components/ai/ChatCarousel';
import ChatUser from '../../components/ai/ChatUser';
import ChatDate from '../../components/ai/ChatDate';
import { useDispatch } from 'react-redux';
import { setLayout, setPage } from '../../store/layout';

import AOS from "aos";
import "aos/dist/aos.css";
import { getAiData, getData, postAiData } from '../../apis/api';
import { AIEvent, AIEventRecommend, AIRoutineRecommend, ChatItem } from '../../features/ai/types/aiType';
import SelectCalendarSheet from '../../components/common/SelectCalendarSheet';
import { Calendar } from '../../features/multiCalendar/types/calendarType';
import { createEventInfo } from '../../features/event/services/eventService';
import { insertRoutine } from '../../features/routine/services/insertRoutineService';
import { useNavigate } from 'react-router-dom';
import RoutineCategory from '../routine/RoutineCategory';
import { RoutineCategoryType } from '../../features/routine/types/routineInsertType';
import useAuth from '../../hooks/useAuth';
import Button from '../../components/common/Buttons';

const Ai: React.FC = () => {
    const [chatList, setChatList] = useState<ChatItem[]>([]);
    const [scrollPage, setScrollPage] = useState(0);
    const [isInitialLoad, setIsInitialLoad] = useState<boolean>(true);
    const [chatUse, setChatUse] = useState(false);
    const [hasMore, setHasMore] = useState<boolean>(true);
    const [cursor, setCurosr] = useState<number>(0);

    const [inputType, setInputType] = useState(''); // 입력받을 때 type(현재는 event 밖에 없음)
    const [loading, setLoading] = useState(false); // AI 통신 시 동작 제한
    const [threadId, setThreadId] = useState('');
    const [userMessage, setUserMessage] = useState(''); // 채팅 입력
    const [isFirstFetch, setIsFirstFetch] = useState(true);
    const [calendarSheetOpen, setCalendarSheetOpen] = useState(false);
    const [categorySheetOpen, setCategorySheetOpen] = useState(false);
    const [selectCalendar, setSelectCalendar] = useState<Calendar>();
    const [selectedCategory, setSelectedCategory,] = useState<RoutineCategoryType>();
    const [saveEventData, setSaveEventData] = useState<any>(); // 이벤트 저장 때 사용
    const [saveRoutineData, setSaveRoutineData] = useState<any>();
    const [chatUpdate, setChatUpdate] = useState(false);
    const [lastChatType, setLastChatType] = useState({ type: '', resend: false });

    const aiWp = useRef<HTMLDivElement>(null);
    const topRef = useRef<HTMLDivElement | null>(null);
    const user = useAuth();
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const scrollContainer = document.body;

    const scrollToBottom = () => {
        if (messagesEndRef.current) {
            messagesEndRef.current.scrollIntoView({ behavior: "smooth" });
        }
    };

    useEffect(() => {
        console.log(chatList);
        // 수정한 경우(AI 버튼) 스크롤 이동 안되게
        console.log(chatUpdate);
        if (!chatUpdate) {
            scrollToBottom()
            setChatUpdate(false);
        }
    }, [chatList]);

    useEffect(() => {
        // 컴포넌트가 마운트된 후에 바로 맨 아래로 스크롤합니다.
        scrollToBottom()
        fetchMoreChatData();
    }, []);

    const handleObserver = useCallback(
        (entries: IntersectionObserverEntry[]) => {
            const target = entries[0];
            if (target.isIntersecting && hasMore && !isInitialLoad) {
                if (scrollContainer) {
                    const previousHeight = scrollContainer.scrollHeight;
                    setTimeout(() => {
                        const newHeight = scrollContainer.scrollHeight;
                        window.scrollTo(0, newHeight - previousHeight);
                        // scrollContainer.scrollTop = newHeight - previousHeight;
                    }, 100);
                }
                setScrollPage((prev) => prev + 1);
            }
        },
        [hasMore, isInitialLoad]
    );

    useEffect(() => {
        const observer = new IntersectionObserver(handleObserver, { threshold: 1.0 });

        if (topRef.current) {
            observer.observe(topRef.current);
        }

        return () => {
            observer.disconnect();
        };
    }, [handleObserver]);

    useEffect(() => {
        if (scrollPage > 1)
            fetchMoreChatData();
    }, [scrollPage])

    useEffect(() => {
        if (isInitialLoad && scrollContainer) {
            // 최초 로드 시 스크롤을 가장 하단으로 이동
            setTimeout(() => {
                scrollToBottom()
            }, 500);

            setIsInitialLoad(false);
        }
    }, [isInitialLoad, chatList]);

    useEffect(() => {
        if (saveRoutineData) {
            setCategorySheetOpen(true);
        }
    }, [saveRoutineData])

    useEffect(() => {
        if (selectedCategory) {
            handleRoutineUpdate();
        }
    }, [selectedCategory])

    const fetchMoreChatData = async () => { // page 또는 cursor 사용 예정
        try {
            const response: any = await getData(`/ai?cursor=${cursor}`);
            console.log(response);

            if (response.status == 200 && response.data.length > 0) {

                let minId = response.data[0].id; // 초기값을 첫 번째 객체의 id로 설정

                response.data.forEach((item: any) => {
                    if (item.id < minId) {
                        minId = item.id;
                    }
                });

                setCurosr(minId);
                const sortedData = response.data.sort((a: any, b: any) => new Date(a.createdDate).getTime() - new Date(b.createdDate).getTime());
                setChatList(prev => [...sortedData, ...prev]);

                if (response.data.length < 15) {
                    setHasMore(false);
                }


            } else {
                // 에러 오류

                setHasMore(false);
            }

        } catch (error) {
            // 에러 오류

            setHasMore(false);
        }

        // 최초 fetchMoreChatData 실행 후 chat_bot 아이템 추가
        if (isFirstFetch) {
            const newChat: ChatItem = { id: Date.now(), type: 'chat_bot', content: '', createdDate: new Date().toISOString() };
            setChatList(prev => [...prev, newChat]);
            setIsFirstFetch(false); // 최초 실행 후 false로 설정
        }
    };

    const formatDate = (dateString: string) => {
        const date = new Date(dateString);
        const year = date.getFullYear();
        const month = date.getMonth() + 1; // getMonth()는 0부터 시작하므로 1을 더해줍니다.
        const day = date.getDate();
        return `${year}년 ${month.toString().padStart(2, '0')}월 ${day.toString().padStart(2, '0')}일`;
    };

    const renderChatItem = (chatItem: ChatItem, index: number) => {
        const previousDate = index > 0 ? chatList[index - 1].createdDate : '';
        const isNewDay = !previousDate || formatDate(previousDate) !== formatDate(chatItem.createdDate);

        return (
            <React.Fragment key={index}>
                {
                    isNewDay &&
                    <ChatDate date={formatDate(chatItem.createdDate)} />
                }

                {
                    chatItem.type == 'EVENT' &&
                    <>
                        <div className='chat-message mb-20'>
                            <p className='caption mb-4'>✨일정생성</p>
                            <Typography variant='body1'>입력하신 내용을 바탕으로 일정을 만들었어요</Typography>
                        </div>
                        <ChatCarousel chat={chatItem} setCalendarSheetOpen={setCalendarSheetOpen} setSaveEventData={setSaveEventData} setSaveRoutineData={setSaveRoutineData} />
                    </>
                }

                {
                    chatItem.type == 'ROUTINE_RECOMMEND' &&
                    <>
                        <div className='chat-message mb-20'>
                            <p className='caption mb-4'>🤟추천습관</p>
                            <Typography variant='body1'>{user.user?.nickname}님의 성향을 분석해 추천 습관을 만들었어요</Typography>
                        </div>
                        <ChatCarousel chat={chatItem} setCalendarSheetOpen={setCalendarSheetOpen} setSaveEventData={setSaveEventData} setSaveRoutineData={setSaveRoutineData} />
                    </>
                }

                {
                    chatItem.type == 'EVENT_RECOMMEND' &&
                    <>
                        <div className='chat-message mb-20'>
                            <p className='caption mb-4'>📆 추천일정</p>
                            <Typography variant='body1'>{user.user?.nickname}님의 성향을 분석해 추천 일정을 만들었어요</Typography>
                        </div>
                        <ChatCarousel chat={chatItem} setCalendarSheetOpen={setCalendarSheetOpen} setSaveEventData={setSaveEventData} setSaveRoutineData={setSaveRoutineData} />
                    </>
                }

                {
                    chatItem.type == 'SEARCH' &&
                    <div className='chat-message mb-20'>
                        <Typography variant='caption' className='mb-4'>🔍검색결과</Typography>
                        <Typography variant='body1'>{user.user?.nickname}님의 생일은 7월 23일 입니다. 해당 날짜로 이동하시겠어요?</Typography>
                    </div>
                }

                {
                    chatItem.type == 'example' &&
                    <div className='chat-message mb-20'>
                        <Typography variant='caption' className='mb-4'>예시</Typography>
                        <Typography variant='body1'>{chatItem.content}</Typography>
                    </div>
                }

                {
                    chatItem.type == 'USER' &&
                    <ChatUser content={chatItem.content} />
                }

                {
                    chatItem.type == 'chat_bot' &&
                    <ChatHaruBubble handleClickButton={handleClickButton} loading={loading} />
                }

                {
                    chatItem.type == 'wait' &&
                    <ChatWaiting key="loading" />
                }

                {
                    chatItem.type == 'ERROR' &&
                    <div className='chat-message mb-20'>
                        <Typography variant='caption' className='mb-4'>🚫 오류</Typography>
                        <Typography variant='body1'>오류가 발생했어요.</Typography>
                        <Button className='mt-8' color='main' onClick={handleRetry}>다시 시도하기</Button>
                    </div>
                }

                {
                    // AI 응답 이후
                    chatItem.type == 'RESULT' &&
                    <>
                        <ul className='chat-cate-wp mb-20'>
                            <li>
                                <Button variant='outline' color="main" size='sm' onClick={handelCallharu}>👀 다른 추천을 받고싶어</Button>
                            </li>
                            <li>
                                <Button variant='outline' color="main" size='sm' onClick={() => handleClickButton(lastChatType.type, true)}>🖋 다시 추천해줘</Button>
                            </li>
                        </ul>
                    </>
                }

            </React.Fragment>
        );
    };

    const handleRetry = () => {


        setChatList(prevChatList => {
            const newChatList = [...prevChatList];
            newChatList.pop(); // 마지막 요소 삭제
            return [...newChatList];
        });

        handleClickButton(lastChatType.type, lastChatType.resend);
    }

    // 임시로 넣음
    const textarea = useRef<HTMLTextAreaElement>(null);
    const messagesEndRef = useRef<HTMLDivElement>(null);

    const handleResizeHeight = () => {
        if (textarea.current && aiWp.current) {
            const textareaHeight = textarea.current.scrollHeight;
            const aiWpMarginBottom = textareaHeight;
            const aiWpMinHeight = `calc(100vh - 6.5rem - ${Math.min(textareaHeight, 100)}px)`;

            textarea.current.style.height = 'auto'; // height 초기화
            textarea.current.style.height = `${Math.min(textareaHeight, 100)}px`; // max height 설정
            aiWp.current.style.minHeight = aiWpMinHeight; // ai-wp의 min height 값 설정
        }
    };

    useEffect(() => {
        //layout
        dispatch(setLayout('historyBack'));
        dispatch(setPage('하루달 AI'));
    }, [dispatch])

    useEffect(() => {
        handleResizeHeight(); // 컴포넌트가 마운트될 때 한 번 호출하여 초기화
    }, []);

    useEffect(() => {
        scrollToBottom();
    }, [loading]);

    useEffect(() => {
        handleResizeHeight();
    }, [userMessage]);

    const handleClickButton = async (type: string, resend: boolean = false) => {
        if (loading) {
            //  기능 동작은 한번에 한개씩만 가능함
            return;
        }

        // 쓰레드 요청
        if (threadId == '') {
            const threadRequest = await getAiData('/assistant/check');
            if (threadRequest.status == 200) {
                setThreadId(threadRequest.data as string);
            } else {
                // 에러처리 오류처리
                console.error('Error fetching thread ID');
                return;
            }
        }

        setLoading(true);
        setLastChatType({ type, resend: resend });

        let newChat: ChatItem;

        const createExampleChat = (content: string): ChatItem => ({
            id: Date.now(),
            type: 'example',
            content,
            createdDate: new Date().toISOString()
        });

        const createWaitChat = (): ChatItem => ({
            id: Date.now(),
            type: 'wait',
            content: '',
            createdDate: new Date().toISOString()
        });

        const addChatToList = (chat: ChatItem) => {
            setChatList(prevChatList => [...prevChatList, chat]);
        };

        const removeLastChat = () => {
            setChatList(prevChatList => prevChatList.slice(0, -1));
        };

        if (type === 'SEARCH') {
            newChat = createExampleChat('입력해보세요');
            setChatUse(true);
            setInputType(type);
            addChatToList(newChat);
        } else if (type === 'EVENT') {
            newChat = createExampleChat('오늘 오전 7시에는 수영을 갈 예정이고, 오후 1시에는 친구들과 약속이 있어!');
            setChatUse(true);
            setInputType(type);
            addChatToList(newChat);
        } else if (type === 'EVENT_RECOMMEND' || type === 'ROUTINE_RECOMMEND') {

            newChat = createWaitChat();
            addChatToList(newChat);
            setChatUse(false);

            const sendAiRequest = async () => {
                // 저장
                try {
                    const sendType = resend ? type + "_RE" : type;
                    const AiRequest: any = await postAiData('/assistant/reply', { thread: threadId, message: userMessage.trim(), type: sendType });
                    console.log(AiRequest);

                    if (AiRequest.status == 200 && AiRequest.data.length > 0) { // 데이터 길이 체크
                        let newAIChat: ChatItem;
                        if (type === 'EVENT_RECOMMEND') {
                            newAIChat = { id: Date.now(), type: type, content: '', createdDate: new Date().toISOString(), aiEventRecommends: AiRequest.data as AIEventRecommend[] };
                        } else {
                            newAIChat = { id: Date.now(), type: type, content: '', createdDate: new Date().toISOString(), aiRoutineRecommends: AiRequest.data as AIRoutineRecommend[] };
                        }

                        const resultAIChat: ChatItem = { id: Date.now(), type: 'RESULT', content: '', createdDate: new Date().toISOString() }

                        // 마지막 `wait` 타입의 채팅 삭제하고 새로운 채팅 추가
                        setChatList(prevChatList => {
                            const newChatList = [...prevChatList];
                            newChatList.pop(); // 마지막 요소 삭제
                            return [...newChatList, newAIChat, resultAIChat];
                        });
                    } else {
                        // 에러처리 오류처리
                        console.error("Error: No data received");

                        if (AiRequest.status == 400 || AiRequest.status == 500) {
                            // setTimeout(async () => {
                            //     await sendAiRequest();
                            // }, 5000);

                            let newAIChat: ChatItem;
                            newAIChat = { id: Date.now(), type: 'ERROR', content: '', createdDate: new Date().toISOString() };
                            setChatList(prevChatList => {
                                const newChatList = [...prevChatList];
                                newChatList.pop(); // 마지막 요소 삭제
                                return [...newChatList, newAIChat];
                            });
                        } else {
                            removeLastChat();
                        }
                    }
                } catch (error) {
                    // 에러처리 오류처리
                    console.error("Error: ", error);

                    removeLastChat();
                } finally {
                    setLoading(false);
                }
            }

            await sendAiRequest();

        }
    };

    const handelCallharu = () => {

        if (loading) {
            return;
        }

        let newChat: ChatItem;

        newChat = { id: Date.now(), type: 'chat_bot', content: '', createdDate: new Date().toISOString() };
        setChatList([...chatList, newChat])

    }

    const handleSendMessage = async () => {

        if (!chatUse || userMessage.trim().length < 1) {
            return;
        }

        setChatUse(false);

        // 메시지 입력
        if (userMessage.trim()) {
            const messageContent = userMessage.trim();
            let newUserChat: ChatItem = { id: Date.now(), type: 'USER', content: messageContent, createdDate: new Date().toISOString() };
            setChatList(prevChatList => [...prevChatList, newUserChat]);
            setUserMessage(''); // Clear the textarea
            if (textarea.current) {
                textarea.current.style.height = 'auto'; // Reset height to auto
            }
        }

        // 입력된 데이터 저장 및 처리
        if (inputType != '') {
            // 처리 되는 동안 로딩 화면 처리
            const waitChat: ChatItem = { id: Date.now(), type: 'wait', content: '', createdDate: new Date().toISOString() };
            setChatList(prevChatList => [...prevChatList, waitChat]);

            // 저장
            try {
                const eventAiRequest: any = await postAiData('/assistant/reply', { thread: threadId, message: userMessage.trim(), type: inputType });
                console.log(eventAiRequest);

                if (eventAiRequest.status == 200 && eventAiRequest.data.length > 0) { // 데이터 길이 체크
                    const newAIChat: ChatItem = { id: Date.now(), type: 'EVENT', content: '', createdDate: new Date().toISOString(), aiEvents: eventAiRequest.data as AIEvent[] };
                    console.log(newAIChat);
                    // 마지막 `wait` 타입의 채팅 삭제하고 새로운 채팅 추가
                    setChatList(prevChatList => {
                        const newChatList = [...prevChatList];
                        newChatList.pop(); // 마지막 요소 삭제
                        return [...newChatList, newAIChat];
                    });
                } else {
                    // 에러처리 오류처리
                    console.error("Error: No data received");

                    setChatList(prevChatList => {
                        const newChatList = [...prevChatList];
                        newChatList.pop();
                        return newChatList;
                    });
                }
            } catch (error) {
                // 에러처리 오류처리
                console.error("Error: ", error);

                setChatList(prevChatList => {
                    const newChatList = [...prevChatList];
                    newChatList.pop();
                    return newChatList;
                });
            } finally {
                setLoading(false);
            }
        }
    };

    const handleCalendar = async (calendar: Calendar) => {
        saveEventData.calendarId = calendar.idx;

        try {
            // 일정생성
            const response = await createEventInfo(saveEventData);
            const eventIdx = response.data;

            try {
                // 타입에 따라 데이터 변경
                const aiResponse = await postAiData('/assistant/edit', { idx: saveEventData.idx, relatedId: eventIdx, type: saveEventData.type });

                setChatUpdate(true); // 채팅 생성 시 스크롤 방지

                const chatItemToUpdate = chatList.find(item => item.id === saveEventData.chatIdx); // 업데이트 할 채팅 찾기

                if (chatItemToUpdate) {
                    if (saveEventData.type == 'EVENT_RECOMMEND' && chatItemToUpdate.aiEventRecommends) {
                        const updatedData: any = chatItemToUpdate.aiEventRecommends.map(item => {
                            if (item.idx === saveEventData.idx) {
                                const updateData = { status: "COMPLETED", relatedId: eventIdx }
                                return { ...item, ...updateData };
                            }
                            return item;
                        });

                        setChatList(prevChatList => prevChatList.map(item =>
                            item.id === saveEventData.chatIdx ? { ...item, aiEventRecommends: updatedData } : item
                        ));
                    } else if (saveEventData.type == 'EVENT' && chatItemToUpdate.aiEvents) {
                        const updatedData: any = chatItemToUpdate.aiEvents.map(item => {
                            if (item.idx === saveEventData.idx) {
                                const updateData = { status: "COMPLETED", relatedId: eventIdx }
                                return { ...item, ...updateData };
                            }
                            return item;
                        });

                        setChatList(prevChatList => prevChatList.map(item =>
                            item.id === saveEventData.chatIdx ? { ...item, aiEvents: updatedData } : item
                        ));
                    }
                }

            } catch (error) {
                console.log(error)
            }

        } catch (error) {
            console.log(error)
        }

        setSelectCalendar(calendar);
        setCalendarSheetOpen(false);

        // 성공 시 표시 화면
    };

    const handleRoutineUpdate = async () => {
        if (!selectedCategory) {
            return;
        }

        // 1. 등록
        const response = await insertRoutine({ name: saveRoutineData.name, startDate: saveRoutineData.startDate, endDate: null, emoji: saveRoutineData.emoji, categoryId: selectedCategory.idx, type: 'REPETITION', repeatedNumber: 0, isRemind: false, remindDate: [], repeated: saveRoutineData.repeated }, navigate);

        console.log(response);
        const routineIdx = response.data;

        // 2. 수정
        const aiResponse = await postAiData('/assistant/edit', { idx: saveRoutineData.idx, relatedId: routineIdx, type: saveRoutineData.chatType });
        console.log(aiResponse);

        setChatUpdate(true);

        const chatItemToUpdate = chatList.find(item => item.id === saveRoutineData.chatIdx);
        if (chatItemToUpdate) {
            if (saveRoutineData.chatType == 'ROUTINE_RECOMMEND' && chatItemToUpdate.aiRoutineRecommends) {
                const updatedData: any = chatItemToUpdate.aiRoutineRecommends.map(item => {
                    if (item.idx === saveRoutineData.idx) {
                        const updateData = { status: "COMPLETED", relatedId: routineIdx }
                        return { ...item, ...updateData };
                    }
                    return item;
                });

                setChatList(prevChatList => prevChatList.map(item =>
                    item.id === saveRoutineData.chatIdx ? { ...item, aiRoutineRecommends: updatedData } : item
                ));
            }
        }

        // 성공 시 표시 화면
    }

    const handleMessageCancel = () => {
        setChatUse(false);
        setLoading(false);

        setChatList(prevChatList => {
            const newChatList = [...prevChatList];
            newChatList.pop();
            return newChatList;
        });
    }

    useEffect(() => {
        AOS.init();
    }, [])

    return (
        <>
            <div className="ai-wp grid-20" ref={aiWp}>
                <div ref={topRef}></div>


                {/* chatbot -기본세팅값*/}
                {/* <ChatHaruBubble handleClickButton={handleClickButton} /> */}

                {chatList.map(renderChatItem)}

                <div ref={messagesEndRef} />

            </div>

            {/* chat footer */}
            <div className='ai-footer'>
                {
                    chatUse ?
                        <>
                            <ul className='aifooter-box'>
                                {/* 
                                길이제한있는 teaxtarea 작성하면서 enter누르면 길어지고 일정높이부터는 고정
                                텍스트를 전송한 후에는 원래 형태로 돌아옴
                            */}
                                <li>
                                    <textarea ref={textarea} name="" id="" rows={1} value={userMessage} onChange={(e) => setUserMessage(e.target.value)} style={{ maxHeight: '100px' }} placeholder={chatUse ? '원하는 일정을 자유롭게 작성해보세요!' : ''} disabled={!chatUse}
                                    ></textarea>
                                </li>
                                {/* callharu를 누르면 ChatHaruBubble가 뜸! */}
                                <li><button className='btn-callharu' onClick={handleMessageCancel}></button></li>
                                <li><button className='btn-sending' onClick={handleSendMessage}><IcSend /></button></li>
                            </ul>
                            </>
                :
                <>
                    <Button className='btn-startharudal' color='main' size='lg' onClick={handelCallharu}>하루달 AI 사용하기</Button>
                </>
                    }

            </div>

            {/* 캘린더 선택 */}
            {calendarSheetOpen && (
                <SelectCalendarSheet
                    selected={selectCalendar}
                    close={() => {
                        setCalendarSheetOpen(false);
                    }}
                    onClick={(calendar: Calendar) => {
                        handleCalendar(calendar);
                    }}
                />
            )}

            {/* 루틴 카테고리 선택 */}
            {categorySheetOpen && <RoutineCategory setSelectedCategory={setSelectedCategory} setCategorySheetOpen={setCategorySheetOpen} />}
        </>
    );
};

export default Ai;
