import React, { useEffect, useState } from 'react';
// import routine styles
import './routine.css';
import './board.css';
import Typography from '../../components/common/Typography';
import { useDispatch } from 'react-redux';
import routine from '../../features/routine/stores/routine';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { deleteRoutine, findRoutine } from '../../features/routine/services/insertRoutineService';
import { RoutineDetailProps, RepeatedDayWeek } from '../../features/routine/types/routineInsertType';
import useRoutineHook from '../../features/routine/hooks/useRoutine.hook';
import { useSelector } from 'react-redux';
import { RootState } from '../../store';
import Header from '../../layout/routine/Header';
import Swal from 'sweetalert2';
import { setLayout, setPage } from '../../store/layout';

const RoutineDay: React.FC = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const [routineDetail, setRoutineDetail] = useState<RoutineDetailProps | null>(null);

    const { id } = useParams();

    useEffect(() => {
        // layout 세팅
        dispatch(setLayout('challengeDetail'));
        dispatch(setPage('챌린지 상세보기'));

        const fetchRoutineDetail = async () => {
            try {
                const response = await findRoutine(Number(id));
                setRoutineDetail(response.data);
            } catch (error) {
                console.error('상세 정보를 가져오는 데 실패했습니다.', error);
            }
        };

        fetchRoutineDetail();
    }, [id, dispatch]);

    const daysMap: { [key: string]: string } = {
        monday: '월',
        tuesday: '화',
        wednesday: '수',
        thursday: '목',
        friday: '금',
        saturday: '토',
        sunday: '일',
    };

    // true인 요일을 한글로 나타내는 배열을 반환
    const getTrueDaysInKorean = (dayWeek: RepeatedDayWeek): string => {
        const trueDaysInKorean = Object.entries(dayWeek)
            .filter(([day, isTrue]: [string, boolean]) => isTrue)
            .map(([day]: [string, boolean]) => daysMap[day]);

        return trueDaysInKorean.join(', ');
    };

    const repeatDay = routineDetail ? getTrueDaysInKorean(routineDetail.dayWeek) : '';

    const formatDate = (date: Date | string): string => {
        let dateObj: Date;
        if (typeof date === 'string') {
            dateObj = new Date(date); // 문자열을 Date 객체로 변환
        } else {
            dateObj = date;
        }

        const year = dateObj.getFullYear();
        const month = dateObj.getMonth() + 1;
        const day = dateObj.getDate();

        return `${year}년 ${month}월 ${day}일에 시작했어요`;
    };

    const formattedStartDate = routineDetail ? formatDate(routineDetail.startDate) : '';

    const handleDeleteRoutine = async () => {
        try {
            await Swal.fire({
                text: '삭제하시겠어요?',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: '확인',
                cancelButtonText: '취소',
                reverseButtons: true
            }).then(async (result) => {
                if (result.isConfirmed) {
                    routineDetail && await deleteRoutine(routineDetail.idx);
                    navigate('/routine');
                }
            });
        } catch (error) {
            console.error('삭제에 실패하였습니다:', error);
        }
    };

    const getPercentageEmoji = (completedNumber: number | null, repeatedNumber: number | null) => {
        if (completedNumber !== null && repeatedNumber !== null && repeatedNumber !== 0) {
            const completionPercentage = (completedNumber / repeatedNumber) * 100;
            if (completionPercentage <= 24) {
                return '🙂';
            } else if (completionPercentage <= 49) {
                return '😊';
            } else if (completionPercentage <= 74) {
                return '😄';
            } else if (completionPercentage <= 99) {
                return '🤗';
            } else {
                return '🥰';
            }
        }
    };
   
    return (
        <>
            <Header handleDeleteRoutine={handleDeleteRoutine} routineDetail={routineDetail} />
            <div className="board-wp grid-20 board">
                {/* 제목 */}
                <div className="card routine-board-tit">
                    <Typography variant="h5">
                        <span>{routineDetail?.emoji} </span>
                        {routineDetail?.name}
                    </Typography>
                    <Typography variant="body2" className="mt-8">
                        {formattedStartDate}
                    </Typography>
                </div>

                {/* 챌린지스탬프 */}
                <div className="card mt-34 step-card">
                    <ul>
                        {/* 시작 */}
                        <li className={routineDetail?.completedNumber === 0 ? '' : routineDetail?.completedNumber && (routineDetail?.completedNumber / routineDetail?.repeatedNumber) * 100 < 20 ? 'active' : ''}><a href="#">🔥</a></li>
                        <li className={routineDetail?.completedNumber === 0 ? '' : routineDetail?.completedNumber && (routineDetail?.completedNumber / routineDetail?.repeatedNumber) * 100 >= 20 && (routineDetail?.completedNumber / routineDetail?.repeatedNumber) * 100 < 40 ? 'doing' : routineDetail?.completedNumber && (routineDetail?.completedNumber / routineDetail?.repeatedNumber) * 100 >= 40 ? 'active' : ''}><a href="#"></a></li>
                        <li className={routineDetail?.completedNumber === 0 ? '' : routineDetail?.completedNumber && (routineDetail?.completedNumber / routineDetail?.repeatedNumber) * 100 >= 40 && (routineDetail?.completedNumber / routineDetail?.repeatedNumber) * 100 < 60 ? 'doing' : routineDetail?.completedNumber && (routineDetail?.completedNumber / routineDetail?.repeatedNumber) * 100 >= 60 ? 'active' : ''}><a href="#"></a></li>
                        <li className={routineDetail?.completedNumber === 0 ? '' : routineDetail?.completedNumber && (routineDetail?.completedNumber / routineDetail?.repeatedNumber) * 100 >= 60 && (routineDetail?.completedNumber / routineDetail?.repeatedNumber) * 100 < 80 ? 'doing' : routineDetail?.completedNumber && (routineDetail?.completedNumber / routineDetail?.repeatedNumber) * 100 >= 80 ? 'active' : ''}><a href="#"></a></li>
                        <li className={routineDetail?.completedNumber === 0 ? '' : routineDetail?.completedNumber && (routineDetail?.completedNumber / routineDetail?.repeatedNumber) * 100 >= 80 && (routineDetail?.completedNumber / routineDetail?.repeatedNumber) * 100 < 100 ? 'doing' : routineDetail?.completedNumber && (routineDetail?.completedNumber / routineDetail?.repeatedNumber) * 100 >= 100 ? 'active' : ''}><a href="#"></a></li>
                        <li className={routineDetail?.completedNumber === routineDetail?.repeatedNumber ? 'active' : ''}><a href="#">🎉</a></li>
                    </ul>
                </div>

                <div className='card cha-info-box'>
                    <ul>
                        <li>
                            <Typography className='mb-8' variant='caption'>챌린지 완료</Typography>
                            <Typography variant='h4'>{routineDetail?.completedNumber}</Typography>
                        </li>
                        <li>
                            <Typography className='mb-8' variant='caption'>남은 횟수</Typography>
                            <Typography variant='h4'>{routineDetail  && routineDetail?.repeatedNumber - routineDetail?.completedNumber}</Typography>
                        </li>
                        <li>
                            <Typography className='mb-8' variant='caption'>챌린지 지수</Typography>
                            <Typography variant='h4'>{routineDetail && routineDetail?.completedNumber !== null && routineDetail?.repeatedNumber !== null ? getPercentageEmoji(routineDetail?.completedNumber, routineDetail?.repeatedNumber) : '🙂'}</Typography>
                            {/* 
                            5단계로 구분해주세요(20%): 🙂 > 😊 > 😄 > 🤗 > 🥰
                             */}
                        </li>
                    </ul>
                </div>

                {/* 알림설정 */}
                <div className="card mt-34">
                    <div className="write-control">
                        <p className="body-01 control-tit">알림 받기</p>
                        <div className="switch-wrapper">
                            <input
                                type="checkbox"
                                id="switch"
                                className="switch-inp"
                                checked={routineDetail?.isRemind}
                                hidden
                                disabled
                            />
                            <label htmlFor="switch" className="switch-label">
                                <span className="onf-btn"></span>
                            </label>
                        </div>
                    </div>
                </div>

            </div>
        </>
    );
};

export default RoutineDay;
