import { useDispatch, useSelector } from 'react-redux';
import { updateYearMonth } from '../../day/stores/day';
import SwiperCore from 'swiper';

const useSlideChange = () => {
    const dispatch = useDispatch();
    const selectDayReducer = useSelector((state: any) => state.day);

    const handleSlideChange = (swiper: SwiperCore) => {
        const curYear = new Date().getFullYear();
        const curMonth = new Date().getMonth() + 1;
        const curDay = new Date().getDate();
        
        if(!swiper.swipeDirection) return;

        const newMonth = swiper.swipeDirection === "prev" ? selectDayReducer.month - 1 : selectDayReducer.month + 1;
        const newYear = swiper.swipeDirection === "prev" && newMonth === 0 ? selectDayReducer.year - 1 : swiper.swipeDirection === "next" && newMonth === 13 ? selectDayReducer.year + 1 : selectDayReducer.year;

        const month = newMonth === 0 ? 12 : newMonth === 13 ? 1 : newMonth;

        if (curYear === newYear && curMonth === month) {
            dispatch(updateYearMonth(newYear, month, curDay));
        } else {
            dispatch(updateYearMonth(newYear, month, 1));
        }

        if (swiper.activeIndex - 1 === 1) {
            setTimeout(() => swiper.slideTo(2, 0, false), 0);
        }
    };

    return { handleSlideChange };
};

export default useSlideChange;