import Typography from '../common/Typography';
import './aicomponents.css';

type ChatUserProps = {
    content: string;
}

const ChatUser: React.FC<ChatUserProps> = ({ content }) => {
    return (
        <>
            <div className='chat-message my-chat mb-20'>
                <Typography variant='body1'>{content}</Typography>
            </div>
        </>
    );
}

export default ChatUser;