import { ReactElement, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { findAllNotification } from "../../features/notification/services/notificationService";
import { updateNotificationList } from "../../features/notification/stores/reducer";

export const NotiProvider = ({ children }: { children: ReactElement }) => {
  const dispatch = useDispatch();
  const layout = useSelector((layoutReducer: any) => layoutReducer.layout);

  useEffect(() => {
    const fetchNotificationList = async () => {
      try {
        const response = await findAllNotification();
        dispatch(updateNotificationList(response.data));
      } catch (error) {
        console.error('조회에 실패했습니다:', error);
      }
    };

    fetchNotificationList();
  }, [layout, dispatch]);

  return children;
};
