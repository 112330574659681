import Button from './Buttons';
import { useEffect, useState } from 'react';
import BgOverlay from './BgOverlay';
import { Calendar, SelectedCalendar } from '../../features/multiCalendar/types/calendarType';
import { findAllWriteCalendars } from '../../features/multiCalendar/services/multiCalendarService';
import Swal from 'sweetalert2';
import { OnClickFunc } from 'react-calendar';

interface SelectCalendarSheetProps {
    close: () => void;
    onClick: (calendar: Calendar) => void;
    selected?: Calendar;
}

function SelectCalendarSheet({ close, onClick, selected }: SelectCalendarSheetProps) {
    const [calendars, setCalendars] = useState<Calendar[]>([]);
    const [selectedCalendar, setSelectedCalendar] = useState<Calendar | null>(null);
    
    const handleCalendarSelect = (calendar: Calendar) => {
        setSelectedCalendar((prevCal) => (prevCal?.idx === calendar.idx ? null : calendar));
    };

    useEffect(() => {
        async function fetchCalendars() {
            try {
                const response = await findAllWriteCalendars();
                setCalendars(response.data);
            } catch (error) {
                console.error('캘린더 목록을 불러오는데 실패하였습니다:', error);
            }
        }

        if(selected) {
            setSelectedCalendar(selected);
        }

        fetchCalendars();
    }, []);

    function selectValidation() {
        Swal.fire({
            text: '캘린더를 선택해주세요',
            showCancelButton: false,
            confirmButtonText: '돌아가기',
        })
    }

    return (
        <>
            <div className="card card-drawer multilist-sheet">
                <div className="sheet-header">
                    <div className="sheet-handle"></div>
                </div>
                <div className="multi-header">
                    <h3 className="subtit-01">캘린더 선택하기</h3>
                </div>

                <ul className="card multi-list mt-20">
                    {calendars.map((calendar, index) => (
                        <li key={calendar.idx}>
                            <p className="subtit-02">
                                {calendar.emoji} {calendar.name}
                            </p>
                            <div className="checkbox small">
                                <input
                                    type="checkbox"
                                    checked={selectedCalendar?.idx === calendar.idx}
                                    onChange={() => handleCalendarSelect(calendar)}
                                    id={`calendar-${calendar.idx}`}
                                />
                                <label htmlFor={`calendar-${calendar.idx}`}></label>
                            </div>
                        </li>
                    ))}
                </ul>
                <div className="fix-bottom-btn btnwp-two" style={{ display: 'flex' }}>
                    <Button variant="outline" size="lg" onClick={close} style={{ flex: 1 }}>
                        취소
                    </Button>
                    <Button color="main" size="lg" style={{ flex: 1 }}
                        onClick={() => {
                            if (selectedCalendar !== null) {
                                onClick(selectedCalendar)
                            } else {
                                selectValidation();
                            }
                        }}
                    >
                        선택하기
                    </Button>
                </div>
            </div>
            <BgOverlay close={close} />
        </>
    );
}

export default SelectCalendarSheet;