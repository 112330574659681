import { useState } from "react";
import { ChallengeProps, DayProps, RoutineProps, UpdateCompletedRoutineProps, WeekProps } from "../types/routineDayType";
import { updateCompletedRoutine } from "../services/dailyRoutineService";

export const useDailyRoutine = () => {
    const [ weeks, setWeeks ] = useState<WeekProps[]>([]); // 슬라이드에 표시될 날짜배열
    const [ selectRoutinList, setSelectRoutineList ] = useState<RoutineProps[]>([]); // 화면에 보여지는 선택된 루틴 리스트
    const [ selectChellangeList, setSelectChellangeList ] = useState<ChallengeProps[]>([]); // 화면에 보여지는 선택된 루틴 리스트
    const [ selectedDayPorps , setSelectedDayPorps ] = useState<DayProps>(); // 선택된 날짜의 일자정보
    const [ showWeekDayList, setShowWeekDayList ] = useState<WeekProps>([]); // 현재 보이는 주별 일정 리스트

    const toggleRoutineCompletion = (routineId: number) => {
        const day = selectedDayPorps;
        if(day != undefined){
            const updatedWeeks = weeks.map(week => 
              week.map(d => {
                if (d.fullDate.toDateString() === day.fullDate.toDateString()) {
                    const updatedRoutines = d.routines
                        .filter(routine => {
                            const routineDate = routine.startDate !== undefined ? new Date(routine.startDate) : undefined;
                            const endDate = routine.endDate !== undefined ? new Date(routine.endDate) : undefined;
                            if(routineDate && endDate) {
                                return !(day.fullDate >= routineDate && day.fullDate <= endDate);
                            }
                            return true;
                        })
                        .map(routine => 
                            {
                                let updateRoutine = routine;
                                if(routine.id === routineId) {
                                    updateRoutine = { ...routine, isCompleted: !routine.isCompleted }
                                    const updateProps:UpdateCompletedRoutineProps = {id:routine.id,completedDate:d.fullDate}
                                    updateCompletedRoutine(updateProps);
                                } 

                                return updateRoutine;
                            }

                        );
                    const completionRate = updatedRoutines.filter(r => r.isCompleted).length / updatedRoutines.length * 100;

                    setSelectRoutineList(updatedRoutines); // 현재 선택된 루틴 리스트 반영
                    return { ...d, routines: updatedRoutines, completionRate: completionRate };
                }
                return d;
              })
            );
            setWeeks(updatedWeeks);
        }
    };

    const toggleChallengeCompletion = (routineId: number) => {
        const day = selectedDayPorps;
        if(day != undefined){

            const addCompletedNumber = weeks
            .flatMap(week => week)
            .find(d => d.fullDate.toDateString() === day.fullDate.toDateString())
            ?.challenges.find(challenge => challenge.id === routineId)?.isCompleted ? -1 : 1;

            const updatedWeeks = weeks.map(week => 
              week.map(d => {
                const updatedChallenges  = d.challenges.map(challenge => 
                    {
                        if(challenge.id === routineId){
                            
                            if(d.fullDate.toDateString() === day.fullDate.toDateString()) {
                                const updateProps:UpdateCompletedRoutineProps = {
                                    id:challenge.id,
                                    completedDate:d.fullDate
                                }
                                updateCompletedRoutine(updateProps);
                                return { 
                                    ...challenge, 
                                    isCompleted: !challenge.isCompleted, 
                                    completedNumber: challenge.completedNumber + addCompletedNumber 
                                };
    
                            } else {
                                return { 
                                    ...challenge, 
                                    completedNumber: challenge.completedNumber + addCompletedNumber 
                                };
                            }
                        }

                        return challenge;

                    }

                );

                return { ...d, challenges: updatedChallenges };
              })
            );
            setWeeks(updatedWeeks);
        }
    };

    return {weeks, setWeeks, selectRoutinList, setSelectRoutineList, selectedDayPorps, setSelectedDayPorps, showWeekDayList, setShowWeekDayList, toggleRoutineCompletion,toggleChallengeCompletion ,selectChellangeList,setSelectChellangeList}
}