import { createSlice } from "@reduxjs/toolkit";

interface ScheduleState {
    schedule: any[]; // 타입에 따라 수정
    dailySchedule: { [key: string]: any[] }; // 날짜를 키로 하고 배열을 값으로 갖는 객체
}

const initialState: ScheduleState = {
    schedule: [],
    dailySchedule: {},
};

const scheduleReducer = createSlice({
    name: 'event',
    initialState,
    reducers: {
        updateSchesule(state, action) {
            state.schedule = action.payload;
        },
        updateDailySchedule(state, action) {
          const { date, data } = action.payload;

          // 해당 날짜의 데이터가 이미 존재하는지 확인합니다.
          const existingData = state.dailySchedule[date];
          // 이미 데이터가 존재하면 새로운 데이터를 추가하고, 아니면 새로 추가합니다.
          if (existingData) {
            state.dailySchedule[date] = [data];
          } else {
            state.dailySchedule[date] = [data];
          }
        },
    }
});

export default scheduleReducer.reducer;
export const { updateSchesule, updateDailySchedule } = scheduleReducer.actions;
