import { getData, postData } from "../../../apis/api"
import { Interests } from "../../user/types/userDetailType";

export const interestsSave = async (names:Array<String>):Promise<void> => {

    const url = '/interests'

    const response = await postData(url,{names});

    console.log(response);

}

export const findInterests = async () => {
    const response = await getData<Interests[]>('/interests/find');

    return response;
}