import dayjs from "dayjs";
import { getData } from "../../../apis/api"
import Swal from "sweetalert2";
import { MonthRoutineProps } from "../types/routineDayType";

export const getMonthlyRoutine = async (idx:string, startDate:Date, endDate:Date):Promise<MonthRoutineProps[]> => {
    
    try {
        
        const response = await getData<MonthRoutineProps[]>(`/routine/monthly/${idx}?start-date=${dayjs(startDate).format('YYYY-MM-DD')}&end-date=${dayjs(endDate).format('YYYY-MM-DD')}`);
    
        if(response.status != 200) {
            // 오류 출력
            await Swal.fire('실패', '오류가 발생했습니다..', 'error');
        }

        return response.data;

    } catch (error) {
        await Swal.fire('실패', '오류가 발생했습니다..', 'error');
    }

    return [];

}