import { useRef, useState } from 'react';

const vhToPx = (vh: number) => (vh / 100) * window.innerHeight;

const useTouchHandlers = (
    dailylistSheetVisible: boolean,
    setDailylistSheetVisible: (value: boolean) => void,
    swiperClass: HTMLElement | null,
    calTopRef: React.RefObject<HTMLDivElement>,
    subtit01Ref: React.RefObject<HTMLHeadingElement>,
    mainRef: React.RefObject<HTMLButtonElement>,
    listsRef: React.RefObject<NodeListOf<HTMLUListElement>>,
    itemsRef: React.RefObject<NodeListOf<HTMLLIElement>>
) => {
    const startYRef = useRef<number | null>(null);
    const startXRef = useRef<number | null>(null);
    const [upDownStep, setUpDownStep] = useState<number>(1);
    const [swipeDrag, setSwipeDrag] = useState<boolean>(false);
    const [swipeDirection, setSwipeDirection] = useState<string | null>(null);

    const handleTouchStart = (e: React.TouchEvent<HTMLDivElement>) => {
        startYRef.current = e.touches[0].clientY;
        startXRef.current = e.touches[0].clientX;
        setSwipeDrag(false);
        setSwipeDirection(null);
    };

    const handleTouchMove = (e: React.TouchEvent<HTMLDivElement>) => {
        if (!startYRef.current || !startXRef.current) return;

        const currentY = e.touches[0].clientY;
        const currentX = e.touches[0].clientX;
        const deltaY = currentY - startYRef.current;
        const deltaX = currentX - startXRef.current;

        const SWIPE_THRESHOLD = 50;

        if (!swipeDirection) {
            if (Math.abs(deltaX) > SWIPE_THRESHOLD && Math.abs(deltaX) > Math.abs(deltaY)) {
                setSwipeDirection('x');
                setSwipeDrag(true);
                return;
            } else if (Math.abs(deltaY) > SWIPE_THRESHOLD && Math.abs(deltaY) > Math.abs(deltaX)) {
                setSwipeDirection('y');
            }
        }

        if (swipeDirection === 'x') {
            setSwipeDrag(true);
            return;
        }

        if (swipeDirection === 'y' && !(dailylistSheetVisible === false && deltaY > 0)) {
            if (!dailylistSheetVisible && deltaY < 0) {
                setDailylistSheetVisible(true);
            }

            const viewportHeight = window.innerHeight;
            const movementPercentage = (Math.abs(deltaY) / viewportHeight) * 100;

            if (swiperClass) {
                if (dailylistSheetVisible && deltaY < 0) {
                    swiperClass.style.height = upDownStep === 0 ? `${vhToPx(Math.max(75 - movementPercentage, 45))}px` : (upDownStep === 2 ? '0px' : `${vhToPx(Math.max(45 - movementPercentage, 0))}px`);
                }

                if (dailylistSheetVisible && deltaY > 0) {
                    swiperClass.style.height = upDownStep === 2 ? `${vhToPx(Math.min((0 + movementPercentage), 45))}px` : `${vhToPx(Math.min((45 + movementPercentage), 75))}px`;
                }
            }

            adjustListStyle(deltaY);
        }
    };

    const handleTouchEnd = (e: React.TouchEvent<HTMLDivElement>) => {
        setSwipeDrag(false);
        setSwipeDirection(null);

        if (!startYRef.current || !startXRef.current) return;

        const endY = e.changedTouches[0]?.clientY;
        const deltaX = e.changedTouches[0]?.clientX - startXRef.current;
        const deltaY = endY - startYRef.current;

        if ((Math.abs(deltaX) > Math.abs(deltaY) && Math.abs(deltaX) > 50 && Math.abs(deltaY) > 100) || swipeDrag) {
            return;
        }

        const MIN_DRAG_DISTANCE = 30;
        if (Math.abs(deltaY) < MIN_DRAG_DISTANCE) return;

        toggleVisibility(endY);
    };

    const adjustListStyle = (deltaY: number) => {
        const screenHeight = window.innerHeight;
        const progress = Math.abs(deltaY) / screenHeight;

        const minListMargin = 0.5;
        const maxListMargin = 1.25;
        const minItemHeight = 2.5;
        const maxItemHeight = 9;

        let listMargin = 0;
        let itemHeight = 0;

        let calTopHeight = 0;
        let scale = 0;
        let opacity = 0;
        let rgba = 0;

        const movementPercentage = (Math.abs(deltaY) / screenHeight) * 100;

        if (deltaY < 0) {
            if (upDownStep === 1) return;

            listMargin = minListMargin + (maxListMargin - minListMargin) * (1 - progress);
            itemHeight = minItemHeight + (maxItemHeight - minItemHeight) * (1 - progress);

            calTopHeight = Math.min(2 - movementPercentage * 0.05, 2);
            scale = Math.min(1 - movementPercentage * 0.015, 1);
            opacity = Math.min(1 - movementPercentage * 0.015, 1);
            rgba = Math.min(1 - movementPercentage * 0.015, 1);
        } else {
            if (upDownStep === 2) return;

            calTopHeight = Math.min(movementPercentage * 0.055, 2);
            scale = Math.min(movementPercentage * 0.019, 1);
            opacity = Math.min(movementPercentage * 0.017, 1);
            rgba = Math.min(movementPercentage * 0.017, 1);
        }

        // if (calTopRef.current) {
        //     calTopRef.current.style.height = `${calTopHeight}rem`;
        //     calTopRef.current.style.opacity = `${opacity}`;
        // }
        // if (subtit01Ref.current) {
        //     subtit01Ref.current.style.scale = `${scale}`;
        //     subtit01Ref.current.style.transformOrigin = '30px bottom';
        // }
        // if (mainRef.current) {
        //     mainRef.current.style.transformOrigin = 'bottom';
        //     mainRef.current.style.scale = `${scale}`;
        // }

        listsRef.current?.forEach(list => {
            list.style.marginBottom = `${listMargin}rem`;
            list.style.borderBottom = `1px solid rgba(222, 226, 230, ${rgba})`;
        });

        itemsRef.current?.forEach(item => {
            item.style.minHeight = `${vhToPx(itemHeight)}px`;
        });
    };

    const toggleVisibility = (endY: number) => {
        const startY = startYRef.current ?? endY;

        let listMargin = 0;
        let itemHeight = 0;
        let progress = 0;

        if (endY < startY) {
            listMargin = 0.5;
            itemHeight = 2.5;
            progress = 0;

            itemsRef.current?.forEach(item => {
                item.style.minHeight = `${itemHeight}rem`;
            });

            if (swiperClass) {
                swiperClass.style.height = upDownStep === 1 || upDownStep === 2 ? '0px' : `${vhToPx(45)}px`;
            }

            if (calTopRef.current && upDownStep === 1) {
                calTopRef.current.style.display = 'none';
            }

            setUpDownStep(upDownStep === 2 ? upDownStep : upDownStep + 1);
        } else {
            listMargin = upDownStep !== 2 ? 1.25 : 0.5;
            itemHeight = upDownStep !== 2 ? 9 : 2.5;
            progress = upDownStep !== 2 ? 1 : 0;

            itemsRef.current?.forEach(item => {
                item.style.minHeight = upDownStep !== 2 ? `${vhToPx(itemHeight)}px` : `${itemHeight}rem`;
            });

            if (swiperClass) {
                swiperClass.style.height = upDownStep === 1 || upDownStep === 0 ? `${vhToPx(75)}px` : `${vhToPx(45)}px`;
            }

            if (calTopRef.current) {
                calTopRef.current.style.display = 'flex';
                calTopRef.current.style.height = '100%';
                calTopRef.current.style.opacity = '1';
            }
            if (subtit01Ref.current) {
                subtit01Ref.current.style.scale = `1`;
            }
            if (mainRef.current) {
                mainRef.current.style.scale = `1`;
            }

            setUpDownStep(upDownStep === 0 ? upDownStep : upDownStep - 1);
        }

        listsRef.current?.forEach(list => {
            list.style.marginBottom = `${listMargin}rem`;
            list.style.borderBottom = progress === 1 ? '1px solid var(--stroke-01)' : 'none';
        });
        setDailylistSheetVisible(!(endY >= startY && (upDownStep === 1 || upDownStep === 0)));
    };

    const handleDailylistSheetVisible = () => {
        setUpDownStep(1)

        let listMargin = 0;
        let itemHeight = 0;

        listMargin = 0.5
        itemHeight = 2.5

        itemsRef.current?.forEach(item => {
            (item as HTMLElement).style.minHeight = `${itemHeight}rem`;
        });

        if (swiperClass) { 
            swiperClass.style.height = `${vhToPx(45)}px`;
        }

        // if (calTopRef.current) {
        //     calTopRef.current.style.display = 'none';
        // }
        listsRef.current?.forEach(list => {
            (list as HTMLElement).style.marginBottom =  `${listMargin}rem`;
            (list as HTMLElement).style.borderBottom =  'none';
        })

        setDailylistSheetVisible(true);
    }

    return { setSwipeDrag, handleTouchStart, handleTouchMove, handleTouchEnd, handleDailylistSheetVisible };
};

export default useTouchHandlers;
