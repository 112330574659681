import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';

// import calendar styles
import './calendar.css';
// import './calendarday.css'
import './board.css';
import PaletteSheet from '../../components/common/PaletteSheet';
import DropMenu from '../../components/common/DropMenu';
import DateTimePickerSheet from '../../components/event/DateTimePickerSheet';
import Button from '../../components/common/Buttons';
import UserSearchSheet from '../../components/common/UserSearchSheet';
import NoimageSymbol from '../../assets/images/noimages/img_noimage_symbol.png';

//feature
import { createEventFiles, createEventInfo, updateEventInfo } from '../../features/event/services/eventService';
import { useDispatch } from 'react-redux';
import Chips from '../../components/common/Chips';
import { setLayout, setPage } from '../../store/layout';
import SelectCalendarSheet from '../../components/common/SelectCalendarSheet';
import { Calendar as CalendarType } from '../../features/multiCalendar/types/calendarType';
import { findAllWriteCalendars } from '../../features/multiCalendar/services/multiCalendarService';
import { useEventHook } from '../../features/event/hooks/useEvent.hook';
import DatePickerSheet from '../../components/event/DatePickerSheet';
import Swal from 'sweetalert2';
import { event } from 'jquery';

const CalendarDay: React.FC = () => {
    const dispatch = useDispatch();
    const location = useLocation();
    const pageInfo = { ...location.state };
    const mode = pageInfo.mode || '';
    const idx = pageInfo.idx || '';
    const dateString = pageInfo.dateString || '';
    const navigate = useNavigate();

    useEffect(() => {
        dispatch(setLayout('CalendarDay'));
        if (mode === 'write') {
            dispatch(setPage('새로운 일정 등록'));
        } 
    }, []);

    const {
        values,
        setValues,
        handleChange,
        handleInputChange,
        repeatLabel,
        toggleRepeatMenu,
        repeatMenu,
        alarmEnabled,
        showAlarmMenu,
        setShowAlarmMenu,
        selectedAlarm,
        alamMenuItems,
        calendarSheetOpen,
        setCalendarSheetOpen,
        selectCalendar,
        setSelectCalendar,
        openPaletteSheet,
        togglePaletteSheet,
        handlePaletteColor,
        repeatMenuItems,
        startDatePicker,
        endDatePicker,
        setStartDatePicker,
        setEndDatePicker,
        handleTagChange,
        handleKeyDown,
        fetchEvent,
        appendEventUser,
        deleteEventUser,
        eventUsers,
        closeUserSearchSheet,
        setIsUserSearchSheetActive,
        isUserSearchSheetActive,
        inviteUsers,
        repeatEndDatePicker,
        setRepeatEndDatePicker,
        selectedStartDate,
        setSelectedStartDate,
        selectedStartTime,
        setSelectedStartTime,
        selectedEndDate,
        setSelectedEndDate,
        selectedEndTime,
        setSelectedEndTime,
        startTimeFormatted,
        endTimeFormatted,
        repeatedDate, 
        setRepeatedDate, 
        handleToggleAlarm,
        eventFiles,
        handleFileChange,
        handleDeleteFile,
    } = useEventHook(mode);
    
    const handleCalendar = (calendar: CalendarType) => {
        handleChange('calendarId', calendar.idx);
        setSelectCalendar(calendar);
        setCalendarSheetOpen(false);
    };

    useEffect(() => {
        fetchEvent(idx);
    }, [idx, mode]);

    useEffect(() => {
        if (mode !== 'update' && dateString) {
            const [year, month, day] = dateString.split('-').map(Number);
            const newDate = new Date(year, month - 1, day);
            setSelectedStartDate(newDate);

            const startDateTime = new Date(newDate);
            startDateTime.setHours(12);
            startDateTime.setMinutes(0);
            setSelectedStartTime(startDateTime);

            const endDateTime = new Date(newDate);
            endDateTime.setHours(13);
            endDateTime.setMinutes(0);
            setSelectedEndDate(endDateTime);
            setSelectedEndTime(endDateTime);

            handleChange('startDate', startDateTime);
            handleChange('endDate', endDateTime);
        }
    }, [location.state]);

    useEffect(() => {
        // 초기 캘린더 세팅
        setEventCalendarId();
    }, []);
    
    // console.log('selectedStartDate :', selectedStartDate, 'selectedStartTime :', selectedStartTime);
    const setEventCalendarId = async () => {
        try {
            const response = await findAllWriteCalendars();
            if (mode === 'write') {
                response.data.map((calendar) => {
                    if (calendar.selected) {
                        setSelectCalendar(calendar);
                        setValues((prevEvent) => ({ ...prevEvent, calendarId: calendar.idx }));
                    }
                });
            } else if (mode === 'board') {
                response.data.map((calendar) => {
                    if (calendar.idx === values.calendarId) {
                        setSelectCalendar(calendar);
                    }
                });
            }
        } catch (error) {
            console.error('캘린더 목록을 불러오는데 실패하였습니다:', error);
        }
    };

    const handleSubmit = async () => {
        try {
            let startDate = new Date(values.startDate);
            let endDate = new Date(values.endDate);
            let repeatedDate = values.repeatedDate ? new Date(values.repeatedDate) : undefined;
    
            startDate.setHours(startDate.getHours() + 9);
            endDate.setHours(endDate.getHours() + 9);

            if (repeatedDate) {
                repeatedDate.setHours(repeatedDate.getHours() + 9);
            }
    
            if (mode == 'write') {
                const response = await createEventInfo({ ...values, startDate: startDate, endDate: endDate, repeatedDate: repeatedDate });
                const eventIdx = response.data;
                await inviteUsers(eventIdx);
                if (eventFiles.length > 0) {
                    await createEventFiles(eventIdx, eventFiles);
                }
                navigate('/calendar');
            } else {
                await updateEventInfo({ ...values, startDate: startDate, endDate: endDate, repeatedDate: repeatedDate,});
                await createEventFiles(idx, eventFiles);
                await inviteUsers(idx);
                navigate('/event', { state: { mode: 'board', idx: idx, edit: true } });
            }
        } catch (error) {
            console.error('이벤트 등록 또는 수정에 실패하였습니다', error);
        }
    };

    const handleStartDayModal = (date: Date) => {
        setSelectedStartDate(date);
        const newEndDate = addOneHour(date); 
        setSelectedEndDate((prevEndDate) => {
            if (prevEndDate && date.getTime() >= prevEndDate.getTime()) {
                return newEndDate;
            }
            return prevEndDate;
        });
        handleChange('startDate', date);
        handleChange('endDate', newEndDate); 
    };
    
    const handleStartTimeModal = (hour: number, minute: number, period: string) => {
        const date = new Date(selectedStartDate || new Date());

        if (hour === 12 && period === 'AM') {
            date.setHours(0, minute);
        } else if (hour === 12 && period === 'PM') {
            date.setHours(12, minute);

        } else {
            date.setHours(period === 'AM' ? hour : hour + 12, minute);
        }
        
        setSelectedStartTime(date);
        const newEndDate = addOneHour(date); 
        setSelectedEndTime(newEndDate);
        setStartDatePicker(false);
        handleChange('startDate', date);
        handleChange('endDate', newEndDate); 
    };
    
    const handleEndDayModal = (date: Date) => {
        if (selectedStartDate && date.getTime() >= selectedStartDate.getTime()) {
            setSelectedEndDate(date);
            handleChange('endDate', date); 
        }
        if (repeatedDate && date > repeatedDate) {
            setRepeatedDate(null);
        }
    };
    
    const handleEndTimeModal = (hour: number, minute: number, period: string) => {
        const date = new Date(selectedEndDate || new Date());
    
        if (hour === 12 && period === 'AM') {
            date.setHours(0, minute);
        } else if (hour === 12 && period === 'PM') {
            date.setHours(12, minute);

        } else {
            date.setHours(period === 'AM' ? hour : hour + 12, minute);
        }
        
        if (date.getTime() < selectedStartTime.getTime()) {
            Swal.fire({
                text: '종료시간은 시작시간 이후여야 해요',
                confirmButtonText: '돌아가기',
            });
        } else {
            setSelectedEndTime(date);
            setEndDatePicker(false);
            handleChange('endDate', date); 
        }
    };
        
    const addOneHour = (date: Date): Date => {
        const newDate = new Date(date.getTime());
        newDate.setHours(newDate.getHours() + 1);
        return newDate;
    };

    const handleRepeatedDayModal = (date: Date) => {
        setRepeatedDate(date);
        handleChange('repeatedDate', date); 
        setRepeatEndDatePicker(false);
    };

    function formatDate(date: Date | null) {
        if (!date) {
            return ''; 
        }
    
        const year = date.getFullYear();
        const month = date.getMonth() + 1;
        const day = date.getDate();
    
        return `${year}년 ${month}월 ${day}일`;
    }
    
    
    return (
        <>
            <div
                className='board-wp grid-20 write'
                onClick={() => {
                    if (repeatMenu) toggleRepeatMenu();
                }}
            >
                <div className="title-labeling mb-20">
                    <input
                        type="text"
                        name="name"
                        placeholder="일정 제목을 입력하세요"
                        onChange={handleInputChange}
                        value={values.name}
                        autoComplete="off"
                    />
                    <button className="btn-lebeling" onClick={() => togglePaletteSheet()}>
                        <span className={'chips ' + values.label}></span>
                    </button>
                    {openPaletteSheet && <PaletteSheet openSheet={togglePaletteSheet} setColor={handlePaletteColor} />}
                </div>
                {/* 시간설정 */}
                <p className="body-02 mb-8">일정 시간 설정</p>
                <div className="card">
                    <div className="write-control" onClick={() => setStartDatePicker((prev) => !prev)}>
                        <p className="body-01 control-tit">시작</p>
                        <input
                            type="text"
                            name="startDate"
                            className="inp-date"
                            placeholder="시작 날짜를 선택하세요"
                            value={startTimeFormatted}
                            readOnly
                        />
                        {startDatePicker && (
                            <DateTimePickerSheet
                                tabType={'tab1'}
                                setDayModal={handleStartDayModal}
                                setTimeModal={handleStartTimeModal}
                                startDate={selectedStartTime}
                            />
                        )}
                    </div>
                    <hr className="hr-dashed mt-20 mb-20" />
                    <div className="write-control" onClick={() => setEndDatePicker((prev) => !prev)}>
                        <p className="body-01 control-tit">종료</p>
                        <input
                            type="text"
                            name="endDate"
                            className="inp-date"
                            placeholder="종료 날짜를 선택하세요"
                            value={endTimeFormatted}
                            readOnly
                        />
                        {endDatePicker && (
                            <DateTimePickerSheet
                                tabType={'tab1'}
                                setDayModal={handleEndDayModal}
                                setTimeModal={handleEndTimeModal}
                                startDate={selectedStartTime}
                                endDate={selectedEndTime}
                            />
                        )}
                    </div>
                </div>
                {/* 반복설정 */}
                <p className="body-02 mt-20 mb-8">반복 설정</p>
                <div className="card" style={{ position: 'relative' }}>
                    <div className="write-control">
                        <p className="body-01 control-tit">반복 설정</p>
                        <button className="btn-edit txt-input" onClick={() => toggleRepeatMenu()}>
                            {repeatLabel}
                        </button>
                    </div>
                    {repeatLabel !== '반복 안함' && (
                        <>
                            <hr className="hr-dashed mt-20 mb-20" />
                            <div
                                className="write-control"
                                onClick={() => values.repeatedType !== null && setRepeatEndDatePicker((prev) => !prev)}
                            >
                                <p className="body-01 control-tit">반복 종료</p>
                                <input type="text" className="inp-date" placeholder="반복 종료 날짜를 선택하세요" value={repeatedDate ? formatDate(repeatedDate) : ''} readOnly />
                                {repeatEndDatePicker && <DatePickerSheet setDayModal={handleRepeatedDayModal} selectedDate={repeatedDate} endDate={selectedEndTime}/>}
                            </div>
                        </>
                    )}
                    {repeatMenu && (
                        <DropMenu items={repeatMenuItems} style={{position: 'absolute', top: '44px', right: '20px'}}/>
                    )}
                </div>
                {/* 장소설정 */}
                <p className="body-02 mb-8 mt-20">장소</p>
                <div className="card">
                    <div className="write-control">
                        <input
                            type="text"
                            name="place"
                            placeholder="장소를 입력하세요"
                            onChange={handleInputChange}
                            value={values.place}
                            autoComplete="off"
                        />
                    </div>
                </div>
                {/* 알림설정 */}
                <div className="card mt-20" style={{ position: 'relative' }}>
                    <div className="write-control">
                        <p className="body-01 control-tit">알림 받기</p>
                        <div className="switch-wrapper">
                            <input
                                type="checkbox"
                                id="alarm"
                                className="switch-inp"
                                hidden
                                checked={alarmEnabled}
                                onChange={handleToggleAlarm}
                            />
                            <label htmlFor="alarm" className="switch-label">
                                <span className="onf-btn"></span>
                            </label>
                        </div>
                    </div>
                    {alarmEnabled && (
                        <>
                            <hr className="hr-dashed mt-20 mb-20" />
                            <div className="write-control" onClick={() => setShowAlarmMenu(!showAlarmMenu)} >
                                <p className="body-01 control-tit">알림 시간</p>
                                <button className="btn-edit txt-input" >
                                    {selectedAlarm.label}
                                </button>
                            </div>
                            {showAlarmMenu && ( 
                                <DropMenu items={alamMenuItems} 
                                    style={{ position: 'absolute', top: '120px', right: '20px' }} 
                                    showDropMenu={setShowAlarmMenu}  
                                />
                            )}                        
                        </>
                    )}
                </div>
                {/* 캘린더 설정 */}
                <p className="body-02 mb-8 mt-20">캘린더 설정</p>
                <Button
                    variant="text"
                    size="sm"
                    className="btn-shareuser success"
                    onClick={() => {
                        setCalendarSheetOpen((prev) => !prev);
                    }}
                >
                    {values.calendarId !== 0 ? selectCalendar?.emoji + ' ' : ''}
                    {values.calendarId !== 0 ? selectCalendar?.name : '캘린더를 선택해주세요'}
                </Button>
                {calendarSheetOpen && (
                    <SelectCalendarSheet
                        selected={selectCalendar}
                        close={() => {
                            setCalendarSheetOpen(false);
                        }}
                        onClick={(calendar: CalendarType) => {
                            handleCalendar(calendar);
                        }}
                    />
                )}
                {/* 공유하기 */}
                <p className="body-02 mb-8 mt-20">공유하기</p>
                <Button
                    variant="text"
                    size="sm"
                    className="btn-shareuser"
                    style={{ display: 'flex', flexWrap: 'wrap' }}
                    onClick={() => {
                        if (values.eventGrade === "USER") {
                            return;
                        }
                        setIsUserSearchSheetActive(true);
                    }}
                    disabled={values.eventGrade === "USER"} 
                >
                    {values.eventGrade === "USER" ? (
                        <span style={{ fontSize: '14px', lineHeight: '20px' }}>공유 권한이 없어요</span>
                    ) : (
                        <>
                            {eventUsers.length > 0 ?
                                eventUsers.map((user, idx) => (
                                    <Chips
                                        color={user.accept === "ACCEPT" ? "black" : "grey"}
                                        key={idx}
                                        style={{ marginRight: '5px', display: 'flex', alignItems: 'center' }}
                                    >
                                        <img
                                            src={user.profile || NoimageSymbol}
                                            alt="Profile"
                                            style={{ width: '20px', height: '20px', borderRadius: '50%', marginRight: '5px' }}
                                        />
                                        <span style={{ fontSize: '14px', lineHeight: '20px' }}>{user.nickName}</span>
                                    </Chips>
                                ))
                                : (
                                    <span style={{ fontSize: '14px', lineHeight: '20px' }}>일정을 공유하고 싶은 회원을 추가하세요</span>
                                )
                            }
                        </>
                    )}
                </Button>
                {isUserSearchSheetActive && (
                    <UserSearchSheet
                        close={closeUserSearchSheet}
                        appendEventUser={appendEventUser}
                        deleteEventUser={deleteEventUser}
                        eventUsers={eventUsers}
                    />
                )}
                {/* 첨부파일 */}
                <div className="card mt-20">
                    <div className="write-control">
                        <div className="fileupload-wp">
                            <input
                                type="file"
                                id="files"
                                className="input-file"
                                accept=".jpg,.jpeg,.png,.gif"
                                multiple
                                onChange={handleFileChange}
                            />
                            <label className="btn btn-sm main btn-file" htmlFor="files">
                                파일선택
                            </label>
                        </div>
                    </div>
                </div>
                {eventFiles.map((image, index) => (
                    <ul key={index} className="card ul-filelist">
                        <li>
                        <span>{image.name}</span>

                            <Button variant="text" color="error" onClick={() => handleDeleteFile(index)}>
                                삭제
                            </Button>
                        </li>
                    </ul>
                ))}
                <p className="caption mt-8 pl-20">
                    첨부가능한 파일은 이미지 형식만 가능합니다.
                    <br />
                    최대 5개의 파일, 전체 파일의 크기는 30MB로 제한됩니다.
                </p>
                {/* 메모 */}
                <div className="card mt-20">
                    <div className="write-control">
                        <textarea
                            name="memo"
                            placeholder="메모를 입력하세요"
                            onChange={handleInputChange}
                            value={values.memo}
                        ></textarea>
                    </div>
                </div>
                {/* 해시태그 */}
                <div className="card mt-20">
                    <div className="write-control">
                        <input
                            type="text"
                            name="tag"
                            placeholder="해시태그를 입력하세요 예) #일상 #이벤트"
                            onChange={handleTagChange}
                            onKeyDown={handleKeyDown}
                            value={values.tag}
                            autoComplete="off"
                        />
                    </div>
                </div>
                <div className="fix-bottom-btn">
                    <button className="btn btn-lg main btn-bottomfix" onClick={handleSubmit} disabled={!values.name ||  values.calendarId === 0}>
                        {idx == '' ? '등록하기' : '수정하기'}
                    </button>
                </div>
            </div>
        </>
    );
};

export default CalendarDay;