import { Calendar } from './../features/multiCalendar/types/calendarType';
import { combineReducers } from "redux";
import auth from "../features/auth/stores/auth"
import day from "../features/day/stores/day"
import schedule from "../features/schedule/stores/schedule"
import routine from "../features/routine/stores/routine"
import { configureStore } from "@reduxjs/toolkit";
import notification from '../features/notification/stores/reducer';
import layout from "./layout";


const reducers = combineReducers({
    auth,
    day,
    schedule,
    notification,
    routine,
    layout
  });

const rootReducer = configureStore({
    reducer: reducers
})

export type RootState = ReturnType<typeof rootReducer.getState>;
export type AppDispatch = typeof rootReducer.dispatch;

export default rootReducer;