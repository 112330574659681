import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getHolidays, getSchedule } from "../service/scheduleService";
import { updateYearMonth } from '../../day/stores/day';
import { updateSchesule } from '../stores/schedule';
import { addMonths, attachZero } from '../../../utils/calendar/CalendarUtils';
import dayjs from 'dayjs';
import { Holiday } from '../../event/stores/eventType';
import { useSearchParams } from 'react-router-dom';

const useCalendarState = (todayBtn: boolean, setTodayBtn: (value: boolean) => void, swiperContainer: any) => {
    const dispatch = useDispatch();
    const selectDayReducer = useSelector((state: any) => state.day);

    const [months, setMonths] = useState<Date[]>([]);
    const [currentHolidays, setCurrentHolidays] = useState<Holiday[]>([]);
    
    const [searchParams] = useSearchParams();

    useEffect(() => {

        const dateParam = searchParams.get('date');
        const today = dateParam ? new Date(dateParam) : new Date();

        if(dateParam){
            dispatch(updateYearMonth(today.getFullYear(), today.getMonth() + 1, today.getDate()));
        }

        setMonths([addMonths(today, -2), addMonths(today, -1), today, addMonths(today, 1), addMonths(today, 2)]);
    }, []);

    useEffect(() => {
        
        const numDaysInMonth = new Date(selectDayReducer.year, selectDayReducer.month, 0).getDate();
        const startDate = new Date(`${selectDayReducer.year}-${attachZero(selectDayReducer.month)}-01`);
        const endDate = new Date(`${selectDayReducer.year}-${attachZero(selectDayReducer.month)}-${numDaysInMonth}`);

        getSchedule(startDate, endDate).then(data => dispatch(updateSchesule(data)));
        getHolidays(selectDayReducer.year, selectDayReducer.month).then(data => setCurrentHolidays(data ?? []));

        const selectDay = dayjs(`${selectDayReducer.year}-${selectDayReducer.month}-01`).toDate();
        setMonths([addMonths(selectDay, -2), addMonths(selectDay, -1), selectDay, addMonths(selectDay, 1), addMonths(selectDay, 2)]);

        if (todayBtn) {
            swiperContainer?.slideTo(2);
            setTodayBtn(false);
        }

    }, [selectDayReducer.year, selectDayReducer.month, todayBtn, dispatch, swiperContainer]);

    return { months, currentHolidays };
};

export default useCalendarState;