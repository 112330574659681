import React, { useState, useEffect } from 'react';
import Button from '../../components/common/Buttons';
import { useNavigate } from 'react-router-dom';
import './calendar.css';
import './board.css';
import EmojiSheet from '../../components/common/EmojiSheet';
import useMultiCalendarHook from '../../features/multiCalendar/hooks/useMultiCalendar.hook';
import { useDispatch } from 'react-redux';
import Header from '../../layout/multiCalendar/Header';
import { setLayout, setPage } from '../../store/layout';
import UserSearchSheet from '../../components/common/UserSearchSheet';
import Chips from '../../components/common/Chips';
import NoimageSymbol from "../../assets/images/noimages/img_noimage_symbol.png";

const MultiCalendar: React.FC = () => {
    const dispatch = useDispatch();

    const [isUserSearchSheetActive, setIsUserSearchSheetActive] = useState(false);

    const { 
        values, 
        handleName, 
        isEmojiSheetActive,
        setIsEmojiSheetActive, 
        selectedEmoji, 
        handleEmojiSave, 
        toggleEmojiSheet, 
        updateValues, 
        handleGrade, 
        calendarUsers,
        readMode, 
        editMode, 
        handleEditMode, 
        handleDeleteCalendar, 
        calendar,
        handleLeaveCalendar,
        appendCalendarUser,
        deleteCalendarUsers,
        handleRegister
    } = useMultiCalendarHook();

    const closeUserSearchSheet = () => {
        setIsUserSearchSheetActive(false);
    };

    useEffect(() => {
        if (editMode) {
            dispatch(setLayout('multiCalendarEdit'));
            dispatch(setPage('캘린더 수정'));
        } else if (readMode) {
            const layout = calendar && calendar.userGrade === 'USER' ? 'multiCalendarUserDetail' : 'multiCalendarDetail';
            dispatch(setLayout(layout));
            dispatch(setPage('캘린더 상세보기'));
        } else {
            dispatch(setLayout('multiCalendarAdd'));
            dispatch(setPage('캘린더 등록'));
        }
    }, [dispatch, handleEditMode]);

    return (
        <>
            <Header handleDeleteCalendar={handleDeleteCalendar} handleEditMode={handleEditMode} />
            <div className={`board-wp grid-20 ${!editMode && !readMode ? 'write' : readMode ? 'board' : 'write'}`}>
                <div className='title-labeling mb-20'>
                    <input
                        type="text"
                        name=""
                        id=""
                        value={!editMode && !readMode ? values.name : updateValues.name}
                        onChange={handleName}
                        placeholder='캘린더 이름을 입력하세요'
                        disabled={readMode}
                    />
                    <button className='btn-lebeling' onClick={toggleEmojiSheet}>
                        <span className='body-01'>{!editMode && !readMode ? selectedEmoji : updateValues.emoji}</span>
                    </button>
                    {isEmojiSheetActive && !readMode && (
                        <EmojiSheet onSaveEmoji={handleEmojiSave} selectedEmoji={!editMode ? selectedEmoji : updateValues.emoji} closeEmojiSheet={() => setIsEmojiSheetActive(false)}/>
                    )}
                </div>
                <p className='body-02 mb-8 mt-20'>읽기 설정</p>
                <div className='card'>
                    <div className='write-control'>
                        <p>읽기전용 설정</p>
                        <div className="switch-wrapper">
                            <input 
                                type="checkbox" 
                                id="switch3" 
                                className='switch-inp' 
                                hidden
                                checked={(editMode || readMode) ? updateValues.grade === 'READ' : undefined}
                                disabled={readMode}
                                onChange={(event) => {
                                    const grade = event.target.checked ? 'READ' : 'WRITE';
                                    handleGrade(grade);
                                }}     
                            />
                            <label htmlFor="switch3" className="switch-label">
                                <span className="onf-btn"></span>
                            </label>
                        </div>
                    </div>
                </div>
                <p className='body-02 mb-8 mt-20'>공유하기</p>
                <Button 
                    variant='text' 
                    size='sm' 
                    className='btn-shareuser'
                    style={{ display: 'flex', flexWrap: 'wrap' }} 
                    onClick={() => {
                        if (!readMode) {
                            setIsUserSearchSheetActive(true);
                        }
                    }}
                    disabled={readMode} 
                >
                    {calendarUsers.length > 0 ? (
                        calendarUsers.map((user, idx) => (
                            // <Chips 
                            //     color='grey' 
                            //     key={idx} 
                            //     style={{ marginRight: '5px', marginBottom: '2px', marginTop: '2px' }} 
                            // >
                            //     {user.nickName}
                            // </Chips>
                            <Chips 
                                color={user.accept === "ACCEPT" ? "black" : "grey"} 
                                key={idx} 
                                style={{ marginRight: '5px', display: 'flex', alignItems: 'center' }} 
                            >
                                <img 
                                    src={(user.profile || NoimageSymbol)} 
                                    alt="Profile" 
                                    style={{ width: '20px', height: '20px', borderRadius: '50%', marginRight: '5px' }} 
                                />
                                <span style={{ fontSize: '14px', lineHeight: '20px' }}>{user.nickName}</span>
                            </Chips>
                        ))
                    ) : (
                        readMode ? '공유된 회원이 없어요' : '캘린더를 공유하고 싶은 회원을 추가하세요'
                    )}
                </Button>
                {isUserSearchSheetActive && 
                    <UserSearchSheet 
                        close={closeUserSearchSheet} 
                        appendEventUser={appendCalendarUser} 
                        deleteEventUser={deleteCalendarUsers} 
                        eventUsers={calendarUsers}
                    />
                }
                <div className='fix-bottom-btn'>
                    <button
                        className="btn btn-lg main btn-bottomfix"
                        onClick={handleRegister}
                        disabled={editMode ? !updateValues.name.trim() : !values.name.trim()}
                    >
                        {!editMode ? '등록하기' : '수정하기'}
                    </button>
                </div>
                <br/>
                {readMode && calendar && calendar.userGrade === 'USER' && (
                    <div style={{ display: 'flex', opacity: '.8' }}>
                        <Button size="sm" onClick={handleLeaveCalendar} variant='text'>캘린더 탈퇴하기</Button>
                    </div>
                )}
            </div>
        </>
    );
};

export default MultiCalendar;
