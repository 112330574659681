interface BgOverlayProps {
    close?: () => void;
}

function BgOverlay({close} : BgOverlayProps) {

    return (
        <>
            <div className="bg-overlay" onClick={close}></div>
        </>
    )
}

export default BgOverlay;