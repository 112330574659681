// import Swiper JS
import { Swiper, SwiperSlide } from 'swiper/react';
// import Swiper styles
import 'swiper/css';// import calendarcomponent styles
import './calendarcomponent.css';
import EventItem from "./EventItem";
import ResizableBar from './ResizableBar';
import { Dispatch, SetStateAction, useEffect, useRef, useState } from 'react';

import HarudalSlider, { HarudalSliderMethods } from '../common/Slider';
import { useSelector } from 'react-redux';
import { setSelectSchedule } from '../../features/schedule/service/scheduleService';
import { updateDailySchedule } from '../../features/schedule/stores/schedule';
import { useDispatch } from 'react-redux';
import { updateYearMonth } from '../../features/day/stores/day';
import { Holiday } from '../../features/event/stores/eventType';

interface DaylistSheetProps {
    settingCalendar: (vector?:string, lastDay?:number) => void,
    setSwipeDrag: Dispatch<SetStateAction<boolean>>,
    currentHolidays: Holiday[]
}

const DaylistSheet: React.FC<DaylistSheetProps> = ({ settingCalendar, setSwipeDrag, currentHolidays }) => {
    const sliderRef = useRef<HarudalSliderMethods>(null);
    const selectDayReducer = useSelector((dateReducer: any) => dateReducer.day);
    const dispatch = useDispatch();
    
    const [checkChangeDateCount, setCheckChangeDateCount] = useState(0);
    const [checkChangeDate, setCheckChangeDate] = useState<string[]>([]);
    
    useEffect(() => {
        const curSelectDay = `${selectDayReducer.year}-${selectDayReducer.month < 10 ? '0' + selectDayReducer.month : selectDayReducer.month}-${selectDayReducer.day < 10 ? '0' + selectDayReducer.day : selectDayReducer.day}`;
        const currentDate = new Date(curSelectDay);
        
        // 이전 날짜 가져오기
        const previousDate = new Date(currentDate);
        previousDate.setDate(currentDate.getDate() - 1);

        // 이후 날짜 가져오기
        const nextDate = new Date(currentDate);
        nextDate.setDate(currentDate.getDate() + 1);

        // 비동기 함수 선언
        const updateSchedules = async () => {
            try {
                // 이전 날짜 스케줄 설정
                const previousData = await setSelectSchedule(previousDate);
                dispatch(updateDailySchedule({ date: formatDate(previousDate), data: previousData }));
                
                // 이후 날짜 스케줄 설정
                const nextData = await setSelectSchedule(nextDate);
                dispatch(updateDailySchedule({ date: formatDate(nextDate), data: nextData }));

                // 상태를 업데이트하는 대신 이전 상태를 기반으로 업데이트
                setCheckChangeDate([
                    formatDate(previousDate),
                    formatDate(currentDate),
                    formatDate(nextDate)
                ]);

                // 슬라이더 인덱스 설정
                sliderRef.current?.setCurrentIndex(1);
            } catch (error) {
                console.error("Error updating schedules: ", error);
            }
        };

        // 스케줄 업데이트 함수 호출
        updateSchedules();

    }, [selectDayReducer.day, selectDayReducer.month])
    
    // weeks 상태 변경에 따라 currentIndex 업데이트
    useEffect(() => {
        if (checkChangeDateCount > 0) {
            const currentIndex = sliderRef.current?.getCurrentIndex() ?? 0;
            sliderRef.current?.setCurrentIndex(currentIndex + 1);
            setCheckChangeDateCount(0); // 상태 초기화
        }
    }, [checkChangeDate, checkChangeDateCount]); // weeks가 변경될 때마다 실행

    const handleSlideChange = (index:number) => {
        // 현재 사용 되어지는 배열 index
        const nowWeeks = checkChangeDate[index];
    }
    
    const addNextWeek = async () => {
        // 날짜위치에 따른 날짜 변경
        const currentDate = new Date(checkChangeDate[2]);
        dispatch(updateYearMonth(currentDate.getFullYear(), currentDate.getMonth() + 1, currentDate.getDate(), currentDate.getDay()));
    };
    
    const addPreviousWeek = async () => {
        // // 날짜위치에 따른 날짜 변경
        const currentDate = new Date(checkChangeDate[0]);
        dispatch(updateYearMonth(currentDate.getFullYear(), currentDate.getMonth() + 1, currentDate.getDate(), currentDate.getDay()));
        
    };
    
    // 날짜 형식 변경 (YYYY-MM-DD 형식)
    const formatDate = (date: Date) => {
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');
        return `${year}-${month}-${day}`;
    };

    const onTouchStart = () => {
        setSwipeDrag(true);
    }

    const onTouchEnd = () => {
        setSwipeDrag(false);
    }

    return (
        <>
            <div className='card card-drawer dailylist-sheet'>
                <ResizableBar /> 
                <HarudalSlider
                    ref={sliderRef}
                    initialSlide={1} 
                    onSlideChange={(index) => handleSlideChange(index)}
                    onReachBeginning={addPreviousWeek}
                    onReachEnd={addNextWeek}
                    onTouchStart={onTouchStart}
                    onTouchEnd={onTouchEnd}
                >
                    {checkChangeDate.map((date: string, idx: number) => (
                        <div key={idx} className='grid-16 pt-0 pb-0'>
                            <EventItem dateString={date} currentHolidays={currentHolidays} />
                        </div>
                    ))}
                </HarudalSlider>
            </div>
        </>
    )
}

export default DaylistSheet;