import { ReactElement, createContext, useEffect, useReducer } from "react";
import { getAccessToken, getAppToken, getRefershToken, getUserInfo, refreshAccessToken, sessionLogout } from "../../features/auth/services/authService";
import { Auth, JWTContextType } from "../../features/auth/types/authType";
import { verifyToken } from "../../utils/auth";
import auth from "../../features/auth/stores/auth";
import { LOGIN, LOGOUT } from "../../features/auth/stores/action";
import { LoginUser } from "../../features/user/types/user";
import { client, getData } from "../../apis/api";
import { useNavigate } from "react-router-dom";


// constant
const initialState: Auth = {
    isLoggedIn: null,
    user: {name:'',uuid:'', isUserDetail:false, profile: null, email:'', nickname:'', isMulti:false}
};


const JWTContext = createContext<JWTContextType | null>(null);

export const JWTProvider = ({ children }: { children: ReactElement }) => {
    const [state, dispatch] = useReducer(auth, initialState);

    const fetchUserData = async () => {
        try {
            const accessToken = getAccessToken();
            const refreshToken= getRefershToken();

            if (accessToken && verifyToken(accessToken)) {

                const user = await getUserInfo();
                await getAppToken();

                dispatch({
                    type: LOGIN,
                    payload: {
                        isLoggedIn: true,
                        user: user
                    }
                });
            } else if(refreshToken && verifyToken(refreshToken)) {
                const newAccessToken = getAccessToken();
                client.defaults.headers.common['Authorization'] = `Bearer ${newAccessToken}`;

                const user = await getUserInfo();

                dispatch({
                    type: LOGIN,
                    payload: {
                        isLoggedIn: true,
                        user: user
                    }
                });
            } else if(!window.location.href.includes('/auth')) {
                dispatch({
                    type: LOGOUT
                });
            }
        } catch (err) {
            console.error(err);
            sessionLogout();
            dispatch({
                type: LOGOUT
            });
        }
    };

    useEffect(() => {
        fetchUserData();
    }, []);
  
    const login = async (user:LoginUser) => {
        dispatch({
            type: LOGIN,
            payload: {
            isLoggedIn: true,
            user: user
            }
        });
    };
  
  
    const logout = () => {
        sessionLogout();
        dispatch({
            type: LOGOUT
        });
    };
  
    return <JWTContext.Provider value={{ ...state, login, logout, fetchUserData }}>{children}</JWTContext.Provider>;
};

export default JWTContext;
