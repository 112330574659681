import Calendar from "react-calendar";
import BgOverlay from "../common/BgOverlay";
import dayjs from "dayjs";
import Button from "../common/Buttons";
import { useState } from "react";

interface DatePickerSheetProps {
    selectedDay: Date;
    changeSelectedDay : Function;
    setShowDatePicker: (show: boolean) => void;
}

type  ValuePiece = Date|null;

const DatePickerSheet: React.FC<DatePickerSheetProps> = ({
    selectedDay,
    changeSelectedDay,
    setShowDatePicker 
}) => {

    const [value, onChange] = useState<ValuePiece|[ValuePiece,ValuePiece]>(new Date(selectedDay));


    return (
        <>
            <div className='card card-drawer'>
                <div className='sheet-header'>
                    <div className='sheet-handle'></div>
                </div>
                <div className='tabs-content'>
                    <Calendar className='drawer-calendar' formatDay ={(locale, date) => dayjs(date).format('DD')}
                        calendarType="US" //요일 세팅때문에 넣음
                        prevLabel=""
                        nextLabel=""
                        onChange={onChange}
                        value={value}
                    />
                </div>
                <div className="mt-16">
                    <Button color="main" size="lg" onClick={() => changeSelectedDay(value)}>선택하기</Button>
                </div>
            </div>
            <BgOverlay close={() => setShowDatePicker(false)}/>
        </>        
    );
}


export default DatePickerSheet;