import React, { useEffect, useState } from 'react';
// import Swiper JS
import { Swiper, SwiperSlide } from 'swiper/react';
// import Swiper styles
import 'swiper/css';
// import calendar styles
import './calendarMonth.css';
import CalendarCompoent from "../../components/calendar/CalendarCompoent";
import { useNavigate } from 'react-router-dom';

import { useSelector, useDispatch } from 'react-redux';
import { setMonthSchedule } from '../../features/schedule/service/scheduleService';
import Typography from '../../components/common/Typography';
import RoutineCard from '../../components/routine/RoutineCard';
import { Link } from 'react-router-dom';
import { setLayout, setPage } from '../../store/layout';

import AOS from "aos";
import "aos/dist/aos.css";
import dayjs from 'dayjs';

const CalendarMonth: React.FC = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [dateList, setDateList] = useState<Array<string>>([]);
    const [schedule, setSchedule] = useState<Array<number>>([]);

    const dayStore = useSelector((dayReducer: any) => dayReducer.day);
    
    useEffect(() => {
        dispatch(setLayout('Year'));
        dispatch(setPage(''));
    }, [])
    
    useEffect(() => {
        createDayList(dayStore.year, dayStore.month);

        setMonthSchedule(dayStore.year, dayStore.month)
            .then((result) => {
                const allNullCheck = result.data.every(function (value) {
                    return value === null;
                });

                if (!allNullCheck) {
                    setSchedule(result.data);
                }
        });
    }, [])

    function formatDateRange(startDate: Date, endDate: Date): string {
        const formatDate = (date: Date) => dayjs(date).format('M월 D일');
        const formatTime = (date: Date) => {
            const hour = dayjs(date).hour();
            const minute = dayjs(date).minute();
            const period = hour < 12 ? '오전' : '오후';
            const formattedHour = hour % 12 || 12;
            const formattedMinute = minute.toString().padStart(2, '0');
            return `${period} ${formattedHour}:${formattedMinute}`;
        };

        const startFormattedDate = formatDate(startDate);
        const endFormattedDate = formatDate(endDate);

        const startFormattedTime = formatTime(startDate);
        const endFormattedTime = formatTime(endDate);

        if (startFormattedDate === endFormattedDate) {
            return `${startFormattedTime} ~ ${endFormattedTime}`;
        } else {
            return `${startFormattedDate} ${startFormattedTime} ~ ${endFormattedDate} ${endFormattedTime}`;
        }
    }

    const createDayList = (year: number, month: number) => {
        const lastDay = new Date(year, month + 1, 0).getDate();

        const dateArr = [];
        for (let i = 0; i < lastDay; i++) {
            dateArr[i] = `${year}-${month<10?'0'+month:month}-${i+1<10?'0'+(i+1):i+1}`;
            
        }
        setDateList(dateArr);
    }

    useEffect(() => {
        AOS.init();
      },[])

    return (
        <>
            <div className="routine-wp">
                {/* 루틴 메인 주표시 */}
                {schedule.length > 0 ? 
                    schedule.map((sc: any, index: number) => (
                        <div className="grid-16" key={index} id={`week-${index}`}>
                            <ul className='ul-routine'>
                                <li>
                                    {sc !== null ? 
                                    <>
                                        <Typography variant='h5'><div data-aos="fade" data-aos-duration="1500">{dateList[index]}</div></Typography>
                                        {
                                            sc?.map((s: any, i: number) => (
                                                <div className='card' key={i} onClick={() => { navigate('/calendarday', {state: {mode: 'board', idx: s.idx}})}}
                                                    data-aos="slide-up" data-aos-duration="500">
                                                    <div className={'dilylist-item'}>
                                                        <span className={'item-lbl'}>
                                                            <div className={`chips chips-sm ${s.label}`}></div>
                                                        </span>
                                                        <p className={'item-tit'}>{s.name}</p>
                                                        <p className={'item-date'}>{formatDateRange(s.startDate, s.endDate)}</p>
                                                    </div>
                                                </div>
                                            ))
                                        }
                                    </>
                                    :
                                    <>
                                        <Typography variant='h5' data-aos="fade" data-aos-duration="1500"><div>{dateList[index]}</div></Typography>
                                            <div style={{height: 30}} data-aos="slide-up" data-aos-duration="500">
                                                <p style={{ fontSize: 15, textAlign: 'center'}}>등록된 일정이 없습니다.</p>
                                            </div>
                                    </>}
                                </li>
                            </ul>
                        </div>
                    ))
                    :
                    <div className="grid-16">
                        <ul className='ul-routine'>
                            <li>
                                <div style={{height: 30}} data-aos="slide-up" data-aos-duration="500">
                                    <p style={{ fontSize: 15, textAlign: 'center' }}>{ dayStore.month }월에 등록된 일정이 없습니다.</p>
                                </div>
                            </li>
                        </ul>
                    </div>
                }
            </div>    
        </>
    );
};

export default CalendarMonth;

