// calendarcomponent.tsx

import React, { useRef } from 'react';
import './calendarcomponent.css';
import { useDispatch, useSelector } from 'react-redux';
import { updateDay, updateYearMonth } from '../../features/day/stores/day';
import { updateDailySchedule } from '../../features/schedule/stores/schedule';
import { setSelectSchedule } from "../../features/schedule/service/scheduleService";
import dayjs from 'dayjs';
import { Holiday } from '../../features/event/stores/eventType';

interface CalendarComponentProps {
    calendar: Date;
    handleDailylistSheetVisible?: Function;
    currentHolidays?: Holiday[];
    type?: string;
}

const CalendarComponent: React.FC<CalendarComponentProps> = ({ calendar, currentHolidays, handleDailylistSheetVisible, type='month' }) => {
    const daysOfWeek = ['일', '월', '화', '수', '목', '금', '토'];

    const dispatch = useDispatch();
    const selectDayReducer = useSelector((dateReducer: any) => dateReducer.day);
    const scheduleReducer = useSelector((scheduleReducer: any) => scheduleReducer.schedule);

    const handleDayClick = (day: number, weekIndex: number,e:React.MouseEvent, clickDate:Date) => {
        // e.stopPropagation();

        if (!dayjs(calendar).isSame(dayjs(clickDate), 'month')) {
            return; // 클릭한 날짜가 현재 월과 다르면 함수 실행 안함
        }

        dispatch(updateYearMonth(selectDayReducer.year, selectDayReducer.month, day, weekIndex));
        const date = `${selectDayReducer.year}-${selectDayReducer.month < 10 ? '0' + selectDayReducer.month : selectDayReducer.month}-${day < 10 ? '0' + day : day}`;
        
        setSelectSchedule(new Date(date))
        .then((data) => {
            dispatch(updateDailySchedule({ date: date, data: data }));
        });

        if (handleDailylistSheetVisible instanceof Function) {
            handleDailylistSheetVisible();
        }
    };

    const removeDuplicateStrings = (strings: string[]) => {
        const uniqueStrings = new Set(strings);

        return Array.from(uniqueStrings);
    }
    
    const startOfMonth = dayjs(calendar).startOf('month');
    const endOfMonth = dayjs(calendar).endOf('month');
    const startDate = startOfMonth.startOf('week');
    const endDate = endOfMonth.endOf('week');

    const today = dayjs();

    const renderCells = () => {
        const rows = [];
        let days = [];
        let day = startDate;
        let formattedDate = "";
        while (day.isBefore(endDate, 'day')) {
          for (let i = 0; i < 7; i++) {

            formattedDate = day.format('D');
            const holiday = currentHolidays ? currentHolidays.find(holiday =>
              dayjs(holiday.date).isSame(day, 'day')
            ) : null;

            const isToday = day.isSame(today, 'day');
            const copyDay = day.date();
            const clickDate = day.toDate();
            const isSameMonth = day.isSame( dayjs(calendar), 'month');
            const isYear = type == 'year';
            days.push(
                <li 
                    key={day.toString()} 
                    className={`${isToday && !isYear && isSameMonth ? "today" : ""} ${selectDayReducer.day == day.date() && isSameMonth && !isYear ? "select-day" : ""} ${holiday ? 'text-red' : ''}`} 
                    onClick={(e: React.MouseEvent) => handleDayClick(copyDay,i,e,clickDate)}
                >
                    <p className={holiday ? 'text-red' : '' }>
                        {isSameMonth ? formattedDate : ""}
                    </p>
                    {
                       (<div className="day-chipbox mt-8">
                            {
                                isSameMonth && Array.isArray(scheduleReducer.schedule) &&
                                scheduleReducer.schedule.map((label: Array<string> | null, idx2: number) => (
                                    (
                                        day.date() === (idx2 + 1) && 
                                        <React.Fragment key={idx2}>
                                            {
                                            // Collect up to 4 divs with holiday included only once if applicable
                                            Array.from({ length: 4 }).map((_, id) => {
                                                if (id === 0 && holiday) {
                                                    return <div key="holiday" className="chips chips-sm red"></div>;
                                                } else if (label && removeDuplicateStrings(label)[id - (holiday ? 1 : 0)]) {
                                                    return <div key={id} className={`chips chips-sm ${removeDuplicateStrings(label)[id - (holiday ? 1 : 0)]}`}></div>;
                                                } else {
                                                    return null;
                                                }
                                            })
                                            }
                                        </React.Fragment>
                                    )
                                ))
                            }
                        </div>)
                    }
                </li>
            );
            day = day.add(1, 'day');
          }
          rows.push(
            <ul className="weekly-row mb-20" key={day.toString()}>
              {days}
            </ul>
          );
          days = [];
        }
    
        return <>{rows}</>;
      };

    return (
        <>
            <div className="item-calendar">
                <ul className="day-week mb-20">
                    {daysOfWeek.map((day) => (
                        <li key={day}><p>{day}</p></li>
                    ))}
                </ul>
                {renderCells()}
            </div>
        </>
    );
}

export default CalendarComponent;
