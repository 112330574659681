import React, { useState } from 'react';
import BgOverlay from "./BgOverlay";
import InpDatePicker from "./InpDatePicker";
import './datepicker.css';
import InpTimePicker from "./InpTimePicker";
import InpRepeatDatePicker from './InpRepeatDatePicker';

interface DateTimePickerSheetProp {
    setDayModal: (date: Date) => void,
    selectedDate?: Date | null,
    endDate?: Date
} 

const DateTimePickerSheet: React.FC<DateTimePickerSheetProp> = ({setDayModal, selectedDate, endDate }) => {
   
    return (
        <>
            <div className='card card-drawer' onClick={(event) => event.stopPropagation()}>
                <div className='sheet-header'>
                    <div className='sheet-handle'></div>
                </div>
                <div className='tabs-content'>
                    <InpRepeatDatePicker setDayModal={setDayModal} repeatedDate={selectedDate} endDate={endDate}  />
                </div>
            </div>
            <BgOverlay />
        </>
    );
};

export default DateTimePickerSheet;
