import Swal from 'sweetalert2';
import { getData, postData, patchData, deleteData } from '../../../apis/api';
import {
    InsertRoutineProps,
    RoutineCategoryType,
    InsertCategoryProps,
    RoutineDetailProps,
    UpdateRestProps,
    UpdatetRoutineProps,
} from '../types/routineInsertType';
import { RoutineRestProps } from '../types/routineRestType';
import { APIResponse } from '../../../apis/interface/response';

export const insertRoutine = async (insertData: InsertRoutineProps, navigate: Function) => {
    try {
        console.log(insertData);
        return await postData<InsertRoutineProps>('/routine/insert', insertData);
    } catch (error) {
        throw new Error('루틴 등록에 실패하였습니다.');
    }
};

export const findRoutineCategory = async () => {
    try {
        const response = await getData<RoutineCategoryType[]>(`/routine/category?page=1&size=10`);
        return response;
    } catch (error) {
        throw new Error('카테고리 조회에 실패하였습니다.');
    }
};

export const insertRoutineCategory = async (insertData: InsertCategoryProps) => {
    try {
        await postData<InsertCategoryProps>('/routine/category/insert', insertData);
    } catch (error) {
        throw new Error('카테고리 등록에 실패하였습니다.');
    }
};

export const updateRoutineCategory = async (idx: number, updateData: InsertCategoryProps) => {
    try {
        await patchData(`/routine/category/update/${idx}`, updateData);
    } catch (error) {
        throw new Error('카테고리 수정에 실패하였습니다.');
    }
};

export const deleteRoutineCategory = async (idx: number) => {
    try {
        await deleteData(`/routine/category/delete/${idx}`);
    } catch (error) {
        throw new Error('삭제에 실패하였습니다.');
    }
};

export const findRoutine = async (idx: number) => {
    try {
        const response = await getData<RoutineDetailProps>(`/routine/${idx}`);
        return response;
    } catch (error) {
        throw new Error('습관 조회에 실패하였습니다.');
    }
};

export const updateRestMode = async (updateData: UpdateRestProps) => {
    try {
        await patchData(`/routine/rest/update`, updateData);
    } catch (error) {
        throw new Error('휴식모드 전환에 실패하였습니다.');
    }
};

export const updateRoutine = async (idx: number, updateData: UpdatetRoutineProps) => {
    try {
        await patchData(`/routine/update/${idx}`, updateData);
    } catch (error) {
        throw new Error('루틴 수정에 실패하였습니다.');
    }
};

export const deleteRoutine = async (idx: number) => {
	try {
		await deleteData(`routine/delete/${idx}`);
	} catch (error) {
		throw new Error('삭제에 실패하였습니다.');
	}
};

export const findRestRoutine = async () => {
    try {
        const response = await getData<RoutineRestProps[]>(`/routine/rest`);
        return response;
    } catch (error) {
        throw new Error('휴식중인 루틴 조회에 실패하였습니다.');
    }
};

export const deleteRestMode = async (idx: number) => {
	try {
		await deleteData(`routine/rest/delete/${idx}`);
	} catch (error) {
		throw new Error('휴식모드 해제에 실패하였습니다.');
	}
};

export const countRoutineByCategory = async (categoryIdx: number) => {
    try {
        const response = await getData<number>(`/routine/count/${categoryIdx}`);
        return response.data;
    } catch (error) {
        throw new Error('휴식중인 루틴 조회에 실패하였습니다.');
    }
};

export const searchRoutine = async (values: string|undefined) => {
    try {
        const result:any = await getData<RoutineDetailProps>(`/routine/search/list?search=${values}`);
        if(result.status === 200) return result.data;
    } catch (error) {
        console.error('루틴 검색에 실패하였습니다.', error);
    }    
}