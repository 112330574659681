import Chips from "./Chips";

// string 이 아닌 문자열 리터럴 타입으로 추론시키기 위해 const
const colors = ["red", "orange", "yellow", "green", "blue", "purple", "grey"] as const;

interface PaletteProps {
    onClick: (color: string) => void; 
}

const Palette: React.FC<PaletteProps> = ({ onClick }) => {
    return (
      <>
        <div className="palette-menu">
          <ul>
            {colors.map((color) => (
                Array.from({ length: 9 }).map((_, index) => (
                    <li key={`${color}-alpha${9 - index}`}>
                        <Chips
                            color={color}
                            className={`alpha${9 - index}`}
                            onClick={(e) => {
                              onClick(color+` alpha${9 - index}`);
                              $('.active').removeClass('active');
                              e.currentTarget.classList.add('active');
                            }}
                        />
                    </li>
                ))
            ))}
          </ul>
        </div>
      </>
    );
  };

export default Palette;