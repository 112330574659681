import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import DateTimePickerSheet from '../../components/common/DateTimePickerSheet';
import RoutineWrite from '../../components/routine/RoutineWrite'
import Typography from '../../components/common/Typography';
import Button from '../../components/common/Buttons';
import RoutineResting from '../../components/routine/RoutineResting';
// import routine styles
import './board.css';
import './routine.css';
import { RoutineRestProps } from '../../features/routine/types/routineRestType';
import { useDispatch } from 'react-redux';
import { setLayout, setPage } from '../../store/layout';
import { findRestRoutine } from '../../features/routine/services/insertRoutineService';

import AOS from "aos";
import "aos/dist/aos.css";


const RoutineRest: React.FC = () => {
    const [restRoutineList, setRestRoutineList] = useState<RoutineRestProps[]>([]);

    const dispatch = useDispatch();

    const fetchRestRoutines = async () => {
        try {
            const response  = await findRestRoutine();
            const routines = response.data;
            setRestRoutineList(routines);
        } catch (error) {
            console.error('조회에 실패하였습니다', error);
        }
    };

    const handleRestRoutineRemoved = (removedIdx: number) => {
        const updatedList = restRoutineList.filter(routine => routine.idx !== removedIdx);
        setRestRoutineList(updatedList);
    };

    useEffect(() => {
        //layout
        dispatch(setLayout('rest'));
        dispatch(setPage('휴식중인 습관'));
        //setRestRoutineList 초기화

        fetchRestRoutines();

    },[dispatch])

    useEffect(() => {
        AOS.init();
      },[])
    
    return (
        <>
            <div className="routine-wp grid-20 routine-rest">
                <ul className="routine-list">
                    {
                        restRoutineList.length > 0 ?
                        restRoutineList.map((data,idx) => (
                            <RoutineResting key={idx} restRoutine={data}  onRestRoutineRemoved={() => handleRestRoutineRemoved(data.idx)} data-aos="slide-up" data-aos-duration="500"/>
                        ))
                        :
                        <li className='card list-nodata' data-aos="slide-up" data-aos-duration="500">
                            <Typography variant='body2'>
                            휴식 중인 습관이 없습니다.<br />
                            여유를 원하신다면 습관을 잠시 멈춰보세요!
                            </Typography>
                            <Link to={'/routine'} className='btn btn-sm main'>습관 목록으로</Link>
                        </li>
                    }

                    
                </ul>
            </div>
        </>
    );
};

export default RoutineRest;
