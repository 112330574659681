import React, { useRef, useState } from 'react';
import BgOverlay from "./BgOverlay";
import Chips from "./Chips";
import NoimageSymbol from "../../assets/images/noimages/img_noimage_symbol.png";

//feature
import { eventType } from "../../features/event/stores/eventType";
 import { searchUser } from '../../features/event/services/eventService';
import Button from './Buttons';
import { InviteUser } from '../../features/user/types/user';
import Typography from './Typography';

const initInsertEvent:eventType = {
    calendarId: 0,
    name: "",
    startDate: new Date(0),
    endDate: new Date(0),
    place: "",
    memo: "",
    tag: "",
    label: "red",
    repeatedType: null,
    repeatedDate: undefined,
    remindTime: undefined
}

interface UserSearchSheetProps {
    appendEventUser: (inviteUser: InviteUser) => void,
    deleteEventUser: (email: string) => void,
    eventUsers: InviteUser[],
    close?: () => void,
}

const UserSearchSheet:React.FC<UserSearchSheetProps> = ({ appendEventUser, deleteEventUser, eventUsers, close }) => {
    
    const inputEmailRef = useRef<HTMLInputElement>(null);
    const [isSelectOpen, setIsSelectOpen] = useState<boolean>(false);
    const [userOptions, setUserOption] = useState<InviteUser[]>([]);
    
    const userSearch = async () => {
        const { result } = await searchUser(inputEmailRef?.current?.value);
        setUserOption(result);
    }
 
    return (
        <>
            <style>
                {`
                .user-option {
                    display: flex;
                    align-items: center;
                    margin-bottom: 10px;
                }

                .user-option img {
                    width: 30px;
                    height: 30px; 
                    object-fit: cover; 
                    border-radius: 50%; 
                    margin-right: 10px;
                }

                .user-info {
                    display: flex;
                    flex-direction: column;
                }

                .user-info span {
                    font-size: 14px;
                }
               .button-container {
                    margin-left: auto; 
                }
                `}
            </style>
            <div
                className='card card-drawer sheet-share'
                onClick={(event) => {
                    event.preventDefault();
                    event.stopPropagation()
                }}
            >
                <div className='sheet-header'>
                    <div className='sheet-handle' ></div>
                </div>
                {/* 유저 검색창 */}
                <div className='user-search'>
                    <div>
                        <input
                        className="search-input"
                        type="text"
                        placeholder="공유할 회원의 닉네임 또는 이메일을 입력하세요"
                        ref={inputEmailRef}
                        onKeyUp={(event) => {
                            if (event.key === "Enter") {
                                setIsSelectOpen(prev => !prev);
                                userSearch();
                            }
                        }}
                        />
                        <button className='btn-sm btn-text btn-usersheetbtn'
                            onClick={
                                () => {
                                    setIsSelectOpen(prev => !prev);
                                    userSearch();
                                }
                            }
                        ></button>
                    </div>
                    {/* 유저 검색 목록 */}
                    {/* <div className={`select-box-container ${isSelectOpen ? 'open' : ''}`}>
                        <select className="select-box" multiple>
                            {userOptions.map(option => (
                                <option key={option.email} value={option.email} onClick={
                                    () => {
                                        appendEventUser(option);
                                        setIsSelectOpen(prev => !prev);
                                        if(inputEmailRef.current) inputEmailRef.current.value = "";
                                    }}>
                                    {option.email}({option.nickName})
                                </option>
                            ))}
                        </select>
                    </div> */}
                    <div className={`select-box-container ${isSelectOpen ? 'open' : ''}`}>
                        <div className="select-box">
                            {userOptions.length === 0 ? (
                                <Typography variant='body2'>해당하는 유저가 없습니다.</Typography>
                            ) : (
                                userOptions.map(option => (
                                    <div
                                        key={option.email}
                                        className={`user-option ${eventUsers.find(user => user.email === option.email) && 'selected'}`}
                                        onClick={() => {
                                            appendEventUser(option);
                                            setIsSelectOpen(false);
                                            if (inputEmailRef.current) inputEmailRef.current.value = "";
                                        }}
                                    >
                                        <img src={(option.profile || NoimageSymbol)} />
                                        <div className="user-info">
                                            <span>{option.email}({option.nickName})</span>
                                        </div>
                                    </div>
                                ))
                            )}
                        </div>
                    </div>

                </div>
                {/* 추가한 유저 목록 */}
                {/* <div className='tag-list'>
                    <div className='tag-listwp'>
                        {eventUsers.map((user: InviteUser, idx:number) => (
                            <Chips color='grey' key={idx}>{user.email}({user.nickName})<span className='btn-chipsclose' onClick={() => deleteEventUser(user.email)}></span></Chips>
                        ))}
                    </div>
                </div> */}
                <div className='tag-list'>
                    <div className='tag-listwp'>
                    {eventUsers.map((user: InviteUser, idx:number) => (
                        <div key={idx} className="user-option">
                            <img src={(user.profile || NoimageSymbol)} />
                            <div className="user-info">
                                <span>{user.email}({user.nickName})</span>
                            </div>
                            <div className="button-container">
                                {user.accept === "ACCEPT" ? (
                                    <Button variant={'text'} size={'sm'} onClick={() => deleteEventUser(user.email)}>삭제</Button>
                                ) : (
                                    <Button variant={'text'} size={'sm'} onClick={() => deleteEventUser(user.email)}>취소</Button>
                                )}
                            </div>
                        </div>
                    ))}
                    </div>
                </div>
            </div>
            <BgOverlay close={close}/>
        </>
    )
}

export default UserSearchSheet;