import './aicomponents.css';

type ChatDateProps = {
    date:string;
}

const ChatDate:React.FC<ChatDateProps> = ({
    date
}) => {
    return (
        <>
            <div className='date-line'>
                    <hr className='hr-solid' />
                    <span className='hr-date'>{date}</span>
                </div>
        </>
    )
}

export default ChatDate;