import { useState, useEffect } from 'react';
import {
    InsertRoutineProps,
    RoutineCategoryType,
    InsertCategoryProps,
    UpdatetRoutineProps,
    UpdateCategoryProps,
} from '../types/routineInsertType';
import {
    findRoutineCategory,
    insertRoutineCategory,
    updateRoutineCategory,
    deleteRoutineCategory,
    countRoutineByCategory,
} from '../services/insertRoutineService';
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';

const useRoutineHook = (editMode?: boolean) => {
    const navigate = useNavigate();

    const [isEmojiSheetActive, setIsEmojiSheetActive] = useState(false);
    const [isSwitchOn, setIsSwitchOn] = useState(false);
    const [selectedEmoji, setSelectedEmoji] = useState<string>('😀');
    const [isTimePickerOpen, setIsTimePickerOpen] = useState(false);
    const [categories, setCategories] = useState<RoutineCategoryType[]>([]);
    const [startDate, setStartDate] = useState<Date | null>(null);
    const [endDate, setEndDate] = useState<Date | null>(null);
    const [isDatePickerSheetOpen, setIsDatePickerSheetOpen] = useState(false);
    const [dateType, setDateType] = useState<'start' | 'end' | null>(null);
    const [categoryValues, setCategoryValues] = useState<InsertCategoryProps>({ name: '', color: 'red' });
    const [cateUpdateValues, setCateUpdateValues] = useState<UpdateCategoryProps>({ idx: 0, name: '', color: '' });
    const [showRestDateSheet, setShowRestDateSheet] = useState(false);
    const [selectedCategory, setSelectedCategory] = useState<RoutineCategoryType>();
    const [selectedTime, setSelectedTime] = useState<Date | null>(null);

    const [values, setValues] = useState<InsertRoutineProps>({
        name: '',
        emoji: '',
        startDate: null,
        endDate: null,
        categoryId: null,
        type: 'REPETITION',
        repeatedNumber: 1,
        isRemind: false,
        remindDate: [],
        repeated: {
            monday: false,
            tuesday: false,
            wednesday: false,
            thursday: false,
            friday: false,
            saturday: false,
            sunday: false,
        },
    });

    const [updateValues, setUpdateValues] = useState<UpdatetRoutineProps>({
        name: '',
        emoji: '',
        startDate: null,
        endDate: null,
        categoryId: 0,
        repeatedNumber: 1,
        isRemind: false,
        remindDate: [],
        repeated: {
            monday: false,
            tuesday: false,
            wednesday: false,
            thursday: false,
            friday: false,
            saturday: false,
            sunday: false,
        },
    });

    const [repeated, setRepeated] = useState({
        monday: false,
        tuesday: false,
        wednesday: false,
        thursday: false,
        friday: false,
        saturday: false,
        sunday: false,
    });

    const handleDatePickerOpen = (type: 'start' | 'end') => {
        setDateType(type);
        setIsDatePickerSheetOpen(true);
    };

    const handleDateSelect = (date: Date | null) => {
        const updateState = editMode ? setUpdateValues : setValues;
        
        if (dateType === 'start') {
            const utcDate = date !== null ? new Date(date.getTime() - (date.getTimezoneOffset() * 60000)) : null;
            setStartDate(date);

            updateState((prevState: any) => ({
                ...prevState,
                startDate: utcDate,
                endDate: null,
            }));
            setEndDate(null);
        } else if (dateType === 'end') {
            const utcDate = date !== null ? new Date(date.getTime() - (date.getTimezoneOffset() * 60000)) : null; 
            setEndDate(date);

            updateState((prevState: any) => ({
                ...prevState,
                endDate: utcDate,
            }));
        }
        setIsDatePickerSheetOpen(false);
    };

    const fetchCategories = async () => {
        try {
            const response = await findRoutineCategory();
            setCategories(response.data);
        } catch (error) {
            console.error('카테고리 조회에 실패하였습니다:', error);
        }
    };

    const handleName = (event: { target: { value: any } }) => {
        const newName = event.target.value;
        const updateState = editMode ? setUpdateValues : setValues;

        updateState((prevState: any) => ({
            ...prevState,
            name: newName,
        }));
    };

    const handleCategoryName = (event: React.ChangeEvent<HTMLInputElement>) => {
        setCategoryValues((prevState) => {
            return { ...prevState, name: event.target.value };
        });
    };

    const handleCateNameUpdate = (event: React.ChangeEvent<HTMLInputElement>) => {
        setCateUpdateValues((prevState) => {
            return { ...prevState, name: event.target.value };
        });
    };

    const handleCateColorUpdate = (color: string) => {
        setCateUpdateValues((prevState) => ({
            ...prevState,
            color: color,
        }));
    };

    const handleCategoryColor = (color: string) => {
        setCategoryValues((prevState) => ({
            ...prevState,
            color: color,
        }));
    };

    const handleEmojiSave = (emoji: string) => {
        const updateState = editMode ? setUpdateValues : setValues;

        updateState((prevState: any) => ({
            ...prevState,
            emoji: emoji,
        }));

        setIsEmojiSheetActive(false);
        setSelectedEmoji(emoji);
    };

    const toggleEmojiSheet = () => {
        setIsEmojiSheetActive((prevState) => !prevState);
    };

    const handleSwitchToggle = () => {
        setIsSwitchOn((prevIsSwitchOn) => {
            const newIsSwitchOn = !prevIsSwitchOn;

            const updateState = editMode ? setUpdateValues : setValues;

            updateState((prevState: any) => ({
                ...prevState,
                isRemind: newIsSwitchOn,
            }));

            return newIsSwitchOn;
        });
    };

    const handleDaySelection = (day: keyof typeof repeated) => {
        const updatedRepeated = {
            ...repeated,
            [day]: !repeated[day],
        };

        setRepeated(updatedRepeated);

        const updateState = editMode ? setUpdateValues : setValues;

        updateState((prevState: any) => ({
            ...prevState,
            repeated: updatedRepeated,
        }));
    };

    const getButtonStyle = (day: keyof typeof repeated) => {
        return repeated[day] ? 'contained' : 'outline';
    };

    useEffect(() => {
        const today = new Date().getDay();

        const initialRepeated = {
            monday: today === 1,
            tuesday: today === 2,
            wednesday: today === 3,
            thursday: today === 4,
            friday: today === 5,
            saturday: today === 6,
            sunday: today === 0,
        };

        setRepeated(initialRepeated);

        const updateState = editMode ? setUpdateValues : setValues;

        updateState((prevState: any) => ({
            ...prevState,
            repeated: initialRepeated,
        }));
    }, []);

    const handleRepeatedNumber = (e: React.ChangeEvent<HTMLInputElement>) => {
        let inputValue = parseInt(e.target.value, 10);
        if (inputValue < 1 || inputValue > 999) {
            Swal.fire({
                text: '1~999 사이의 숫자를 입력해주세요',
                confirmButtonText: '돌아가기',
            });
            inputValue = values.repeatedNumber;
        }
    
        const updateState = editMode ? setUpdateValues : setValues;
    
        updateState((prevState: any) => ({
            ...prevState,
            repeatedNumber: inputValue,
        }));
    };

    const handleCategorySelect = async () => {
        if (!selectedCategory) return;

        const selectedIdx = selectedCategory?.idx;
        const updateState = editMode ? setUpdateValues : setValues;

        updateState((prevState: any) => ({
            ...prevState,
            categoryId: selectedIdx,
        }));
    };

    const handleInsertCategory = async () => {
        try {
            await insertRoutineCategory(categoryValues);
        } catch (error) {
            console.error('카테고리 등록에 실패하였습니다', error);
        }
    };

    const handleUpdateCategory = async (idx: number, cateUpdateValues: InsertCategoryProps) => {
        try {
            await updateRoutineCategory(idx, cateUpdateValues);
        } catch (error) {
            console.error('카테고리 수정에 실패하였습니다:', error);
        }
    };

    const handleRestModeClick = () => {
        setShowRestDateSheet(true);
    };

    const handleSetTimeModal = (selectedHour: any, selectedMinute: any, selectedPeriod: any) => {
        let hour = parseInt(selectedHour, 10);
        
        if (selectedPeriod === 'PM') {
            hour += 12;
        }
    
        const selectedDateTime = new Date();
        selectedDateTime.setHours(hour);
        selectedDateTime.setMinutes(selectedMinute);
        selectedDateTime.setSeconds(0);
    
        setSelectedTime(selectedDateTime);            
        setIsTimePickerOpen(false);
    };
    
    return {
        values,
        setValues,
        handleName,
        isEmojiSheetActive,
        setIsEmojiSheetActive,
        selectedEmoji,
        handleEmojiSave,
        toggleEmojiSheet,
        isSwitchOn,
        handleSwitchToggle,
        handleDaySelection,
        getButtonStyle,
        repeated,
        fetchCategories,
        startDate,
        endDate,
        isDatePickerSheetOpen,
        handleDatePickerOpen,
        handleDateSelect,
        setIsDatePickerSheetOpen,
        dateType,
        handleSetTimeModal,
        isTimePickerOpen,
        setIsTimePickerOpen,
        handleRepeatedNumber,
        setRepeated,
        setStartDate,
        setEndDate,
        setIsSwitchOn,
        setUpdateValues,
        updateValues,
        selectedCategory,
        setSelectedCategory,
        handleCategorySelect,
        categories,
        handleInsertCategory,
        handleCategoryName,
        handleCategoryColor,
        categoryValues,
        setCategoryValues,
        handleCateNameUpdate,
        handleCateColorUpdate,
        cateUpdateValues,
        setCateUpdateValues,
        handleUpdateCategory,
        selectedTime,
        setSelectedTime
    };
};

export default useRoutineHook;