//  import imgages
import NoimageSymbol  from "../../assets/images/noimages/img_noimage_symbol.png";

interface AvatarProps {
    src?: string;
    alt?: string | "noimage";
    size?: "sm" | "lg";
    className?: string;
    style?: React.CSSProperties;
    onClick?: () => void;
}


const Avatar: React.FC<AvatarProps> = ({
    src='',
    alt='noimage',
    size='',
    className='',
    onClick
}) => {
    const prefix = 'avatar-';
    const classes = [
        'avatar',
        size && `${prefix}${size}`,
        className
    ].filter(Boolean).join(' ');

    return (
        <div className={classes} onClick={onClick}>
            <img src={src != '' ? src : NoimageSymbol} alt={alt} />
        </div>
    )
}

export default Avatar;