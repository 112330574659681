
import { Link } from 'react-router-dom';
import Typography from '../../components/common/Typography';
import RoutineWeek from '../../components/routine/RoutineWeek';
import RoutineCheck from '../../components/routine/RoutineCheck';
import 'swiper/css';
import './routine.css';
import { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../store';
import { useDispatch } from 'react-redux';
import { setCalendarSelect, setSelectedDay } from '../../features/routine/stores/routine';
import { getWeekDates } from '../../features/routine/services/dailyRoutineService';
import { useDailyRoutine } from '../../features/routine/hooks/useDailyRoutine.hook';
import HarudalSlider, { HarudalSliderMethods } from '../../components/common/Slider';
import { setLayout } from '../../store/layout';

import AOS from "aos";
import "aos/dist/aos.css";

const Routine = () => {
    const selectedDay = useSelector((state: RootState) => state.routine.selectedDay);
    const calendarSelect = useSelector((state: RootState) => state.routine.calendarSelect);
    const dateManuallySelected = useSelector((state: RootState) => state.routine.dateManuallySelected);

    const baseWeekDay = selectedDay == '' ? new Date() : new Date(selectedDay);

    const { weeks, setWeeks, selectRoutinList, setSelectRoutineList, setSelectedDayPorps, showWeekDayList, setShowWeekDayList, toggleRoutineCompletion, selectChellangeList, setSelectChellangeList, toggleChallengeCompletion } = useDailyRoutine();

    const [currentWeekCount, setCurrentWeekCount] = useState(0);
    const [loading, setLoading] = useState(false);

    const sliderRef = useRef<HarudalSliderMethods>(null);
    const dispatch = useDispatch();

    useEffect(() => {
        // layout 세팅
        dispatch(setLayout('main'));

        const initializeWeeks = async () => {
            const initialWeeks = await getWeekDates(baseWeekDay);
            setWeeks(initialWeeks);
        };
        initializeWeeks();
    }, [])

    useEffect(() => {
        if (weeks.length == 1) {
            // 지정된 날짜가 없으면 오늘 날짜로 selected
            if (selectedDay == '') {
                dispatch(setSelectedDay(new Date().toISOString()));
            }

            // 최초 보이는 주별 일정 세팅
            setShowWeekDayList(weeks[0]);

            addPreviousWeek();
            addNextWeek();
        }
    }, [weeks])

    useEffect(() => {
        // 초기화
        if (calendarSelect) {
            const updateWeeks = async () => {
                const updatedWeeks = await getWeekDates(new Date(selectedDay));
                setShowWeekDayList([]);
                setSelectRoutineList([]);
                setSelectedDayPorps(undefined);
                setWeeks(updatedWeeks);
                sliderRef.current?.setCurrentIndex(1);
                dispatch(setCalendarSelect(false));
            };
            updateWeeks();
        }
    }, [calendarSelect])

    useEffect(() => {
        // 현재 사용 되어지는 배열 index
        const index = sliderRef.current?.getCurrentIndex() ?? 0;

        const nowWeeks = weeks[index];

        if (nowWeeks) {
            const selectDayData = nowWeeks.filter(day => day.fullDate.toISOString().slice(0, 10) === selectedDay.slice(0, 10));

            // selectedDay 변할 때 해당 루틴 리스트 변경하기
            if (selectDayData.length > 0) {
                const selectedDayProps = selectDayData[0];
                setSelectedDayPorps(selectedDayProps);

                const filteredRoutines = selectedDayProps.routines.filter(routine => {
                    const currentDate = new Date(selectedDay);
                    const startDate = routine.startDate ? new Date(routine.startDate) : null;
                    const endDate = routine.endDate ? new Date(routine.endDate) : null;
                
                    const formattedCurrentDate  = new Date(currentDate.setHours(0, 0, 0, 0));

                    if (startDate && endDate) {
                        return !(formattedCurrentDate  >= startDate && formattedCurrentDate <= endDate);
                    }
    
                    return true;
                });
    
                setSelectRoutineList(filteredRoutines);
    
                const filteredChallenges = selectedDayProps.challenges.filter(
                    challenge => !(challenge.repeatedNumber === challenge.completedNumber && !challenge.isCompleted)
                );

                setSelectChellangeList(filteredChallenges);
            }
        }
    }, [selectedDay, weeks])

    /**
     * 렌더링 할 때마다 새로 생성해야함(너무 계속 렌더링하는 느낌이라면 슬라이드를 넉넉히 만드는 방법 사용)
     * 셀렉트 날짜에 따라 생성해줌
     * 대신 
     */

    const addNextWeek = async () => {
        setLoading(true);
        const lastWeek = weeks[weeks.length - 1];
        if (lastWeek) {
            const lastDay = lastWeek[6].fullDate; // 마지막 날짜를 기준으로 새로운 주 생성
            const nextWeekStart = new Date(lastDay);
            nextWeekStart.setDate(lastDay.getDate() + 1); // 다음 주의 시작일

            const newWeek = await getWeekDates(nextWeekStart);
            setWeeks((weeks) => [...weeks, ...newWeek]);
        }
        setLoading(false);
    };

    const addPreviousWeek = async () => {
        setLoading(true);
        const firstWeek = weeks[0];
        const firstDay = firstWeek[0].fullDate; // 첫 날짜를 기준으로 새로운 주 생성
        const previousWeekStart = new Date(firstDay);
        previousWeekStart.setDate(firstDay.getDate() - 7); // 이전 주의 시작일


        const newWeek = await getWeekDates(previousWeekStart);
        setWeeks((weeks) => [...newWeek, ...weeks]);

        // 최초에 시작할 때는 동작 x
        if (weeks.length >= 3) {
            setCurrentWeekCount(newWeek.length);
        }
        setLoading(false);
    };

    // weeks 상태 변경에 따라 currentIndex 업데이트
    useEffect(() => {
        if (currentWeekCount > 0) {
            const currentIndex = sliderRef.current?.getCurrentIndex() ?? 0;
            sliderRef.current?.setCurrentIndex(currentIndex + currentWeekCount);
            setCurrentWeekCount(0); // 상태 초기화
        }
    }, [weeks, currentWeekCount]); // weeks가 변경될 때마다 실행

    const handleSlideChange = (index: number) => {
        if (loading) return; 

        // 현재 사용 되어지는 배열 index
        const nowWeeks = weeks[index];

        // if (!nowWeeks) {
        //     console.warn(`No week data available for index ${index}`);
        //     // Optionally, you can reset the slider to the nearest valid index to prevent displaying an empty slide
        //     const validIndex = Math.min(index, weeks.length - 1);
        //     sliderRef.current?.setCurrentIndex(validIndex);
        //     return;
        // }
        
        // 이동 시 일요일을 selected 시키기
        if (weeks.length >= 3) { // 최초 슬라이드 생성에서는 selected 동작 x
            dispatch(setSelectedDay(nowWeeks[0].fullDate.toISOString()))
            setShowWeekDayList(nowWeeks);
        }
    }

    const makeClassNameByWeekDay = (date: Date) => {
        const classes = [
            new Date().toISOString().slice(0, 10) === date.toISOString().slice(0, 10)
            && 'today',
            selectedDay.slice(0, 10) === date.toISOString().slice(0, 10)
            && 'active'
        ].filter(Boolean).join(' ');

        return classes;
    }

    useEffect(() => {
        AOS.init();
      },[])

    return (
        <>
            <div className="routine-wp">
                {/* 루틴 메인 주표시 */}
                <div className="week-box grid-20" data-aos="fade" data-aos-duration="2000">
                    <ul className="week-text mb-8">
                        {
                            showWeekDayList.map((day, dayIndex) => (
                                <li key={dayIndex} className={makeClassNameByWeekDay(day.fullDate)}>{day.dayOfWeek}</li>
                            ))
                        }
                    </ul>
                    {/* 
                        일주일단위로 슬라이드가 넘어감
                    */}
                    {
                        // 잠시 주석
                        // loading ?
                        // <div className="loading-overlay"></div>
                        // :
                        // ''
                    }
                    <HarudalSlider
                        ref={sliderRef}
                        initialSlide={1}
                        onSlideChange={(index) => handleSlideChange(index)}
                        onReachEnd={addNextWeek}
                        onReachBeginning={addPreviousWeek}
                    >
                        {/* slide start */}
                        {weeks.map((week, index) => (
                            <div key={index} style={{ minWidth: '100%' }}>
                                <RoutineWeek week={week} />
                            </div>
                        ))}
                    </HarudalSlider>
                    
                </div>

                {
                    selectRoutinList.length > 0 || selectChellangeList.length > 0 ?
                        <>
                            {
                                selectChellangeList.length > 0 && (
                                    <div className="routine-main grid-20" data-aos-delay="500" data-aos="fade-up" data-aos-duration="500">
                                        <Typography className='mb-8' variant='h5'>챌린지</Typography>
                                        <ul className="routine-list">
                                            {
                                                selectChellangeList.map((routine, routineIdx) => {

                                                    return (
                                                        <RoutineCheck key={routineIdx} routine={routine} toggleRoutineCompletion={toggleChallengeCompletion} />
                                                    );
                                                })
                                            }
                                        </ul>
                                    </div>
                                )
                            }
                            {
                                selectRoutinList.length > 0 &&
                                (<div className="routine-main grid-20" data-aos-delay="500" data-aos="fade-up" data-aos-duration="500">
                                    <Typography className='mb-8' variant='h5'>습관</Typography>
                                    <ul className="routine-list">
                                        {
                                            selectRoutinList.map((routine, routineIdx) => {

                                                return (
                                                    <RoutineCheck key={routineIdx} routine={routine} toggleRoutineCompletion={toggleRoutineCompletion} />
                                                );
                                            })
                                        }
                                    </ul>
                                </div>)
                            }
                        </>
                        :
                        <div className="routine-main grid-20" data-aos-delay="1000" data-aos="fade-up" data-aos-duration="1000">
                            <ul className="routine-list">
                                <li className='card list-nodata'>
                                    <Typography variant='body2'>
                                        등록된 습관이 없습니다.<br />
                                        작성이 어려우시다면 하루달에게 물어보세요!
                                    </Typography>
                                    <Link to={'/ai'} className='btn btn-sm main'>하루달에게 추천받기</Link>
                                </li>
                            </ul>
                        </div>

                }

                {/* 휴식모드 모달 */}
                {/* <RestDateSheet/> */}
            </div>
            <Link to={'/routineadd?mode=write'} className="btn-floating"><span className='floating-symbol'></span></Link>
        </>
    )
}

export default Routine;
