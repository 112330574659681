// import calendarcomponent styles
import React, { useEffect, useState } from 'react';
import './calendarcomponent.css';
import { Link, useNavigate } from 'react-router-dom';
import { useSelector, useDispatch  } from 'react-redux';
import { solarToLunar } from '../../utils/calendar/CalendarUtils'
import Button from '../common/Buttons';
import dayjs from 'dayjs';
import { Holiday } from '../../features/event/stores/eventType';
import { setSelectSchedule } from '../../features/schedule/service/scheduleService';
import { updateDailySchedule } from '../../features/schedule/stores/schedule';

interface EventItemProps {
    dateString: string,
    currentHolidays: Holiday[]
}

const EventItem: React.FC<EventItemProps> = ({ dateString, currentHolidays }) => {
    const navigate = useNavigate();
    const daysOfWeek = ['일', '월', '화', '수', '목', '금', '토'];
    const selectDayReducer = useSelector((dateReducer: any) => dateReducer.day);
    const scheduleReducer = useSelector((scheduleReducer: any) => scheduleReducer.schedule);
    const [yin, setYin] = useState<any>(solarToLunar(selectDayReducer.year, selectDayReducer.month, selectDayReducer.day));
    const weekIndex = new Date(dateString).getDay();
    const [isHoliday, setIsHoliday] = useState(false);
    const dispatch = useDispatch();
    
    useEffect(() => {
        setYin(solarToLunar(selectDayReducer.year, selectDayReducer.month, selectDayReducer.day));
        
    }, [selectDayReducer.year, selectDayReducer.month, selectDayReducer.day]);

    useEffect(() => {
        const isHoliday = currentHolidays.some(holiday => holiday.date === dateString);
        setIsHoliday(isHoliday);
        const fetchData = async () => {
            const data = await setSelectSchedule(new Date(dateString));
            dispatch(updateDailySchedule({ date: dateString, data: data }));
        };
        fetchData();
    }, [dateString, currentHolidays]);

    function formatDateRange(startDate: Date, endDate: Date): string {
        const formatDate = (date: Date) => dayjs(date).format('M월 D일');
        const formatTime = (date: Date) => {
            const hour = dayjs(date).hour();
            const minute = dayjs(date).minute();
            const period = hour < 12 ? '오전' : '오후';
            const formattedHour = hour % 12 || 12; // 0시를 12시로 표시
            const formattedMinute = minute.toString().padStart(2, '0');
            return `${period} ${formattedHour}:${formattedMinute}`;
        };

        const startFormattedDate = formatDate(startDate);
        const endFormattedDate = formatDate(endDate);

        const startFormattedTime = formatTime(startDate);
        const endFormattedTime = formatTime(endDate);

        if (startFormattedDate === endFormattedDate) {
            return `${startFormattedTime} ~ ${endFormattedTime}`;
        } else {
            return `${startFormattedDate} ${startFormattedTime} ~ ${endFormattedDate} ${endFormattedTime}`;
        }
    }
    
    return (
        <>
            {/* 날짜: 데일리시트=진입화면에서 보이는 날짜 */}
            <p className="dailylist-tit mb-16 subtit-02">
                {dateString.split("-")[2].startsWith("0")  ? dateString.split("-")[2].slice(1) :dateString.split("-")[2] }일 {daysOfWeek[weekIndex]}
                <span className="caption">
                    음력{yin.lunaMonth}.{yin.lunaDay}
                </span>
            </p>
            {/* 날짜: 이벤트리스트=일정리스트에서 보이는 날짜 */}
            <p className="eventitem-tit mb-16 subtit-02">2월 8일 수요일<span className='caption'>음력{yin.month}.{ yin.day }</span></p>
            <div className='card'>
                {
                    isHoliday && currentHolidays.map((holiday, idx) => 
                        (
                            holiday.date == dateString &&
                            <div className={'dilylist-item'} key={idx}>
                                <span className={'item-lbl'}>
                                    <div className={`chips chips-sm red`}></div>
                                </span>
                                <p className={'item-tit'}>{holiday.name}</p>
                            </div>
                        )
                    )
                }

                {scheduleReducer.dailySchedule[dateString] && scheduleReducer.dailySchedule[dateString]['0'].length > 0?
                    scheduleReducer.dailySchedule[dateString]['0'].map((sc: any, idx: number) =>
                        <div className={'dilylist-item'} key={idx} onClick={() => {
                            navigate('/event',
                                {
                                    state: {
                                        mode: 'board',
                                        idx: sc.idx
                                    }
                                }
                            ); }}
                        >
                            <span className={'item-lbl'}>
                                <div className={`chips chips-sm ${sc.label}`}></div>
                            </span>
                            <p className={'item-tit'}>{sc.name}</p>
                            <p className={'item-date'}>{formatDateRange(sc.startDate,sc.endDate)}</p>
                            {/* <p className='item-date'><span>12월 30일 오전 12:12</span>~<span>27년 12월30일 오후 12:12</span></p> */}

                        </div>
                    )
                    :
                    !isHoliday &&
                    <ul>
                        <li className='list-nodata'>
                            등록된 일정이 없습니다.<br />
                            하루달에게 일정을 추천 받아보세요!
                            <Button onClick={() => { navigate('/ai')}} className='btn btn-sm main'>하루달에게 추천받기</Button>
                        </li>
                    </ul>
                }
            </div>
        </>
    )
}

export default EventItem;