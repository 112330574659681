import { ReactElement } from "react";
import DefaultLayout from "../layout/Layout";
import NoneLayout from "../layout/NoneLayout";
import RoutineLayout from "../layout/RoutineRayout";
import CalendarLayout from "../layout/CalendarLayout";

interface LayoutComponent {
    [key: string]: (element: JSX.Element) => ReactElement;
}

const layouts: LayoutComponent  = {
    DefaultLayout:(element: JSX.Element) => <DefaultLayout>{ element }</DefaultLayout>,
    RoutineLayout: (element: JSX.Element) => <RoutineLayout>{element}</RoutineLayout>,
    CalendarLayout: (element: JSX.Element) => <CalendarLayout>{element}</CalendarLayout>,
    NoneLayout: (element: JSX.Element) => <NoneLayout>{ element }</NoneLayout>
};

export default layouts;