import React from 'react';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import dayjs from 'dayjs';
import '../event/datepicker.css';

interface InpDatePickerProps {
    setDayModal: (isOpen: boolean) => void;
    startDate?: Date | null; 
    currentDate?: Date | null;
    onDateSelect: (date: Date | null) => void;
}


const InpDatePicker: React.FC<InpDatePickerProps> = ({ setDayModal, startDate, currentDate, onDateSelect }) => {
    const handleDayClick = (value: Date) => {
        const isSameDate = currentDate && currentDate.toDateString() === value.toDateString();

        if (isSameDate) {
            onDateSelect(null); 
        } else {
            onDateSelect(value);
        }
        setDayModal(false);
    };

    return (
        <Calendar
            className='drawer-calendar'
            formatDay={(locale, date) => dayjs(date).format('DD')}
            calendarType="US"
            prevLabel=""
            nextLabel=""
            minDate={startDate !== null ? startDate : undefined} // startDate가 null이면 undefined를 할당
            onClickDay={handleDayClick}
            value={currentDate}
        />

    );
};

export default InpDatePicker;
