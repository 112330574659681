import dayjs from "dayjs";
import { getData } from "../../../apis/api";
import { DailyRoutineProps, WeekDatas, WeeklyRoutineProps, WeeklyRoutineStatProps } from "../types/routineWeekType";


const dummyroutine:WeeklyRoutineProps[] = [
    {
        id:1,
        name:'운동하기',
        rate:50,
        color:'red',
        emoji:'🏋️‍♂️',
        dailyList: [
            {
                dayOfWeek:'월',
                isCompleted:true
            },
            {
                dayOfWeek:'수',
                isCompleted:false
            }
        ]
    },
    {
        id:2,
        name:'독서하기',
        rate:33,
        color:'blue',
        emoji:'📚',
        dailyList: [
            {
                dayOfWeek:'월',
                isCompleted:true
            },
            {
                dayOfWeek:'수',
                isCompleted:true
            },
            {
                dayOfWeek:'금',
                isCompleted:false
            }
        ]
    }
]

// 날짜 포맷 출력
export const formatDateRange = (startDate: Date, endDate: Date): string => {
    const currentYear = new Date().getFullYear();
    const isSameYear = startDate.getFullYear() === endDate.getFullYear();
    const startYear = startDate.getFullYear();
    const format = (date: Date) => `${date.getMonth() + 1}월 ${date.getDate()}일`;

    let startDateFormat = format(startDate); 
    let endDateFormat = format(endDate);

    let startYearFormat = (!isSameYear || startYear !== currentYear) ? `${startYear}년 ` : '';

    let endDatePart = startDate.getMonth() !== endDate.getMonth() || !isSameYear ? endDateFormat : `${endDate.getDate()}일`;
    let endYearFormat = (startDate.getMonth() !== endDate.getMonth() || !isSameYear) && !isSameYear ? `${endDate.getFullYear()}년` : '';

    return `${startYearFormat}${startDateFormat}~${endYearFormat}${endDatePart}`;
};

// 주별 루틴 리스트 생성
export const createWeekDates = async (inputDate: Date, status?: Boolean): Promise<WeekDatas> => {

    const THURSDAY_NUM = 4; // 첫째주의 기준은 목요일(4)

    let date = new Date(inputDate.getTime());
    let firstDate = new Date(date.getFullYear(), date.getMonth(), 1);
    let firstDayOfWeek = firstDate.getDay() === 0 ? 7 : firstDate.getDay(); // 일요일을 7로 처리

    let firstThursday = 1 + THURSDAY_NUM - firstDayOfWeek;
    if (firstThursday <= 0) firstThursday += 7;

    let startOfWeek = new Date(date.getFullYear(), date.getMonth(), date.getDate() - date.getDay());
    let endOfWeek = new Date(startOfWeek.getTime());
    endOfWeek.setDate(endOfWeek.getDate() + 6);

    // 날짜 데이터에 해당하는 주별 루틴 리스트 가져오기!!
    const weeklyRoutine = await getWeeklyRoutine(startOfWeek,endOfWeek, status??false);

    return {
        startOfWeek: startOfWeek,
        endOfWeek: endOfWeek,
        weeklyRoutine
    };
};

export const getWeeklyRoutine = async (startOfWeek:Date, endOfWeek:Date, status: Boolean): Promise<WeeklyRoutineProps[]> => {

    const response = await getData<WeeklyRoutineProps[]>(`/routine/weekly?start-date=${dayjs(startOfWeek).format('YYYY-MM-DD')}&end-date=${dayjs(endOfWeek).format('YYYY-MM-DD')}&status=${status}`);

    let weeklyRoutine = response.data;

    if(response.status != 200){
        weeklyRoutine = [];
    }

    return convertToWeeklyRoutineProps(weeklyRoutine,startOfWeek,endOfWeek);
}

// API 데이터를 WeeklyRoutineProps 타입으로 변환
function convertToWeeklyRoutineProps(data: any[], startOfWeek: Date, endOfWeek: Date): WeeklyRoutineProps[] {
    return data.map((item, index) => {
        const daysOfWeek = ["sunday", "monday", "tuesday", "wednesday", "thursday", "friday", "saturday"];
        
        const dailyList: DailyRoutineProps[] = daysOfWeek.reduce((list, day) => {
            const currentDayIndex = daysOfWeek.indexOf(day);
            const currentDay = new Date(startOfWeek);
            currentDay.setDate(currentDay.getDate() + currentDayIndex);

            const routineStart = new Date(item.startDate);
            const routineEnd = item.endDate ? new Date(item.endDate) : new Date(endOfWeek);

            if (item.repeatedDayWeek[day]&& currentDay.getTime() >= routineStart.getTime() && currentDay.getTime() <= routineEnd.getTime()) {
                list.push({
                    dayOfWeek: day,
                    isCompleted: item.routineCompleted[day]
                });
            }
            return list;
        }, [] as DailyRoutineProps[]);

        return {
            id: item.id,
            emoji: item.emoji,
            rate: item.achievementRate,
            name: item.name,
            color: item.color as any,
            dailyList: dailyList,
            restStartDate: new Date(item.restStartDate),
            restEndDate: new Date(item.restEndDate),
            dailyAchievementRates: item.dailyAchievementRates,
            totalAchievementRate: item.totalAchievementRate 
        };
    });
}

export const getWeeklyRoutineStat = async (): Promise<WeeklyRoutineStatProps | null> => {
        const startOfWeek = dayjs().startOf('week').toDate();
        const endOfWeek = dayjs().endOf('week').toDate();
        const status = 1;

        const response = await getData<WeeklyRoutineStatProps>(`/routine/weekly?start-date=${dayjs(startOfWeek).format('YYYY-MM-DD')}&end-date=${dayjs(endOfWeek).format('YYYY-MM-DD')}&status=${status}`);
        
        if (response && response.data) {
            return response.data; 
        } else {
            return null; 
        }
}
