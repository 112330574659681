import { ReactElement } from "react";
import App from '../App';
import Login from '../pages/auth/login';
import NaverLogin from '../pages/auth/callback/naverLogin';
import GoogleLogin from '../pages/auth/callback/googleLogin';
import KakaoLogin from '../pages/auth/callback/kakaoLogin';
import Calendar from '../pages/calendar/Calendar';
import CalendarDay from '../pages/calendar/CalendarDay';
import CalendarMonth from '../pages/calendar/CalendarMonth';
import CalendarYear from '../pages/calendar/CalendarYear';
import EventList from '../pages/calendar/EventList';
import Onboarding from "../pages/auth/onboarding";
import AdditionalInfo from "../pages/auth/additionalInfo";
import MultiCalendar from "../pages/calendar/MultiCalendar";
import Diary from "../pages/diary/diary";
import Ai from "../pages/ai/ai";
import Mypage from "../pages/mypage/mypage";
import Routine from "../pages/routine/routine";
import RoutineDay from "../pages/routine/RoutineDay";
import RoutineAdd from "../pages/routine/RoutineAdd";
import RoutineRest from "../pages/routine/RoutineRest";
import Search from "../pages/search/search";
import Notification from "../pages/notification/notification";
import RoutineWeekly from "../pages/routine/RoutinWeekly";
import MultiCalendarSheet from "../components/calendar/MultiCalendarSheet";
import ChallengeList from "../pages/routine/challengeList";
import Challenge from "../pages/routine/Challenge";
import EventDetail from "../pages/calendar/EventDetail";
import EditProfile from "../pages/mypage/editProfile";
import AppleLogin from "../pages/auth/callback/appleLogin";

interface RouteConfig {
    path: string;
    layout: string;
    element: ReactElement;
}

const DefaultLayout = 'DefaultLayout';
const RoutineLayout = 'RoutineLayout';
const CalendarLayout = 'CalendarLayout';
const NoneLayout = 'NoneLayout';


// 인증 여부 상관 없이 접속 가능한 페이지
const routes: Record<string, RouteConfig> = {
    home: {
        path: '/',
        layout: DefaultLayout,
        element: <App />,
    },
    naverAuth: {
        path: '/auth/naver',
        layout: NoneLayout,
        element: <NaverLogin />,
    },
    googleAuth: {
        path: '/auth/google',
        layout: NoneLayout,
        element: <GoogleLogin />,
    },
    kakaoAuth: {
        path: '/auth/kakao',
        layout: NoneLayout,
        element: <KakaoLogin />,
    },
    appleAuth: {
        path: '/auth/apple',
        layout: NoneLayout,
        element: <AppleLogin />,
    },
};

//  인증을 반드시 하지 않아야만 접속 가능한 페이지
const noAuthRoutes: Record<string, RouteConfig> = {
    login: {
        path: '/login',
        layout: '',
        element: <Login />,
    },
    onboarding: {
        path: '/onboarding',
        layout: DefaultLayout,
        element: <Onboarding />,
    },
};

//  인증을 반드시 해야만 접속 가능한 페이지
const authRoutes: Record<string, RouteConfig> = {
    additionalInfo: {
        path: '/additional-info',
        layout: NoneLayout,
        element: <AdditionalInfo />,
    },
    calendar: {
        path: '/calendar',
        layout: CalendarLayout,
        element: <Calendar />,
    },
    calendarDay: {
        path: '/calendarday',
        layout: CalendarLayout,
        element: <CalendarDay />,
    },
    calendarMonth: {
        path: '/calendarmonth',
        layout: CalendarLayout,
        element: <CalendarMonth />,
    },
    calendarYear: {
        path: '/calendaryear',
        layout: CalendarLayout,
        element: <CalendarYear />,
    },
    eventList: {
        path: '/eventlist',
        layout: DefaultLayout,
        element: <EventList />,
    },
    eventDetail: {
        path: '/event',
        layout: DefaultLayout,
        element: <EventDetail />,
    },
    multiCalendar: {
        path: '/multiCalendar',
        layout: DefaultLayout,
        element: <MultiCalendar />,
    },
    multiCalendarSheet: {
        path: '/multiCalendarSheet',
        layout: DefaultLayout,
        element: <MultiCalendarSheet />,
    },    
    diary: {
        path: '/diary',
        layout: DefaultLayout,
        element: <Diary />
    },
    ai: {
        path: '/ai',
        layout: DefaultLayout,
        element: <Ai />
    },
    mypage: {
        path: '/mypage',
        layout: DefaultLayout,
        element: <Mypage />
    },
    editProfile: {
        path: '/editProfile',
        layout: DefaultLayout,
        element: <EditProfile />
    },
    routine: {
        path: '/routine',
        layout: RoutineLayout,
        element: <Routine />
    },
    routineWeekly: {
        path: '/routineweekly',
        layout: RoutineLayout,
        element: <RoutineWeekly />
    },
    routineDay: {
        path: '/routineday/:id',
        layout: RoutineLayout,
        element: <RoutineDay />
    },
    routineAdd: {
        path: '/routineadd',
        layout: RoutineLayout,
        element: <RoutineAdd />
    },
    routineRest: {
        path: '/routinerest',
        layout: RoutineLayout,
        element: <RoutineRest />
    },
    challengeList: {
        path: '/challengelist',
        layout: RoutineLayout,
        element: <ChallengeList />
    },
    challenge: {
        path: '/challenge/:id',
        layout: RoutineLayout,
        element: <Challenge />
    },
    search: {
        path: '/search',
        layout: DefaultLayout,
        element: <Search />
    },
    notification: {
        path: '/notification',
        layout: DefaultLayout,
        element: <Notification />
    },
}

//  권한 체크가 필요한 페이지
const protectRoutes: Record<string, RouteConfig> = {
    login: {
        path: '/admin',
        layout: DefaultLayout,
        element: <Login />,
    },
}

export { routes, noAuthRoutes, authRoutes, protectRoutes };
