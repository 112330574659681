import axios, { Axios, AxiosRequestConfig } from 'axios' // 추가
import { APIResponse } from '../interface/response'
import { error } from 'console'
import { getAccessToken, refreshAccessToken } from '../../features/auth/services/authService'

let refreshTokenPromise: Promise<string | null> | null = null;
let isRefreshing: boolean = false; // 리프레시 중인지를 나타내는 플래그
let subscribers: ((accessToken: string | null) => void)[] = [];// 리프레시 완료 후 알림을 받을 대기열

// 액세스 토큰이 갱신된 후 대기 중인 요청들을 재시도하는 함수
function onAccessTokenFetched(accessToken: string | null) {
    subscribers.forEach(callback => callback(accessToken));
    subscribers = []; // 대기열 클리어
}

// 액세스 토큰 갱신을 대기하는 함수
function addSubscriber(callback: (accessToken: string | null) => void): void {
    subscribers.push(callback);
}

// axios 인스턴스 생성
export const client: Axios = axios.create({
    baseURL: process.env.REACT_APP_API_URL || 'http://localhost:8080',
    headers: {
    'Content-Type': 'application/json',
    }
})

// 요청 인터셉터 추가
client.interceptors.request.use(config => {
    const token = getAccessToken();
    if(token) {
        config.headers['Authorization'] =`Bearer ${token}`;
    }
    return config;
}, error => {
    return Promise.reject(error);
})

// 응답 인터셉터 추가
client.interceptors.response.use(response => {
    return response;
}, async error => {
    const originalRequest = error.config;
    if(error.response.status === 401 && !originalRequest._retry) {
        if (isRefreshing) {
            // 이미 리프레시 중이면 대기
            return new Promise((resolve, reject) => {
                addSubscriber((accessToken: string | null) => {
                    if (accessToken) {
                        originalRequest.headers['Authorization'] = `Bearer ${accessToken}`;
                        resolve(client.request(originalRequest));
                    } else {
                        reject(error);
                    }
                });
            });
        }

        // 토큰 만료 시 처리 로직
        originalRequest._retry = true; // 재시도 플래그 설정
        isRefreshing = true;


        if (!refreshTokenPromise) {
            refreshTokenPromise = refreshAccessToken()
                .then(() => {
                    const newAccessToken = getAccessToken();
                    onAccessTokenFetched(newAccessToken);
                    return newAccessToken;
                })
                .catch((refreshError) => {
                    onAccessTokenFetched(null);
                    throw refreshError;
                })
                .finally(() => {
                    isRefreshing = false;
                    refreshTokenPromise = null;
                });
        }

        try {
            const newAccessToken = await refreshTokenPromise;
            originalRequest.headers['Authorization'] = `Bearer ${newAccessToken}`;
            return client.request(originalRequest);
        } catch (refreshError) {
            return Promise.reject(refreshError);
        }
    }

    return Promise.reject(error)
})


// 테스트 get 메서드
// export const getData = async <T>(url: string, config?: AxiosRequestConfig): Promise<T> => {
//     try {
//     const response = await client.get<T>(url, config);
//     return response.data;
//     } catch (error: any) {
//     throw new Error(error.message);
//     }
// };

//TODO: GET 메서드
export const getData = async <T>(url: string, config?: AxiosRequestConfig): Promise<APIResponse<T>> => {
    try {
    const response = await client.get<APIResponse<T>>(url, config);
    return response.data;
    } catch (error: any) {
    throw new Error(error.message);
    }
};

//TODO: POST 메서드
export const postData = async <T>(url: string, data?: any, config?: AxiosRequestConfig): Promise<APIResponse<T>> => {
    try {
    const response = await client.post<APIResponse<T>>(url, data, config);
    return response.data;
    } catch (error: any) {
    throw new Error(error.message);
    }
};

export const postDatapostDataWithoutResponse  = async <T>(url: string, data?: any, config?: AxiosRequestConfig): Promise<T> => {
    try {
        const response = await client.post<APIResponse<T>>(url, data, config);
        return response.data.data; 
    } catch (error: any) {
        throw new Error(error.message);
    }
};


//TODO: PUT 메서드
export const putData = async <T>(url: string, data?: any, config?: AxiosRequestConfig): Promise<APIResponse<T>> => {
    try {
    const response = await client.put<APIResponse<T>>(url, data, config);
    return response.data;
    } catch (error: any) {
    throw new Error(error.message);
    }
};


//TODO: PATCH 메서드
export const patchData = async <T>(url: string, data?: any, config?: AxiosRequestConfig): Promise<APIResponse<T>> => {
    try {
    const response = await client.patch<APIResponse<T>>(url, data, config);
    return response.data;
    } catch (error: any) {
    throw new Error(error.message);
    }
};

//TODO: Delete 메서드
export const deleteData = async <T>(url: string, config?: AxiosRequestConfig): Promise<APIResponse<T>> => {
    try {
    const response = await client.delete<APIResponse<T>>(url, config);
    return response.data;
    } catch (error: any) {
    throw new Error(error.message);
    }
};

//===================== AI Axios 설정 =============================== 

export const AIClient: Axios = axios.create({
    baseURL: process.env.REACT_APP_AI_API_URL || 'http://localhost:4000',
    headers: {
    'Content-Type': 'application/json',
    }
})

// 요청 인터셉터 추가
AIClient.interceptors.request.use(config => {
    const token = getAccessToken();
    if(token) {
        config.headers['Authorization'] =`Bearer ${token}`;
    }
    return config;
}, error => {
    return Promise.reject(error);
})

// 응답 인터셉터 추가
AIClient.interceptors.response.use(response => {
    return response;
}, async error => {
    const originalRequest = error.config;
    if(error.response.status === 401 && !originalRequest._retry) {
        if (isRefreshing) {
            // 이미 리프레시 중이면 대기
            return new Promise((resolve) => {
                addSubscriber((accessToken: string | null) => {
                    originalRequest.headers['Authorization'] = `Bearer ${accessToken}`;
                    resolve(AIClient.request(originalRequest));
                });
            });
        }

        // 토큰 만료 시 처리 로직
        originalRequest._retry = true; // 재시도 플래그 설정
        isRefreshing = true;


        try {
            await refreshAccessToken(); // 새로운 accessToken 요청
            const newAccessToken = getAccessToken();
            axios.defaults.headers.common['Authorization'] = `Bearer ${newAccessToken}`;
            onAccessTokenFetched(newAccessToken);
            return AIClient.request(originalRequest);
        } catch (refreshError) {
            return Promise.reject(refreshError)
        } finally {
            isRefreshing = false;
        }
    }

    return Promise.reject(error)
})

//TODO: GET 메서드
export const getAiData = async <T>(url: string, config?: AxiosRequestConfig): Promise<APIResponse<T>> => {
    try {
    const response = await AIClient.get<APIResponse<T>>(url, config);
    return response.data;
    } catch (error: any) {
    throw new Error(error.message);
    }
};

//TODO: POST 메서드
export const postAiData = async <T>(url: string, data?: any, config?: AxiosRequestConfig): Promise<APIResponse<T>> => {
    try {
    const response = await AIClient.post<APIResponse<T>>(url, data, config);
    return response.data;
    } catch (error: any) {
    throw new Error(error.message);
    }
};