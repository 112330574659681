
import AdditionalForm from "../../components/auth/addintionalInfo/AdditionalForm";
import AdditionalChoose from "../../components/auth/addintionalInfo/AdditionalChoose";

// import auth styles
import './auth.css';
import { useState } from "react";


function AdditionalInfo() {

    const [ step, setStep ] = useState<number>(1);

    return (
        <>
            <div className='addinfo-wp'>
                {
                    step == 1 ?
                    <AdditionalForm setStep={setStep}/>
                    :
                    <AdditionalChoose/>
                }
            </div>
        </>
    )
}

export default AdditionalInfo;