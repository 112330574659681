
import React, { useState, useEffect, useRef } from 'react';
import { DailyRoutineProps, WeeklyRoutineProps } from '../../features/routine/types/routineWeekType';
import Chips from '../common/Chips';
import Typography from '../common/Typography';
import './routinecomponent.css';
import { Challenge } from '../../features/routine/types/challengeType';
import { useNavigate } from 'react-router-dom';

interface routineCardProps {
    index:number
    challenge: Challenge;
    routineCompleteChange : (day:string, id:number, idx:number) => void;
}

const weekListKr = ['일', '월', '화', '수', '목', '금', '토'];
const weekList = ["sunday", "monday", "tuesday", "wednesday", "thursday", "friday", "saturday"];


const RoutineCard: React.FC<routineCardProps> = ({
    challenge,
    routineCompleteChange,
    index
}) => {

    const navigate = useNavigate();
    const [progress, setProgress] = useState(0);

    useEffect(() => {
        const calculatedProgress = (challenge.completedNumber / challenge.repeatedNumber) * 100;
        setProgress(calculatedProgress);
    }, [challenge.completedNumber, challenge.repeatedNumber]);

    const handleCardClick = () => {
        navigate(`/challenge/${challenge.id}`);
    };

    return (
        <>
            <div className='card' onClick={handleCardClick}>
                <div className='routine-item mb-8'>
                    <Typography variant='caption' className='item-cate-tit'>🔥챌린지</Typography>
                    <Typography variant='h4' className='item-tit'>{challenge.name}</Typography>
                    <span className='item-lbl'>
                        <Chips color={challenge.color} className='chal-chip'>{challenge.completedNumber}<span className='caption'>/ {challenge.repeatedNumber}</span></Chips>
                    </span>
                </div>
                {/* 챌린지 게이지 */}
                <div className="progress">
                    <div className="progress-chips" style={{ width: `${progress}%` }}>🔥</div>
                    <div className="progress-bar">
                        <Chips color={challenge.color} className='progress-inner' style={{ width: `${progress}%`}}> </Chips>
                    </div>
                </div>
            </div>
        </>
    )
}

export default RoutineCard;