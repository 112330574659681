// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* 
    Common Animation.css
 */

@keyframes widthfull {
    0% {
        width: 0;
    }

    70% {
        width: 50%;
    }

    100% {
        width: 100%;
    }
}

@keyframes checkBounce {
    0% {
        box-shadow: none;
    }

    70% {
        box-shadow: 0px 0px 10px 1px var(--color-text-05);
    }

    100% {
        box-shadow: none;
    }
}`, "",{"version":3,"sources":["webpack://./src/components/common/animation.css"],"names":[],"mappings":"AAAA;;EAEE;;AAEF;IACI;QACI,QAAQ;IACZ;;IAEA;QACI,UAAU;IACd;;IAEA;QACI,WAAW;IACf;AACJ;;AAEA;IACI;QACI,gBAAgB;IACpB;;IAEA;QACI,iDAAiD;IACrD;;IAEA;QACI,gBAAgB;IACpB;AACJ","sourcesContent":["/* \n    Common Animation.css\n */\n\n@keyframes widthfull {\n    0% {\n        width: 0;\n    }\n\n    70% {\n        width: 50%;\n    }\n\n    100% {\n        width: 100%;\n    }\n}\n\n@keyframes checkBounce {\n    0% {\n        box-shadow: none;\n    }\n\n    70% {\n        box-shadow: 0px 0px 10px 1px var(--color-text-05);\n    }\n\n    100% {\n        box-shadow: none;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
