import Button from '../../components/common/Buttons';
import PaletteSheet from '../../components/common/PaletteSheet';
import useRoutineHook from '../../features/routine/hooks/useRoutine.hook';
import Chips from '../../components/common/Chips';
import { useEffect, useState } from 'react';
import Typography from '../../components/common/Typography';
import BgOverlay from '../../components/common/BgOverlay';
import { RoutineCategoryType } from '../../features/routine/types/routineInsertType';
import { countRoutineByCategory, deleteRoutineCategory } from '../../features/routine/services/insertRoutineService';
import Swal from 'sweetalert2';

const RoutineCategory: React.FC<{ setSelectedCategory: (category: RoutineCategoryType) => void, setCategorySheetOpen?: (open: boolean) => void }> = ({
    setSelectedCategory,
    setCategorySheetOpen
}) => {
    const {
        categories,
        handleInsertCategory,
        handleCategoryName,
        handleCategoryColor,
        categoryValues,
        setCategoryValues,
        fetchCategories,
        handleCateNameUpdate,
        handleCateColorUpdate,
        cateUpdateValues,
        setCateUpdateValues,
        handleUpdateCategory,
    } = useRoutineHook();

    const [isPaletteSheetOpen, setPaletteSheetOpen] = useState(false);
    const [editMode, setEditMode] = useState(false);
    const [addMode, setAddMode] = useState(false);
    const [isOpen, setIsOpen] = useState(true);
    const [selectedEditCategory, setSelectedEditCategory] = useState<RoutineCategoryType | null>(null);

    const openPaletteSheet = () => setPaletteSheetOpen(true);
    const closePaletteSheet = () => setPaletteSheetOpen(false);

    const initialCategoryValues = {
        name: '',
        color: 'red',
    };

    const handleAddMode = () => setAddMode(true);

    const nameValid = () => categoryValues.name.trim() !== '' || cateUpdateValues.name.trim() !== '';

    const handleSelectCategory = (category: RoutineCategoryType) => {
        setSelectedCategory(category);
        setIsOpen(false);
    };

    const handleEditMode = (category: RoutineCategoryType) => {
        setSelectedEditCategory(category);
        setCateUpdateValues({
            idx: category.idx,
            name: category.name,
            color: category.color,
        });
        setEditMode(true);
    };

    const handleSubmit = async () => {
        if (editMode && selectedEditCategory) {
            await handleUpdateCategory(selectedEditCategory.idx, cateUpdateValues);
        } else {
            await handleInsertCategory();
            setCategoryValues(initialCategoryValues);
        }
        fetchCategories();
        setEditMode(false);
        setAddMode(false);
    };

    const handleDeleteCategory = async (idx: number) => {
        try {
            const response = await countRoutineByCategory(idx);
            console.log(response);
            if (response > 0) {
                Swal.fire({
                    text: '카테고리에 연결된 루틴이 있으면 삭제할 수 없어요',
                    confirmButtonText: '돌아가기',
                });
            } else {
                await Swal.fire({
                    text: '삭제하시겠어요?',
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonText: '확인',
                    cancelButtonText: '취소',
                    reverseButtons: true
                }).then(async (result) => {
                    if (result.isConfirmed) {
                        await deleteRoutineCategory(idx);
                        fetchCategories();
                        setEditMode(false);
                        setAddMode(false);
                    }
                });
            }
        } catch (error) {
            console.error('삭제에 실패하였습니다:', error);
        }
    };

    const closeRoutineCategory = () => setIsOpen(false);

    useEffect(() => {
        if(setCategorySheetOpen && !isOpen) setCategorySheetOpen(isOpen);
    }, [isOpen])

    useEffect(() => {
        fetchCategories();
    }, []);

    const renderCategoryList = () => (
        <>
            <div className="card card-drawer multilist-sheet category-sheet routine">
                <div className="sheet-header">
                    <div className="sheet-handle"></div>
                </div>
                <div className="multi-header">
                    <h3 className="subtit-01">카테고리 설정하기</h3>
                </div>
                <div className="card multi-add mt-20">
                    <p className="subtit-02">카테고리 생성하기</p>
                    <button className="btn btn-sm error btn-add" onClick={handleAddMode}></button>
                </div>
                <ul className="multi-list category-list mt-20">
                    {categories.map((category) => (
                        <li key={category.idx} className="card mb-16" onClick={() => handleSelectCategory(category)}>
                            <Chips size="sm" color={category.color as | 'red' | 'orange' | 'yellow' | 'green' | 'blue' | 'purple' | 'grey' | undefined}></Chips>
                            <span>&nbsp;&nbsp;</span>
                            <p className="subtit-02">{category.name}</p>
                            <button
                                className="btn-sm btn-text btn-edit"
                                onClick={(e) => {
                                    e.stopPropagation();
                                    handleEditMode(category);
                                }}
                            ></button>
                        </li>
                    ))}
                </ul>
            </div>
            <BgOverlay close={closeRoutineCategory} />
        </>
    );

    const renderCategoryForm = () => (
        <>
            <div className="card card-drawer multilist-sheet category-sheet">
                <div className="sheet-header">
                    <div className="sheet-handle"></div>
                </div>
                <div className="multi-header">
                    <h3 className="subtit-01">{editMode ? '카테고리 수정하기' : '카테고리 등록하기'}</h3>
                </div>
                <div className="title-labeling mb-20 mt-20">
                    <input
                        type="text"
                        value={editMode ? cateUpdateValues.name : categoryValues.name}
                        onChange={editMode ? handleCateNameUpdate : handleCategoryName}
                        placeholder="카테고리 제목을 입력하세요"
                    />
                    <button className="btn-lebeling" onClick={openPaletteSheet}>
                        <span className={'chips ' + (editMode ? cateUpdateValues.color : categoryValues.color)}></span>
                    </button>
                    {isPaletteSheetOpen && (
                        <PaletteSheet
                            openSheet={closePaletteSheet}
                            setColor={editMode ? handleCateColorUpdate : handleCategoryColor}
                        />
                    )}
                </div>
                <div className="fix-bottom-btn" style={{ display: 'flex', justifyContent: editMode ? 'space-between' : 'flex-end' }}>
                    {editMode && (
                        <Button style={{ flex: '1', marginRight: '5px' }}
                            color="error"
                            variant="text"
                            size="lg"
                            onClick={() => handleDeleteCategory(cateUpdateValues.idx)}
                        >
                            삭제하기
                        </Button>
                    )}
                    <Button
                        style={editMode ? { flex: '1' } : {}}
                        onClick={handleSubmit}
                        color="main"
                        size="lg"
                        disabled={!nameValid()}
                    >
                        {editMode ? '수정하기' : '등록하기'}
                    </Button>
                </div>

            </div>
            <BgOverlay close={closeRoutineCategory} />
        </>
    );

    if (!isOpen) {
        return null;
    }

    return editMode || addMode ? renderCategoryForm() : renderCategoryList();
};

export default RoutineCategory;