import dayjs from "dayjs";
import { getData, patchData } from "../../../apis/api"
import { ChallengeProps, RoutineProps, UpdateCompletedRoutineProps, WeekProps } from "../types/routineDayType";

// 루틴정보 가져오기
// http://localhost:8080/routine/daily?date=2024-04-09
export const getDailyRoutine = async (date:string):Promise<RoutineProps[]> => {

    const response = await getData<RoutineProps[]>(`/routine/daily?date=${date}`);

    const routines = response.data;

    if(response.status != 200) {
        console.log(response); // 에러 처리 해야함
    }

    return routines;
}
export const getDailyChallenge = async (date:string):Promise<ChallengeProps[]> => {

    const response = await getData<ChallengeProps[]>(`/routine/dailyChallenge?date=${date}`);

    const routines = response.data;

    if(response.status != 200) {
        console.log(response); // 에러 처리 해야함
    }

    return routines;
}

export const getWeekDates = async (date:Date):Promise<WeekProps[]> => {
    let weeks: WeekProps[] = [];
    let days: WeekProps = [];
    const day = dayjs(date);
    const firstDayOfWeek = day.startOf('week');
    for (let i = 0; i < 7; i++) {
        const routines = await getDailyRoutine(firstDayOfWeek.add(i, 'day').format('YYYY-MM-DD'));
        const challenges = await getDailyChallenge(firstDayOfWeek.add(i, 'day').format('YYYY-MM-DD'));

        const filteredRoutines = routines.filter(routine => {
            const currentDate = firstDayOfWeek.add(i, 'day');

            const routineStartDate = routine.startDate ? new Date(routine.startDate) : undefined;
            const routineEndDate = routine.endDate ? new Date(routine.endDate) : undefined;
            if (routineStartDate && routineEndDate) {
                return !(currentDate.toDate() >= routineStartDate && currentDate.toDate() <= routineEndDate);
            }
            return true;
        });

        const completionRate = filteredRoutines.filter(r => r.isCompleted).length / filteredRoutines.length * 100;
        
        days.push({
            date: parseInt(firstDayOfWeek.add(i, 'day').format('DD')),
            dayOfWeek: ['일', '월', '화', '수', '목', '금', '토'][firstDayOfWeek.add(i, 'day').day()],
            fullDate: firstDayOfWeek.add(i, 'day').add(9, 'hour').toDate(),
            routines: routines,
            challenges: challenges,
            completionRate: completionRate
        });
    }
    
    weeks.push(days);
    
    return weeks;
}

export const updateCompletedRoutine = async (param:UpdateCompletedRoutineProps):Promise<void> => {
    const response = await patchData('/routine/completed/update',{idx:param.id, completedDate:param.completedDate});

    if(response.status != 200) {
        console.log(response); // 에러 처리 해야함
    }
}