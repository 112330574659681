import { jwtDecode } from "jwt-decode";
import { KeyedObject } from "../features/auth/types/authType";

const stateGenerator = (length: number): string => {
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    let result = '';
    const charactersLength = characters.length;
    for (let i = 0; i < length; i++) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    
    return result;
}

const verifyToken: (st: string | null) => boolean = (serviceToken) => {
    if (!serviceToken) {
      return false;
    }
    const decoded: KeyedObject = jwtDecode(serviceToken);
  
    /**
     * Property 'exp' does not exist on type '<T = unknown>(token: string, options?: JwtDecodeOptions | undefined) => T'.
     */
    return decoded.exp > Date.now() / 1000;
  };

export {
    stateGenerator,verifyToken
}