import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

// import calendar styles
import './calendar.css';
// import './calendarday.css'
import './board.css';
import Button from '../../components/common/Buttons';

//feature
import { useEventFormView } from '../../features/event/hooks/useEventFormView.hook';
import { useEventHook } from '../../features/event/hooks/useEvent.hook';
import Chips from '../../components/common/Chips';
import NoimageSymbol from '../../assets/images/noimages/img_noimage_symbol.png';
import Header from '../../layout/calendar/Header';


const EventDetail: React.FC = () => {
    const location = useLocation();
    const pageInfo = { ...location.state };
    const mode = pageInfo.mode || '';
    const idx = pageInfo.idx || '';

    const { getTagChips } = useEventFormView();
    const { values, fetchEvent } = useEventHook(mode);

    const [isImageOpen, setIsImageOpen] = useState(false);
    const [openedImage, setOpenedImage] = useState('');
    
    useEffect(() => {
        fetchEvent(idx);
    }, []);
    
    const handleImageClick = (imageUrl: string) => {
        if (isImageOpen && imageUrl === openedImage) {
            setIsImageOpen(false);
            setOpenedImage('');
        } else {
            setIsImageOpen(true);
            setOpenedImage(imageUrl);
        }
    };

    const formatDate = (date: string | Date): string => {
        if (typeof date === 'string') {
            date = new Date(date);
        }

        const year = date.getFullYear();
        const month = date.getMonth() + 1;
        const day = date.getDate();
        const hours = date.getHours();
        const minutes = date.getMinutes();
        const ampm = hours >= 12 ? '오후' : '오전';
        const formattedHours = hours % 12 || 12;

        return `${year}년 ${month}월 ${day}일 ${ampm} ${formattedHours}시 ${minutes < 10 ? '0' + minutes : minutes}분`;
    };

    const formatRepeatedDate = (date: string | Date): string => {
        if (typeof date === 'string') {
            date = new Date(date);
        }

        const year = date.getFullYear();
        const month = date.getMonth() + 1;
        const day = date.getDate();


        return `${year}년 ${month}월 ${day}일`;
    };

    const getRepeatedTypeText = (repeatedType: string | null): string => {
        switch (repeatedType) {
            case 'DAILY':
                return '매일 반복';
            case 'WEEKLY':
                return '매주 반복';
            case 'MONTHLY':
                return '매월 반복';
            case 'YEARLY':
                return '매년 반복';
            default:
                return '반복 안함';
        }
    };

    return (
        <>
            {values.calendarId && <Header calendarIdx={values.calendarId} />}
            <div className="board-wp grid-20 board">
                <div className="title-labeling mb-20">
                    <input type="text" name="name" value={values.name} autoComplete="off" disabled />
                    <button className="btn-lebeling">
                        <span className={'chips ' + values.label}></span>
                    </button>
                </div>
                {/* 시간설정 */}
                <p className="body-02 mb-8">일정 시간 설정</p>

                <div className="card">
                    <div className="write-control">
                        <p className="body-01 control-tit">시작</p>
                        <input type="text" className="inp-date" value={formatDate(values.startDate)} disabled />
                    </div>
                    <hr className="hr-dashed mt-20 mb-20" />
                    <div className="write-control">
                        <p className="body-01 control-tit">종료</p>
                        <input type="text" className="inp-date" value={formatDate(values.endDate)} disabled />
                    </div>
                </div>
                {/* 반복설정 */}
                <p className="body-02 mt-20 mb-8">반복 설정</p>
                <div className="card" style={{ position: 'relative' }}>
                    <div className="write-control">
                        <p className="body-01 control-tit">반복 설정</p>
                        <button className="btn-edit txt-input">{getRepeatedTypeText(values.repeatedType)}</button>
                    </div>
                    {values.repeatedType && (
                        <>
                            <hr className="hr-dashed mt-20 mb-20" />
                            <div className="write-control">
                                <p className="body-01 control-tit">반복 종료</p>
                                <input
                                    type="text"
                                    className="inp-date"
                                    placeholder=""
                                    value={values.repeatedDate ? formatRepeatedDate(values.repeatedDate) : ''}
                                    readOnly
                                />
                            </div>
                        </>
                    )}
                </div>
                {/* 장소설정 */}
                {values.place && (
                    <>
                        <p className="body-02 mb-8 mt-20">장소</p>
                        <div className="card">
                            <div className="write-control">
                                <input type="text" name="place" value={values.place} disabled autoComplete="off" />
                            </div>
                        </div>
                    </>
                )}
                {/* 알림설정 */}
                <div className="card mt-20" style={{ position: 'relative' }}>
                    <div className="write-control">
                        <p className="body-01 control-tit">알림 받기</p>
                        <div className="switch-wrapper">
                            <input
                                type="checkbox"
                                id="alarm"
                                className="switch-inp"
                                checked={values.remindTime !== undefined && values.remindTime !== null}
                                disabled 
                            />
                            <label htmlFor="alarm" className="switch-label">
                                <span className="onf-btn"></span>
                            </label>
                        </div>
                    </div>
                    {(values.remindTime !== undefined && values.remindTime !== null) && (
                        <>
                            <hr className="hr-dashed mt-20 mb-20" />
                            <div className="write-control">
                                <p className="body-01 control-tit">알림 시간</p>
                                {values.remindTime}분 전 알림
                            </div>
                        </>
                    )}
                </div>
                {/* 캘린더 설정 */}
                <p className="body-02 mb-8 mt-20">캘린더 설정</p>
                <Button variant="text" size="sm" className="btn-shareuser info">
                    {values.calendarEmoji} {values.calendarName}
                </Button>

                {/* 공유하기 NEW */}
                {values.eventGrade === 'ADMIN' && (
                    <>
                    <p className="body-02 mb-8 mt-20">공유하기</p>
                        <Button
                            variant="text"
                            size="sm"
                            className="btn-shareuser"
                            style={{ display: 'flex', flexWrap: 'wrap' }}
                        >
                            {values.userList && values.userList.length > 0
                                ? values.userList.every((user) => user.accept === 'DENIED')
                                    ? '공유된 회원이 없어요'
                                    : values.userList.map(
                                        (user, idx) =>
                                            user.accept !== 'DENIED' && (
                                                <Chips
                                                    color={user.accept === "ACCEPT" ? "black" : "grey"}
                                                    key={idx}
                                                    style={{ marginRight: '5px', display: 'flex', alignItems: 'center' }}
                                                >
                                                <img
                                                    src={user.profile || NoimageSymbol}
                                                    alt="Profile"
                                                    style={{
                                                        width: '20px',
                                                        height: '20px',
                                                        borderRadius: '50%',
                                                        marginRight: '5px',
                                                    }}
                                                />
                                                <span style={{ fontSize: '14px', lineHeight: '20px' }}>
                                                    {user.nickName}
                                                </span>
                                            </Chips>
                                        )
                                    )
                                : '공유된 회원이 없어요'}
                        </Button>
                    </>
                )}
                {/* 첨부파일 */}
                {Array.isArray(values.imageList) && values.imageList.length > 0 && (
                    <>
                        <p className="body-02 mb-8 mt-20">첨부 파일</p>
                        {values.imageList && values.imageList.length > 0 && (
                            <div className="file-preview-container" style={{ overflowX: 'auto', display: 'flex' }}>
                                {values.imageList.map((image, index) => (
                                    <div key={index} className="file-preview" style={{ marginRight: '10px' }}>
                                        <img
                                            src={image?.imageName}
                                            alt={`Image ${index + 1}`}
                                            className="preview-image"
                                            style={{ width: '60px', height: '60px', cursor: 'pointer' }}
                                            onClick={() => handleImageClick(image?.imageName)}
                                        />
                                    </div>
                                ))}
                            </div>
                        )}
                        {isImageOpen && (
                            <div className="modal" onClick={() => handleImageClick(openedImage)}>
                                <div className="modal-content">
                                    <img
                                        src={openedImage}
                                        alt="Opened Image"
                                        style={{ maxWidth: '100%', maxHeight: '100%' }}
                                    />
                                </div>
                            </div>
                        )}
                    </>
                )}
                {/* 메모 */}
                {values.memo && (
                    <>
                        <p className="body-02 mb-8 mt-20">메모</p>
                        <div className="card mt-20">
                            <div className="write-control">
                                <textarea name="memo" value={values.memo} disabled></textarea>
                            </div>
                        </div>
                    </>
                )}
                {/* 해시태그 */}
                {values.tag && (
                    <>
                        <p className="body-02 mb-8 mt-20">해시태그</p>
                        <div className="card mt-20" style={{ display: 'flex' }}>
                            {getTagChips(values.tag).map((item, index) => (
                                <Chips color="grey" key={index} style={{ marginRight: '10px' }}>
                                    {item}
                                </Chips>
                            ))}
                        </div>
                    </>
                )}
            </div>
        </>
    );
};

export default EventDetail;
