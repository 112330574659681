import { useState, useEffect, useRef } from 'react';
import { Calendar, insertCalendarType, updateCalendarType, CalendarSelectionRequest } from '../types/calendarType';
import { findAllCalendar, addCalendar, inviteCalendarUser, cancelCalendarUser, findAllCalendarUser, deleteCalendar, deleteCalendarUser, updateCalendarSelected, updateCalendar } from '../services/multiCalendarService';
import { getData } from '../../../apis/api';
import { useLocation, useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
import { InviteUser } from '../../user/types/user';

const useMultiCalendarHook = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const calendar = location.state ? location.state.calendar : null;

    const [editMode, setEditMode] = useState(location.state ? location.state.editMode : false);
    const [readMode, setReadMode] = useState(location.state ? location.state.readMode : false);
    const [values, setValues] = useState<insertCalendarType>({ name: '', emoji: '😀', selected: 0, grade: 'WRITE', isDefault: false });
    const [calendars, setCalendars] = useState<Calendar[]>([]);
    const [isEmojiSheetActive, setIsEmojiSheetActive] = useState<boolean>(false);
    const [selectedEmoji, setSelectedEmoji] = useState<string>('😀');
    const [updateValues, setUpdateValues] = useState<updateCalendarType>({ name: '', emoji: '', grade: 'WRITE' });
    const inputRef = useRef<HTMLInputElement>(null);
    const [options, setOption] = useState<InviteUser[]>([]);
    const [calendarUsers, setCalendarUsers] = useState<InviteUser[]>([]);
    const [selectedCalendars, setSelectedCalendars] = useState<{ [calendarId: number]: boolean }>({});
    const [selectedUsers, setSelectedUsers] = useState<InviteUser[]>([]);
    const [calendarUserList, setCalendarUserList] = useState<InviteUser[]>([]);

    useEffect(() => {
        fetchCalendars();
    }, []);

    useEffect(() => {
        if (calendar) {
            setUpdateValues({
                name: calendar.name,
                emoji: calendar.emoji,
                grade: calendar.grade,
            });

            const fetchCalendarUsers = async () => {
                try {
                    const response = await findAllCalendarUser(calendar.idx);
                    const filteredUsers = response.data.filter((user: InviteUser) => user.accept !== 'DENIED');
                    setCalendarUserList(filteredUsers);
                    setCalendarUsers(filteredUsers);
                } catch (error) {
                    console.error('회원 조회에 실패하였습니다.', error);
                }
            };

            fetchCalendarUsers();
        }
    }, [calendar, setUpdateValues]);

    const handleEditMode = () => {
        setEditMode(true);
        setReadMode(false);
    };

    const handleDeleteCalendar = async () => {
        const selectedCalendars = calendars.filter(cal => cal.selected);
        const isOnlySelectedCalendar = selectedCalendars.length === 1 && selectedCalendars[0].idx === calendar.idx;

        try {
            if (calendar.isDefault) {
                Swal.fire({
                    text: '기본 캘린더는 삭제할 수 없어요',
                    showCancelButton: false,
                    confirmButtonText: '돌아가기',
                });
                return;
            }
            if (calendarUsers.length > 0) {
                Swal.fire({
                    text: '공유된 회원이 있으면 삭제할 수 없어요',
                    showCancelButton: false,
                    confirmButtonText: '돌아가기',
                });
                return;
            }
            if (isOnlySelectedCalendar) {
                Swal.fire({
                    text: '선택된 캘린더가 하나일 땐 삭제할 수 없어요',
                    showCancelButton: false,
                    confirmButtonText: '돌아가기',
                });
                return;
            }
            await deleteCalendar(calendar.idx);
            navigate('/multicalendarsheet');
        } catch (error) {
            console.error('캘린더 삭제에 실패했습니다:', error);
        }
    };

    const fetchCalendars = async () => {
        try {
            const response = await findAllCalendar();
            setCalendars(response.data);
        } catch (error) {
            console.error('캘린더 조회에 실패하였습니다', error);
        }
    };

    const handleGrade = (grade: string) => {
        const updateState = editMode ? setUpdateValues : setValues;

        updateState((prevState: any) => ({
            ...prevState,
            grade: grade,
        }));
    };

    const handleName = (event: React.ChangeEvent<HTMLInputElement>) => {
        const newName = event.target.value;
        const updateState = editMode ? setUpdateValues : setValues;

        updateState((prevState: any) => ({
            ...prevState,
            name: newName,
        }));
    };

    const handleEmojiSave = (emoji: string) => {
        const updateState = editMode ? setUpdateValues : setValues;

        updateState((prevState: any) => ({
            ...prevState,
            emoji: emoji,
        }));

        if (editMode) {
            toggleEmojiSheet();
        } else {
            setIsEmojiSheetActive(false);
        }

        setSelectedEmoji(emoji);
    };

    const toggleEmojiSheet = () => {
        setIsEmojiSheetActive((prevState) => !prevState);
    };

    useEffect(() => {
        if (inputRef.current) {
            getData<any>(`/user/search/list?search=${inputRef.current.value}&page=${1}`)
                .then((response) => {
                    const result: InviteUser[] = response.data;
                    setOption(result);
                })
                .catch((error) => {
                    console.error('회원 검색에 실패하였습니다', error);
                });
        }
    }, []);

    const inviteUsers = async (calendarIdx: number) => {
        const newUsers = calendarUsers.map((user: InviteUser) => user.email);
        const currentUsers = calendarUserList.map((user: InviteUser) => user.email);
        const usersToAdd = newUsers.filter((user: string) => !currentUsers.includes(user));
        const usersToRemove = currentUsers.filter((user:string) => !newUsers.includes(user));

        if (usersToRemove.length > 0) {
            await cancelCalendarUser(calendarIdx, usersToRemove);
        }

        if (usersToAdd.length > 0) {
            await inviteCalendarUser(calendarIdx, usersToAdd);
        }
    };

    const handleLeaveCalendar = async () => {
        
        try {
            const selectedCalendars = calendars.filter(cal => cal.selected);
            const isOnlySelectedCalendar = selectedCalendars.length === 1 && selectedCalendars[0].idx === calendar.idx;

            if (isOnlySelectedCalendar) {
                Swal.fire({
                    text: '선택된 캘린더가 하나일 땐 삭제할 수 없어요',
                    showCancelButton: false,
                    confirmButtonText: '돌아가기',
                });
                return;
            }
            await deleteCalendarUser(calendar.idx, navigate);
        } catch (error) {
            console.error(error);
        }
    };

    const handleCheckboxChange = (calendarId: number) => {
        setSelectedCalendars((prevSelectedCalendars) => ({
            ...prevSelectedCalendars,
            [calendarId]: !prevSelectedCalendars[calendarId],
        }));
    };

    const handleSelectCalendars = async () => {
        const hasSelectedCalendar = Object.values(selectedCalendars).some((isSelected) => isSelected);

        if (!hasSelectedCalendar) {
            Swal.fire({
                text: '하나 이상의 캘린더를 선택해야 해요',
                confirmButtonText: '돌아가기',
            });
            return;
        }

        const updateRequests: CalendarSelectionRequest[] = calendars.map((calendar) => ({
            calendarId: calendar.idx,
            isSelected: selectedCalendars[calendar.idx],
        }));

        try {
            await updateCalendarSelected(updateRequests);
            navigate('/calendar');
        } catch (error) {
            console.error(error);
        }
    };

    const appendCalendarUser = (inviteUser: InviteUser) => {
        if (inviteUser.email) {
            setCalendarUsers((prevOptions) => {
                if (prevOptions.some((user) => user.email === inviteUser.email)) {
                    Swal.fire({
                        text: '이미 선택된 회원이에요',
                        confirmButtonText: '돌아가기',
                    });
                    return prevOptions;
                }
                return [...prevOptions, inviteUser];
            });
        }
    };

    const deleteCalendarUsers = (email: string) => {
        if (email) {
            setCalendarUsers((prevUsers: InviteUser[]) => prevUsers.filter((user: InviteUser) => user.email !== email));
        }
    };

    const handleRegister = async () => {
        try {
            if (!editMode) {
                const calendarIdx: number = await addCalendar(values);
                await inviteUsers(calendarIdx);
                navigate('/multiCalendarsheet');

            } else {
                await updateCalendar(calendar.idx, { name: updateValues.name, emoji: updateValues.emoji, grade: updateValues.grade });
                await inviteUsers(calendar.idx);
                navigate('/multiCalendarsheet', { state: { settingMode: true } });
            }
        } catch (error) {
            console.error('캘린더 등록 또는 수정에 실패하였습니다', error);
        }
    };
    
    return {
        values,
        handleName,
        isEmojiSheetActive,
        setIsEmojiSheetActive,
        selectedEmoji,
        toggleEmojiSheet,
        updateValues,
        handleEmojiSave,
        handleGrade,
        calendarUsers,
        inviteUsers,
        readMode,
        editMode,
        handleEditMode,
        handleDeleteCalendar,
        calendar,
        handleLeaveCalendar,
        appendCalendarUser,
        calendars,
        handleCheckboxChange,
        handleSelectCalendars,
        setSelectedCalendars,
        deleteCalendarUser,
        deleteCalendarUsers,
        calendarUserList,
        selectedCalendars,
        selectedUsers,
        setSelectedUsers,
        handleRegister
    };
};

export default useMultiCalendarHook;