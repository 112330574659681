import { useEffect, useState } from "react";
import { getAccessToken, naverLogin } from "../../../features/auth/services/authService";
import useAuth from "../../../hooks/useAuth";
import { useNavigate } from "react-router";


function NaverLogin() {
    const { login, isLoggedIn, user } = useAuth();
    const navigate = useNavigate();
    // get parameter 받기
    const queryParams = new URLSearchParams(window.location.search);
    const state = queryParams.get('state');
    const code = queryParams.get('code');

    useEffect(() => {
        const fetchUser = async () => {
            if (!code) {
                console.log('code값 없음');
                return;
            }

            try {
                // 로그인 처리하고 결과로 사용자 정보 받기
                const userInfo = await naverLogin(state,code);
                login(userInfo); // 사용자 로그인 처리
            } catch (error) {
                console.error('로그인 처리 중 오류 발생:', error);
            }
        };

        fetchUser();
    },[])

    useEffect(() => {
        if(isLoggedIn){
            if(user?.isUserDetail){
                navigate('/calendar');
            } else {
                navigate('/additional-info');
            }
        }
    },[isLoggedIn])



    return (
        // 로딩
        <>
        </>
    )
}

export default NaverLogin;