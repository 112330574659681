import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router";
import { setLayout, setPage } from "../../store/layout";
import { getWeeklyRoutine } from "../../features/routine/services/weeklyRoutineService";
import dayjs from "dayjs";
import Typography from "../../components/common/Typography";
import "./waiting.css";
import { findChallengeStatistics } from "../../features/routine/services/challengeService";
import Chips from "../../components/common/Chips";
import { ChallengeStatProps } from "../../features/routine/types/challengeType";
import { monthlyEventCount } from "../../features/event/services/eventService";

const Diary = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [totalRate, setTotalRate] = useState(0);
    const [dailyRate, setDailyRate] = useState<number[]>([]);
    const [challengeCount, setChallengeCount] = useState<{ completedCount: number, startedCount: number }>({ completedCount: 0, startedCount: 0 });
    const [completedChallenges, setCompletedChallenges] = useState<ChallengeStatProps[]>([]); 
    const [showCompletedChallenges, setShowCompletedChallenges] = useState(false);
    const [monthlyEventCounts, setMonthlyEventCounts] = useState<{ totalCount: number, remainingCount: number }>({ totalCount: 0, remainingCount: 0 });
 
    useEffect(() => {
        const startOfWeek = dayjs().startOf('week').toDate();
        const endOfWeek = dayjs().endOf('week').toDate();

        const fetchMonthlyEvent = async () => {
            const response = await monthlyEventCount();
            setMonthlyEventCounts(response);            
        }

        const fetchWeeklyChallengeStat = async () => {
            try {
                const response = await findChallengeStatistics();
                if (response && response.length > 0) {
                    const completed = response.filter(challenge => challenge.completed);
                    const incomplete = response.filter(challenge => !challenge.completed);
                    setChallengeCount({ 
                        completedCount: completed.length, 
                        startedCount: incomplete.length 
                    });
                    setCompletedChallenges(completed);
                }
            } catch (error) {
                console.error('챌린지 내역을 불러오는데 실패했습니다.', error);
            }
        };

        const fetchWeeklyRoutineStat = async () => {
            try {
                const response = await getWeeklyRoutine(startOfWeek, endOfWeek, true);
                if (response && response.length > 0) {
                    const orderedDailyRates = [0, 0, 0, 0, 0, 0, 0]; 
                    response[0]?.dailyAchievementRates?.forEach((rate, index) => {
                        const dayIndex = (index + 1) % 7; 
                        orderedDailyRates[dayIndex] = rate;
                    });
                    setDailyRate(orderedDailyRates || []);
                }
                const totalRates = response[0]?.totalAchievementRate ?? 0;
                setTotalRate(totalRates);
            } catch (error) {
                console.error('습관 내역을 불러오는데 실패했습니다.', error);
            }
        };
        
        fetchMonthlyEvent();
        fetchWeeklyChallengeStat();
        fetchWeeklyRoutineStat();

        dispatch(setLayout('historyBack'));
        dispatch(setPage('통계'));
    }, [dispatch]);

    const renderBars = () => {
        const daysOfWeek = ['일', '월', '화', '수', '목', '금', '토'];
        const todayIndex = dayjs().day();

        return daysOfWeek.map((day, index) => {
            const rate = dailyRate.length > index ? dailyRate[index] : 0;
            const isToday = index === todayIndex;

            return (
                <div key={day} className="bar-container">
                    <div className="bar">
                        <div className="bar-fill" style={{ height: `${rate}%` }}></div>
                    </div>
                    <div className="bar-label" style={{ color: isToday ? '#36A2EB' : 'inherit' }}>
                        {day}
                    </div>
                </div>
            );
        });
    };

    const handleCompletedChallengesClick = () => {
        setShowCompletedChallenges(!showCompletedChallenges); 
    };

    const handleChallengeClick = (challengeIdx: number) => {
        navigate(`/challenge/${challengeIdx}`);
    };

    return (
        <>
            <style>{`
                .content {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    margin: 0 auto;
                }
                .chart {
                    display: flex;
                    justify-content: space-between;
                    align-items: flex-end;
                    height: 150px;
                    margin-bottom: 20px;
                    flex: 1;
                }
                .bar-container {
                    width: 5%;
                    position: relative;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                }
                .bar {
                    width: 100%;
                    height: 100px; /* Fixed height for the bar */
                    background-color: var(--background-02);
                    position: relative;
                    display: flex;
                    align-items: flex-end;
                    border-radius: 10px;
                }
                .bar-fill {
                    width: 100%;
                    background-color: #36A2EB;
                    border-radius: 10px;
                }
                .bar-label {
                    margin-top: 5px;
                    font-size: 13px;
                    white-space: nowrap;
                }
                    
                .doughnut {
                    position: relative;
                    width: 100px;
                    height: 100px;
                    border-radius: 50%;
                    background: conic-gradient(#36A2EB ${totalRate}%, var(--background-02) ${totalRate}% 100%);
                    margin-left: 30px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }
                .doughnut-inner {
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    width: 70px;
                    height: 70px;
                    background-color: white;
                    border-radius: 50%;
                    transform: translate(-50%, -50%);
                    display: flex;
                    justify-content: center;
                    align-items: center;
                }
                .doughnut-inner span {
                    font-size: 20px;
                    font-weight: bold;
                }

                .completed-challenges-header {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    background-color: white;
                    border-radius: 15px;
                    border: 1px solid rgba(0, 0, 0, 0.1);                    
                    padding: 10px 20px;
                    margin-bottom: 10px;
                    font-size: 16px;
                    font-weight: bold;
                    height: 60px;
                }
                .completed-challenges-header .arrow-icon {
                    width: 0;
                    height: 0;
                    border-left: 5px solid transparent;
                    border-right: 5px solid transparent;
                    border-top: 5px solid gray;
                    margin-left: 10px;
                }
                .completed-challenge {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    background-color: var(--background-01);
                    border-radius: 15px;
                    padding: 10px 20px;
                    margin-bottom: 10px;
                    cursor: pointer;
                }
                .completed-challenge span {
                    font-size: 14px;
                }
                .completed-challenge .chips {
                    display: flex;
                    align-items: center;
                }

                .achievement-stats {
                    display: flex;
                    justify-content: space-around;
                    align-items: center;
                    padding: 20px;
                    background-color: white;
                    border-radius: 10px;
                    border: 1px solid rgba(0, 0, 0, 0.1);                    
                    margin-bottom: 20px;
                    margin-top: 20px;
                }
                .achievement-stats .stat {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                }
                .achievement-stats .stat .rate {
                    font-size: 24px;
                    font-weight: bold;
                }
                .achievement-stats .stat .label {
                    font-size: 15px;
                    margin-top: 15px;
                }
                .achievement-stats .stat .start {
                    font-size: 25px;
                    font-weight: bold;
                    color: #36A2EB;
                }                       
                .achievement-stats .stat .complete {
                    font-size: 25px;
                    font-weight: bold;
                }
            `}</style>

            <div className="board-wp grid-20">
                <div className="diary-container">

                    <div>
                        <Typography variant="h5">📆 이번 달 일정 현황</Typography> 

                        <div className="achievement-stats">
                            <div className="stat">
                                <div className="start">{monthlyEventCounts.totalCount}</div>
                                <div className="label">총 일정 갯수</div>
                            </div>
                            <div className="stat">
                                <div className="complete">{monthlyEventCounts.remainingCount}</div>
                                <div className="label">남은 일정</div>
                            </div>
                        </div>

                    </div>

                    <div style={{ marginTop: '30px' }}>
                        <Typography variant="h5">🤟 이번 주 습관 현황</Typography> 
                        <Typography variant="body1">평균 습관 달성률은 {Math.round(totalRate)}%입니다.</Typography>
                        <div className="card mt-20">
                            <div className="content">
                                <div className="chart">
                                    {renderBars()}
                                </div>
                                <div className="doughnut">
                                    <div className="doughnut-inner">
                                        <span>{Math.round(totalRate)}%</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div style={{ marginTop: '30px' }}>
                        <Typography variant="h5">🔥 이번 달  챌린지 현황</Typography> 

                        <div className="achievement-stats">
                            <div className="stat">
                                <div className="start">{challengeCount.startedCount}</div>
                                <div className="label">시작한 챌린지</div>
                            </div>
                            <div className="stat">
                                <div className="complete">{challengeCount.completedCount}</div>
                                <div className="label">완료한 챌린지</div>
                            </div>
                        </div>
                
                        <div className="completed-challenges-header" onClick={handleCompletedChallengesClick}>
                            <Typography variant="h5">완료한 챌린지</Typography>
                            <div className="arrow-icon" /> 
                        </div>
                        {showCompletedChallenges && (
                            completedChallenges.map((challenge, index) => (
                                <div
                                    key={index}
                                    className="completed-challenge"
                                    onClick={() => handleChallengeClick(challenge.idx)}
                                >
                                    <Typography variant="h5">{challenge.name}</Typography> 
                                    <div className="chips">
                                        <Chips color={"grey"}>
                                            <span style={{ display: 'flex', fontSize: '14px', lineHeight: '20px' }}>Completed</span>
                                        </Chips>
                                    </div>
                                </div>
                            ))
                        )}
                    </div>

                </div>
            </div>
        </>
    );
}

export default Diary;
