import React, { useState, useEffect } from 'react';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import dayjs from 'dayjs';
import './datepicker.css';

interface InpDatePickerProps {
    setDayModal: Function,
    startDate?: Date,
    endDate?: Date,
    tabChange?: Function
}

const InpDatePicker: React.FC<InpDatePickerProps> = ({ setDayModal, startDate, endDate, tabChange }) => {
    const [selectedDate, setSelectedDate] = useState<Date | undefined>(startDate);

    useEffect(() => {
        if (startDate) {
            setSelectedDate(startDate);
        }

        if (endDate) {
            setSelectedDate(endDate);
        }

    }, []);

    const handleDateChange = (value: Date) => {
        setSelectedDate(value);
        setDayModal(value);
        tabChange && tabChange();
    };

    return (
        <>
            <Calendar className='drawer-calendar' formatDay ={(locale, date) => dayjs(date).format('DD')}
                calendarType="US" // 요일 세팅때문에 넣음
                prevLabel=""
                nextLabel=""
                minDate={endDate ? startDate : undefined}                
                value={selectedDate}
                onClickDay={handleDateChange}
                />
        </>
    );
};

export default InpDatePicker;
