

// Checkbox 컴포넌트의 props 타입 정의
interface CheckboxProps {
    className?: string;
    size?: "sm" | "md" | "lg";
    id: string;
    checked?: boolean;
    onChange?: () => void;
}

const Checkbox: React.FC<CheckboxProps> = ({
    className = '',
    size = '',
    id = 'check1',
    checked = false,
    onChange

}) => {
    const classes = [
        'checkbox',
        size && `${size}`,
        className
    ].filter(Boolean).join(' ');

    return (
        <div className={classes}>
            <input type="checkbox" id={id} onChange={onChange} checked={checked}/>
            <label htmlFor={id}></label>
        </div>
    )
}

export default Checkbox;