import Typography from '../../components/common/Typography';
import Button from '../../components/common/Buttons';
import './aicomponents.css';
import { AIEvent, AIEventRecommend, AIRoutineRecommend, ChatItem } from '../../features/ai/types/aiType';
import dayjs from 'dayjs';
import ChatWaiting from './ChatWaiting';
import { createEventInfo } from '../../features/event/services/eventService';
import { useNavigate } from 'react-router-dom';
import { findRoutine, insertRoutine } from '../../features/routine/services/insertRoutineService';
import { postAiData } from '../../apis/api';

type ChatCarouselProp = {
    chat: ChatItem;
    setCalendarSheetOpen: React.Dispatch<React.SetStateAction<boolean>>; 
    setSaveEventData: React.Dispatch<React.SetStateAction<any>>; 
    setSaveRoutineData: React.Dispatch<React.SetStateAction<any>>; 
}
 
const ChatCarousel:React.FC<ChatCarouselProp> = ({
    chat,
    setCalendarSheetOpen,
    setSaveEventData,
    setSaveRoutineData
}) => {
    const type = chat.type;
    const aiEventRecommends = chat.aiEventRecommends;
    const aiEvenst = chat.aiEvents;
    const aiRoutineRecommends = chat.aiRoutineRecommends;
    const createdDate = chat.createdDate;
    const status = chat.status;
    const chatId = chat.id;
    
    const navigate = useNavigate();
    
    function formatDate(dateString:string) {
        // dayjs 객체 생성
        const date = dayjs(dateString);
        
        // "년월일" 형식으로 변환
        return date.format('YYYY년 MM월 DD일 HH:mm');
    }

    const renderRoutineDays = (routine: AIRoutineRecommend): string => {
        const days = [
            { name: '일', value: routine?.repeatedDayWeek?.sunday },
            { name: '월', value: routine?.repeatedDayWeek?.monday },
            { name: '화', value: routine?.repeatedDayWeek?.tuesday },
            { name: '수', value: routine?.repeatedDayWeek?.wednesday },
            { name: '목', value: routine?.repeatedDayWeek?.thursday },
            { name: '금', value: routine?.repeatedDayWeek?.friday },
            { name: '토', value: routine?.repeatedDayWeek?.saturday },
        ];

        const activeDays = days.filter(day => day.value).map(day => day.name);
        
        return activeDays.length === 7 ? '매일마다' : `${activeDays.join(',')}요일마다`;
    };

    const handleButton = async (data:any) => {
        if(data.status == 'COMPLETED') {
            // 수정페이지로 이동
            if(type === 'EVENT' || type === 'EVENT_RECOMMEND' && data.relatedId) {
                navigate('/calendarDay',
                    {
                        state: {
                            mode: 'update',
                            idx: data.relatedId,
                            edit: true
                        }
                    }
                );
            } else {
                // 예외처리 해야함
                const response = await findRoutine(Number(data.relatedId));
                if(response){
                    navigate('/routineadd', { state: { routineDetail: response.data, editMode: true } });
                }
            }
            

        } else if(data.status == 'PROCESSED') {
            if(type === 'EVENT' || type === 'EVENT_RECOMMEND') {
                // 일정 등록
                setCalendarSheetOpen(true);
                setSaveEventData({idx: data.idx, type: type, chatIdx:chatId, name: data.name, startDate: data.startDate, endDate: dayjs(data.endDate).add(1, 'hour'), place: data.place, label: data.label});
                
            } else {
                // 습관 등록
                setSaveRoutineData({idx: data.idx, chatIdx:chatId, name: data.name, startDate: new Date(), endDate: null, emoji: data.emoji, categoryId: null, type: 'REPETITION', repeatedNumber: 0, isRemind:false, remindDate: [], repeated: data.repeatedDayWeek, chatType: type});
            }
        }
    }

    const renderItem = () => {

        let renderData: any[] = [];
        let renderTitle: string = '';

        if (type === 'EVENT') {
            renderData = aiEvenst || [];
            renderTitle = '✨일정생성';
        } else if (type === 'EVENT_RECOMMEND') {
            renderData = aiEventRecommends || [];
            renderTitle = '📆 추천일정';
        } else {
            renderData = aiRoutineRecommends || [];
            renderTitle = '🤟추천습관';
        }

        return(
            <>
                {
                    renderData.length > 0 ? renderData?.map((data: any, idx: number) => (
                        <li className='card mb-20' key={idx}>
                            <p className='caption'>{renderTitle}</p>
                            <Typography className='mt-8 suggest-tit' variant='h3'>
                                {
                                    data.emoji ?
                                    <div className='mr-8'>{data.emoji}</div>
                                    :
                                    <div className={`chips chips-sm ${data.label} mr-8`}></div>
                                }
                                {data.name}
                            </Typography>
                            <hr className='hr-dashed mt-16' />
                            <Typography className='mt-16' variant='body2'>
                                {
                                    data.startDate ?
                                    `시작일: ${formatDate(data.startDate)}`
                                    : renderRoutineDays(data)
                                }
                            </Typography>
                            <Typography className='mt-16' variant='body2'>
                                {
                                    data.endDate &&
                                    `종료일: ${formatDate(data.endDate)}`
                                }
                            </Typography>
                            {/* <Typography className='mt-8' variant='body1'>오후 11시 30분</Typography> */}

                            {
                                data.place &&
                                <Typography className='mt-16' variant='body2'>장소 - {data.place}</Typography>
                            }

                            {
                                data.comment &&
                                <Typography className='mt-16' variant='caption'>*{data.comment}</Typography>
                            }
                            
                            <Button className='mt-16' color='main' onClick={()=>handleButton(data)}>{data.status == 'PROCESSED' ? '등록하기' : '수정하기'}</Button>
                        </li> 
                    )) : (
                        dayjs().diff(dayjs(createdDate),'minute') <= 5 ?
                        <ChatWaiting key="loading" />
                        :
                        <Typography variant='h5'>오류가 발생했습니다.</Typography>
                    )
                }
                
            </>
        )
    }

    return (
        <>
            <div className='chat-carousel mb-20'>
                    <ul>
                        {renderItem()}
                    </ul>
                </div>
        </>
    )
}

export default ChatCarousel;