import React, { useState } from 'react';
// import calendar styles
import './calendar.css';
import EventItem from "../../components/calendar/EventItem";

const EventList: React.FC = () => {
    const [schedule, setSchedule] = useState<Object>([]);

    return (
        <>
            <div className='calendar-wp grid-16'>
                <ul className='ul-eventlist'>
                    {/*  #개발요청:확인 후 삭제 부탁합니다
                        일정과 습관이 있는 날만 표기하기
                     */}
                    {/* <li className='mb-28'>
                        <EventItem />
                    </li>
                    <li className='mb-28'>
                        <EventItem />
                    </li>
                    <li className='mb-28'>
                        <EventItem />
                    </li>
                    <li className='mb-28'>
                        <EventItem />
                    </li> */}
                </ul>
            </div>
        </>
    );
};

export default EventList;
