// reducer.ts
import { dayState } from '../types/dayType';
import { SET_DATE, UPDATE_DAY } from './action';

export const updateDay = (day: number, week: string) => ({ type: UPDATE_DAY, payload: { day, week } });
export const updateYearMonth = (year?: number, month?: number, day?: number, weekIndex?: number) => ({ type: SET_DATE, payload: { year, month, day, weekIndex } });

const dateReducer = (state = dayState, action: { type: string, payload: any }) => {
  switch (action.type) {
    case SET_DATE:
      return {
        ...state,
        year: action.payload.year,
        month: action.payload.month,
        day: action.payload.day,
        week: action.payload.weekIndex
      };
    case UPDATE_DAY:
      return {
        ...state,
        day: action.payload.day,
        week: action.payload.week,
      }
    default:
      return state;
  }
};

export default dateReducer;
