// RestDateSheet.tsx
import React, { useState } from 'react';
import BgOverlay from "../common/BgOverlay";
import Typography from '../common/Typography';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../store';
import { setShowRestMode } from '../../features/routine/stores/routine';
import { updateRestMode } from '../../features/routine/services/insertRoutineService';
import { UpdateRestProps } from '../../features/routine/types/routineInsertType';
import Swal from 'sweetalert2';
import InpRestDatePicker from "./InpRestDatePicker";
import { useNavigate } from 'react-router-dom';
import Button from '../common/Buttons';

interface RestDateSheetProps {
    idx: number;
}

const RestDateSheet: React.FC<RestDateSheetProps> = ({ idx }) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const showRestMode = useSelector((state: RootState) => state.routine.showRestMode);
    
    const [startDate, setStartDate] = useState<string | null>(null);
    const [endDate, setEndDate] = useState<string | null>(null);
    const [selectedDate, setSelectedDate] = useState<Date | null>(null);

    const handleRestModeConfirm = async () => {
        if (!startDate) {
            await Swal.fire({
                text: '시작일을 선택해주세요',
                showCancelButton: false,
                confirmButtonText: '돌아가기',
            })
            return;
        }

        if (!endDate) {
            await Swal.fire({
                text: '종료일을 선택해주세요',
                showCancelButton: false,
                confirmButtonText: '돌아가기',
            })
            return;
        }

        const result = await Swal.fire({
            title: '휴식 모드로 전환하시겠어요?',
            text: `${startDate} ~ ${endDate}`,
            showCancelButton: true,
            reverseButtons: true,
            confirmButtonText: '전환하기',
            cancelButtonText: '돌아가기',
        });

        if (result.isConfirmed) {
            const updateData: UpdateRestProps = {
                idx,
                startDate: new Date(startDate),
                endDate: new Date(endDate),
            };

            try {
                await updateRestMode(updateData);
                closeRestDateSheet();
                navigate('/routinerest');
            } catch (error) {
                console.error('휴식모드 전환에 실패했습니다.', error);
            }
        }
    };
    
    const closeRestDateSheet = () => {
        dispatch(setShowRestMode(false));
    };

    const handleStartDateClick = () => {
        setStartDate(null);
        setEndDate(null);
        setSelectedDate(null);
    };

    const handleEndDateClick = () => {
        setEndDate(null);
        setSelectedDate(null);
    };

    const handleReset = () => {
        setStartDate(null);
        setEndDate(null);
        setSelectedDate(null);
    };

    return (
        <>
            {showRestMode && (
                <>
                    <div className='card card-drawer rest-sheet'>
                        <div className='sheet-header'>
                            <div className='sheet-handle'></div>
                        </div>

                        <Typography variant='h4' className='mb-16'>휴식모드</Typography>
                        <Typography variant='body2'>
                            습관을 잠시 멈추고 싶으신가요?<br/>
                            날짜를 설정해주세요.
                        </Typography>

                        <div className='inp-date-range mt-16 mb-16'>
                            <input
                                type='text'
                                value={startDate || ''}
                                placeholder='휴식 시작일'
                                className='inp-datepicker'
                                onClick={handleStartDateClick}
                                readOnly
                            />
                            -
                            <input
                                type='text'
                                value={endDate || ''}
                                placeholder='휴식 종료일'
                                className='inp-datepicker'
                                onClick={handleEndDateClick}
                                readOnly
                            />
                        </div>

                        <hr className='hr-dashed mb-16' />

                        <InpRestDatePicker
                            startDate={startDate}
                            endDate={endDate}
                            setStartDate={setStartDate}
                            setEndDate={setEndDate}
                            selectedDate={selectedDate}
                            setSelectedDate={setSelectedDate}
                        />
                        <div className="btn-half-column">
                            <Button color="main" variant="outline" onClick={handleReset}>
                                초기화
                            </Button>
                            <Button color="main" variant="contained" onClick={handleRestModeConfirm}>
                                저장하기
                            </Button>
                        </div>
                    </div>
                    <BgOverlay close={closeRestDateSheet} />
                </>
            )}
        </>
    );
};

export default RestDateSheet;
