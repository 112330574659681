
import { useSelector } from 'react-redux';
import Typography from '../../components/common/Typography';
import ChallengeCard from '../../components/routine/ChallengeCard';
import './routine.css';
import { RootState } from '../../store';
import { WeekDatas } from '../../features/routine/types/routineWeekType';
import { Key, useEffect, useState } from 'react';
import { useWeeklyRoutine } from '../../features/routine/hooks/useWeeklyRoutine.hook';
import { createWeekDates, formatDateRange } from '../../features/routine/services/weeklyRoutineService';
import { useDispatch } from 'react-redux';
import { setLayout, setPage } from '../../store/layout';
import { findAllChallenge } from '../../features/routine/services/challengeService';
import { Challenge } from '../../features/routine/types/challengeType';

import AOS from "aos";
import "aos/dist/aos.css";
import { Link } from 'react-router-dom';


const RoutineWeekly = () => {

    const {contentHeightBefore, handleTouchStart, handleTouchEnd, routineCompleteChange } = useWeeklyRoutine();
    const [challengeList, setChallengeList] = useState<Challenge[]>([]);
    const [showCompletedChallenges, setShowCompletedChallenges] = useState(false); 

    const selectedDay = useSelector((state: RootState) => state.routine.selectedDay);
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(setLayout('historyBack'));
        dispatch(setPage('챌린지 모아보기'));   
    }, [dispatch]);

    useEffect(() => {
        const fetchChallengeData = async () => {
            try {
                const response = await findAllChallenge(!showCompletedChallenges);
                setChallengeList(response.data);
            } catch (error) {
                console.error('Error fetching challenge data:', error);
            }
        };
    
        fetchChallengeData();
    }, [showCompletedChallenges]);
    
    const handleToggleChange = () => {
        setShowCompletedChallenges(!showCompletedChallenges);
    };

    // // selecDay 변경 시 동작하는 이벤트(현재는 최초 경우에만 동작함)
    // useEffect(() => {

    //     if (isNaN(new Date(selectedDay).getTime())) {
    //         return;
    //     }

    //     // 비동기 함수를 별도로 정의
    //     const fetchWeekData = async () => {
    //         const newWeekDataList = [];
    //         const today = new Date();
    //         let todayWeekIndex = 0;

    //         for (let i = -2; i <= 2; i++) {
    //             const baseDate = new Date(selectedDay);
    //             baseDate.setDate(baseDate.getDate() + i * 7);

    //             // createWeekDates 함수 호출
    //             const weekData = await createWeekDates(baseDate);
    //             if (weekData.weeklyRoutine.length > 0) {
    //                 // 오늘이 이 주에 포함되어 있는지 확인
    //                 if (today >= new Date(weekData.startOfWeek) && today <= new Date(weekData.endOfWeek)) {
    //                     todayWeekIndex = i + 2; // 실제 인덱스 조정
    //                 }
    //                 newWeekDataList.push(weekData);
    //             }
    //         }

    //         setWeekDataList(newWeekDataList);

    //         // 최초 스크롤 이동(selectedDay 기준)
    //         setTimeout(() => {
    //             const navbarHeight = document.querySelector('.nav-bar')?.clientHeight || 0;
    //             const todayWeekElement = document.getElementById(`week-${todayWeekIndex}`);
    //             if (todayWeekElement) {
    //                 const topPosition = todayWeekElement.getBoundingClientRect().top + window.pageYOffset - navbarHeight;
    //                 window.scrollTo({ top: topPosition, behavior: 'smooth' });
    //             }
    //         }, 0);
    //     };

    //     // 비동기 함수 실행
    //     fetchWeekData();
    // }, [selectedDay])
  

    // 위로 페이징 될 때 스크롤 조정하는 이벤트  

    useEffect(() => {
        const adjustScroll = () => {
            const contentHeightAfter = document.documentElement.scrollHeight;
            const scrollOffset = contentHeightAfter - contentHeightBefore;
            // 스크롤 위치를 조정하여 콘텐츠 추가 전 위치에 유지
            window.scrollTo(0, window.scrollY + scrollOffset);
        };

        if (contentHeightBefore) {
            adjustScroll();
        }
    }, [contentHeightBefore])

    useEffect(() => {
        window.addEventListener('touchstart', handleTouchStart);
        window.addEventListener('touchend', handleTouchEnd);

        // 컴포넌트 언마운트 시 이벤트 리스너 제거
        return () => {
            window.removeEventListener('touchstart', handleTouchStart);
            window.removeEventListener('touchend', handleTouchEnd);
        };
    }, [handleTouchStart, handleTouchEnd]);

    useEffect(() => {
        AOS.init();
      },[])

    return (
        <>
            <div className="routine-wp challenge-wp">
                <div className='challenge-switch'>
                    <Typography variant='caption'>완료된 챌린지 보기</Typography>
                    <div className="switch-wrapper">
                        <input type="checkbox" id="switch" className='switch-inp' hidden checked={showCompletedChallenges} onChange={handleToggleChange} />
                        <label htmlFor="switch" className="switch-label">
                            <span className="onf-btn"></span>
                        </label>
                    </div>
                </div>
                {/* 루틴 메인 주표시 */}
                {challengeList.length > 0 ? 
                    <>
                        {challengeList.map((challenge, index) => (
                            <div className="grid-16" key={index} data-aos="slide-up" data-aos-duration="500">
                                <ul className='ul-routine'>
                                    {/* 하루단위로 묶음 */}
                                    <li key={index}>
                                        <ChallengeCard index={index} challenge={challenge} routineCompleteChange={routineCompleteChange} />
                                    </li>
                                </ul>
                            </div>
                        ))
                        } 
                    </>
                    :
                    <div className="routine-main grid-20" data-aos-delay="1000" data-aos="fade-up" data-aos-duration="1000">
                        <ul className="routine-list">
                            <li className='card list-nodata'>
                                <Typography variant='body2'>
                                    등록된 챌린지가 없습니다.<br />
                                    작성이 어려우시다면 하루달에게 물어보세요!
                                </Typography>
                                <Link to={'/ai'} className='btn btn-sm main'>하루달에게 추천받기</Link>
                            </li>
                        </ul>
                    </div>
                }
            </div>
        </>
    )
}

export default RoutineWeekly;
