
import Avatar from '../../components/common/Avatar';
import Typography from '../../components/common/Typography';
import Button from '../../components/common/Buttons';
import Symbol from "../../assets/images/symbol/symbol.png";
import './aicomponents.css';
import { useState } from 'react';
import useAuth from '../../hooks/useAuth';

type ChatHaruBubbleProp = {
    handleClickButton: (type: string) => void;
    loading: boolean;
}

const ChatHaruBubble:React.FC<ChatHaruBubbleProp> = ({ handleClickButton, loading }) => {

    const user = useAuth();

    return (
        <>
        <div>
            {/* chatbot */}
            <div className='chat-haru pb-20'>
                <div data-aos="zoom-in" data-aos-duration="500"><Avatar src={Symbol} alt='harudal'/></div>
                <Typography variant='h5'>하루달</Typography>
            </div>
            {/* chat message -welcome */}
            <div className='chat-message mb-20' data-aos-delay="500" data-aos="fade-up" data-aos-duration="500">
                <Typography variant='body1'>안녕하세요! {user.user?.nickname}님.<br />
                    AI하루달에게 궁금하신 점이나 일정,습관을 추천받아보세요!</Typography>
            </div>
            <ul className='chat-cate-wp mb-20' data-aos-delay="1000" data-aos="fade-up" data-aos-duration="500">
                <li>
                    <Button variant='outline' color="main" size='sm' onClick={() => handleClickButton('EVENT')} disabled={loading}>🖋 자유롭게 일정을 작성해보세요</Button>
                </li>
                {/* <li>
                    <Button variant='outline' color="main" size='sm' onClick={() => handleClickButton('search')} disabled={loading}>🔍 검색하기</Button>
                </li> */}
                <li>
                    <Button variant='outline' color="main" size='sm' onClick={() => handleClickButton('EVENT_RECOMMEND')} disabled={loading}>📆 일정 추천받기</Button>
                </li>
                <li>
                    <Button variant='outline' color="main" size='sm' onClick={() => handleClickButton('ROUTINE_RECOMMEND')} disabled={loading}>🤟 습관 추천받기</Button>
                </li>
                {/* <li>
                    <Button variant='outline' color="main" size='sm' disabled>📊 내 습관 점검</Button>
                </li> */}
            </ul>
        </div>
        </>
    )
}

export default ChatHaruBubble;