
import Typography from '../common/Typography';
import './notificationcomponent.css';
import { NotificationType } from '../../features/notification/types/notificationType';
import useNotificationHook from '../../features/notification/hooks/useNotification.hook';

function SystemNotiCard(props: NotificationType) {
    const { formatNotificationDate, handleCardClick, isRead } = useNotificationHook(props.isRead);

    return (
        <>
            <div className={`card noti-card ${isRead ? 'noti-read' : ''}`} onClick={() => handleCardClick(props.idx)}>
                <div className='noti-card-top mb-8'>
                    <Typography variant='body2' className='noti-tit'><span className='mr-8'>⚙</span>시스템 알림</Typography>
                    <Typography variant='body2' className='noti-date'>
                        {formatNotificationDate(props.date)}
                    </Typography>                
                </div>
                <Typography variant='h5' className='mb-8'>정기점검안내드립니다 [4월7일 12:00 ~ 17:30]까지 이용이 불가능합니다. 더 나은 서비스로 만나뵐게요!</Typography>
                <div className='noti-card-bottom'>
                </div>
            </div>
        </>
    )
}

export default SystemNotiCard;