import React, { useEffect, useState } from 'react';
// import Swiper JS
import { Swiper, SwiperSlide } from 'swiper/react';
import { EffectFade } from 'swiper/modules';
// import Swiper styles
import 'swiper/css';
// import calendar styles
import './calendar.css';
import YearsCompoent from "../../components/calendar/YearsCompoent";
import { useSelector } from 'react-redux';
import { updateYearMonth } from '../../features/day/stores/day';
import { useDispatch } from 'react-redux';
import SwiperCore from "swiper";
import { setSelectSchedule } from '../../features/schedule/service/scheduleService';
import { updateDailySchedule } from '../../features/schedule/stores/schedule';
import { useNavigate } from 'react-router-dom';
import { setLayout, setPage } from '../../store/layout';
import { addYears } from '../../utils/calendar/CalendarUtils';
import dayjs from 'dayjs';

import AOS from "aos";
import "aos/dist/aos.css";

const CalendarYear: React.FC = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const selectDayReducer = useSelector((dateReducer: any) => dateReducer.day);

    // 스와이프 변경감지
    const [detectChangeSwiper, setDetectChangeSwiper] = useState<number>(0);

    const [years, setYears] = useState<Date[]>([]);

    useEffect(() => {
        dispatch(setLayout('Year'));
        dispatch(setPage(''));

        const today = new Date();

        setYears([addYears(today, -1), today, addYears(today, 1)]);

    },[])

    useEffect(() => {

        const selectDay = dayjs(`${selectDayReducer.year}-06-01`).toDate();
        
        setYears([addYears(selectDay, -1), selectDay, addYears(selectDay, 1)]);


    }, [selectDayReducer.year]);

    const handleSlideChange = (swiper: SwiperCore) => {
        if (swiper.swipeDirection === "prev") {
            dispatch(updateYearMonth(selectDayReducer.year - 1,1,1));
            setDetectChangeSwiper(prev => prev - 1);

            if (swiper.activeIndex - 1 === 0) {
                setTimeout(() => {
                    swiper.slideTo(1, 0, false); // 첫번째 인덱스로 이동
                }, 100);
            }

        } else if (swiper.swipeDirection === "next") {
            dispatch(updateYearMonth(selectDayReducer.year + 1,1,1));
            setDetectChangeSwiper(prev => prev + 1);

            if (swiper.activeIndex - 1 === 0) {
                setTimeout(() => {
                    swiper.slideTo(1, 0, false); // 첫번째 인덱스로 이동
                }, 100);
            }

        }
    };

    const handleToayButton = () => {
        dispatch(updateYearMonth(new Date().getFullYear(), new Date().getMonth() + 1, new Date().getDate()));
        const date = `${selectDayReducer.year}-${selectDayReducer.month < 10 ? '0' + selectDayReducer.month : selectDayReducer.month}-${selectDayReducer.day < 10 ? '0' + selectDayReducer.day : selectDayReducer.day}`;

        // setSelectSchedule(new Date(date))
        // .then((data) => {
        //     dispatch(updateDailySchedule(data));
        // }).then(() => navigate("/calendar"));
        navigate("/calendar")
    }

    useEffect(() => {
        AOS.init();
    }, [])


    return (
        <>
            <div className='calendar-wp grid-16 pt-0 pb-0' style={{ overflow: 'hidden' }}>
                <div className='cal-top mb-20' data-aos="fade" data-aos-duration="1500">
                    <h2 className='subtit-01'>{selectDayReducer.year}년</h2>
                    <button className='btn btn-sm main' onClick={handleToayButton}>오늘</button>
                </div>
                {/* year start */}
                <div data-aos="slide-left" data-aos-duration="500">
                    <Swiper initialSlide={1} onTouchEnd={handleSlideChange} className='swiper-years'>
                        {years.map((year,idx) =>( 
                            <SwiperSlide>
                                <YearsCompoent detectChangeSwiper={ detectChangeSwiper } nowYear={year.getFullYear()} />
                            </SwiperSlide>
                        ))}
                    </Swiper>
                    {/* year end */}
                </div>
            </div>
        </>
    );
};

export default CalendarYear;
