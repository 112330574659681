import React from 'react';
// import header styles
import './header.css';
import { useNavigate } from 'react-router';
import { useSelector } from 'react-redux';
import { RootState } from '../../store';
import { useLocation } from 'react-router-dom';

const Header: React.FC = () => {
    const selectDayReducer = useSelector((dateReducer: any) => dateReducer.day);
    const navigate = useNavigate();
    const location = useLocation();


    const layout = useSelector((state: RootState) => state.layout.layout);
    const page = useSelector((state: RootState) => state.layout.page);

    const pageInfo = { ...location.state };
    const edit = pageInfo.edit || '';

    return (
        <>
            {layout === "historyBack" ? (
                <header>
                    <div className="nav-bar">
                        <ul className="nav-left">
                            <li className="left-back">
                                {(edit ? (
                                    <button className="nav-icon btn-back" onClick={() => navigate("/calendar")}></button>
                                ) :
                                    <button className="nav-icon btn-back" onClick={() => navigate(-1)}></button>
                                )}
                            </li>
                            <li className="title-center">
                                <div className="nav-dropbox">
                                    <h3 className="subtit-02">{page}</h3>
                                </div>
                            </li>
                        </ul>
                    </div>
                </header>
            ) : (
                <header>
                    <div className="nav-bar">
                        <ul className="nav-left">
                            {/* 
                                히스토리백 버튼
                            */}
                            {/* <li className="left-back">
                                <button className="nav-icon btn-back"></button>
                            </li> */}

                            {/* 캘린더, 루틴 메인
                                tit + changedate(누르면날짜변경모달생성)
                                */}
                            <li onClick={() => navigate('/calendaryear')}>
                                <div className="nav-changedate">
                                    <h3 className="pl-8 subtit-02">2024년 3월</h3>
                                    <button className="nav-icon btn-changedate"></button>
                                </div>
                            </li>
                            {/* 
                                캘린더서브,루틴서브
                            */}
                            {/* <li className="title-center">
                                <div className="nav-dropbox">
                                    <h3 className="subtit-02">페이지 제목</h3>
                                </div>
                            </li> */}
                        </ul>
                        <ul className="nav-right">
                            {/* 
                                검색 

                            */}
                            <li onClick={() => navigate('/search')}><button className="nav-icon btn-search"></button></li>
                            {/* 알림
                                알림이 있을 경우 active추가 
                                */}
                            <li onClick={() => navigate('/notification')}><button className="ml-8 nav-icon btn-noti active"></button></li>
                            {/* 편집
                                누르면 드랍메뉴생성 
                                */}
                            {/* <li><button className="nav-icon btn-edit"></button></li> */}
                            {/* 
                                캘린더에서만 보이기!
                            */}
                            <li><button className="ml-8 nav-icon btn-layout"></button></li>
                        </ul>
                    </div>
                </header>
            )}
        </>
    );
};

export default Header;
