import Typography from '../../components/common/Typography';
import AiNotiCard from '../../components/notification/AiNotiCard';
import EventNotiCard from '../../components/notification/EventNotiCard';
import InviteNotiCard from '../../components/notification/InviteNotiCard';
import OutNotiCard from '../../components/notification/OutNotiCard';
import RefuseNotiCard from '../../components/notification/RefuseNotiCard';
import RoutineNotiCard from '../../components/notification/RoutineNotiCard';
import StatisticNotiCard from '../../components/notification/StatisticNotiCard';
import SystemNotiCard from '../../components/notification/SystemNotiCard';
import { NotificationType, ComponentMap } from '../../features/notification/types/notificationType';
import useNotificationHook from '../../features/notification/hooks/useNotification.hook';
import './notification.css';
import { useNavigate } from 'react-router-dom';
import Header from '../../layout/default/Header';
import { useDispatch } from 'react-redux';
import { useEffect } from 'react';
import { setLayout, setPage } from '../../store/layout';

const Notification = () => {
    const { todayNotifications, pastNotifications } = useNotificationHook();
    
    const dispatch = useDispatch();

    // 타입-컴포넌트 매핑 객체
    const componentMap: ComponentMap = {
        CALENDAR: InviteNotiCard,
        CALENDAR_DENIED: RefuseNotiCard,
        CALENDAR_CANCEL: OutNotiCard,
        CALENDAR_DEPORT: OutNotiCard,
        EVENT: InviteNotiCard,
        EVENT_DENIED: RefuseNotiCard,
        EVENT_CANCEL: OutNotiCard,
        EVENT_DEPORT: OutNotiCard,
        GENERAL_AI: AiNotiCard,
        GENERAL_EVENT: EventNotiCard,
        GENERAL_ROUTINE: RoutineNotiCard,
        GENERAL_STATISTICS: StatisticNotiCard,
        GENERAL_SYSTEM: SystemNotiCard,
    };

    // 컴포넌트 동적으로 렌더링하는 함수
    const renderNotificationComponent = (type: NotificationType['type'], props: NotificationType) => {
        const Component = componentMap[type];
        if (Component) {
            return <Component {...props} />;
        }
        return null;
    };

    useEffect(() => {
        dispatch(setLayout('historyBack'));
        dispatch(setPage('알림'));   
    }, [dispatch]);

    return (
        <>
            <Header />
            <div className="notification-wp">   
                <div className="bnr-noti">
                    <Typography variant="body2">최대 7일까지의 알림을 확인할 수 있어요</Typography>
                </div>
                <div className="notification-list grid-20">
                    {/* 오늘 알림 */}
                    {todayNotifications.length > 0 && (
                        <>
                            <Typography variant="body2" className="noti-value">
                                오늘
                            </Typography>
                            <ul className="mt-8">
                                {todayNotifications.map((notif: NotificationType) => (
                                    <li key={notif.idx} className="mb-16">
                                        {notif.type && renderNotificationComponent(notif.type, notif)}
                                    </li>
                                ))}
                            </ul>
                             <hr className="hr-dashed mt-34 mb-28" />
                        </>          
                    )}
                    {/* 지난 알림 */}
                    {pastNotifications.length > 0 && (
                        <>
                            <Typography variant="body2" className="noti-value">
                                지난 알림
                            </Typography>
                            <ul className="mt-8">
                                {pastNotifications.map((notif: NotificationType) => (
                                    <li key={notif.idx} className="mb-16">
                                        {notif.type && renderNotificationComponent(notif.type, notif)}
                                    </li>
                                ))}
                            </ul>
                        </>
                    )}
                </div>
            </div>
        </>
    );
};

export default Notification;
