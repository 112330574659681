// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
/* notification */
.noti-card{
    position: relative;
}

.noti-card::after{
    position: absolute;
    right: .8rem;
    top: .8rem;
    content: '';
    width: .4rem;
    height: .4rem;
    border-radius: 100%;
    background-color: var(--semantic-error);
}

.noti-card-top{
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: var(--color-text-04);
}

.noti-card-top .noti-tit{
    font-weight: var(--font-semibold);
    display: flex;
    align-items: center;
}
.noti-card-top .noti-tit span{
    font-size: var(--body-02);
}

.noti-card-bottom{
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.noti-card-bottom:has(.btn-text){
    margin-bottom: -.75rem;
}

.noti-card-bottom .btn-text{
    padding: 0;
    margin-right: -.25rem;
    display: flex;
    align-items: center;
}

.noti-card-bottom .btn-text img{
    height: 1.25rem;
}

.noti-card-top img.ic-symbol{
    height: 1rem;
}

.noti-read{
    background-color: var(--background-01);
}

.noti-read::after{
    display: none;
}

.noti-read .subtit-02{
    color: var(--color-text-04);
}

.noti-card-bottom:not(:has(.btn)){
    margin-bottom: -.75rem;
}`, "",{"version":3,"sources":["webpack://./src/components/notification/notificationcomponent.css"],"names":[],"mappings":";AACA,iBAAiB;AACjB;IACI,kBAAkB;AACtB;;AAEA;IACI,kBAAkB;IAClB,YAAY;IACZ,UAAU;IACV,WAAW;IACX,YAAY;IACZ,aAAa;IACb,mBAAmB;IACnB,uCAAuC;AAC3C;;AAEA;IACI,aAAa;IACb,mBAAmB;IACnB,8BAA8B;IAC9B,2BAA2B;AAC/B;;AAEA;IACI,iCAAiC;IACjC,aAAa;IACb,mBAAmB;AACvB;AACA;IACI,yBAAyB;AAC7B;;AAEA;IACI,aAAa;IACb,mBAAmB;IACnB,yBAAyB;AAC7B;;AAEA;IACI,sBAAsB;AAC1B;;AAEA;IACI,UAAU;IACV,qBAAqB;IACrB,aAAa;IACb,mBAAmB;AACvB;;AAEA;IACI,eAAe;AACnB;;AAEA;IACI,YAAY;AAChB;;AAEA;IACI,sCAAsC;AAC1C;;AAEA;IACI,aAAa;AACjB;;AAEA;IACI,2BAA2B;AAC/B;;AAEA;IACI,sBAAsB;AAC1B","sourcesContent":["\n/* notification */\n.noti-card{\n    position: relative;\n}\n\n.noti-card::after{\n    position: absolute;\n    right: .8rem;\n    top: .8rem;\n    content: '';\n    width: .4rem;\n    height: .4rem;\n    border-radius: 100%;\n    background-color: var(--semantic-error);\n}\n\n.noti-card-top{\n    display: flex;\n    align-items: center;\n    justify-content: space-between;\n    color: var(--color-text-04);\n}\n\n.noti-card-top .noti-tit{\n    font-weight: var(--font-semibold);\n    display: flex;\n    align-items: center;\n}\n.noti-card-top .noti-tit span{\n    font-size: var(--body-02);\n}\n\n.noti-card-bottom{\n    display: flex;\n    align-items: center;\n    justify-content: flex-end;\n}\n\n.noti-card-bottom:has(.btn-text){\n    margin-bottom: -.75rem;\n}\n\n.noti-card-bottom .btn-text{\n    padding: 0;\n    margin-right: -.25rem;\n    display: flex;\n    align-items: center;\n}\n\n.noti-card-bottom .btn-text img{\n    height: 1.25rem;\n}\n\n.noti-card-top img.ic-symbol{\n    height: 1rem;\n}\n\n.noti-read{\n    background-color: var(--background-01);\n}\n\n.noti-read::after{\n    display: none;\n}\n\n.noti-read .subtit-02{\n    color: var(--color-text-04);\n}\n\n.noti-card-bottom:not(:has(.btn)){\n    margin-bottom: -.75rem;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
