import React, { useEffect, useState } from 'react';
// import header styles
import '../default/header.css';
import { useNavigate } from 'react-router';
import DropMenu from '../../components/common/DropMenu';
import { useSelector } from 'react-redux';
import { RootState } from '../../store';
import { useDispatch } from 'react-redux';
import { setSelectedDay } from '../../features/routine/stores/routine';
import DatePickerSheet from '../../components/routine/DatePickerSheet';
import { updateYearMonth } from '../../features/day/stores/day';
import { updateDailySchedule } from '../../features/schedule/stores/schedule';
import { setSelectSchedule } from '../../features/schedule/service/scheduleService';
import { useLocation } from 'react-router-dom';
import { deleteEvent, deleteEventUser, deleteEventUserByCalendar } from '../../features/event/services/eventService';


interface HeaderProps {
    calendarIdx?: number;
}

const Header: React.FC<HeaderProps> = ({ calendarIdx }) => {
    const location = useLocation();
    const selectDayReducer = useSelector((dateReducer: any) => dateReducer.day);
    const [ showMainDropMenu, setShowMainDropMenu ] = useState(false);
    const [ showDetailDropMenu, setShowDetailDropMenu ] = useState(false);
    const [ showEditDropMenu, setShowEditDropMenu ] = useState(false);
    
    const [ showDatePicker, setShowDatePicker ] = useState(false);
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const selectedDay = useSelector((state:RootState) => state.routine.selectedDay);
    const layout = useSelector((state:RootState) => state.layout.layout);
    const page = useSelector((state:RootState) => state.layout.page);

    const allRead = useSelector((state: RootState) => state.notification.allRead);   

    const pageInfo = { ...location.state };
    const mode = pageInfo.mode || '';
    const idx = pageInfo.idx || '';
    const edit = pageInfo.edit || '';

    // selectDay 최초 세팅
    useEffect(() => {
        if(selectedDay === ''){
            dispatch(setSelectedDay(new Date().toISOString()));
        }
    }, [])
    
    const mainMenuItems = [
        {
            label: '연도별로 보기', onClick: () => {
                setShowMainDropMenu(false);
                navigate('/calendarYear');
            }
        },
        {
            label: '월별 모아보기', onClick: () => {
                setShowMainDropMenu(false);
                navigate('/calendarMonth')
            }
        },
        {
            label: '월별로 보기', onClick: () => {
                setShowMainDropMenu(false);
                navigate('/calendar',
                    {
                        state: {
                            calendarStatus: 'Year'
                        }
                    }
                )
            }
        }
    ]
    
    const editMenuItems = [
        {
            label: '수정하기', onClick: () => {
                setShowEditDropMenu(false);
                navigate('/calendarDay',
                    {
                        state: {
                            mode: 'update',
                            idx: idx,
                            edit: true
                        }
                    }
                );
            }
        },
        {
            label: '삭제하기', onClick: async () => {
                if (layout === 'EventDetail') {
                    await deleteEvent(idx);
                } else if (layout === 'EventDetailCalendarUser' && calendarIdx !== undefined) {
                    await deleteEventUserByCalendar(calendarIdx, idx);
                } else {
                    await deleteEventUser(idx);
                }
                setShowEditDropMenu(false);
                navigate('/calendar')
            }
        }
    ]

    function chageSelectedDate(date: Date) {
        dispatch(updateYearMonth(new Date(date).getFullYear(), new Date(date).getMonth() + 1, new Date(date).getDate()));
        
        const dateString = `${selectDayReducer.year}-${selectDayReducer.month < 10 ? '0' + selectDayReducer.month : selectDayReducer.month}-${selectDayReducer.day < 10 ? '0' + selectDayReducer.day : selectDayReducer.day}`;
        setSelectSchedule(new Date(dateString))
        .then((data) => {
            dispatch(updateDailySchedule({ date: date, data: data }));
        });
        setShowDatePicker(false);

    }

    return (
        <>
            {/* 
                scroll이 발생하면 header에 add class "scrolling"
             */}
            <header>
                <div className="nav-bar">
                    <ul className="nav-left">
                        {
                            layout === 'Main' ?
                            <>
                                <li onClick={() => setShowDatePicker(!showDatePicker)}>
                                    <div className="nav-changedate">
                                            <h3 className="pl-8 subtit-02">{selectDayReducer.year}년 {selectDayReducer.month}월</h3>
                                        <button className="nav-icon btn-changedate"></button>
                                    </div>
                                </li>
                            </>
                            :
                            <>
                                {/* 
                                    히스토리백 버튼
                                */}
                                <li className="left-back">
                                    {(layout === 'EventDetail' || layout === 'EventDetailUser') && edit ? (
                                        <button className="nav-icon btn-back" onClick={() => navigate("/calendar")}></button>
                                    ) : (    
                                        <button className="nav-icon btn-back" onClick={() => navigate(-1)}></button>
                                    )}
                                </li> 

                                <li className="title-center">
                                    <div className="nav-dropbox">
                                        <h3 className="subtit-02">{page}</h3>
                                    </div>
                                </li>
                            </>
                        }
                    </ul>
                    <ul className="nav-right">
                        {
                            (layout === 'Year' || layout === 'Main') &&
                            <>
                                <li onClick={() => navigate('/search')}><button className="nav-icon btn-search"></button></li>
                                {/* <li onClick={() => navigate('/notification')}><button className="ml-8 nav-icon btn-noti active"></button></li> */}
                                <li onClick={() => navigate('/notification')}>
                                    <button className={`ml-8 nav-icon btn-noti ${!allRead ? 'active' : ''}`}></button>
                                </li>
                                <li><button className="ml-8 nav-icon btn-layout" onClick={() => setShowMainDropMenu(!showMainDropMenu)}></button></li>
                            </>
                        }
                        {
                            layout === 'detail' &&
                            <>
                                <li><button className="nav-icon btn-edit" onClick={() => setShowDetailDropMenu(!showDetailDropMenu)}></button></li>
                            </>
                        }
                        {
                            (layout === 'EventDetail' || layout === 'EventDetailUser' || layout === 'EventDetailCalendarUser') && 
                            <>
                                <li><button className="nav-icon btn-edit" onClick={() => setShowEditDropMenu(!showEditDropMenu)}></button></li>
                            </>
                        }
                    </ul>
                </div>

                {showMainDropMenu && <DropMenu items={mainMenuItems} background={true} style={{position: 'fixed'}} showDropMenu={setShowMainDropMenu}/>}
                {showEditDropMenu && <DropMenu items={editMenuItems} background={true} style={{position: 'fixed', top: '40px'}} showDropMenu={setShowEditDropMenu}/>}
                {showDatePicker && <DatePickerSheet selectedDay={new Date(selectedDay)} changeSelectedDay={chageSelectedDate} setShowDatePicker={setShowDatePicker}/>}
            </header>
        </>
    );
};

export default Header;
