import { postData, getData, deleteData, patchData, postDatapostDataWithoutResponse } from '../../../apis/api';
import { InviteUser } from '../../user/types/user';
import { Calendar, insertCalendarType, updateCalendarType, CalendarSelectionRequest } from '../types/calendarType';
import Swal from 'sweetalert2';

export const addCalendar = async (values: insertCalendarType): Promise<number> => {
    try {
        const response = await postDatapostDataWithoutResponse<number>('calendar/insert', values);
        return response;
    } catch (error) {
        throw new Error('캘린더 생성에 실패하였습니다.');
    }
};

export const findAllCalendar = async () => {
	try {
        const response = await getData<Calendar[]>(`/calendar/find-all?page=0&size=100`);
        return response;
    } catch (error) {
        throw new Error('캘린더 조회에 실패하였습니다.');
    }
};

export const findAllWriteCalendars = async () => {
    try {
        const response = await getData<Calendar[]>(`/calendar/write/find-all`);
        return response;
    } catch (error) {
        throw new Error('캘린더 조회에 실패하였습니다.');
    }
};

export const findCalendar = async (idx: number) => {
    try {
        const response = await getData<Calendar>(`calendar/find/${idx}`);
        return response.data;
    } catch (error) {
        throw new Error('캘린더 조회에 실패하였습니다.');
    }
};

export const deleteCalendar = async (idx: number) => {
    try {
        await Swal.fire({
            text: '삭제하시겠어요?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: '확인',
            cancelButtonText: '취소',
            reverseButtons: true
        }).then(async (result) => {
            if (result.isConfirmed) {
                await deleteData(`calendar/delete/${idx}`);
            }
        });
    } catch (error) {
        throw new Error('삭제에 실패하였습니다.');
    }
};

export const updateCalendar = async (idx: number, updateData: updateCalendarType) => {
    try {
        await patchData(`/calendar/update/${idx}`, updateData);
    } catch (error) {
        throw new Error('캘린더 수정에 실패하였습니다.');
    }
};

export const inviteCalendarUser = async (idx: number, emails: string[]) => {
    try {
        const response = await postData<{ data: string }>(`calendar/invite/${idx}`, { emails });
        return response;
    } catch (error) {
        throw new Error('캘린더 초대에 실패하였습니다.');
    }
};

export const findAllCalendarUser = async (idx: number) => {
    try {
        const response = await getData<InviteUser[]>(`calendar/${idx}/user/list`);
        return response;
    } catch (error) {
        throw new Error('회원 조회에 실패하였습니다.');
    }
};

export const cancelCalendarUser = async (idx: number, emails: string[]) => {
    try {
        const response = await postData<{ data: string }>(`calendar/cancel/${idx}`, { emails });
        return response;
    } catch (error) {
        throw new Error('초대 취소에 실패하였습니다');
    }
};

export const deleteCalendarUser = async (idx: number, navigate: Function) => {
    try {
        const result = await Swal.fire({
            text: '탈퇴하시겠어요?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: '확인',
            cancelButtonText: '취소',
            reverseButtons: true
        });

        if (result.isConfirmed) {
            await deleteData(`calendar/delete/${idx}/user`);
            navigate('/multicalendarsheet');
        } else if (result.dismiss === Swal.DismissReason.cancel) {
            Swal.close();
        }
    } catch (error) {
        throw new Error('탈퇴에 실패하였습니다.');
    }
};

export const updateCalendarSelected = async (updateRequests: CalendarSelectionRequest[]) => {
    try {
        await patchData(`/calendar/select`, updateRequests);
    } catch (error) {
        throw new Error('캘린더 수정에 실패하였습니다.');
    }
};
