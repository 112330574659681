//  import imgages
import NoimageSymbol  from "../../../assets/images/noimages/img_noimage_symbol.png";
import { insertUserDetailType } from "../../../features/user/types/userDetailType";
import Button from "../../common/Buttons";
import Typography from "../../common/Typography";
import { useUserDetailForm } from "../../../features/user/hooks/useUserDetailForm.hook";
import { userDeatailSave, validateNickname } from "../../../features/user/services/userDeatilService";

const initUserDetail:insertUserDetailType = {
    age:10,
    gender:'MALE',
    nickName:'',
    profile:null
}

interface AdditionalFormProps {
    setStep: (step: number) => void;
}

const AdditionalForm:React.FC<AdditionalFormProps> = ({setStep}) => {
    const { values, errors, imageSrc, handleImageChange, handleInputNickname, selectValue, setActive,setErrors }= useUserDetailForm(initUserDetail);

    const saveAndNext = async () =>  {

        // 유효성검사
        const error = validateNickname(values.nickName);
        if(error){
            setErrors(prevState => ({ ...prevState, nickName: error }));
            return;
        }

        // 저장하는 로직
        const result:boolean = await userDeatailSave(values);

        // 저장 성공 시 다음 화면 이동
        if(result){
            setStep(2);
        }
    }

    return (
        <>
        <Typography variant='h2' className='mb-34'>회원가입을 위한 정보를<br />작성해주세요 😀</Typography>

        <form className="additional-form">
            <div className='inp-group group-center'>
                {/* upload start */}
                <div className="upload-profile mb-4">
                    <div className="avatar">
                        <img src={imageSrc ? imageSrc : NoimageSymbol} alt="noimage" />
                    </div>
                    <input className='inp-txt' type="file" name="" id="" accept="image/*" onChange={handleImageChange} />
                </div>
                {/* upload end */}
                <label className='lbl-inp'>프로필을 설정해주세요 <Typography variant="caption">(파일명은 JPG,PNG 사용할 수 있고 최대 5MB까지 업로드 가능해요)</Typography> </label>
                {
                    errors.profile && 
                    <Typography className="validation">*올바른 이미지가 아닙니다, 파일명을 확인해주세요</Typography>
                }
            </div>
            <div className='inp-group'>
                <label className='lbl-inp'>닉네임을 작성해주세요</label>
                <input className='inp-txt' type="text" name="" id="" placeholder='최소 2글자, 최대8글자로 작성해주세요' onChange={handleInputNickname} />
                {
                    errors.nickName &&
                    <Typography className="validation">*올바른 닉네임이 아닙니다, 2~8자 사이로 작성해주세요</Typography>
                }
            </div>
            <div className='inp-group'>
                <label className='lbl-inp'>성별을 알려주세요</label>
                <div className="btn-half-column">
                    <Button className={setActive('gender','MALE')} onClick={()=>selectValue('gender','MALE')}>남자</Button>
                    <Button className={setActive('gender','FEMALE')} onClick={()=>selectValue('gender','FEMALE')}>여자</Button>
                </div>
            </div>
            <div className='inp-group'>
                <label className='lbl-inp'>나이를 알려주세요</label>
                <div className="btn-half-column">
                    <Button className={setActive('age',10)} onClick={()=>selectValue('age',10)}>10대</Button>
                    <Button className={setActive('age',20)} onClick={()=>selectValue('age',20)}>20대</Button>
                </div>
                <div className="btn-half-column mt-8">
                    <Button className={setActive('age',30)} onClick={()=>selectValue('age',30)}>30대</Button>
                    <Button className={setActive('age',40)} onClick={()=>selectValue('age',40)}>40대 이상</Button>
                </div>
            </div>
        </form>

        <div className="next-btn-wp">
            <Button className='btn-lg' onClick={() => saveAndNext()}>다음으로</Button>
        </div>
        </>
    )
}

export default AdditionalForm;