
import Typography from '../common/Typography';
import './notificationcomponent.css';
import { NotificationType } from '../../features/notification/types/notificationType';
import useNotificationHook from '../../features/notification/hooks/useNotification.hook';

function RefuseNotiCard(props: NotificationType) {
    const { formatNotificationDate, handleCardClick, isRead } = useNotificationHook(props.isRead);
    
    return (
        <>
            <div className={`card noti-card ${isRead ? 'noti-read' : ''}`} onClick={() => handleCardClick(props.idx)}>
                <div className='noti-card-top mb-8'>
                    <Typography variant='body2' className='noti-tit'><span className='mr-8'>💔</span>초대 거절</Typography>
                    <Typography variant='body2' className='noti-date'>
                        {formatNotificationDate(props.date)}
                    </Typography>                
                 </div>
                <Typography variant='h5' className='mb-8'>{props.content}</Typography>
                <div className='noti-card-bottom'>
                </div>
            </div>
        </>
    )
}

export default RefuseNotiCard;