import React, { useEffect, useState } from 'react';
import './routine.css';
import './board.css';
import Typography from '../../components/common/Typography';
import { useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { deleteRoutine, findRoutine } from '../../features/routine/services/insertRoutineService';
import { RoutineDetailProps, RepeatedDayWeek } from '../../features/routine/types/routineInsertType';
import RestDateSheet from '../../components/routine/RestDateSheet';
import { useSelector } from 'react-redux';
import { RootState } from '../../store';
import Header from '../../layout/routine/Header';
import Swal from 'sweetalert2';
import { setLayout, setPage } from '../../store/layout';
import RoutineCalendar from '../../components/routine/RoutineCalendar';
import { generateCalendar } from '../../utils/calendar/CalendarUtils';
import dayjs from 'dayjs';
import Chips from '../../components/common/Chips';

const RoutineDay: React.FC = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const [routineDetail, setRoutineDetail] = useState<RoutineDetailProps | null>(null);
    const [baseDate, setBaseDate] = useState<Date>(new Date());
    const [calendar, setCalendar] = useState<number[][]>([]);

    const showRestMode = useSelector((state: RootState) => state.routine.showRestMode);
    
    const { id } = useParams();

    useEffect(() => {
        setCalendar(generateCalendar(baseDate.getFullYear(), baseDate.getMonth() + 1));
    }, [baseDate]);

    useEffect(() => {
        // layout 세팅
        dispatch(setLayout('detail'));
        dispatch(setPage('습관 상세보기'));
        const fetchRoutineDetail = async () => {
            try {
                const response = await findRoutine(Number(id));
                setRoutineDetail(response.data);
            } catch (error) {
                console.error('상세 정보를 가져오는 데 실패했습니다.', error);
            }
        };

        fetchRoutineDetail();
    }, [id, dispatch]);

    const daysMap: { [key: string]: string } = {
        monday: '월',
        tuesday: '화',
        wednesday: '수',
        thursday: '목',
        friday: '금',
        saturday: '토',
        sunday: '일',
    };

    const getTrueDaysInKorean = (dayWeek: RepeatedDayWeek): string => {
        const trueDaysInKorean = Object.entries(dayWeek)
            .filter(([day, isTrue]: [string, boolean]) => isTrue)
            .map(([day]: [string, boolean]) => daysMap[day]);

        return trueDaysInKorean.join(', ');
    };

    const repeatDay = routineDetail ? getTrueDaysInKorean(routineDetail.dayWeek) : '';

    const formatDate = (date: Date | string): string => {
        let dateObj: Date;
        if (typeof date === 'string') {
            dateObj = new Date(date);
        } else {
            dateObj = date;
        }

        const year = dateObj.getFullYear();
        const month = dateObj.getMonth() + 1;
        const day = dateObj.getDate();

        return `${year}년 ${month}월 ${day}일에 시작했어요`;
    };

    const formattedStartDate = routineDetail ? formatDate(routineDetail.startDate) : '';

    const handleDeleteRoutine = async () => {
        try {
            await Swal.fire({
                text: '삭제하시겠어요?',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: '확인',
                cancelButtonText: '취소',
                reverseButtons: true
            }).then(async (result) => {
                if (result.isConfirmed) {
                    routineDetail && await deleteRoutine(routineDetail.idx);
                    navigate('/routine');
                }
            });    
        } catch (error) {
            console.error('삭제에 실패하였습니다:', error);
        }
    };

    const handlePrevMonth = () => {
        setBaseDate(new Date(baseDate.getFullYear(), baseDate.getMonth() - 1, 1));
    };

    const handleNextMonth = () => {
        setBaseDate(new Date(baseDate.getFullYear(), baseDate.getMonth() + 1, 1));
    };

    const formatRestDate = (startDate: Date | undefined, endDate: Date | undefined): string => {
        if (!startDate || !endDate) return '';
    
        const formattedStartDate = dayjs(startDate).format('M월 D일');
        const formattedEndDate = dayjs(endDate).format('M월 D일');
    
        return `${formattedStartDate} ~ ${formattedEndDate}`;
    };

    return (
        <>
            <Header handleDeleteRoutine={handleDeleteRoutine} routineDetail={routineDetail} />
            <div className="board-wp grid-20 board">
                {/* 제목 */}
                <div style={{ display: 'flex', alignItems: 'center', marginTop: '5px' }}>
                    {routineDetail?.isRest && (
                        <Chips color="grey">
                            <span style={{ fontSize: '14px' }}> 휴식중 </span>
                        </Chips>
                    )}
                    <span style={{ marginLeft: '5px', fontSize: '14px' }}>
                        {formatRestDate(routineDetail?.restStartDate, routineDetail?.restEndDate)}
                    </span>
                </div>
                <div className="card mb-16 routine-board-tit">
                    <Typography variant="h5">
                        <span>{routineDetail?.emoji} </span>
                        {routineDetail?.name}
                    </Typography>
                    <div>
                        <Typography variant="body2" className="mt-8">
                            {formattedStartDate}
                        </Typography>
                    </div>
                </div>
                
                {/* 습관시작 */}
                <div className="card mb-16">
                    <div className="write-control">
                        <p className="body-01 control-tit">습관 반복요일</p>
                        <button className="txt-input" disabled>
                            {repeatDay}
                        </button>
                    </div>
                </div>

                {/* 알림설정 */}
                <div className="card mb-34">
                    <div className="write-control">
                        <p className="body-01 control-tit">알림 받기</p>
                        <div className="switch-wrapper">
                            <input
                                type="checkbox"
                                id="switch"
                                className="switch-inp"
                                checked={routineDetail?.isRemind}
                                hidden
                                disabled
                            />
                            <label htmlFor="switch" className="switch-label">
                                <span className="onf-btn"></span>
                            </label>
                        </div>
                    </div>
                </div>

                {/* 월간통계 */}
                <Typography variant="h5" className="mb-16">월간 통계</Typography>
                <div className="card routine-total-cal">
                    <div className='routine-total-top'>
                        <button type='button' className='btn-cal-prev' onClick={handlePrevMonth}></button>
                        <Typography variant="h5" className="mb-8">{dayjs(baseDate).format('YYYY년 MM월')}</Typography>
                        <button type='button' className='btn-cal-next' onClick={handleNextMonth}></button>
                    </div>
                    <RoutineCalendar calendar={calendar} idx={id} month={dayjs(baseDate).format('YYYYMM')} />
                </div>

            </div>
            {showRestMode && <RestDateSheet idx={Number(id)} />}
        </>
    );
};

export default RoutineDay;
