import React, { useEffect } from 'react';
import Copyright from "../../components/common/Copyright";

// import auth styles
import './auth.css';
//  import imgages
import Symbol  from "../../assets/images/symbol/symbol.png";
import Title  from "../../assets/images/pages/login/img_login_title.png";
import Cursive  from "../../assets/images/pages/login/img_login_cursive.png";
import SocialLoginButtons from "../../components/auth/SocialLoginButtons";

import AOS from "aos";
import "aos/dist/aos.css";

// 임시 로그인 화면
function Login() {

    useEffect(() => {
        AOS.init();
      },[])

    return (
        <>
            <div className='login-wp'>

                <div className="login-top" data-aos="fade" data-aos-duration="2000">
                    <img className="login-symbol" src={Symbol} alt="app symbol" />
                    <img className="login-title" src={Title} alt="app title" />
                    <img className="login-cursive" src={Cursive} alt="app description" />
                </div>

                <div className="login-bottom" data-aos-delay="500" data-aos="fade-up" data-aos-duration="500">
                    <p className="body-01">소셜아이디로 간편 시작하기 🚀</p>
                    <hr className="hr-dashed mt-34" />

                    {/* 로그인버튼 영역 */}                    
                    <SocialLoginButtons />
                </div>

                <Copyright />
            </div>
        </>
    )
}


export default Login;

