import ReactDOM from 'react-dom/client';
import './index.css';
import Routes from './routes/index';
import reportWebVitals from './reportWebVitals';

import { Provider } from 'react-redux';
import rootReducer from './store';

import './assets/css/main.css';

import { JWTProvider as AuthProvider } from './utils/context/JWTcontext';
import { NotiProvider } from './utils/context/Noticontext';


const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <Provider store={rootReducer}>
    <AuthProvider>
      <NotiProvider>
        <Routes />
      </NotiProvider>
    </AuthProvider>
  </Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
