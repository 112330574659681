import React, { useState, useEffect } from 'react';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import dayjs from 'dayjs';
import './datepicker.css';

interface InpDatePickerProps {
    setDayModal: Function,
    endDate?: Date,
    repeatedDate?:Date | null,
}

const InpDatePicker: React.FC<InpDatePickerProps> = ({setDayModal, endDate, repeatedDate }) => {
    const [selectedDate, setSelectedDate] = useState<Date | null>(null);

    useEffect(() => {
        if (repeatedDate) {
            setSelectedDate(repeatedDate);
        }
    }, []);

    const handleDateChange = (value: Date) => {
        if (selectedDate && dayjs(selectedDate).isSame(value, 'day')) {
            setSelectedDate(null);
            setDayModal(null);
        } else {
            setSelectedDate(value);
            setDayModal(value);
        }
    }

    return (
        <>
            <Calendar className='drawer-calendar' formatDay ={(locale, date) => dayjs(date).format('DD')}
                calendarType="US" // 요일 세팅때문에 넣음
                prevLabel=""
                nextLabel=""
                minDate={endDate}                
                value={selectedDate}
                onClickDay={handleDateChange}
                />
        </>
    );
};

export default InpDatePicker;
