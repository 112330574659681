import {  Calendar, SelectedCalendar } from '../../multiCalendar/types/calendarType';
import { useEffect, useState } from 'react';
import { insertEventErrors, eventType, EventImage } from '../stores/eventType';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { InviteUser } from '../../user/types/user';
import { cancelEventUser, createEventUsers, findEvent } from '../services/eventService';
import { useDispatch } from 'react-redux';
import { setLayout, setPage } from '../../../store/layout';
import { findCalendar } from '../../multiCalendar/services/multiCalendarService';
import Swal from 'sweetalert2';

const initInsertEvent: eventType = {
    calendarId: 0,
    name: '',
    startDate: new Date(0),
    endDate: new Date(0),
    place: '',
    memo: '',
    tag: '',
    label: 'red',
    repeatedType: null,
    repeatedDate: null,
    remindTime: undefined,
   
};

export const useEventHook = (mode: string) => {
    const dispatch = useDispatch();

    const alarmOptions = [
        { label: '10분 전 알림', value: 10 },
        { label: '20분 전 알림', value: 20 },
        { label: '30분 전 알림', value: 30 },
    ];

    const repeatOptions = [
        { label: '반복 안함', value: null },
        { label: '매일 반복', value: 'DAILY' },
        { label: '매주 반복', value: 'WEEKLY' },
        { label: '매월 반복', value: 'MONTHLY' },
        { label: '매년 반복', value: 'YEARLY' },
    ];

    const [values, setValues] = useState<eventType>(initInsertEvent);
    const [openPaletteSheet, setOpenPaletteSheet] = useState<boolean>(false);
    // 캘린더 설정
    const [calendarSheetOpen, setCalendarSheetOpen] = useState<boolean>(false);
    const [selectCalendar, setSelectCalendar] = useState<Calendar>();
    // 파일
    const [eventFiles, setEventFiles] = useState<File[]>([]);
    const [imageList, setImageList] = useState<EventImage[]>([]);
    // 공유하기
    const [isUserSearchSheetActive, setIsUserSearchSheetActive] = useState(false);
    const [eventUsers, setEventUsers] = useState<InviteUser[]>([]);
    const [eventUserList, setEventUserList] = useState<InviteUser[]>([]);
    // 시간 설정
    const [selectedStartDate, setSelectedStartDate] = useState<Date>();
    const [selectedStartTime, setSelectedStartTime] = useState<Date>(new Date());
    const [selectedEndDate, setSelectedEndDate] = useState<Date>();
    const [selectedEndTime, setSelectedEndTime] = useState<Date>(new Date());
    const [startDatePicker, setStartDatePicker] = useState<boolean>(false);
    const [endDatePicker, setEndDatePicker] = useState<boolean>(false);
    // 반복 설정
    const [repeatMenu, setRepeatMenu] = useState<boolean>(false);
    const [repeatLabel, setRepeatLabel] = useState(repeatOptions[0]?.label);
    const [repeatedDate, setRepeatedDate] = useState<Date | null>(null);
    const [repeatEndDatePicker, setRepeatEndDatePicker] = useState<boolean>(false);
    // 알림
    const [alarmEnabled, setAlarmEnabled] = useState(false);
    const [showAlarmMenu, setShowAlarmMenu] = useState(false); 
    const [selectedAlarm, setSelectedAlarm] = useState(alarmOptions[0]);

    const togglePaletteSheet = () => setOpenPaletteSheet(!openPaletteSheet);
    const toggleRepeatMenu = () => setRepeatMenu(!repeatMenu);

    useEffect(() => {
        const initRepeatLabel = repeatOptions.find((option) => option.value === values?.repeatedType)?.label;
        setRepeatLabel(initRepeatLabel ? initRepeatLabel : repeatOptions[0].label);
    }, [values]);
    
    const repeatMenuItems = repeatOptions.map((option) => ({
        ...option,
        onClick: () => handleRepeatOptionClick(option.value),
    }));

    const handleRepeatOptionClick = (value: any) => {
        handleChange('repeatedType', value);
        toggleRepeatMenu();
    };

    const alamMenuItems = alarmOptions.map(option => ({
        label: option.label,
        onClick: () => {
            setSelectedAlarm(option);  
            setValues(prevValues => ({ ...prevValues, remindTime: option.value }));
            setShowAlarmMenu(false);
        }
    }));

    const handleToggleAlarm = () => {
        setAlarmEnabled(!alarmEnabled);
        if (!alarmEnabled) {
            setValues(prevValues => ({ ...prevValues, remindTime: selectedAlarm.value }));
        } else {
            setValues(prevValues => ({ ...prevValues, remindTime: undefined }));
        }
    };

    const handleChange = (field: string, value: any) => {
        setValues((prevEvent) => ({ ...prevEvent, [field]: value }));
    };

    const handleInputChange = (
        event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>
    ) => {
        const { name, value } = event.target;
        handleChange(name, value);
    };

    const handlePaletteColor = (label: string) => {
        if (label && label.trim() !== '') {
            handleChange('label', label);
        }
    };

    // 첨부 파일
    const MAX_FILES = 5;
    const MAX_SIZE_MB = 30;
    const MAX_SIZE_BYTES = MAX_SIZE_MB * 1024 * 1024;
    
    const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {

        const selectedFiles = event.target.files;
        if (selectedFiles) {
            const newEventFiles: File[] = Array.from(selectedFiles);
    
            setEventFiles((prevFiles) => {
                const combinedFiles = [...prevFiles, ...newEventFiles];
                const uniqueFiles = combinedFiles.filter(
                    (file, index, self) => 
                        index === self.findIndex((f) => f.name === file.name)
                );
    
                if (uniqueFiles.length > MAX_FILES) {
                    Swal.fire({
                        text: '첨부파일은 최대 ' + MAX_FILES + '개 까지 첨부 가능해요',
                        confirmButtonText: '돌아가기',
                    });
                    return prevFiles;
                }
    
                const totalSize = uniqueFiles.reduce((acc, file) => acc + file.size, 0);
                if (totalSize > MAX_SIZE_BYTES) {
                    Swal.fire({
                        text: '전체 파일의 크기가 30MB를 초과했어요',
                        confirmButtonText: '돌아가기',
                    });
                    return prevFiles;
                }  
                
                
                return uniqueFiles;
            });
            
        }
    };
    
    const handleDeleteFile = (index: number) => {
        const updatedEventFiles = eventFiles.filter((_, i) => i !== index);
        setEventFiles(updatedEventFiles);
    
        const fileInput = document.getElementById('files') as HTMLInputElement;
        if (fileInput) {
            fileInput.value = '';
        }
    };

    // 해시태그
    const handleTagChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        let inputTag = e.target.value;
    
        if (inputTag.endsWith(' ')) {
            if (inputTag.length > 1 && inputTag[inputTag.length - 2] === '#') {
                inputTag = inputTag.slice(0, -2); 
            } else {
                inputTag = inputTag.trim() + ' #';
            }
        } else if (inputTag.includes('##')) {
            inputTag = inputTag.replace(/##+/g, '#');
        } else if (!inputTag.startsWith('#')) {
            inputTag = '#' + inputTag;
        }
    
        setValues((prevValues) => ({
            ...prevValues,
            tag: inputTag
        }));
    };
    
    const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
        if (e.key === 'Backspace') {
            if (values.tag.length > 0 && values.tag[values.tag.length - 1] === '#') {
                setValues((prevValues) => ({
                    ...prevValues,
                    tag: prevValues.tag.slice(0, -1)
                }));
            }
        }
    };
    
    // 일정 조회
    const fetchEvent = async (idx: string) => {
        if (idx !== '') {            
            const result: eventType = await findEvent(idx);
            setValues(result);

            const calendarResult = await findCalendar(result.calendarId);

            if(mode === 'update') {
                dispatch(setLayout('CalendarDay'));
                dispatch(setPage('일정 수정'));
            } else if(mode === 'board' && calendarResult.userGrade === 'ADMIN' || (calendarResult.grade === 'WRITE' && calendarResult.userGrade === 'USER')) {
                dispatch(setLayout(result.eventGrade === 'ADMIN' ? 'EventDetail' : 'EventDetailUser'));
                dispatch(setPage('일정 상세'));
            } 

            setSelectedStartDate(new Date(result.startDate));
            setSelectedStartTime(new Date(result.startDate));
            setSelectedEndTime(new Date(result.endDate));
            setSelectedEndDate(new Date(result.endDate));
            handleChange('startDate', new Date(result.startDate));
            handleChange('endDate', new Date(result.endDate));

            if(result.userList) {
                setEventUserList(result.userList);
                setEventUsers(result.userList);
            }

            if (result.repeatedDate !== null && result.repeatedDate !== undefined) {
                setRepeatedDate(new Date(result.repeatedDate));
                handleChange('repeatedDate', result.repeatedDate);
            } else {
                setRepeatedDate(null);
            }

            if (result.calendarId && result.calendarName && result.calendarEmoji) {
                const selectedCalendar: Calendar = {
                    idx: result.calendarId,
                    name: result.calendarName,
                    emoji: result.calendarEmoji,
                };
                setSelectCalendar(selectedCalendar);
            } 

            if (result.remindTime !== undefined && result.remindTime !== null) {
                setAlarmEnabled(true);
                setSelectedAlarm({ label: `${result.remindTime}분 전 알림`, value: result.remindTime });
            }

            if (result.imageList)  {
                setImageList(result.imageList);
                const files = convertEventImagesToFiles(result.imageList);
                setEventFiles(files);
            }
        }
    };

    function convertEventImagesToFiles(eventImages: EventImage[]): File[] {
        const files: File[] = [];
    
        eventImages.forEach(image => {
            const blob = new Blob([new ArrayBuffer(image.imageSize)], { type: 'image/jpeg' });
            const file = new File([blob], image.imageOriginName, { type: 'image/jpeg' });
            files.push(file);
        });
    
        return files;
    }
    
    // 회원 초대
    const appendEventUser = (inviteUser: InviteUser) => {
        if (inviteUser.email) {
            setEventUsers((prevOptions) => {
                if (prevOptions.some((user) => user.email === inviteUser.email)) {
                    Swal.fire({
                        text: '이미 선택된 회원이에요',
                        confirmButtonText: '돌아가기',
                    });
                    return prevOptions;
                }
                return [...prevOptions, inviteUser];
            });
        }
    };

    const deleteEventUser = (email: string) => {
        if (email) {
            setEventUsers((prevUsers: InviteUser[]) => prevUsers.filter((user: InviteUser) => user.email !== email));
            
        }
    };

    const closeUserSearchSheet = () => {
        setIsUserSearchSheetActive(false);
    };

    const inviteUsers = async (inx: number) => {
        const newUsers = eventUsers.map((user: InviteUser) => user.email);
        const currentUsers = eventUserList.map((user: InviteUser) => user.email);
        const usersToAdd = newUsers.filter((user: string) => !currentUsers.includes(user));
        const usersToRemove = currentUsers.filter((user: string) => !newUsers.includes(user));

        if (usersToRemove.length > 0) {
            await cancelEventUser(inx, usersToRemove);
        }

        if (usersToAdd.length > 0) {
            await createEventUsers(inx, usersToAdd);
        }
    };

    // 날짜 시간 형식
    const startDateFormatted = new Intl.DateTimeFormat('ko-KR', {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
    }).format(selectedStartTime);

    const endDateFormatted = new Intl.DateTimeFormat('ko-KR', {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
    }).format(selectedEndTime);
    
    const startTimeHour = selectedStartTime.getHours();
    const startTimeMinute = selectedStartTime.getMinutes();
    const endTimeHour = selectedEndTime.getHours();
    const endTimeMinute = selectedEndTime.getMinutes();
    
    const startTimePeriod = startTimeHour >= 12 ? '오후' : '오전';
    const endTimePeriod = endTimeHour >= 12 ? '오후' : '오전';
    
    const startTimeFormattedHour = startTimeHour % 12 || 12;
    const endTimeFormattedHour = endTimeHour % 12 || 12;
    
    const startTimeFormatted = `${startDateFormatted} ${startTimePeriod} ${startTimeFormattedHour}시 ${startTimeMinute < 10 ? '0' + startTimeMinute : startTimeMinute}분`;
    const endTimeFormatted = `${endDateFormatted} ${endTimePeriod} ${endTimeFormattedHour}시 ${endTimeMinute < 10 ? '0' + endTimeMinute : endTimeMinute}분`;
    
    return {
        values,
        setValues,
        handleChange,
        handleInputChange,
        repeatLabel,
        toggleRepeatMenu,
        repeatMenu,
        calendarSheetOpen,
        alarmEnabled,
        showAlarmMenu,
        setShowAlarmMenu,
        selectedAlarm,
        alamMenuItems,
        setCalendarSheetOpen,
        selectCalendar,
        setSelectCalendar,
        togglePaletteSheet,
        openPaletteSheet,
        handlePaletteColor,
        repeatMenuItems,
        startDatePicker,
        endDatePicker,
        setStartDatePicker,
        setEndDatePicker,
        handleTagChange,
        handleKeyDown,
        fetchEvent,
        appendEventUser,
        deleteEventUser,
        eventUsers,
        setIsUserSearchSheetActive,
        isUserSearchSheetActive,
        closeUserSearchSheet,
        inviteUsers,
        setEventUsers,
        repeatEndDatePicker,
        setRepeatEndDatePicker,
        selectedStartDate,
        setSelectedStartDate,
        selectedStartTime,
        setSelectedStartTime,
        selectedEndDate,
        setSelectedEndDate,
        selectedEndTime,
        setSelectedEndTime,
        startTimeFormatted,
        endTimeFormatted,
        repeatedDate, 
        setRepeatedDate,
        setEventUserList,
        handleToggleAlarm,
        imageList,
        eventFiles,
        handleFileChange,
        handleDeleteFile,
        
    };
};
