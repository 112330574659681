import { getData, patchData } from '../../../apis/api';
import { NotificationType } from '../types/notificationType';

export const findAllNotification = async () => {
    try {
        const response = await getData<NotificationType[]>(`/notification/list?page=1&size=10`);
        return response;
    } catch (error) {
        throw new Error('알림 조회에 실패하였습니다.');
    }
};

// 캘린더/이벤트 초대 수락, 거부
export const acceptUser = async (type:NotificationType['type'], idx: number, isAccept: boolean, calendarIdx?: number) => {
    try {
        const requestData = {
            accept: isAccept ? 'ACCEPT' : 'DENIED',
            calendarIdx: type === 'CALENDAR' ? undefined : calendarIdx
        };

        const endpoint = type === 'CALENDAR' ? `calendar/accept/${idx}` : `event/accept/${idx}`;

        const response = await patchData<{ data: string }>(endpoint, requestData);
        return response;
    } catch (error) {
        throw new Error('처리에 실패하였습니다.');
    }
};

export const updateReadNotification = async (idx: number) => {
    try {
        const response = await patchData(`notification/read/${idx}`);
        return response;
    } catch (error) {
        throw new Error('처리에 실패하였습니다.');
    }
};