import Chips from '../common/Chips';
import Typography from '../common/Typography';
import EmojiSheet from '../../components/common/EmojiSheet';
import useRoutineHook from '../../features/routine/hooks/useRoutine.hook';

import './routinecomponent.css';
import Button from '../common/Buttons';
import { SetStateAction, useEffect, useState } from 'react';
import DatePickerSheet from '../../components/routine/InsertDatePickerSheet';
import TimePickerSheet from '../../components/routine/TimePickerSheet';
import dayjs from 'dayjs';
import { useLocation, useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
import {
    RoutineCategoryType,
    RoutineDetailProps,
    UpdatetRoutineProps,
} from '../../features/routine/types/routineInsertType';
import routine from '../../features/routine/stores/routine';
import { insertRoutine, updateRoutine } from '../../features/routine/services/insertRoutineService';
import RoutineCategory from '../../pages/routine/RoutineCategory';
import { attachZero } from '../../utils/calendar/CalendarUtils';

interface RoutineWriteProps {
    type: 'REPETITION' | 'ACHIEVEMENT';
    routineDetail?: RoutineDetailProps;
    editMode?: boolean;
    selectedCategoryName?: string;
}

const RoutineWrite: React.FC<RoutineWriteProps> = ({ type, routineDetail, editMode }) => {
    const navigate = useNavigate();
    const {
        values,
        setValues,
        handleName,
        isEmojiSheetActive,
        setIsEmojiSheetActive,
        selectedEmoji,
        handleEmojiSave,
        toggleEmojiSheet,
        isSwitchOn,
        handleSwitchToggle,
        handleDaySelection,
        getButtonStyle,
        repeated,
        fetchCategories,
        startDate,
        endDate,
        isDatePickerSheetOpen,
        handleDatePickerOpen,
        handleDateSelect,
        setIsDatePickerSheetOpen,
        dateType,
        handleSetTimeModal,
        isTimePickerOpen,
        setIsTimePickerOpen,
        handleRepeatedNumber,
        setRepeated,
        setStartDate,
        setEndDate,
        setIsSwitchOn,
        setUpdateValues,
        updateValues,
        selectedCategory,
        setSelectedCategory,
        handleCategorySelect,
        selectedTime,
        setSelectedTime
    } = useRoutineHook (editMode);

    const [isCategoryListVisible, setIsCategoryListVisible] = useState(false);

    const handleCategoryList = () => {
        setIsCategoryListVisible((prevState) => !prevState);
    };

    useEffect(() => {
        if (!editMode) {
            const today = new Date();
            today.setHours(0, 0, 0, 0);
            const utcDate = new Date(today.getTime() - (today.getTimezoneOffset() * 60000));
            setStartDate(today);
            setValues((prevValues) => ({
                ...prevValues,
                startDate: utcDate,
                type: type,
            }));
        } else if (editMode && routineDetail) {
            const initialValues = {
                name: routineDetail.name,
                emoji: routineDetail.emoji,
                startDate: routineDetail.startDate,
                endDate: routineDetail.endDate,
                isRemind: routineDetail.isRemind,
                categoryId: routineDetail.categoryId,
                repeatedNumber: routineDetail.repeatedNumber,
                repeated: routineDetail.dayWeek,
                remindDate: routineDetail.remindDate
            };

            setSelectedCategory((prevCategory?: RoutineCategoryType) => {
                const updatedCategory: RoutineCategoryType = {
                    name: routineDetail?.categoryName,
                    idx: routineDetail?.categoryId,
                    color: routineDetail?.categoryColor,
                };

                return updatedCategory;
            });

            setUpdateValues(initialValues);
            setRepeated(initialValues.repeated);
            setStartDate(new Date(initialValues.startDate));
            setEndDate(initialValues.endDate ? new Date(initialValues.endDate) : null);
            setIsSwitchOn(initialValues.isRemind);
            fetchCategories();
        }

        setValues((prevValues) => ({
            ...prevValues,
            emoji: type === 'ACHIEVEMENT' ? '🔥' : '😀',
            repeatedNumber: type === 'REPETITION' ? 0 : prevValues.repeatedNumber,
        }));
    }, [type]);

    useEffect(() => {
        handleCategorySelect();
    }, [selectedCategory]);

    const daysMapping: Record<string, string> = {
        monday: '월',
        tuesday: '화',
        wednesday: '수',
        thursday: '목',
        friday: '금',
        saturday: '토',
        sunday: '일',
    };

    const formatDate = (date: string | number | Date | dayjs.Dayjs | null | undefined) => {
        return dayjs(date).format('YYYY년 M월 D일');
    };

    const isFormValid = () => {
        const isTitleValid = (values.name.trim() || updateValues.name.trim()) !== '';
        const isCategoryValid = selectedCategory !== undefined;
        const isStartDateValid = startDate !== null;
        const isDaySelectedValid = Object.values(repeated).some((daySelected) => daySelected);
        const isRepeatedValid = type !== 'ACHIEVEMENT' || values.repeatedNumber > 0;
        const isRemindTimeValid = !isSwitchOn || (isSwitchOn && selectedTime !== null);

        return isTitleValid && isCategoryValid && isStartDateValid && isDaySelectedValid && isRepeatedValid && isRemindTimeValid;
    };

    const handleInsertRoutine = async () => {
        try {
            let combinedDateTime = null;
    
            if (startDate !== null && selectedTime !== null) {
                const date = `${startDate.getFullYear()}-${attachZero(startDate.getMonth() + 1)}-${attachZero(startDate.getDate())}T${attachZero(selectedTime.getHours())}:${attachZero(selectedTime.getMinutes())}:${attachZero(selectedTime.getSeconds())}Z`
                combinedDateTime = new Date(date).toISOString();
            }
    
            if (editMode && routineDetail) {
                const updatedRoutine: UpdatetRoutineProps = {
                    ...updateValues,
                    remindDate: combinedDateTime !== null ? [new Date(combinedDateTime)] : [], 
                };

                const routineIdx = routineDetail.idx;
                await updateRoutine(routineIdx, updatedRoutine);
                navigate(-1);
            } else {
                const routineValues = {
                    ...values,
                    remindDate: combinedDateTime !== null ? [new Date(combinedDateTime)] : [],
                };
                
                await insertRoutine(routineValues, navigate);
                navigate('/routine');
            }
        } catch (error) {
            console.error('루틴 처리에 실패하였습니다', error);
        }
    };

    useEffect(() => {
        if (editMode && routineDetail && routineDetail.remindDate && routineDetail.remindDate.length > 0) {
            const remindDateTime = dayjs(routineDetail.remindDate[0]);
            setSelectedTime(new Date(0, 0, 0, remindDateTime.hour(), remindDateTime.minute()));
        }
    }, [editMode, routineDetail]);


    const currentTime = new Date();
    currentTime.setMinutes(0, 0, 0);

    return (
        <>
            {/* 타이틀 */}
            <div className="title-labeling mb-20">
                <input
                    type="text"
                    name=""
                    id=""
                    value={!editMode ? values.name : updateValues.name}
                    onChange={handleName}
                    placeholder={type === 'ACHIEVEMENT' ? '챌린지 제목을 입력하세요' : '습관 제목을 입력하세요'}
                />
                {/* btn-lebeling: type이 "ACHIEVEMENT"일 때 토글 버튼 대신 🔥 이모지 표시 */}
                {type === 'ACHIEVEMENT' ? (
                    <button className="btn-lebeling">🔥</button>
                ) : (
                    <button className="btn-lebeling" onClick={toggleEmojiSheet}>
                        {!editMode ? selectedEmoji : updateValues.emoji}
                    </button>
                )}
                {isEmojiSheetActive && (
                    <EmojiSheet
                        onSaveEmoji={handleEmojiSave}
                        selectedEmoji={!editMode ? selectedEmoji : updateValues.emoji}
                        closeEmojiSheet={() => setIsEmojiSheetActive(false)}
                    />
                )}
            </div>
            {/* 챌린지일때만 보이게! */}
            {/* <div className='card mb-20 challenge-set' hidden>
                <div className='write-control'>
                    <p className='body-01 control-tit'>챌린지 횟수</p>
                    <button className='btn-edit txt-input'>7회 반복하기</button>
                </div>
            </div> */}
            {type === 'ACHIEVEMENT' && (
                <div className="card mb-20 challenge-set">
                    <div className="write-control">
                        <p className="body-01 control-tit">챌린지 횟수</p>
                        {/* 챌린지 횟수 입력 필드 */}
                        <input
                            type="number"
                            min="1"
                            max="999"
                            value={!editMode ? values.repeatedNumber : updateValues.repeatedNumber}
                            onChange={handleRepeatedNumber}
                            className="txt-input"
                            style={{ border: 'none' }}
                        />
                    </div>
                </div>
            )}
            {/* 요일선택 */}
            <ul className="week-btn-wp mb-20">
                {['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday'].map((day) => (
                    <li key={day}>
                        <Button
                            variant={getButtonStyle(day as keyof typeof repeated)}
                            onClick={() => handleDaySelection(day as keyof typeof repeated)}
                        >
                            {daysMapping[day]}
                        </Button>
                    </li>
                ))}
            </ul>
            {/* 카테고리 선택 */}
            <Typography variant="body2" className="mb-8">
                카테고리 설정
            </Typography>
            <div className="card mb-20">
                <div className="write-control" onClick={handleCategoryList}>
                    {/* 여기에 바뀐 카테고리 이름이 들어가게 해주세요 */}
                    {/* <Typography variant="body2">
                        {selectedCategory ? selectedCategory.name : '카테고리를 선택하세요'}
                    </Typography> */}
                    <Typography variant="body2">
                        {selectedCategory ? (
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                <Chips size="sm" color={selectedCategory.color as | 'red' | 'orange' | 'yellow' | 'green' | 'blue' | 'purple' | 'grey' | undefined}></Chips>
                                <span style={{ marginLeft: '5px' }}>{selectedCategory.name}</span>
                            </div>
                        ) : (
                            '카테고리를 선택하세요'
                        )}
                    </Typography>
                </div>
            </div>
            {/* 습관시작 */}
            <Typography variant="body2" className="mb-8">
                습관 시작 설정
            </Typography>
            <div className="card">
                <div className="write-control">
                    <p className="body-01 control-tit">시작</p>
                    <input
                        type="text"
                        className="inp-date"
                        placeholder="시작 날짜를 선택하세요"
                        readOnly
                        value={startDate ? formatDate(startDate) : ''}
                        onClick={() => handleDatePickerOpen('start')}
                    />
                </div>
                {type === 'REPETITION' && (
                    <>
                        <hr className="hr-dashed mt-20 mb-20" />
                        <div className="write-control">
                            <p className="body-01 control-tit">종료</p>
                            <input
                                type="text"
                                className="inp-date"
                                placeholder="종료 날짜를 선택하세요"
                                readOnly
                                value={endDate ? formatDate(endDate) : ''}
                                onClick={() => handleDatePickerOpen('end')}
                            />
                        </div>
                    </>
                )}
            </div>
            {isDatePickerSheetOpen && (
                <DatePickerSheet
                    setDayModal={setIsDatePickerSheetOpen}
                    currentDate={dateType === 'start' ? startDate : endDate}
                    onDateSelect={handleDateSelect}
                    startDate={dateType === 'start' ? undefined : startDate}
                />
            )}
            {/* 알림설정 */}
            <div className="card mt-20">
                <div className="write-control">
                    <p className="body-01 control-tit">알림 받기</p>
                    <div className="switch-wrapper">
                        <input
                            type="checkbox"
                            id="switch"
                            className="switch-inp"
                            hidden
                            onChange={handleSwitchToggle}
                            checked={isSwitchOn}
                        />
                        <label htmlFor="switch" className="switch-label" >
                            <span className="onf-btn"></span>
                        </label>
                    </div>
                </div>
                {isSwitchOn && (
                    <>
                        <hr className="hr-dashed mt-20 mb-20" />
                        <div className="write-control">
                            <p className="body-01 control-tit">알림 시간</p>
                            <input
                                type="text"
                                readOnly
                                placeholder="알림 시간을 선택하세요"
                                className="inp-time"
                                onClick={() => setIsTimePickerOpen(true)}
                                value={selectedTime ? dayjs(selectedTime).format('h:mm A') : ''}
                                />
                        </div>
                        {isTimePickerOpen && (
                            <TimePickerSheet
                                setTimeModal={handleSetTimeModal}
                                closeTimePicker={() => setIsTimePickerOpen(false)}
                                selectedTime={selectedTime || currentTime}
                            />
                        )}
                    </>
                )}
            </div>
            <div className="fix-bottom-btn">
                <button
                    className="btn btn-lg main btn-bottomfix"
                    onClick={handleInsertRoutine}
                    disabled={!isFormValid()}
                >
                    {!editMode ? '등록하기' : '수정하기'}
                </button>
            </div>
            {isCategoryListVisible && <RoutineCategory setSelectedCategory={setSelectedCategory} />}
        </>
    );
};

export default RoutineWrite;