import React, { useEffect, useState } from 'react';
import CycleSelect from '../event/CycleSelect'
import Button from './../common/Buttons'

interface InpTimePickerProps {
    setTimeModal: (hour: number, minute: number, period: string) => void;
    startDate?: Date
    endDate?: Date
}

const InpTimePicker: React.FC<InpTimePickerProps> = ({ setTimeModal, startDate, endDate }) => {
    const [selectedHour, setSelectedHour] = useState<number>(0);
    const [selectedMinute, setSelectedMinute] = useState<number>(0);
    const [selectedPeriod, setSelectedPeriod] = useState<string>('AM');

    const handleHourSelect = (hour: number) => {
        setSelectedHour(hour);
    };

    const handleMinuteSelect = (minute: number) => {
        setSelectedMinute(minute);
    };

    const handlePeriodSelect = (period: string) => {
        setSelectedPeriod(period);
    };

    const handleSave = () => {
        setTimeModal(selectedHour, selectedMinute, selectedPeriod);
    };

    return (
        <>
            <div style={{ display: 'flex', justifyContent: 'space-between'}}>
                <CycleSelect renderNum={12} onZeroDisplay={false} className="select-hour" onSelect={handleHourSelect} startNum={ endDate ? endDate.getHours() % 12 || 12 : startDate ? startDate.getHours() % 12 || 12 : 1} />
                <CycleSelect renderNum={11} onZeroDisplay={true} className="select-minute" onSelect={handleMinuteSelect} startNum={endDate ? endDate.getMinutes() : startDate ? startDate.getMinutes() : 0}/>
                <CycleSelect texts={ ['AM', 'PM'] } className="select-period" onSelect={handlePeriodSelect}/>
            </div>
            <Button onClick={handleSave} size='lg'>선택하기</Button>
        </>
    );
};

export default InpTimePicker;
