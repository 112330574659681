import { createSlice } from "@reduxjs/toolkit"

const initialState = {
    selectedDay: '',
    calendarSelect: false,
    dateManuallySelected: false,
    layout: 'main',
    page: '',
    showRestMode: false,
    categoryId: 0,
};

const routine = createSlice({
    name: 'routine',
    initialState,
    reducers: {
        setSelectedDay(state, action) {
            state.selectedDay = action.payload;
        },
        setCalendarSelect(state, action) {
            state.calendarSelect = action.payload;
        },
        setDateManuallySelected(state, action) {
            state.dateManuallySelected = action.payload;
        },
        setShowRestMode(state, action) {
            state.showRestMode = action.payload;
        },
        setCategoryId(state, action) {
            state.categoryId = action.payload;
        },
    },
});

export default routine.reducer;
export const { setSelectedDay, setCalendarSelect, setDateManuallySelected, setShowRestMode, setCategoryId } = routine.actions;