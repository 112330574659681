import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { updateNotificationList } from '../stores/reducer';
import { acceptUser, findAllNotification, updateReadNotification } from '../services/notificationService';
import { RootState } from '../../../store';
import { NotificationType } from '../types/notificationType';


const useNotificationHook = (initialIsRead?: boolean) => {
    const dispatch = useDispatch();

    const [isRead, setIsRead] = useState(initialIsRead);
    const [selectedCalendarIdx, setSelectedCalendarIdx] = useState<number | null>(null);
    const [isSelectCalendarSheetOpen, setIsSelectCalendarSheetOpen] = useState(false);
    
    const notificationList = useSelector((state: RootState) => state.notification.notificationList);

    const handleCardClick = async (idx: number) => {
        try {
            await updateReadNotification(idx);
            setIsRead(true);
        } catch (error) {
            console.error('변경에 실패했습니다.:', error);
        }
    };

    const isNotificationRead = (idx: number) => {
        const notification = notificationList.find(n => n.idx === idx);
        return notification ? notification.isRead : false;
    };

    const getToday = () => {
        const today = new Date();
        const year = today.getFullYear();
        const month = String(today.getMonth() + 1).padStart(2, '0');
        const day = String(today.getDate()).padStart(2, '0');
        return `${year}-${month}-${day}`;
    };

    const todayNotifications = notificationList.filter((notif: { date: string }) => {
        const datePart = notif.date.split(' ')[0];
        return datePart === getToday();
    });

    const pastNotifications = notificationList.filter((notif: { date: string }) => {
        const datePart = notif.date.split(' ')[0];
        return datePart !== getToday();
    });

    const formatNotificationDate = (notificationDate: string): string => {
        const notificationDateTime = new Date(notificationDate);
        const now = new Date();

        const diffInMilliseconds = now.getTime() - notificationDateTime.getTime();
        const diffInSeconds = Math.floor(diffInMilliseconds / 1000);
        const diffInMinutes = Math.floor(diffInSeconds / 60);
        const diffInHours = Math.floor(diffInMinutes / 60);
        const notificationDateOnly = new Date(
            notificationDateTime.getFullYear(),
            notificationDateTime.getMonth(),
            notificationDateTime.getDate()
        );

        if (diffInMinutes < 1) {
            return '방금 전';
        } else if (diffInMinutes < 60) {
            return `${diffInMinutes}분 전`;
        } else if (diffInHours < 24) {
            const today = new Date(now.getFullYear(), now.getMonth(), now.getDate());
            const yesterday = new Date(today);
            yesterday.setDate(today.getDate() - 1);

            if (notificationDateOnly.getTime() === yesterday.getTime()) {
                return '어제';
            } else {
                return `${diffInHours}시간 전`;
            }
        }

        const today = new Date(now.getFullYear(), now.getMonth(), now.getDate());
        const yesterday = new Date(today);
        yesterday.setDate(today.getDate() - 1);

        if (notificationDateOnly.getTime() === yesterday.getTime()) {
            return '어제';
        }

        const diffInDays = Math.floor((today.getTime() - notificationDateOnly.getTime()) / (1000 * 3600 * 24));
        return `${diffInDays}일 전`;
    };

    const handleAcceptReject = async (
        type: NotificationType['type'],
        relateId: number,
        isAccept: boolean,
        calendarIdx?: number
    ) => {
        try {
            await acceptUser(type, relateId, isAccept, calendarIdx);
            const updatedNotifications = await findAllNotification();
            dispatch(updateNotificationList(updatedNotifications.data));
        } catch (error) {
            console.error(`${isAccept ? '수락' : '거절'} 처리에 실패했습니다:`, error);
        }
    };

    const handleCalendarSelect = (calendarIdx: number) => {
        setSelectedCalendarIdx((prevIdx) => (prevIdx === calendarIdx ? null : calendarIdx));
    };

    return {
        todayNotifications,
        pastNotifications,
        handleAcceptReject,
        formatNotificationDate,
        handleCardClick,
        isRead,
        isNotificationRead,
        isSelectCalendarSheetOpen,
        setIsSelectCalendarSheetOpen,
        handleCalendarSelect,
        selectedCalendarIdx,
        setSelectedCalendarIdx,
        notificationList
    };
};

export default useNotificationHook;