import React, { useEffect, useState } from 'react';
// import header styles
import '../default/header.css';
import { useNavigate } from 'react-router';
import DropMenu from '../../components/common/DropMenu';
import { useSelector } from 'react-redux';
import { RootState } from '../../store';

type HeaderProps = {
    handleDeleteCalendar?: () => void;
    handleEditMode?: () => void;
    handleFilterButtonClick?: () => void; 
};

const Header: React.FC<HeaderProps> = ({ handleDeleteCalendar, handleEditMode, handleFilterButtonClick }) => {
    const navigate = useNavigate();
    const [ showEditDropMenu, setShowEditDropMenu ] = useState(false);

    const layout = useSelector((state:RootState) => state.layout.layout);
    const page = useSelector((state:RootState) => state.layout.page);

    const editMenuItems = [
        { label: '수정하기', onClick: () => {
            setShowEditDropMenu(false);
            handleEditMode?.(); 
        }},
        { label: '삭제하기', onClick: () => {
            setShowEditDropMenu(false);
            handleDeleteCalendar?.();
        }},
    ];

    return (
        <>
            {/* 
                scroll이 발생하면 header에 add class "scrolling"
             */}
            <header>
                <div className="nav-bar">
                    <ul className="nav-left">
                        {
                            <>
                                {/* 
                                    히스토리백 버튼
                                */}
                                <li className="left-back">
                                    {layout === 'calendarSelectEdit' ? (
                                        <button className="nav-icon btn-back" onClick={handleFilterButtonClick}></button>
                                    ) : layout === 'calendarSelect' ? (
                                        <button className="nav-icon btn-back" onClick={() => navigate("/calendar")}></button>
                                    ) : (
                                        <button className="nav-icon btn-back" onClick={() => navigate(-1)}></button>
                                    )}
                                </li>
                                <li className="title-center">
                                    <div className="nav-dropbox">
                                        <h3 className="subtit-02">{page}</h3>
                                    </div>
                                </li>
                            </>
                        }
                    </ul>
                    <ul className="nav-right">
                        {
                            layout === 'multiCalendarDetail' &&
                            <>
                                <li><button className="nav-icon btn-edit" onClick={() => setShowEditDropMenu(!showEditDropMenu)}></button></li>
                            </>
                        }
                        {
                            layout === 'calendarSelect' &&
                            <>
                                <li>
                                    <button className="nav-icon btn-filter" onClick={handleFilterButtonClick}></button>
                                </li>                            
                            </>
                        }
                    </ul>
                </div>

                {showEditDropMenu && <DropMenu items={editMenuItems} background={true} style={{ position: 'fixed'}} showDropMenu={setShowEditDropMenu}/>}

            </header>
        </>
    );
};

export default Header;
