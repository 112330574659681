import { useCallback, useState } from "react";
import { WeekDatas } from "../types/routineWeekType";
import { createWeekDates } from "../services/weeklyRoutineService";
import { RootState } from "../../../store";
import { updateCompletedRoutine } from "../services/dailyRoutineService";

export const useWeeklyRoutine = ()=>{
    const [ weekDataList, setWeekDataList ] = useState<WeekDatas[]>([]);
    const [contentHeightBefore, setContentHeightBefore] = useState(0);
    const [isLoading, setIsLoading] = useState(false);

    let touchStartY = 0;
    let touchEndY = 0;

    // 하단 데이터 추가
    const appendWeekData = async () => {
        if (weekDataList.length === 0 || isLoading) return;
        setIsLoading(true);  // 데이터 로딩 시작을 표시
    
        let lastEndDate = new Date(weekDataList[weekDataList.length - 1].endOfWeek);
        let promises: Promise<WeekDatas>[] = [];
    
        // 다음 5주의 데이터를 가져오기 위해 lastEndDate를 계속 업데이트
        for (let i = 1; i <= 5; i++) {
            let newStartDate = new Date(lastEndDate.getFullYear(), lastEndDate.getMonth(), lastEndDate.getDate() + 1);

            // 루틴이 없는 경우 출력 x
            const newData = createWeekDates(newStartDate);
            if((await newData).weeklyRoutine.length > 0) promises.push(createWeekDates(newStartDate));
    
            // 다음 주의 시작일을 설정하기 위해 7일을 추가
            lastEndDate = new Date(newStartDate.getTime());
            lastEndDate.setDate(lastEndDate.getDate() + 7);
        }
    
        const newWeeks = await Promise.all(promises);
        setWeekDataList(prevList => [...prevList, ...newWeeks]);  // 상태 업데이트
        setIsLoading(false);  // 데이터 로딩 종료
    };

    // 상단 데이터 추가
    const prependWeekData = useCallback(async () => {
        if (weekDataList.length === 0 || isLoading) return;
        setIsLoading(true);
    
        let firstStartDate = new Date(weekDataList[0].startOfWeek);
        let promises: Promise<WeekDatas>[] = [];
    
        for (let i = 1; i <= 5; i++) {
            let newEndDate = new Date(firstStartDate.getFullYear(), firstStartDate.getMonth(), firstStartDate.getDate() - 7 * i);
            const newData = createWeekDates(newEndDate,true);
            
            // 루틴이 없는 경우 출력 x
            if((await newData).weeklyRoutine.length > 0) {
                promises.push(newData);
            } else {
                break;
            }
        }

        const newWeeks = await Promise.all(promises.reverse());
        setWeekDataList(prevList => [...newWeeks, ...prevList]);
        setIsLoading(false);
    }, [weekDataList, isLoading]);

    // touchstart 이벤트
    const handleTouchStart = useCallback((e: TouchEvent) => {
        touchStartY = e.touches[0].clientY;
    }, []);

    // touchend
    const handleTouchEnd = useCallback((e: TouchEvent) => {
        touchEndY = e.changedTouches[0].clientY;

        if (!isLoading) {
            if (window.innerHeight + window.scrollY >= document.body.offsetHeight - 100 && touchStartY > touchEndY) {
                // 페이지 하단에서 위로 드래그: 하단에 데이터 추가
                appendWeekData();
            } else if (window.scrollY < 100 && touchStartY < touchEndY) {
                // 페이지 상단에서 아래로 드래그: 상단에 데이터 추가
                prependWeekData();
            }
        }
    }, [appendWeekData, prependWeekData,isLoading]);

    const handleScroll = useCallback(async () => {
        if (!isLoading && window.scrollY + window.innerHeight >= document.body.offsetHeight - 100) {
            // 페이지 하단에 도달: 하단에 데이터 추가
            await appendWeekData();
        }
    }, [appendWeekData, isLoading]);

    // 루틴 달성 이벤트
    const routineCompleteChange = (day:string, id:number, idx:number) => {
        setWeekDataList(current => {
            const updatedList = current.map((weekData, weekIndex) => {
                if (weekIndex === idx) {
                    const updatedWeeklyRoutine = weekData.weeklyRoutine.map(routine => {
                        if (routine.id === id) {

                        const updatedDailyList = routine.dailyList.map(daily => 
                            daily.dayOfWeek === day ? { ...daily, isCompleted: !daily.isCompleted } : daily
                        );

                        const completionRate = Math.floor(updatedDailyList.filter(daily => daily.isCompleted).length / updatedDailyList.length * 100);

                        return { ...routine, dailyList: updatedDailyList, rate: completionRate };
                        }
                        return routine;
                    });

                    // 변경된 루틴의 완료 날짜 계산
                    const dayIndex = ["sunday", "monday", "tuesday", "wednesday", "thursday", "friday", "saturday"].indexOf(day);
                    const completedDate = new Date(Date.UTC(weekData.startOfWeek.getFullYear(), weekData.startOfWeek.getMonth(), weekData.startOfWeek.getDate() + dayIndex));

                    // DB에 변경 사항 저장
                    updateCompletedRoutine({ id, completedDate });

                    return { ...weekData, weeklyRoutine: updatedWeeklyRoutine };
                }
                return weekData;
            });
            return updatedList;
        });
        
        // DB 저장해야함!!
        // const param:UpdateCompletedRoutineProps = {id,completedDate:date}
        // updateCompletedRoutine(param);
    }


    return {weekDataList,setWeekDataList,contentHeightBefore,setContentHeightBefore,touchEndY,touchStartY,appendWeekData,prependWeekData,handleTouchStart,handleTouchEnd,routineCompleteChange, handleScroll};
}

