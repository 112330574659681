import Swal from "sweetalert2";
import { deleteData, patchData, postData } from "../../../apis/api";
import { insertDefaultCalendar, insertUserDetailType, updateUserDetailType } from "../types/userDetailType";

export const validateProfile = (file: File | null): boolean => {
    if (!file || !file.type.startsWith('image/') || file.size > 5242880) {
      // 파일이 없거나 이미지가 아니거나 파일 크기가 5MB를 초과하는 경우
      return true;
    }
    return false;
};

export const validateNickname = (nickname: string): boolean => {
    return !(nickname.length >= 2 && nickname.length <= 8);
};

export const userDeatailSave = async (value: insertUserDetailType) : Promise<boolean> =>  {

    try {

        const formData = new FormData();
        formData.append('age',value.age.toString());
        formData.append('gender',value.gender);
        formData.append('nickName',value.nickName);

        if(value.profile !== null) {
            formData.append('profile',value.profile);
        }

        const response = await postData<insertUserDetailType>('/user/insert',formData,{headers: {'Content-Type': 'multipart/form-data'}});
        
        if (response.status === 201) {
            const defaultCalendar = await postData<insertDefaultCalendar>('/calendar/insert', {
                isDefault: true,
                grade: "WRITE"
            });
            
            return defaultCalendar.status === 201;
        }
    } catch (error) {
        console.log(error);
        // 에러 발생 시 알려주는 로직...
    }

    return false;
}

// 회원 탈퇴
export const userWithdrawal = async (callback: Function) => {
    try {
        const result = await Swal.fire({
            text: '정말 하루달을 탈퇴하실건가요?',
            showCancelButton: true,
            confirmButtonText: '떠날래요',
            cancelButtonText: '더 써볼래요',
            reverseButtons: true,
        });
        
        if (result.isConfirmed) {
            const response = await deleteData('user/withdraw');
            if (response.status === 200) {
                callback(true);
            } else if (response.status === 409) {
                Swal.fire({
                    text: '캘린더에 초대된 회원이 있을 시 탈퇴가 불가능해요',
                    showCancelButton: false,
                    confirmButtonText: '돌아가기',
                });
                callback(false);
            }
        } else {
            callback(false);
        }
    } catch (error) {
        console.error('탈퇴에 실패하였습니다:', error);
        callback(false);
    }
};

// 회원정보 수정
export const updateUserDetail = async (values: updateUserDetailType) : Promise<boolean> =>  {
    try {
        const formData = new FormData();
        formData.append('age',values.age.toString());
        formData.append('gender',values.gender);
        formData.append('nickName',values.nickname);
        if(values.profile !== null) {
            formData.append('profile',values.profile);
        }
        const response = await patchData<updateUserDetailType>('/user/update',formData,{headers: {'Content-Type': 'multipart/form-data'}});
    } catch (error) {
        console.log(error);
    }

    return false;
}
