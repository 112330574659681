
import { useNavigate } from 'react-router-dom';
import Button from '../../components/common/Buttons';
import Typography from '../../components/common/Typography';
import { deleteRestMode } from '../../features/routine/services/insertRoutineService';
import { RoutineRestProps } from '../../features/routine/types/routineRestType';

import './routinecomponent.css';

interface RoutineRestingProps {
    restRoutine: RoutineRestProps
    onRestRoutineRemoved: () => void;
}

const RoutineResting: React.FC<RoutineRestingProps> = ({
    restRoutine,
    onRestRoutineRemoved
}) => {
    
    const navigate = useNavigate();

    const handleRestart = async () => {
        try {
            await deleteRestMode(restRoutine.idx);
            onRestRoutineRemoved();
        } catch (error) {
            console.error('휴식 모드 해제에 실패했습니다:', error);
        }
    };

    return (
        <>
            <li className="card mb-16">
                <div className="list-left"  onClick={() => navigate(`/routineday/${restRoutine.idx}`, {state: {restMode: true}})}>
                    <Typography variant='h5'>{restRoutine.emoji}</Typography>
                    <Typography variant='h5' className='ml-8'>{restRoutine.name}</Typography>
                </div>
                <div className="list-right">
                    <Button variant='text' color='success' size='sm' onClick={handleRestart}>다시 시작하기</Button>
                </div>
            </li>
        </>
    )
}

export default RoutineResting;