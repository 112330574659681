import Typography from '../../components/common/Typography';
import './aicomponents.css';

function ChatWaiting() {

    return (
        <>
            <div className='chat-message waiting mb-20'>
                <div className='bounce-haru'></div>
                <Typography variant='body2' className='mt-4'>작성중입니다.</Typography>
            </div>
        </>
    )
}

export default ChatWaiting;