import React, { useState, useEffect, useMemo } from 'react';
import './scroll.css';
import _ from 'lodash';

interface InpTimePickerProp {
    className?: string;
    renderNum?: number;
    texts?: Array<string>; // text prop 추가
    onZeroDisplay?: boolean;
    onSelect: Function
    startNum?: number;
}

const CycleSelect: React.FC<InpTimePickerProp> = ({ className, renderNum = 0, texts, onZeroDisplay = false, onSelect, startNum = -1 }) => {
    const [currentText, setCurrentText] = useState('AM');
    const [renderStartNum, setRenderStartNum] = useState(startNum == -1 ? 0 : className === 'select-minute' ? (startNum / 5) - 2 : startNum - 2); // 초기값 변경
    const handleEndNumScroll = useMemo(() => _.debounce(() => handleUiSetting(), 100),[]);
    const handleEndTextScroll = useMemo(() => _.debounce(() => handleUiSetting(), 25),[]);

    const handleScroll = (e: React.UIEvent<HTMLDivElement>) => {
        const { scrollTop, clientHeight } = e.currentTarget;
        const scrollMiddle = scrollTop + clientHeight / 2;
        
        if (renderNum) { // renderNum이 정의되었는지 확인
            handleEndNumScroll();
            let newHour = Math.floor(scrollMiddle / 50) + renderStartNum - 2;

            setRenderStartNum(prevNum => {
                let newNum = newHour;
                if (newNum <= 0) {
                    newNum += renderNum + 1;
                } else if (newNum > renderNum) {
                    newNum -= renderNum + 1;
                }

                if (prevNum !== newNum) {
                    return newNum;
                } else {
                    return prevNum;
                }
            });
        }

        if (texts) {
            handleEndTextScroll();
            // 스크롤 위치에 따라 렌더링할 텍스트의 인덱스 계산
            const textIndex = Math.floor(scrollTop / 25); // 예시로 100 픽셀마다 하나의 텍스트를 렌더링한다고 가정
            // 해당 인덱스에 있는 텍스트 렌더링
            const textToRender = texts[textIndex];
            setCurrentText(prevText => {
                if (textToRender !== '' && textToRender !== undefined) {
                    return textToRender;
                } else {
                    return prevText;
                }
            });
        }

    };

    const renderContent = () => {
        if (texts) {
            return renderText();
        } else {
            return renderNums();
        }
    };

    const renderText = () => {
        if (texts) {
            return texts.map((text) => (
                <div key={`text-${text}`} style={textItemStyle}>
                    <span>{text}</span>
                </div>
            ));
        }
    };

    const renderNums = () => {
        const nums = [];
        if (renderNum) {
            for (let i = 0; i < 5; i++) {
                let num = renderStartNum + i;
                if (num < 0) {
                    num += onZeroDisplay ? renderNum + 1 : renderNum;
                } else if (num > renderNum) {
                    num -= onZeroDisplay ? renderNum + 1 : renderNum;
                }
                if (className === 'select-minute') {
                    nums.push(num * 5);
                } else {
                    nums.push(num);
                }
            }

            let displayCur = 0;
            if (className === 'select-minute') {
                displayCur = renderStartNum + 2 > renderNum ?
                    (renderStartNum + 1 - renderNum) * 5
                    :
                    (renderStartNum + 2) * 5
            } else {
                displayCur = renderStartNum + 2 > renderNum ?
                    renderStartNum + 2 - renderNum
                    :
                    renderStartNum + 2
            }
            return nums.map((num) => (
                <div key={`hour-${num}`} style={num === displayCur ? { ...hourItemStyle,
                    color: 'var(--semantic-info)',
                    backgroundColor: 'var(--background-01)',
                    borderRadius: 'var(--radius-large)',
                    // border: '2px solid var(--stroke-01)',
                    boxSizing: 'border-box' } : hourItemStyle}>
                    <span>{num < 10 ? `0${num}` : num}</span>
                </div>
            ));
        }
    };

    const handleUiSetting = () => {
        if (className) {
            const container = document.querySelector(`.${className}`);
            if (className === 'select-period') {
                if (container) {

                    const children = Array.from(container.childNodes).filter(node => node.nodeType === Node.ELEMENT_NODE);
                    const containerRect = container.getBoundingClientRect();
                    const containerTop = containerRect.top;

                    const childTop = (children[0] as HTMLElement).getBoundingClientRect().top + 25;

                    if (childTop > containerTop) {
                        container.scrollTo({ top: 0 });
                    } else {
                        container.scrollTo({ top: containerTop });
                    }
                } 
            } else {
                if (container && container.scrollHeight > 200) {
                    const middleScroll = container.scrollHeight / 2 - container.clientHeight / 2;
                    container.scrollTo({ top: middleScroll });
                }
            }
        }
    }

    useEffect(() => {
        // 컴포넌트가 처음 렌더링될 때 스크롤 위치를 조정합니다.
        handleUiSetting();
    }, [className]);

    useEffect(() => {
        onSelect(currentText);
    }, [currentText]);

    useEffect(() => {
        // 선택된 숫자 저장
        
        let displayCur = 0;

        if (className === 'select-minute') {
            displayCur = renderStartNum + 2 > renderNum ?
                (renderStartNum + 1 - renderNum) * 5
                :
                (renderStartNum + 2) * 5
        } else {
            displayCur = renderStartNum + 2 > renderNum ?
                renderStartNum + 2 - renderNum
                :
                renderStartNum + 2
        }
        if (className === 'select-hour') {
            onSelect(displayCur);
        } else if(className === 'select-minute') {
            onSelect(displayCur);
        }

    }, [renderStartNum]);

    return (
        <div style={containerStyle}>
            <div style={texts ? textContainerStyle : hourContainerStyle} className={`scrollcss ${className}`} onScroll={handleScroll}>
                {renderContent()}
            </div>
        </div>
    );
}

const containerStyle: React.CSSProperties = {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginBottom: '20px',
};

const textContainerStyle: React.CSSProperties = {
    width: '100px',
    height: '45px',
    overflowY: 'scroll',
    marginTop: '50px',
    backgroundColor: 'var(--background-01)',
    color: 'var(--semantic-info)',
    borderRadius: 'var(--radius-large)',
    // border: '2px solid var(--stroke-01)',
    boxSizing: 'border-box',
    overflowX: 'hidden',
};

const textItemStyle: React.CSSProperties = {
    width: '100px',
    height: '45px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: '1.5rem',
};

const hourContainerStyle: React.CSSProperties = {
    width: '80px',
    height: '150px',
    overflowY: 'scroll',
    overflowX: 'hidden',
};

const hourItemStyle: React.CSSProperties = {
    width: '80px',
    height: '45px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: '1.5rem',
};

export default CycleSelect;
