
import Button from '../common/Buttons';
import Typography from '../common/Typography';
import IcRight  from "../../assets/images/icon/system/ic_expand_right.svg";
import IcSymbol  from "../../assets/images/symbol/symbol.png";
import './notificationcomponent.css';
import { NotificationType } from '../../features/notification/types/notificationType';
import useNotificationHook from '../../features/notification/hooks/useNotification.hook';
import { useNavigate } from 'react-router-dom';

function AiNotiCard(props: NotificationType) {
    const navigate = useNavigate();

    const { formatNotificationDate, handleCardClick, isNotificationRead } = useNotificationHook();
    
    const isRead = isNotificationRead(props.idx);

    return (
        <>
            <div className={`card noti-card ${isRead ? 'noti-read' : ''}`}>
                <div className='noti-card-top mb-8'>
                    <Typography variant='body2' className='noti-tit'><img src={IcSymbol} alt="symbol" className='ic-symbol mr-8' /> 하루달 AI</Typography>
                    <Typography variant='body2' className='noti-date'>
                        {formatNotificationDate(props.date)}
                    </Typography>                
                </div>
                <Typography variant='h5' className='mb-8'>하루달 AI가 답장을 보냈어요, 지금 바로 확인하세요!</Typography>
                <div className='noti-card-bottom'>
                    <Button variant='text' size='sm' onClick={() => {navigate('/ai'); handleCardClick(props.idx);}}>
                        답변 확인하기
                        <img src={IcRight} alt="icon" />
                    </Button>
                </div>
            </div>
        </>
    )
}

export default AiNotiCard;