import React, { useEffect } from 'react';
import logo from './logo.svg';
import './App.css';
import useAuth from './hooks/useAuth';
import { useNavigate } from 'react-router';

function App() {
  const { isLoggedIn } = useAuth();
  const navigate = useNavigate();

  useEffect(()=> {
    if(isLoggedIn){
      navigate('/calendar');
    } else {
      navigate('/onboarding');
    }
  })

  return (
    <>
    
    </>
  );
}

export default App;
