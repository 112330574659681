import { BrowserRouter, Route, Routes } from 'react-router-dom';
import LayoutComponent from './LayoutComponents';
import PrivateRoute from './PrivateRoute';
import ProtectRoute from './ProtectRoute';
import { routes, noAuthRoutes, authRoutes, protectRoutes } from './Routes';
import Error404 from '../pages/Error404';
import ScrollToTop from './ScrolltoTop';

const AppRouter = () => {
    return (
        <BrowserRouter>
            <ScrollToTop />
            <Routes>
                {/* 인증 여부 상관 없이 접속 가능한 페이지 정의 */}
                {Object.keys(routes).map((routeKey: string) => (
                    Object.keys(LayoutComponent).map((layoutKey: string) => {
                        const layout = LayoutComponent[layoutKey];
                        const routeLayout = routes[routeKey].layout;
                        if (layoutKey === routeLayout) {
                            return (
                                <Route
                                    key={routeKey}
                                    path={routes[routeKey].path}
                                    element={layout(routes[routeKey].element)}
                                />
                            );
                        }
                    })
                ))}

                {/* 인증을 반드시 하지 않아야만 접속 가능한 페이지 정의 */}
                <Route element={<PrivateRoute authentication={false} />}>
                    <Route>
                        {Object.keys(noAuthRoutes).map((routeKey: string) => (
                            <Route
                                key={routeKey}
                                path={noAuthRoutes[routeKey].path}
                                element={noAuthRoutes[routeKey].element}
                            />
                        ))}
                    </Route>
                </Route>

                {/* 인증을 반드시 해야지만 접속 가능한 페이지 정의 */}
                <Route element={<PrivateRoute authentication={true} />}>
                    {Object.keys(authRoutes).map((routeKey: string) => (
                        Object.keys(LayoutComponent).map((layoutKey: string) => {
                            const layout = LayoutComponent[layoutKey];
                            const routeLayout = authRoutes[routeKey].layout;

                            if (layoutKey === routeLayout) {
                                return (
                                    <Route
                                        key={routeKey}
                                        path={authRoutes[routeKey].path}
                                        element={layout(authRoutes[routeKey].element)}
                                    />
                                );
                            }
                        })
                    ))}
                    {/* 권한 체크가 필요한 페이지 정의 */}
                    {/* ProtectRoute는 반드시 로그인한 사용자의 한해서만 되도록 구현되어 PrivateRoute안에 종속되어야한다. */}
                    <Route element={<ProtectRoute />}>
                        {Object.keys(protectRoutes).map((routeKey: string) => (
                            Object.keys(LayoutComponent).map((layoutKey: string) => {
                                const layout = LayoutComponent[layoutKey];
                                const routeLayout = protectRoutes[routeKey].layout;

                                if (layoutKey === routeLayout) {
                                    return (
                                        <Route
                                            key={routeKey}
                                            path={protectRoutes[routeKey].path}
                                            element={layout(protectRoutes[routeKey].element)}
                                        />
                                    );
                                }
                            })
                        ))}

                    </Route>
                </Route>

                {/* 인증/권한 여부와 상관 없이 접근 가능한 Error 페이지 정의 */}
                <Route path='/*' element={<Error404/>}/>
            </Routes>
        </BrowserRouter>
    );
}

export default AppRouter;