
import { useSelector } from 'react-redux';
import Typography from '../../components/common/Typography';
import RoutineCard from '../../components/routine/RoutineCard';
import './routine.css';
import { RootState } from '../../store';
import { WeekDatas } from '../../features/routine/types/routineWeekType';
import { useEffect } from 'react';
import { useWeeklyRoutine } from '../../features/routine/hooks/useWeeklyRoutine.hook';
import { createWeekDates, formatDateRange } from '../../features/routine/services/weeklyRoutineService';
import { useDispatch } from 'react-redux';
import { setLayout } from '../../store/layout';


const RoutineWeekly = () => {
    
    const {weekDataList,setWeekDataList,contentHeightBefore,handleTouchStart,handleTouchEnd,routineCompleteChange,handleScroll} = useWeeklyRoutine();

    const selectedDay = useSelector((state:RootState) => state.routine.selectedDay);
    const dispatch = useDispatch();

    useEffect(() => {
        // layout 세팅
        dispatch(setLayout('main'));
    },[])

    // selecDay 변경 시 동작하는 이벤트(현재는 최초 경우에만 동작함)
    useEffect(() => {
        
        if(isNaN(new Date(selectedDay).getTime())) {
            return;
        }

        // 비동기 함수를 별도로 정의
        const fetchWeekData = async () => {
            const newWeekDataList = [];
            const today = new Date();
            let todayWeekIndex = 0;

            let loopIndex = 0;

            for (let i = -2; i <= 2 + loopIndex; i++) {
                const baseDate = new Date(selectedDay);
                baseDate.setDate(baseDate.getDate() + i * 7);

                // createWeekDates 함수 호출
                const weekData = await createWeekDates(baseDate, i == -2);
                if (weekData.weeklyRoutine.length > 0) {
                    // 오늘이 이 주에 포함되어 있는지 확인
                    if (today >= new Date(weekData.startOfWeek) && today <= new Date(weekData.endOfWeek)) {
                        todayWeekIndex = i + 2; // 실제 인덱스 조정
                    }
                    newWeekDataList.push(weekData);
                } else {
                    loopIndex++;
                }
            }

            setWeekDataList(newWeekDataList);

            // 최초 스크롤 이동(selectedDay 기준)
            setTimeout(() => {
                const navbarHeight = document.querySelector('.nav-bar')?.clientHeight || 0;
                const todayWeekElement = document.getElementById(`week-${todayWeekIndex}`);
                if (todayWeekElement) {
                    const topPosition = todayWeekElement.getBoundingClientRect().top + window.pageYOffset - navbarHeight;
                    window.scrollTo({ top: topPosition, behavior: 'smooth' });
                }
            }, 0);
        };

        // 비동기 함수 실행
        fetchWeekData();
    },[selectedDay])
    useEffect(() => {
        console.log(weekDataList);
    }, [weekDataList])

    // 위로 페이징 될 때 스크롤 조정하는 이벤트
    useEffect(() => {
        const adjustScroll = () => {
            const contentHeightAfter = document.documentElement.scrollHeight;
            const scrollOffset = contentHeightAfter - contentHeightBefore;
            // 스크롤 위치를 조정하여 콘텐츠 추가 전 위치에 유지
            window.scrollTo(0, window.scrollY + scrollOffset);
        };

        if (contentHeightBefore) {
            adjustScroll();
        }
    },[contentHeightBefore])
    
    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        window.addEventListener('touchstart', handleTouchStart);
        window.addEventListener('touchend', handleTouchEnd);

        // 컴포넌트 언마운트 시 이벤트 리스너 제거
        return () => {
            window.removeEventListener('scroll', handleScroll);
            window.removeEventListener('touchstart', handleTouchStart);
            window.removeEventListener('touchend', handleTouchEnd);
        };
    }, [handleTouchStart, handleTouchEnd]);

    return (
        <>
            <div className="routine-wp">
                {/* 루틴 메인 주표시 */}
                {
                    weekDataList.length > 0 ?
                    weekDataList.map((weekData, index) => (
                    <div className="grid-16" key={index} id={`week-${index}`}>
                        <ul className='ul-routine'>
                            {/* 하루단위로 묶음 */}
                            <li>
                                <Typography variant='h5' className='routine-tit mb-8'>{formatDateRange(weekData.startOfWeek,weekData.endOfWeek)}</Typography>
                                {
                                    weekData.weeklyRoutine.length > 0 &&
                                    weekData.weeklyRoutine.map((routine,routineIdx)=> (
                                        <RoutineCard key={routineIdx} index={index} weeklyRoutine={routine} 
                                        routineCompleteChange={routineCompleteChange} startOfWeek={weekData.startOfWeek}/>
                                    ))
                                }
                            </li>
                        </ul>
                    </div>
                    ))
                    :
                    ''
                }
            </div>
            
        </>
    )
}

export default RoutineWeekly;
