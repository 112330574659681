import { file } from "googleapis/build/src/apis/file";
import { deleteData, getData, patchData, postData } from "../../../apis/api";
import { eventCount, eventType } from "../stores/eventType";
import Swal from "sweetalert2";

export const validateTitle = (title: string): boolean => {
    return !(title.length >= 1 && title.length <= 30);
}

export const validateTime = (time: string): string => {
    const isoDate = time;
    if (isoDate) {
        if (isoDate.split('T')[0] === undefined && isoDate.split('T')[1] === undefined) {
            return 'none date';
        }
    }
    return 'ok';
}

export const validateCalendarId = (calendarId: string): boolean => {
    if (calendarId === '선택없음') { 
        return true;
    }
    return false;
}

export const createEventInfo = async (values:eventType): Promise<any> => {
    try {  
        const response = postData<eventType>('/event', values);
        return response;

    } catch (error) {
        throw new Error('이벤트 생성에 실패하였습니다.');
    }
}

export const findEvent = async (idx:number|string) => {
    try {
        const response = await getData<eventType>('/event/' + idx);
        return response.data

    } catch (error) {
        throw new Error('이벤트 조회에 실패하였습니다.');
    }
}

export const createEventUsers = async (idx: number, emails: string[]) => {
    try {  
        const response = await postData<{ data: string }>(`event/invite/${idx}`, { emails });
        return response;
    } catch (error) {
        throw new Error('이벤트 회원 생성에 실패하였습니다.');
    }
}

export const cancelEventUser = async (idx: number, emails: string[]) => {
    try {
        const response = await postData<{ data: string }>(`event/cancel/${idx}`, { emails });
        return response;
    } catch (error) {
        throw new Error('초대 취소에 실패하였습니다');
    }
};

export const createEventFiles = async (inx: number, files: File[]): Promise<boolean> => {
    try {  
        const formData = new FormData();
        files.forEach((file, index) => {
            formData.append(`file`, file);
        });

        const response = await postData<string[]>('/event/insert/file/' + inx, formData, {
            headers: {'Content-Type': 'multipart/form-data'}
        });
        return true;

    } catch (error) {
        throw new Error('이벤트 파일 생성에 실패하였습니다.');
    }
}

export const updateEventInfo = async (values:eventType): Promise<any> => {
    try {  
        const response = await patchData<eventType>('/event', values);
        return response;

    } catch (error) {
        throw new Error('이벤트 생성에 실패하였습니다.');
    }
}

export const updateEventCalendar = async (eventIdx: number, calendarIdx: number) => {
    try {
        await patchData(`/event/calendar?eventIdx=${eventIdx}&calendarIdx=${calendarIdx}`)
    } catch (error) {
        throw new Error('캘린더 수정에 실패하였습니다.');
    }
};

export const deleteEvent = async (idx: number): Promise<void> => {
    try {
        const result = await Swal.fire({
            text: '삭제하시겠어요?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: '확인',
            cancelButtonText: '취소',
            reverseButtons: true
        });

        if (result.isConfirmed) {
            try {
                const response = await deleteData<eventType>(`/event/${idx}`);
                if (response.status === 202) {
                } else if (response.status === 404) {
                    await Swal.fire({
                        text: '삭제 권한이 없어요',
                        showCancelButton: false,
                        confirmButtonText: '돌아가기',
                    });
                } 
            } catch (error) {
                console.error('이벤트 삭제에 실패하였습니다:', error);
            }
        }
    } catch (error) {
        console.error('이벤트 삭제에 실패하였습니다:', error);
    }
};

export const deleteEventUser = async (idx: number): Promise<void> => {
    try {
        const result = await Swal.fire({
            text: '삭제하시겠어요?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: '확인',
            cancelButtonText: '취소',
            reverseButtons: true
        });

        if (result.isConfirmed) {
            try {
                const response = await deleteData<eventType>('/event/delete/' + idx);
                if (response.status === 202) {
                } else if (response.status === 404) {
                    await Swal.fire({
                        text: '삭제 권한이 없어요',
                        showCancelButton: false,
                        confirmButtonText: '돌아가기',
                    });
                } 
            } catch (error) {
                console.error('이벤트 삭제에 실패하였습니다:', error);
            }
        }
    } catch (error) {
        console.error('이벤트 삭제에 실패하였습니다:', error);
    }
};

export const deleteEventUserByCalendar = async (calendarIdx: number, eventIdx: number): Promise<void> => {
    try {
        await Swal.fire({
            text: '삭제하시겠어요?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: '확인',
            cancelButtonText: '취소',
            reverseButtons: true
        }).then(async (result) => {
            if (result.isConfirmed) {
                await deleteData<eventType>(`/event/delete/${calendarIdx}/${eventIdx}`);
            }
        });
    } catch (error) {
        throw new Error('이벤트 삭제에 실패하였습니다.');
    }
};

export const searchCalendar = async () => {
    try {
        const result:any = await getData<string[]>(`/calendar/find-all?page=0&size=10`);
        if(result.status === 200) return result.data;
    } catch (error) {
        console.error('Error fetching users:', error);
    }    
}

export const searchUser = async (values: string|undefined) => {
    try {
        const result:any = await getData<any>(`/user/search/list?search=${values}&page=${1}`);
        if(result.status === 200) return result.data;
    } catch (error) {
        console.error('Error fetching users:', error);
    }    
}

export const searchEvent = async (values: string|undefined) => {
    try {
        const result:any = await getData<eventType>(`/event/search/list?search=${values}`);
        if(result.status === 200) return result.data;
    } catch (error) {
        console.error('이벤트 검색에 실패하였습니다.', error);
    }    
}

export const monthlyEventCount = async (): Promise<eventCount> => {
    try {
        const response = await getData<eventCount>('/event/count');
        return response.data;
    } catch (error) {
        throw new Error('이벤트 조회에 실패하였습니다.');
    }
}